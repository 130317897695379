import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, TextField, Container, Paper } from '@mui/material';
import jobDescriptions from './jobDescriptions';
import IndividualFooter from './IndividualFooter';
import IndividualHeader from './IndividualHeader';

const SearchJob = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  // Page load animation
  useEffect(() => {
    const element = document.getElementById('searchJobContainer');
    element.style.opacity = 0;
    element.style.transition = 'opacity 1.2s ease';
    setTimeout(() => {
      element.style.opacity = 1;
    }, 100);
  }, []);

  // Handle search on Enter key
  const handleSearchSubmit = (event) => {
    if (event.key === 'Enter' && searchTerm) {
      navigate(`/results?query=${searchTerm}`);
    }
  };

  // Handle suggestion click
  const handleSuggestionClick = (jobTitle) => {
    navigate(`/results?query=${jobTitle}`);
  };

  // Suggested job descriptions
  const suggestions = jobDescriptions.slice(0, 4);

  return (
    <>
    <IndividualHeader />
    <Container
      maxWidth="md"
      id="searchJobContainer"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        width: '100%',
        opacity: 0, // Initially hidden for fade-in effect
      }}
    >
      {/* Gradient Header Text */}
      <Typography
        variant="h3"
        align="center"
        sx={{
          fontWeight: 'bold',
          mb: 2,
          '& span': {
            background: 'linear-gradient(90deg, rgb(255, 38, 0), #2E8B57)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
          },
        }}
      >
        Hi there!<br />
        What <span>would you like to know?</span>
      </Typography>

      {/* Instructions */}
      <Typography variant="body1" align="center" color="textSecondary" sx={{ mb: 3 }}>
        Use one of the most common job suggestions below or enter your own query to begin
      </Typography>

      {/* Suggested Job Descriptions */}
      <Box sx={{ display: 'flex', gap: 2, mb: 4, flexWrap: 'wrap', justifyContent: 'center' }}>
        {suggestions.map((job, index) => (
          <Paper
            key={index}
            elevation={3}
            sx={{
              padding: 2,
              width: '200px',
              textAlign: 'center',
              cursor: 'pointer',
              borderRadius: '8px',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0px 6px 15px rgba(255, 38, 0, 0.2)',
              },
            }}
            onClick={() => handleSuggestionClick(job.title)}
          >
            <Typography variant="body1" fontWeight="bold" color="black">
              {job.title}
            </Typography>
          </Paper>
        ))}
      </Box>

      {/* Search Box */}
      <Box
        sx={{
          width: '100%',
          maxWidth: '600px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        //   mb: 4,
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Ask whatever you want..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleSearchSubmit}
          sx={{
            bgcolor: '#ffffff',
            borderRadius: '10px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            transition: 'box-shadow 0.3s ease, border 0.3s ease',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#D1D5DB',
              },
              '&:hover fieldset': {
                borderColor: 'rgb(255, 38, 0)',
                boxShadow: '0px 6px 15px rgba(255, 38, 0, 0.15)',
              },
              '&.Mui-focused fieldset': {
                borderColor: 'rgb(255, 38, 0)',
              },
            },
          }}
        />
      </Box>
    </Container>
      <IndividualFooter />
      </>
  );
};

export default SearchJob;
