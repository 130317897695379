// import React, { useState, useEffect } from 'react';
// import { Box, Typography, Divider, Switch } from '@mui/material';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import CommonApi from '../../../apis/CommonApi';

// const SocialLoginItem = ({ icon, title, status, statusColor, checked, onToggle }) => (
//     <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ py: 1 }}>
//         <Box display="flex" alignItems="center" gap={2}>
//             {icon}
//             <Box>
//                 <Typography variant="body1" fontWeight="bold">
//                     {title}
//                 </Typography>
//                 <Typography variant="body2" color="textSecondary">
//                     {status}
//                 </Typography>
//             </Box>
//         </Box>
//         <Switch
//             checked={checked}
//             color={statusColor}
//             onChange={onToggle}
//             inputProps={{ 'aria-label': `${title} toggle` }}
//         />
//     </Box>
// );

// const SocialLogins = () => {
//     const [linkedInStatus, setLinkedInStatus] = useState({ enabled: false, name: 'Disabled' });

//     useEffect(() => {
//         const fetchSocialLoginStatus = async () => {
//             try {
//                 const data = await CommonApi.getSocialLoginStatus();
//                 setLinkedInStatus({
//                     enabled: data.linkedIn.enabled,
//                     name: data.linkedIn.enabled ? data.linkedIn.name : 'Disabled',
//                 });
//             } catch (error) {
//                 console.error("Error fetching social login status:", error);
//             }
//         };

//         fetchSocialLoginStatus();
//     }, []);

//     const handleLinkedInToggle = async () => {
//         if (linkedInStatus.enabled) {
//             await CommonApi.disableLinkedIn();
//             setLinkedInStatus({ enabled: false, name: 'Disabled' });
//         } else {
//             const response = await CommonApi.enableLinkedIn('Your LinkedIn Name');
//             setLinkedInStatus({ enabled: true, name: response.name });
//         }
//     };

//     return (
//         <Box>
//             <Typography variant="h6" component="h2" fontWeight="bold" sx={{ mb: 1 }}>
//                 Connect To
//             </Typography>

//             <Divider sx={{ my: 1 }} />

//             <SocialLoginItem
//                 icon={<LinkedInIcon sx={{ fontSize: 40, color: '#0A66C2' }} />}
//                 title="LinkedIn Account"
//                 status={linkedInStatus.name}
//                 statusColor={linkedInStatus.enabled ? 'error' : 'primary'}
//                 checked={linkedInStatus.enabled}
//                 onToggle={handleLinkedInToggle}
//             />

//             <Divider sx={{ my: 1 }} />

//             <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
//                 * If you disable social logins, you’ll still be able to log in using your email and password.
//             </Typography>
//         </Box>
//     );
// };

// export default SocialLogins;
import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider, Switch } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CommonApi from '../../../apis/CommonApi';

const SocialLoginItem = ({ icon, title, status, statusColor, checked, onToggle }) => (
  <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ py: 1 }}>
    <Box display="flex" alignItems="center" gap={2}>
      {icon}
      <Box>
        <Typography variant="body1" fontWeight="bold">{title}</Typography>
        <Typography variant="body2" color="textSecondary">{status}</Typography>
      </Box>
    </Box>
    <Switch
      checked={checked}
      color={statusColor}
      onChange={onToggle}
      inputProps={{ 'aria-label': `${title} toggle` }}
    />
  </Box>
);

const SocialLogins = () => {
  const [linkedInStatus, setLinkedInStatus] = useState({ enabled: false, name: 'Disabled' });

  useEffect(() => {
    const fetchSocialLoginStatus = async () => {
      try {
        const { data } = await CommonApi.getSocialLoginStatus();
        setLinkedInStatus({
          enabled: data.linkedIn.enabled,
          name: data.linkedIn.name
        });
      } catch (error) {
        console.error("Error fetching social login status:", error);
      }
    };

    fetchSocialLoginStatus();
  }, []);

  const handleLinkedInToggle = async () => {
    if (linkedInStatus.enabled) {
      // Disable LinkedIn
      await CommonApi.disableLinkedIn();
      setLinkedInStatus({ enabled: false, name: 'Disabled' });
    } else {
      // Enable LinkedIn
      const linkedInUrl = await CommonApi.getLinkedInAuthUrl();
      const width = 600;
      const height = 600;
      const left = (window.innerWidth - width) / 2;
      const top = (window.innerHeight - height) / 2;

      const popup = window.open(linkedInUrl, 'LinkedInAuth', `width=${width},height=${height},top=${top},left=${left}`);

      // Listen for LinkedIn authentication completion
      window.addEventListener("message", (event) => {
        if (event.origin !== window.location.origin) return; // Ensure message is from the same origin

        const linkedInData = event.data;
        if (linkedInData && linkedInData.name) {
          setLinkedInStatus({ enabled: true, name: linkedInData.name });
        }
      });
    }
  };

  return (
    <Box>
      <Typography variant="h6" component="h2" fontWeight="bold" sx={{ mb: 1 }}>
        Connect To
      </Typography>
      <Divider sx={{ my: 1 }} />
      <SocialLoginItem
        icon={<LinkedInIcon sx={{ fontSize: 40, color: '#0A66C2' }} />}
        title="LinkedIn Account"
        status={linkedInStatus.enabled ? linkedInStatus.name : 'Disabled'}
        statusColor={linkedInStatus.enabled ? 'success' : 'primary'}
        checked={linkedInStatus.enabled}
        onToggle={handleLinkedInToggle}
      />
      <Divider sx={{ my: 1 }} />
      <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
        * If you disable social logins, you’ll still be able to log in using your email and password.
      </Typography>
    </Box>
  );
};

export default SocialLogins;
