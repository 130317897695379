import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumb from '../../components/TestBreadcrumb';
import useStyles from "../../layouts/navbar/NavbarStyles"; 
import Logo from "../../assets/svg/TO Logo.svg"; 
import Profile from "../../assets/svg/profile.svg"; 
import CommonApi from '../../apis/CommonApi'; 
import LocalStorage from '../../utils/LocalStorage'; 
import MenuIcon from "@mui/icons-material/Menu"; 
import CloseIcon from "@mui/icons-material/Close"; 
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography } from "@mui/material";

const Navbar = () => {
  const classes = useStyles();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); 
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const [companyName, setCompanyName] = useState(''); 
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = LocalStorage.getAccessToken();
      try {
        const response = await CommonApi.getCurrentUser(token);
        setCompanyName(response.data.company.companyName); // Ensure correct path
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

      const fetchUserData = async () => {
        const token = LocalStorage.getAccessToken();
        try {
          const response = await CommonApi.getUserProfile(token);
          setUserData(response);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
  

    fetchUserDetails();
    fetchUserData();

    window.addEventListener('beforeunload', handleLogout); // Logout on tab/browser close

    return () => {
      window.removeEventListener('beforeunload', handleLogout);
    };
  }, []);

  const user = LocalStorage.getUserData();
  const isProductOwner = user && user.role === "platformOwner";
  const isCandidate = user && user.role === "candidate";


  const adminLinks = [
    { to: "/", text: "Dashboard"},
    { to: "/users", text: "Users Management" },
    { to: "/jobdescription", text: "Job Descriptions" },
    // { to: '/job_details', text: 'Views', icon: Newspaper },
    { to: "/chatbot", text: "Chatbot" },
  ];



  const superAdminLinks = [
    { to: "/dashboard", text: "Dashboard" },
    { to: "/company", text: "Company" },
  ];

  const candidateLinks = [
    { to: "/chatbot", text: "Interview"},
  ];


  const links = isProductOwner
  ? superAdminLinks
  : isCandidate
  ? candidateLinks
  : adminLinks;

  const handleProfileClick = () => {
    setOpenProfileDialog(true); 
    setIsDropdownOpen(false); 
  };

  const handleLogout = async () => {
    try {
      const response = await CommonApi.Logout();
      if (response.status === 200) {
        LocalStorage.clearAll();
        window.location.href = '/'; // Redirect to login page
      } else {
        console.error('Logout failed', response);
      }
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMenuLinkClick = (path) => {
    navigate(path);
    setIsMobileMenuOpen(false); 
  };

  return (
    <header className="w-full">
      <div className={classes.nav}>
        <img src={Logo} alt="Logo" className="h-8" />
        <div className="flex items-center space-x-4">
          <div className={classes.companyName}>{companyName}</div>
          <div className="relative inline-block">
            <img
              src={Profile} 
              alt="User Profile"
              className="h-8 w-8 rounded-full cursor-pointer" 
              onClick={handleDropdownToggle} 
            />
            {isDropdownOpen && (
              <div className={`absolute right-0 mt-2 w-40 bg-white shadow-lg rounded-md z-50`}>
                <div className="py-2">
                  <button onClick={handleProfileClick} className={classes.dropdownButton}>
                    My Profile
                  </button>
                  <button onClick={handleLogout} className={classes.dropdownButton}>
                    Logout
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* Hamburger Menu Icon for Mobile and Tablet */}
          <div className={classes.hamburgerMenu}>
            <button onClick={toggleMobileMenu}>
              {isMobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile and Tablet Menu */}
      {isMobileMenuOpen && (
        <nav className={classes.mobileMenu}>
          <Link to="/" className={classes.navLink} onClick={() => handleMenuLinkClick('/')}>Dashboard</Link>
          <Link to="/users" className={classes.navLink} onClick={() => handleMenuLinkClick('/users-management')}>Users Management</Link>
          <Link to="/jobdescription" className={classes.navLink} onClick={() => handleMenuLinkClick('/jobdescription')}>Job Descriptions</Link>
          <Link to="/chatbot" className={classes.navLink} onClick={() => handleMenuLinkClick('/chatbot')}>Chatbot</Link>
        </nav>
      )}

      {/* Desktop Navigation Menu - Only for large screens */}
      <div className={classes.navSection}>
        <nav className="hidden md:block">
          <div className={classes.navLinks}>
            {/* <Link to="/" className={classes.navLink}>Dashboard</Link>
            <Link to="/users" className={classes.navLink}>Users Management</Link>
            <Link to="/jobdescription" className={classes.navLink}>Job Descriptions</Link>
            <Link to="/chatbot" className={classes.navLink}>Chatbot</Link> */}
            {links.map((link, index) => (
              <Link to={link.to} className={classes.navLink}>{link.text}</Link>
            ))}
          </div>
        </nav>
      </div>

      <div className={classes.breadcrumbContainer}>
        <Breadcrumb />
      </div>

      {/* My Profile Dialog */}
      <Dialog open={openProfileDialog} onClose={() => setOpenProfileDialog(false)} fullWidth maxWidth="sm">
        <DialogTitle>My Profile</DialogTitle>
        <DialogContent>
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <img
              src={Profile} 
              alt="Profile"
              style={{ borderRadius: "50%", width: "80px", height: "80px" }}
            />
          </div>
          {userData && (
            <>
              <Typography variant="h6" align="center">{userData.username}</Typography>
              <Typography variant="body2" align="center" color="textSecondary">{userData.email}</Typography>
              {/* <TextField fullWidth label="Name" margin="normal" defaultValue={userData.name || "Jon"} />
              <TextField fullWidth label="Last Name" margin="normal" defaultValue="Doe" /> */}
              <TextField fullWidth label="Role" margin="normal" defaultValue={userData.role} />
              <TextField fullWidth label="Change Password" type="password" margin="normal" placeholder="New Password" />
            </>
          )}
          <div style={{ textAlign: "center", margin: "20px 0" }}>
            <Button variant="contained" color="primary">Click to replace</Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenProfileDialog(false)} color="secondary">Cancel</Button>
          <Button onClick={() => setOpenProfileDialog(false)} color="primary">Save Changes</Button>
        </DialogActions>
      </Dialog>
    </header>
  );
};

export default Navbar;
