import { Button as MuiButton } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ButtonStyles from "./ButtonStyles";

/* fun starts here */
function Button(props) {
    // assign useStyles to classes
    const classes = ButtonStyles();

    //based on props automatically it will take styles
    const { className, children,
        smallSave, mediumInvite, onboardBtn, brownMnRedCancel, brownMnSave, brownMnMediumSave,
        ...otherProps } = props;

    /**
     * When you called this function just you have to pass defined props for styles
     * ----------
     * if prop is not available create new class in CustomButtonStyles file for styles and add that class in
     * >props
     * >btnClasses
     * >Button.propTypes
     * ------------
     * ------------
     * @parem children
     * -------------
     * content of the button excepted type `node`
     * */

    // Assigning the styles to variables
    const btnClasses = classNames({
        [className]: className,
        [classes.smallSave]: smallSave, 
        [classes.mediumInvite]: mediumInvite,
        [classes.onboardBtn]: onboardBtn,
        [classes.brownMnRedCancel]: brownMnRedCancel,
        [classes.brownMnSave]: brownMnSave,
        [classes.brownMnMediumSave]: brownMnMediumSave,       
    });

    return (
        <MuiButton {...otherProps} className={btnClasses}>
            {children}
        </MuiButton>
    );
}

export default Button;

Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    smallSave: PropTypes.bool,    
    mediumInvite: PropTypes.bool,
    onboardBtn: PropTypes.bool,
    brownMnRedCancel: PropTypes.bool,
    brownMnSave: PropTypes.bool,
    brownMnMediumSave: PropTypes.bool,
};

