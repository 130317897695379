import * as React from "react";
import DashboardStyles from "../DasboardStyles";
import {
  Box,
  Slide,
  Dialog,
} from "@mui/material";
import CompanyAddIcon from "../../../assets/svg/add-company.svg";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AddCompanyForm from "./AddCompanyForm";
import CompanyList from "./CompanyList";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} timeout={500} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper ": {
    borderRadius: "16px",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ControlledAccordions() {
  const classes = DashboardStyles();
  const navigate = useNavigate();

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} timeout={500} />;
  });

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper ": {
      borderRadius: "16px",
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box sx={{ width: "100%" }}>
        <Box style={{ padding: "40px 10px 10px 30px" }}>
          <div
            style={{
              display: "flow",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <CompanyList />
            </div>
            {/* <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "14px",
                }}
              >
                <button
                  type="button"
                  style={{
                    all: "unset",
                    padding: "0px 12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    textAlign: "center",
                    fontSize: "16px",
                    fontFamily: "Nunito Sans, sans-serif",
                    fontWeight: "400",
                    minWidth: "150px",
                    height: "40px",
                    border: "1.5px solid rgba(12, 117, 235, 1)",
                    backgroundColor: "rgba(12, 117, 235, 1)",
                    borderRadius: "6px",
                    color: "#ffffff",
                  }}
                >
                  <img
                    src={CompanyAddIcon}
                    alt="Userplus"
                    style={{ margin: "0px 8px" }}
                  />{" "}
                  Add Company
                </button>
              </div>
            </div> */}
          </div>
        </Box>
      </Box>
    </Box>
  );
}
