import React, { useState, useEffect } from 'react';
import { Box, Grid, } from '@mui/material';
import LocalStorage from '../../../../../utils/LocalStorage';
import Input from '../../../../../components/input/Input';
import Text from '../../../../../components/customText/Text';
import { isValid, validate_emptyField, validate_charWithSpace } from "../../../../../components/Validation";
import Button from '../../../../../components/customButton/Button';
import { addErrorMsg, addSuccessMsg } from '../../../../../utils/utils';
import IndividualEmployeeAPI from '../../../../../apis/employee/addEmployee/IndividualEmployee';
import { useLocation } from 'react-router-dom';

export default function GeneralDetails(props) {
    // const {first_name, middle_name, last_name, dateOfBirth, gender, bloodGroup, maritalStatus} = props
    const { individualData } = props
    const bd = individualData ? individualData.basic_details : ''

    // const individualData = props
    const [state, setState] = useState({
        request_id: LocalStorage.uid(),
        first_name: "",
        middle_name: "",
        last_name: "",
        dob: "",
        gender: "",
        blood_group: "",
        marital_status: "",
    });


    const [isDisabled, setIsDisabled] = useState(true)
    const location = useLocation();

    const [error, setError] = useState({});

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
        handleValidations(e);
    };


    useEffect(() => {
        // let userData = {
        //     ...state,
        //     first_name,
        //     middle_name,
        //     last_name,
        //     dob,
        //     gender,
        //     blood_group,
        //     marital_status,
        // }
        // setState(userData);
        // console.log('hello useEffect', first_name)
        console.log(bd, "individualData");
        setState(bd)
    }, [bd]);


    const handleValidations = (e) => {
        let input = e.target;
        let err = error;
        switch (input.name || input.tagName) {
            case "first_name":
                err.first_name = validate_charWithSpace(input.value);
                break;
            case "middle_name":
                err.middle_name = validate_charWithSpace(input.value);
                break;
            case "lastName":
                err.lastName = validate_charWithSpace(input.value);
                break;
            case "dob":
                err.dob = validate_emptyField(input.value);
                break;
            case "gender":
                err.gender = validate_emptyField(input.value);
                break;
            case "blood_group":
                err.blood_group = validate_emptyField(input.value);
                break;
            case "marital_status":
                err.marital_status = validate_emptyField(input.value);
                break;
            default:
                break;
        }
        setError(err);
    }

    const validateAll = () => {
        let { first_name, middle_name, last_name, dob, gender, blood_group, marital_status, } = state;
        let errors = {};
        errors.first_name = validate_emptyField(first_name);
        errors.middle_name = validate_emptyField(middle_name);
        errors.last_name = validate_emptyField(last_name);
        errors.dob = validate_emptyField(dob);
        errors.gender = validate_emptyField(gender);
        errors.blood_group = validate_emptyField(blood_group);
        errors.marital_status = validate_emptyField(marital_status);
        return errors;

    };

    const handleSubmit = () => {
        let errors = validateAll();
        console.log('handleSubmit', handleSubmit)
        if (isValid(errors)) {
            updateGeneralDetails();
            console.log('no error')
        } else {
            console.log(errors);
            setError(errors);
        }
        setIsDisabled(!isDisabled)
    }

    const updateGeneralDetails = () => {
        let individualData = { ...state, request_id: LocalStorage.uid() };
        IndividualEmployeeAPI.updateGeneralDetails(individualData, location.state?.id).then((response) => {
            setTimeout(() => {
                if (response.individualData.statusCode == 1003) {
                    addSuccessMsg(response.individualData.message);
                } else {
                    addErrorMsg(response.individualData.message);
                }
            }, 400)
        });
    }


    return (
        <Box py={1}>
            <Grid container spacing={0}>

                <Grid lg={6} md={6} sm={12} xs={12}>
                    <Box p={1}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'first_name',
                                value: state.first_name,
                                type: 'text',
                            }}
                            disabled={isDisabled}
                            handleChange={handleChange}
                            clientInput
                            labelText={<Text largeLabel>First Name</Text>}
                        />
                        {!isDisabled && <Text errorText> {error.first_name ? error.first_name : ""}</Text>}
                    </Box>
                </Grid>
                <Grid lg={6} md={6} sm={12} xs={12}>
                    <Box p={1}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'middle_name',
                                value: state.middle_name,
                                type: 'text'
                            }}
                            disabled={isDisabled}
                            handleChange={handleChange}
                            clientInput
                            labelText={<Text largeLabel>Middle Name</Text>}
                        />
                        {!isDisabled && <Text errorText> {error.middle_name ? error.middle_name : ""}</Text>}
                    </Box>
                </Grid>
                <Grid lg={6} md={6} sm={12} xs={12}>
                    <Box p={1}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'last_name',
                                value: state.last_name,
                                type: 'text'
                            }}
                            disabled={isDisabled}
                            handleChange={handleChange}
                            clientInput
                            labelText={<Text largeLabel>Last Name</Text>}
                        />
                        {!isDisabled && <Text errorText> {error.last_name ? error.last_name : ""}</Text>}
                    </Box>
                </Grid>
                <Grid lg={6} md={6} sm={12} xs={12}>
                    <Box p={1}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'dob',
                                value: state.dob,
                                type: 'text'
                            }}
                            disabled={isDisabled}
                            handleChange={handleChange}
                            clientInput
                            labelText={<Text largeLabel>Date of Birth</Text>}
                        />
                        {!isDisabled && <Text errorText> {error.firstName ? error.firstName : ""}</Text>}
                    </Box>
                </Grid>
                <Grid lg={6} md={6} sm={12} xs={12}>
                    <Box p={1}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'gender',
                                value: state.gender,
                                type: 'text'
                            }}
                            disabled={isDisabled}
                            handleChange={handleChange}
                            clientInput
                            labelText={<Text largeLabel>Gender</Text>}
                        />
                        {!isDisabled && <Text errorText> {error.firstName ? error.firstName : ""}</Text>}
                    </Box>
                </Grid>
                <Grid lg={6} md={6} sm={12} xs={12}>
                    <Box p={1}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'blood_group',
                                value: state.blood_group,
                                type: 'text'
                            }}
                            disabled={isDisabled}
                            handleChange={handleChange}
                            clientInput
                            labelText={<Text largeLabel>Blood Group</Text>}
                        />
                        {!isDisabled && <Text errorText> {error.blood_group ? error.blood_group : ""}</Text>}
                    </Box>
                </Grid>
                <Grid lg={6} md={6} sm={12} xs={12}>
                    <Box p={1}>
                        <Input
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                name: 'marital_status',
                                value: state.marital_status,
                                type: 'text'
                            }}
                            disabled={isDisabled}
                            handleChange={handleChange}
                            clientInput
                            labelText={<Text largeLabel>Marital Status</Text>}
                        />
                        {!isDisabled && <Text errorText> {error.marital_status ? error.marital_status : ""}</Text>}
                    </Box>
                </Grid>
            </Grid>

            <Box my={2} display={"flex"} justifyContent={"end"}>
                <Button save onClick={() => handleSubmit()}>
                    {isDisabled === true ? 'Edit' : "Save"}
                </Button>
            </Box>

        </Box>
    );
}