// import {
//   addErrorMsg,
//   connectionFailed,
//   getAxios,
//   removeLoader,
// } from "../utils/utils";
// import axios from "axios";

// class BaseApi {
//   get(URL, loader) {
//     if (loader === undefined) {
//       // addLoader()
//     }
//     return getAxios()
//       .get(URL)
//       .then((res) => {
//         // removeLoader()
//         return res;
//       })
//       .catch((err) => {
//         if (err.code === "ERR_NETWORK") {
//           connectionFailed();
//           if (
//             window.navigator.onLine &&
//             !window.navigator.connection.effectiveType.endsWith("2g")
//           ) {
//             setTimeout(() => {
//               window.location.reload();
//             }, 100000);
//           }
//         } else if (err.response.data && err.response.data.statusCode === 1008) {
//           addErrorMsg(err.response.data.message);
//           removeLoader();
//           localStorage.clear();
//           window.location.reload();
//         } else {
//           removeLoader();
//         }
//         // throw new Error(err)
//       });
//   }
//   getWithParams(URL, token, loader) {
//     if (loader == undefined) {
//       // addLoader()
//     }
//     return getAxios()
//       .get(URL, {
//         headers: {
//           Authorization: `${token}`,
//         },
//       })
//       .then((res) => {
//         // removeLoader()
//         return res;
//       })
//       .catch((err) => {
//         if (err.code == "ERR_NETWORK") {
//           connectionFailed();
//           if (
//             window.navigator.onLine &&
//             !window.navigator.connection.effectiveType.endsWith("2g")
//           ) {
//             setTimeout(() => {
//               window.location.reload();
//             }, 100000);
//           }
//         } else if (err.response.data && err.response.data.statusCode == 1008) {
//           addErrorMsg(err.response.data.message);
//           removeLoader();
//           localStorage.clear();
//           window.location.reload();
//         } else {
//           removeLoader();
//         }
//         return err.response;
//         // throw new Error(err)
//       });
//   }
//   async getWithToken(URL, token, loader) {
//     if (loader == undefined) {
//       // addLoader()
//     }

//     try {
//       const res = await axios.get(URL, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       return res; // Ensure the response is returned correctly
//     } catch (err) {
//       if (err.code == "ERR_NETWORK") {
//         connectionFailed();
//         if (
//           window.navigator.onLine &&
//           !window.navigator.connection.effectiveType.endsWith("2g")
//         ) {
//           setTimeout(() => {
//             window.location.reload();
//           }, 100000);
//         }
//       } else if (
//         err.response &&
//         err.response.data &&
//         err.response.data.statusCode == 1008
//       ) {
//         addErrorMsg(err.response.data.message);
//         removeLoader();
//         localStorage.clear();
//         window.location.reload();
//       } else {
//         removeLoader();
//       }
//       throw err; // Ensure the error is thrown so it can be caught by the caller
//     }
//   }

//   postWithToken(url, data, token) {
//     return axios.post(url, data, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//   }

//   postWithData(url, data) {
//     return axios.post(url, data);
//   }
//   deleteWithToken(url, token) {
//     return axios.delete(url, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//   }

//   postWithData(url, data) {
//     return axios.post(url, data);
//   }

//   // postWithToken(url, data, token) {
//   //   return axios.post(url, data, {
//   //     headers: {
//   //       Authorization: `Bearer ${token}`,
//   //     },
//   //   });
//   // }

//   postFormData(URL, formData, token, loader) {
//     if (loader == undefined) {
//       // addLoader()
//     }
//     return getAxios()
//       .post(URL, formData, {
//         headers: {
//           Authorization: `${token}`,
//           "content-type": "multipart/form-data",
//         },
//       })
//       .then((res) => {
//         // removeLoader()
//         return res;
//       })
//       .catch((err) => {
//         if (err.code == "ERR_NETWORK") {
//           connectionFailed();
//           if (
//             window.navigator.onLine &&
//             !window.navigator.connection.effectiveType.endsWith("2g")
//           ) {
//             setTimeout(() => {
//               window.location.reload();
//             }, 100000);
//           }
//         } else if (err.response.data && err.response.data.statusCode == 1008) {
//           addErrorMsg(err.response.data.message);
//           removeLoader();
//           localStorage.clear();
//           window.location.reload();
//         } else {
//           removeLoader();
//         }
//         return err.response;
//         // throw new Error(err)
//       });
//   }

//   postWithData(URL, data, loader) {
//     if (loader == undefined) {
//       // addLoader()
//     }
//     return getAxios()
//       .post(URL, data)
//       .then((res) => {
//         // removeLoader()
//         return res;
//       })
//       .catch((err) => {
//         if (err.code == "ERR_NETWORK") {
//           connectionFailed();
//           if (
//             window.navigator.onLine &&
//             !window.navigator.connection.effectiveType.endsWith("2g")
//           ) {
//             setTimeout(() => {
//               window.location.reload();
//             }, 100000);
//           }
//         } else if (err.response.data && err.response.data.statusCode == 1008) {
//           addErrorMsg(err.response.data.message);
//           removeLoader();
//           localStorage.clear();
//           window.location.reload();
//         } else {
//           removeLoader();
//         }
//         return err.response;
//         // throw new Error(err)
//       });
//   }

//   putWithData(URL, data, loader) {
//     if (loader == undefined) {
//       // addLoader()
//     }
//     return getAxios()
//       .put(URL, data)
//       .then((res) => {
//         // removeLoader()
//         return res;
//       })
//       .catch((err) => {
//         if (err.code == "ERR_NETWORK") {
//           connectionFailed();
//           if (
//             window.navigator.onLine &&
//             !window.navigator.connection.effectiveType.endsWith("2g")
//           ) {
//             setTimeout(() => {
//               window.location.reload();
//             }, 100000);
//           }
//         } else if (err.response.data && err.response.data.statusCode == 1008) {
//           addErrorMsg(err.response.data.message);
//           removeLoader();
//           localStorage.clear();
//           window.location.reload();
//         } else {
//           removeLoader();
//         }
//         return err.response;
//         // throw new Error(err)
//       });
//   }
//   putWithToken(URL, data, token, loader) {
//     if (loader == undefined) {
//       // addLoader()
//     }
//     return getAxios()
//       .put(URL, data, {
//         headers: {
//           Authorization: `${token}`,
//         },
//       })
//       .then((res) => {
//         // removeLoader()
//         return res;
//       })
//       .catch((err) => {
//         if (err.code == "ERR_NETWORK") {
//           connectionFailed();
//           if (
//             window.navigator.onLine &&
//             !window.navigator.connection.effectiveType.endsWith("2g")
//           ) {
//             setTimeout(() => {
//               window.location.reload();
//             }, 100000);
//           }
//         } else if (err.response.data && err.response.data.statusCode == 1008) {
//           addErrorMsg(err.response.data.message);
//           removeLoader();
//           localStorage.clear();
//           window.location.reload();
//         } else {
//           removeLoader();
//         }
//         return err.response;
//         // throw new Error(err)
//       });
//   }
//   async deleteWithToken(URL, data, token, loader) {
//     if (loader == undefined) {
//       // addLoader()
//     }
//     try {
//       const res = await getAxios().delete(URL, {
//         headers: {
//           Authorization: `${token}`,
//         },
//         data,
//       });
//       return res;
//     } catch (err) {
//       if (err.code == "ERR_NETWORK") {
//         connectionFailed();
//         if (
//           window.navigator.onLine &&
//           !window.navigator.connection.effectiveType.endsWith("2g")
//         ) {
//           setTimeout(() => {
//             window.location.reload();
//           }, 100000);
//         }
//       } else if (err.response.data && err.response.data.statusCode == 1008) {
//         addErrorMsg(err.response.data.message);
//         removeLoader();
//         localStorage.clear();
//         window.location.reload();
//       } else {
//         removeLoader();
//       }
//       return err.response;
//     }
//   }
// }

// export default new BaseApi();

import {
  addErrorMsg,
  connectionFailed,
  getAxios,
  removeLoader,
} from "../utils/utils";
import axios from "axios";

class BaseApi {
  get(URL, loader) {
    if (loader == undefined) {
      // addLoader()
    }
    return getAxios()
      .get(URL)
      .then((res) => {
        // removeLoader()
        return res;
      })
      .catch((err) => {
        if (err.code == "ERR_NETWORK") {
          connectionFailed();
          if (
            window.navigator.onLine &&
            !window.navigator.connection.effectiveType.endsWith("2g")
          ) {
            setTimeout(() => {
              window.location.reload();
            }, 100000);
          }
        } else if (err.response.data && err.response.data.statusCode == 1008) {
          addErrorMsg(err.response.data.message);
          removeLoader();
          localStorage.clear();
          window.location.reload();
        } else {
          removeLoader();
        }
        // throw new Error(err)
      });
  }
  getWithParams(URL, token, loader) {
    if (loader == undefined) {
      // addLoader()
    }
    return getAxios()
      .get(URL, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        // removeLoader()
        return res;
      })
      .catch((err) => {
        if (err.code == "ERR_NETWORK") {
          connectionFailed();
          if (
            window.navigator.onLine &&
            !window.navigator.connection.effectiveType.endsWith("2g")
          ) {
            setTimeout(() => {
              window.location.reload();
            }, 100000);
          }
        } else if (err.response.data && err.response.data.statusCode == 1008) {
          addErrorMsg(err.response.data.message);
          removeLoader();
          localStorage.clear();
          window.location.reload();
        } else {
          removeLoader();
        }
        return err.response;
        // throw new Error(err)
      });
  }
  async getWithToken(URL, token, loader) {
    if (loader == undefined) {
      // addLoader()
    }

    try {
      const res = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res; // Ensure the response is returned correctly
    } catch (err) {
      if (err.code == "ERR_NETWORK") {
        connectionFailed();
        if (
          window.navigator.onLine &&
          !window.navigator.connection.effectiveType.endsWith("2g")
        ) {
          setTimeout(() => {
            window.location.reload();
          }, 100000);
        }
      } else if (
        err.response &&
        err.response.data &&
        err.response.data.statusCode == 1008
      ) {
        addErrorMsg(err.response.data.message);
        removeLoader();
        localStorage.clear();
        window.location.reload();
      } else {
        removeLoader();
      }
      throw err; // Ensure the error is thrown so it can be caught by the caller
    }
  }

  postWithToken(url, data, token) {
    console.log(
      "postWithToken called with URL:",
      url,
      "Data:",
      data,
      "Token:",
      token
    ); // Debugging log
    return axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  postWithData(url, data, headers = {}) {
    return axios.post(url, data, { headers });
  }

  // postWithToken(url, data, token) {
  //   return axios.post(url, data, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  // }

  postFormData(URL, formData, token, loader) {
    if (loader == undefined) {
      // addLoader()
    }
    return getAxios()
      .post(URL, formData, {
        headers: {
          Authorization: `${token}`,
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        // removeLoader()
        return res;
      })
      .catch((err) => {
        if (err.code == "ERR_NETWORK") {
          connectionFailed();
          if (
            window.navigator.onLine &&
            !window.navigator.connection.effectiveType.endsWith("2g")
          ) {
            setTimeout(() => {
              window.location.reload();
            }, 100000);
          }
        } else if (err.response.data && err.response.data.statusCode == 1008) {
          addErrorMsg(err.response.data.message);
          removeLoader();
          localStorage.clear();
          window.location.reload();
        } else {
          removeLoader();
        }
        return err.response;
        // throw new Error(err)
      });
  }

  postWithData(URL, data, loader) {
    if (loader == undefined) {
      // addLoader()
    }
    return getAxios()
      .post(URL, data)
      .then((res) => {
        // removeLoader()
        return res;
      })
      .catch((err) => {
        if (err.code == "ERR_NETWORK") {
          connectionFailed();
          if (
            window.navigator.onLine &&
            !window.navigator.connection.effectiveType.endsWith("2g")
          ) {
            setTimeout(() => {
              window.location.reload();
            }, 100000);
          }
        } else if (err.response.data && err.response.data.statusCode == 1008) {
          addErrorMsg(err.response.data.message);
          removeLoader();
          localStorage.clear();
          window.location.reload();
        } else {
          removeLoader();
        }
        return err.response;
        // throw new Error(err)
      });
  }

  putWithData(URL, data, loader) {
    if (loader == undefined) {
      // addLoader()
    }
    return getAxios()
      .put(URL, data)
      .then((res) => {
        // removeLoader()
        return res;
      })
      .catch((err) => {
        if (err.code == "ERR_NETWORK") {
          connectionFailed();
          if (
            window.navigator.onLine &&
            !window.navigator.connection.effectiveType.endsWith("2g")
          ) {
            setTimeout(() => {
              window.location.reload();
            }, 100000);
          }
        } else if (err.response.data && err.response.data.statusCode == 1008) {
          addErrorMsg(err.response.data.message);
          removeLoader();
          localStorage.clear();
          window.location.reload();
        } else {
          removeLoader();
        }
        return err.response;
        // throw new Error(err)
      });
  }
  async putWithToken(URL, data, token, loader) {
    if (loader == undefined) {
      // addLoader()
    }
    try {
      const res = await axios.put(URL, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'multipart/form-data',
        },
      });
      return res;
    } catch (err) {
      this.handleError(err);
      throw err;
    }
  }

  handleError(err) {
    if (err.code === "ERR_NETWORK") {
      connectionFailed();
      if (
        window.navigator.onLine &&
        !window.navigator.connection.effectiveType.endsWith("2g")
      ) {
        setTimeout(() => {
          window.location.reload();
        }, 100000);
      }
    } else if (err.response.data && err.response.data.statusCode === 1008) {
      addErrorMsg(err.response.data.message);
      removeLoader();
      localStorage.clear();
      window.location.reload();
    } else {
      removeLoader();
    }
  }

  async deleteWithToken(URL, data, token, loader) {
    if (loader == undefined) {
      // addLoader()
    }
    try {
      const res = await getAxios().delete(URL, {
        headers: {
          Authorization: `${token}`,
        },
        data,
      });
      return res;
    } catch (err) {
      if (err.code == "ERR_NETWORK") {
        connectionFailed();
        if (
          window.navigator.onLine &&
          !window.navigator.connection.effectiveType.endsWith("2g")
        ) {
          setTimeout(() => {
            window.location.reload();
          }, 100000);
        }
      } else if (err.response.data && err.response.data.statusCode == 1008) {
        addErrorMsg(err.response.data.message);
        removeLoader();
        localStorage.clear();
        window.location.reload();
      } else {
        removeLoader();
      }
      return err.response;
    }
  }
}

export default new BaseApi();
