import React, { useState, useRef } from 'react';
import {
    Box,
    Typography,
    Button,
    Container,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import IndividualHeader from '../IndividualHeader';
import { useNavigate } from 'react-router-dom';

const AssessmentConfirmation = () => {
    const [experience, setExperience] = useState('');
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [agreedToPrivacy, setAgreedToPrivacy] = useState(false);
    const instructionsRef = useRef(null);
    const sectionsRef = useRef(null);
    const confirmationRef = useRef(null);

    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const scrollToRef = (ref) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleStartSetup = () => {
        navigate('/assessment-environment-setup');
    };

    return (
        <>
            <IndividualHeader />
            <Container
                maxWidth="xl"
                sx={{
                    display: 'flex',
                    flexDirection: isSmallScreen ? 'column' : 'row',
                    mt: 8,
                    px: isSmallScreen ? 2 : 4,
                }}
            >
                {/* Left Fixed Column */}
                <Box
                    sx={{
                        width: isSmallScreen ? '100%' : '30%',
                        paddingRight: isSmallScreen ? 0 : 4,
                        borderRight: isSmallScreen ? 'none' : '1px solid #e0e0e0',
                        backgroundColor: '#f7f7f7',
                        paddingTop: 4,
                        position: isSmallScreen ? 'static' : 'fixed',
                        height: isSmallScreen ? 'auto' : '100vh',
                        mb: isSmallScreen ? 4 : 0,
                    }}
                >
                    <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                        Welcome to Frontend Developer (React) Role Certification Test
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary', mb: 4 }}>
                        Hey Nilesh Gupta,
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                        <strong>Test duration:</strong> 60 mins
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
                        <strong>No. of questions:</strong> 4 questions
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary', mb: 4 }}>
                        <a href="#" style={{ color: '#1d72b8' }}>Platform Help</a> | <a href="#" style={{ color: '#1d72b8' }}>Execution Environment</a> | <a href="#" style={{ color: '#1d72b8' }}>FAQ</a>
                    </Typography>
                </Box>

                {/* Right Scrollable Sections */}
                <Box
                    sx={{
                        width: isSmallScreen ? '100%' : '70%',
                        marginLeft: isSmallScreen ? 0 : '30%',
                        paddingLeft: isSmallScreen ? 0 : 4,
                        overflowY: isSmallScreen ? 'visible' : 'auto',
                        height: isSmallScreen ? 'auto' : '100vh',
                    }}
                >
                    {/* Instructions Section */}
                    <Box
                        ref={instructionsRef}
                        sx={{
                            height: isSmallScreen ? 'auto' : '100vh',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            px: isSmallScreen ? 2 : 0,
                            py: isSmallScreen ? 4 : 0,
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, color: '#2c3e50' }}>
                            Instructions
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            1. This is a timed test. Please make sure you are not interrupted during the test, as the timer cannot be paused once started.
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            2. Please ensure you have a stable internet connection.
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            3. We recommend you to try the <a href="#" style={{ color: '#1d72b8' }}>sample test</a> for a couple of minutes before taking the main test.
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            4. Before taking the test, please go through the <a href="#" style={{ color: '#1d72b8' }}>FAQs</a> to resolve your queries related to the test or the platform.
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2, mt: 3, flexDirection: isSmallScreen ? 'column' : 'row' }}>
                            <Button variant="contained" sx={{ backgroundColor: '#ff2600', borderRadius: '12px' }} onClick={() => scrollToRef(sectionsRef)}>
                                Continue
                            </Button>
                            <Button variant="outlined" sx={{ color: '#ff2600', borderColor: '#ff2600' }}>
                                Try Sample Test
                            </Button>
                        </Box>
                    </Box>

                    {/* Sections Section */}
                    <Box
                        ref={sectionsRef}
                        sx={{
                            height: isSmallScreen ? 'auto' : '100vh',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            px: isSmallScreen ? 2 : 0,
                            py: isSmallScreen ? 4 : 0,
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, color: '#2c3e50' }}>
                            Sections
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 3 }}>
                            There are 3 sections that are part of this test.
                        </Typography>
                        <TableContainer component={Paper} sx={{ mb: 3, width: isSmallScreen ? '100%' : '80%' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell><strong>NUMBER</strong></TableCell>
                                        <TableCell><strong>SECTION</strong></TableCell>
                                        <TableCell><strong>QUESTIONS</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>1</TableCell>
                                        <TableCell>React Project</TableCell>
                                        <TableCell>1</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>2</TableCell>
                                        <TableCell>JavaScript Coding</TableCell>
                                        <TableCell>1</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>3</TableCell>
                                        <TableCell>CSS MCQ</TableCell>
                                        <TableCell>2</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Button variant="contained" sx={{ backgroundColor: '#ff2600', borderRadius: '12px' }} onClick={() => scrollToRef(confirmationRef)}>
                            Continue
                        </Button>
                    </Box>

                    {/* Confirmation Form Section */}
                    <Box
                        ref={confirmationRef}
                        sx={{
                            height: isSmallScreen ? 'auto' : '100vh',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            px: isSmallScreen ? 2 : 0,
                            py: isSmallScreen ? 4 : 0,
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, color: '#2c3e50' }}>
                            Confirmation Form
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                            Before we start, here is some extra information we need to assess you better.
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            <strong>Logged in as</strong>: nilurdr@gmail.com
                        </Typography>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>Work Experience (in years)</InputLabel>
                            <Select
                                value={experience}
                                onChange={(e) => setExperience(e.target.value)}
                                label="Work Experience (in years)"
                            >
                                <MenuItem value="1">{"<1"}</MenuItem>
                                <MenuItem value="2">2</MenuItem>
                                <MenuItem value="3">3</MenuItem>
                                <MenuItem value="4">4</MenuItem>
                                <MenuItem value="5">{">5"}</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={agreedToTerms}
                                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                                />
                            }
                            label="I agree not to copy or refer code from any source, including colleagues, nor will I access any websites or AI tools to assist me with my answers."
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={agreedToPrivacy}
                                    onChange={(e) => setAgreedToPrivacy(e.target.checked)}
                                />
                            }
                            label="I agree to Optima AI's Terms of Service and Privacy policy."
                        />
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: '#ff2600', mt: 3, borderRadius: '12px', width: isSmallScreen ? '100%' : 'auto' }}
                            disabled={!experience || !agreedToTerms || !agreedToPrivacy}
                            onClick={handleStartSetup}
                        >
                            Continue
                        </Button>
                    </Box>
                </Box>
            </Container>
        </>
    );
};

export default AssessmentConfirmation;
