import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Slide,
  Button,
  Box,
  Tabs,
  Tab,
  CircularProgress,
  Alert,
  IconButton,
  Collapse,
  Avatar,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import PaginationComponent from "../../../components/customPagination/PaginationComponent";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import socket from "../../../utils/socket";
import CommonApi from "../../../apis/CommonApi";
import LocalStorage from "../../../utils/LocalStorage";
import noJobsGif from "../../../assets/no-data-available.gif"

const CompanyUsers = () => {
  const location = useLocation();
  const { users, companyName, companyId } = location.state || {
    users: [],
    companyName: "Unknown Company",
    companyId: null,
  };
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const [jobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openRow, setOpenRow] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);

  const loggedInUserEmail = LocalStorage.getUserData()?.user?.email || "";

  useEffect(() => {
    if (companyId) {
      const fetchJobs = async () => {
        setLoading(true);
        const token = LocalStorage.getAccessToken();
        try {
          const response = await CommonApi.getJobsByCompany(
            companyId,
            token,
            currentPage
          );
          setJobs(response.jobs || []);
          setTotalPages(response.totalPages || 0);
          setLoading(false);
        } catch (error) {
          setError(error);
          setLoading(false);
        }
      };
      fetchJobs();
    }
  }, [tabIndex, companyId, currentPage]);

  useEffect(() => {
    socket.on("updateUserStatus", (onlineUsers) => {
      setOnlineUsers(onlineUsers);
    });

    return () => {
      socket.off("updateUserStatus");
    };
  }, []);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const calculateJobStatus = (postingDate) => {
    const now = new Date();
    const postDate = new Date(postingDate);
    const diffTime = now.getTime() - postDate.getTime();
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <= 5) {
      return { text: "Active", color: "green" };
    } else if (diffDays <= 30) {
      return { text: "InActive", color: "red" };
    } else {
      return { text: "InActive", color: "red" };
    }
  };

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Container>
        {/* Back Button with Arrow */}
        <Box display="flex" alignItems="center" mb={2}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(-1)}
            sx={{
              textTransform: "none",
              fontSize: "1rem",
              color: "#1a1a1a",
              fontWeight: "bold",
            }}
          >
            Back to Companies
          </Button>
        </Box>

        {/* Tabs for User List and Job Descriptions */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            aria-label="company details tabs"
          >
            <Tab label="User List" />
            <Tab label="Job Descriptions" />
          </Tabs>
        </Box>

        {tabIndex === 0 && (
          <Box mt={2}>
            {users.length > 0 ? (
              <TableContainer component={Paper} elevation={3}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Role</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user) => (
                      <TableRow key={user._id} hover>
                        <TableCell>
                          <FiberManualRecordIcon
                            style={{
                              color: onlineUsers.includes(user.email)
                                ? "green"
                                : "red",
                            }}
                          />
                        </TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.role}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="200px"
              >
                <Typography variant="h6" color="textSecondary">
                  No users found for this company.
                </Typography>
              </Box>
            )}
          </Box>
        )}

        {tabIndex === 1 && (
          <Box mt={2}>
            {loading ? (
              <CircularProgress />
            ) : error ? (
              <Alert severity="error">{error.message}</Alert>
            ) : jobs.length === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                height="50vh" // Adjust height as needed
              >
                <img
                  src={noJobsGif}
                  alt="No jobs found"
                  style={{ width: '200px', marginBottom: '16px' }} // Adjust size and spacing as needed
                />
                <Typography variant="h6">
                  No jobs found for this company.
                </Typography>
              </Box>
            ) : (
              <>
                <TableContainer component={Paper} elevation={3}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Role Name</TableCell>
                        <TableCell>Experience</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {jobs.map((job) => (
                        <React.Fragment key={job._id}>
                          <TableRow hover>
                            <TableCell>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() =>
                                  setOpenRow(
                                    openRow === job._id ? null : job._id
                                  )
                                }
                              >
                                {openRow === job._id ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell>{job.roleName}</TableCell>
                            <TableCell>{job.experience}</TableCell>
                            <TableCell>{job.address?.city}</TableCell>
                            <TableCell>
                              <Typography
                                style={{
                                  color: calculateJobStatus(job.createdAt)
                                    .color,
                                }}
                              >
                                {calculateJobStatus(job.createdAt).text}
                              </Typography>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              colSpan={6}
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                            >
                              <Collapse
                                in={openRow === job._id}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box margin={1}>
                                  <Typography variant="body1" gutterBottom>
                                    {job.description}
                                  </Typography>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <PaginationComponent
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </>
            )}
          </Box>
        )}
      </Container>
    </Slide>
  );
};

export default CompanyUsers;
