// import { makeStyles } from "@mui/styles";
 
// const useStyles = makeStyles((theme) => ({
//   jobDetailsContent: {
//     justifyContent: "center",
//     alignItems: "center",
//     flexDirection: "column",
//     height: "100vh",
//     marginTop: "30px",
//     backgroundColor: "white",
//   },
//   tableContainer: {
//     width: "100%",
//     maxWidth: "1350px !important",
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//     borderRadius: "8px",
//     overflow: "hidden",
//   },
//   jobDetailsHead: {
//     fontWeight: "bold",
//     fontSize: "24px",
//     marginBottom: "20px",
//     textAlign: "center",
//     color: "#333",
//     backgroundColor: "orange",
//   },
//   actionButtons: {
//     display: "flex",
//     justifyContent: "center",
//     gap: "8px",
//   },
//   statusButton: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     borderRadius: "8px",
//     padding: "4px 12px",
//     fontWeight: "bold",
//     textTransform: "uppercase",
//   },
//   closedStatus: {
//     backgroundColor: "#f8d7da",
//     color: "#721c24",
//   },
  
//   openStatus: {
//     backgroundColor: "#d4edda",
//     color: "#155724",
//   },
//   filterBox: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     width: "100%",
//     marginBottom: "20px",
//     backgroundColor: "#fff",
//     padding: "16px",
//     borderRadius: "8px",
//     boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
//   },
//   createUploadBtns:{
//   backgroundColor: "lightgray",
//   },
//   searchBox: {
//     display: "flex",
//     alignItems: "center",
//     backgroundColor: "#f5f5f5",
//     borderRadius: "8px",
//     padding: "0 8px",
//     marginRight: "16px",
//   },
 
//   filterButton: {
//     borderColor: "#f5f5f5",
//     color: "#555",
//     backgroundColor: "#f5f5f5",
//     borderRadius: "8px",
//     padding: "6px 12px",
//     fontSize: "14px",
//     textTransform: "none",
//     fontWeight: "bold",
//     "&:hover": {
//       backgroundColor: "#f0f0f0",
//       borderColor: "#f0f0f0",
//     },
//   },
//   tableContent:{
//     border: '2px solid #ff2600', 
//     borderRadius: '4px', 
//     overflow: 'hidden',
//     borderTopLeftRadius: '0px', 
//      borderTopRightRadius: '0px' 
//   } ,
//   sortControl: {
//     backgroundColor: "#f5f5f5",
//     borderRadius: "8px",
//     "& .MuiOutlinedInput-root": {
//       padding: "0 12px",
//     },
//     "& .MuiSelect-select": {
//       padding: "6px 0px",
//       fontSize: "14px",
//       fontWeight: "bold",
//       color: "#555",
//     },
//     "& .MuiOutlinedInput-notchedOutline": {
//       border: "none",
//     },
//   },
//   agHeaderCell: {
//     backgroundColor: 'lightgrey', // Light grey color for the header
//     fontWeight: 'bold', // Bold font for better visibility
//     color: '#333', // Darker text color for contrast
//   },
// }));
 
// export default useStyles;

// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles(() => ({
//   jobDetailsContent: {
//     justifyContent: "center",
//     alignItems: "center",
//     flexDirection: "column",
//     height: "100vh",
//     marginTop: "30px",
//     backgroundColor: "white",
//   },
//   tableContainer: {
//     width: "100%",
//     maxWidth: "1350px !important",
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//     borderRadius: "8px",
//     overflow: "hidden",
//   },
//   jobDetailsHead: {
//     fontWeight: "bold",
//     fontSize: "24px",
//     marginBottom: "20px",
//     textAlign: "center",
//     color: "#333",
//     backgroundColor: "orange",
//   },
//   actionButtons: {
//     display: "flex",
//     justifyContent: "center",
//     gap: "8px",
//   },
//   statusButton: {
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     borderRadius: "8px",
//     padding: "4px 12px",
//     fontWeight: "bold",
//     textTransform: "uppercase",
//   },
//   closedStatus: {
//     backgroundColor: "#f8d7da",
//     color: "#721c24",
//   },
//   openStatus: {
//     backgroundColor: "#d4edda",
//     color: "#155724",
//   },
//   filterBox: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     width: "100%",
//     marginBottom: "20px",
//     backgroundColor: "#fff",
//     padding: "16px",
//     borderRadius: "8px",
//     boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
//   },
//   createUploadBtns: {
//     backgroundColor: "lightgray",
//   },
//   searchBox: {
//     display: "flex",
//     alignItems: "center",
//     backgroundColor: "#f5f5f5",
//     borderRadius: "8px",
//     padding: "0 8px",
//     marginRight: "16px",
//   },
//   filterButton: {
//     borderColor: "#f5f5f5",
//     color: "#555",
//     backgroundColor: "#f5f5f5",
//     borderRadius: "8px",
//     padding: "6px 12px",
//     fontSize: "14px",
//     textTransform: "none",
//     fontWeight: "bold",
//     "&:hover": {
//       backgroundColor: "#f0f0f0",
//       borderColor: "#f0f0f0",
//     },
//   },
//   tableContent: {
//     border: '2px solid #ff2600', 
//     borderRadius: '4px', 
//     overflow: 'auto', // Ensure the table content is scrollable on smaller screens
//     borderTopLeftRadius: '0px', 
//     borderTopRightRadius: '0px',
//   },
//   sortControl: {
//     backgroundColor: "#f5f5f5",
//     borderRadius: "8px",
//     "& .MuiOutlinedInput-root": {
//       padding: "0 12px",
//     },
//     "& .MuiSelect-select": {
//       padding: "6px 0px",
//       fontSize: "14px",
//       fontWeight: "bold",
//       color: "#555",
//     },
//     "& .MuiOutlinedInput-notchedOutline": {
//       border: "none",
//     },
//   },
//   agHeaderCell: {
//     backgroundColor: 'lightgrey', 
//     fontWeight: 'bold',
//     color: '#333', 
//   },
//   paginationWrapper: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     marginTop: '16px',
//     '@media (max-width: 600px)': { 
//       flexDirection: 'column',
//       width: '100%',
//     },
//   },
//   paginationControls: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     width: '100%',
//     '@media (max-width: 600px)': {
//       flexDirection: 'column',
//       alignItems: 'center',
//     },
//     '& .ag-paging-panel': {
//       fontSize: '12px',
//       padding: '4px 8px',
//     },
//     '& .ag-paging-button': {
//       fontSize: '12px',
//       padding: '4px 6px',
//     },
//     '& .ag-paging-page-summary-panel': {
//       fontSize: '12px',
//     },
//   },
//   "@media (max-width: 600px)": {
//     tableContainer: {
//       width: "100%",
//       overflowX: "scroll", // Ensure horizontal scrolling if table is wider than the screen
//     },
//     tableContent: {
//       overflowX: "auto",
//     },
//     jobDetailsHead: {
//       fontSize: "18px",
//     },
//   },
//   "@media (max-width: 320px)": { // Specific styles for very small screens (320px width)
//     tableContainer: {
//       width: "100%",
//       overflowX: "scroll", // Ensure horizontal scrolling if table is wider than the screen
//     },
//     paginationWrapper: {
//       flexDirection: "column", // Stack pagination controls vertically
//     },
//     paginationControls: {
//       fontSize: "10px", // Make pagination controls even smaller
//       '& .ag-paging-panel': {
//         fontSize: '8px',
//       },
//       '& .ag-paging-button': {
//         fontSize: '6px',
//         padding: '2px 4px',
//       },
//       '& .ag-paging-page-summary-panel': {
//         fontSize: '2px',
//       },
//     },
//     tableContent: {
//       fontSize: "12px", // Smaller font size for table data
//     },
//     actionButtons: {
//       flexDirection: "column",
//       gap: "4px",
//     },
//     jobDetailsHead: {
//       fontSize: "16px",
//     },
//   },
// }));

// export default useStyles;

// //center of the pagination 
// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles(() => ({
//   jobDetailsContent: {
//     justifyContent: "center",
//     alignItems: "center",
//     flexDirection: "column",
//     height: "100vh",
//     marginTop: "30px",
//     backgroundColor: "white",
//   },
//   tableContainer: {
//     width: "100%",
//     maxWidth: "1350px !important",
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//     borderRadius: "8px",
//     overflow: "hidden",
//   },
//   tableContent: {
//     border: '2px solid #ff2600', 
//     borderRadius: '4px', 
//     overflow: 'auto',
//     borderTopLeftRadius: '0px', 
//     borderTopRightRadius: '0px',
//   },
//   paginationWrapper: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     padding: '10px 0',
//     '@media (max-width: 600px)': {
//       flexDirection: 'column',
//     },
//   },
//   // Custom override for the ag-paging-panel to adjust margins
//   '@global': {
//     '.ag-paging-panel': {
//       margin: '0 !important', // Override default margin with custom setting
//       padding: '0 8px !important', // Adjust padding if needed
//       display: 'flex',
//       justifyContent: 'center',
//     },
//     // Adjust buttons within the pagination for better alignment
//     '.ag-paging-button': {
//       margin: '0 4px', // Add spacing between buttons
//     }
//   },
// }));

// export default useStyles;

// //pagination not fixed 1024px mobile screen export button
// import { makeStyles } from "@mui/styles";
// import { useTheme } from "@mui/material";

// const useStyles = makeStyles(() => ({
//   jobDetailsContent: {
//     justifyContent: "center",
//     alignItems: "center",
//     flexDirection: "column",
//     height: "100vh",
//     marginTop: "30px",
//     backgroundColor: "white",
  
//     [useTheme().breakpoints.up("lg")]: { 
//       maxWidth: "98% !important", 
//       margin: '16px !important',
//     },
//     [useTheme().breakpoints.down("lg")]: { 
//       maxWidth: "1000px !important", 
//       margin: '13px !important',
//     },
//     [useTheme().breakpoints.down("md")]: { 
//       maxWidth: "750px !important", 
//       margin: '10px !important',
//     },
//     [useTheme().breakpoints.down("sm")]: {
//       maxWidth: "90% !important", 
//       margin: '20px !important',
//     }, 
//   },

//   CreateJobDesBtn:{
//     paddingRight: '0px !important',
//     textAlign: 'center !important',
//     [useTheme().breakpoints.up("lg")]: { 
//       width: '170px !important', 
//       fontSize: '14px !important',
//       paddingRight: '0px !important',
//     },
//     [useTheme().breakpoints.down("lg")]: { 
//       width: '130px !important', 
//       fontSize: '10px !important',
//       paddingRight: '0px !important',
//     },
//     [useTheme().breakpoints.down("sm")]: { 
//       width: '130px !important', 
//       fontSize: '10px !important',
//       paddingRight: '0px !important',
//     },
//   },
//   tableContainer: {
//     width: "100%",
//     maxWidth: "1350px !important",
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//     borderRadius: "8px",
//     overflow: "hidden",
//     [useTheme().breakpoints.up("lg")]: { 
//       width: "1600px", 
//     },
//   },
//   tableContent: {
//     border: '2px solid #ff2600', 
//     borderRadius: '4px', 
//     overflow: 'auto',
//     borderTopLeftRadius: '0px', 
//     borderTopRightRadius: '0px',
//     // [useTheme().breakpoints.up("lg")]: { // Adjust for tablet and mobile screens
//     //   width: "1900px", // Hide orange header on tablet and mobile
//     // },
//   },
//   paginationWrapper: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     padding: '10px 0',
//     '@media (max-width: 600px)': {
//       flexDirection: 'column', // Stack pagination vertically for smaller screens
//     },
//   },
//   paginationControls: {
//     display: 'flex',
//     justifyContent: 'space-around',
//     alignItems: 'center',
//     width: '100%',
//     fontSize: '12px',
//     '& .ag-paging-panel': {
//       fontSize: '12px',
//       padding: '4px 8px',
//     },
//     '& .ag-paging-button': {
//       fontSize: '12px',
//       padding: '4px 6px',
//     },
//     '& .ag-paging-page-summary-panel': {
//       display: 'none !important', // Hide the "1 to 8 of 10" text
//     },
//     '@media (max-width: 600px)': {
//       flexDirection: 'column', // Stack pagination controls on small screens
//       '& .ag-paging-panel': {
//         fontSize: '10px',
//       },
//       '& .ag-paging-button': {
//         fontSize: '10px',
//         padding: '2px 4px',
//       },
//     },
//   },
//   '@global': {
//     '.ag-paging-panel': {
//       display: 'flex',
//       justifyContent: 'center !important', // Center pagination
//       margin: '0px !important', // Override default margin
//       padding: '0px !important', // Adjust padding if needed
//     },
//     '.ag-paging-panel > *': {
//       margin: '0px !important', // Force child elements to have no margin
//     },
//     // Ensure pagination buttons are aligned properly
//     '.ag-paging-button': {
//       margin: '0 5px !important', // Space buttons properly
//       fontSize: '12px !important',
//     },
//     // Ensure for very small screens like 320px
//     '@media (max-width: 320px)': {
//       '.ag-paging-panel': {
//         flexDirection: 'column', // Stack pagination controls vertically for mobile
//       },
//       '.ag-paging-button': {
//         margin: '2px 0px !important', // Narrow the button margin for smaller screens
//         padding: '4px !important', // Reduce button padding
//         fontSize: '10px !important', // Smaller text size
//       },
//     },
//   },
// }));

// export default useStyles;


import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";

const useStyles = makeStyles(() => ({
  jobDetailsContent: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100vh",
    marginTop: "30px",
    backgroundColor: "white",
    [useTheme().breakpoints.up("lg")]: { 
      maxWidth: "98% !important", 
      margin: '16px !important',
    },
    [useTheme().breakpoints.down("lg")]: { 
      maxWidth: "1000px !important", 
      margin: '13px !important',
    },
    [useTheme().breakpoints.down("md")]: { 
      maxWidth: "750px !important", 
      margin: '10px !important',
    },
    [useTheme().breakpoints.down("sm")]: {
      maxWidth: "90% !important", 
      margin: '20px !important',
    }, 
  },

  CreateJobDesBtn:{
    paddingRight: '0px !important',
    textAlign: 'center !important',
    [useTheme().breakpoints.up("lg")]: { 
      width: '170px !important', 
      fontSize: '14px !important',
      paddingRight: '0px !important',
    },
    [useTheme().breakpoints.down("lg")]: { 
      width: '130px !important', 
      fontSize: '10px !important',
      paddingRight: '0px !important',
    },
    [useTheme().breakpoints.down("sm")]: { 
      width: '130px !important', 
      fontSize: '10px !important',
      paddingRight: '0px !important',
    },
  },
  tableContainer: {
    width: "100%",
    maxWidth: "1350px !important",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
    [useTheme().breakpoints.up("lg")]: { 
      width: "1600px", 
    },
  },
  tableContent: {
    border: '2px solid #ff2600', 
    borderRadius: '4px', 
    overflow: 'auto',
    borderTopLeftRadius: '0px', 
    borderTopRightRadius: '0px',
  },
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0',
    '@media (max-width: 600px)': {
      flexDirection: 'column', // Stack pagination vertically for smaller screens
    },
  },
  paginationControls: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    fontSize: '12px',
    '& .ag-paging-panel': {
      fontSize: '12px',
      padding: '4px 8px',
    },
    '& .ag-paging-button': {
      fontSize: '12px',
      padding: '4px 6px',
    },
    '& .ag-paging-page-summary-panel': {
      display: 'none !important', // Hide the "1 to 8 of 10" text
    },
    '@media (max-width: 600px)': {
      flexDirection: 'column', // Stack pagination controls on small screens
      '& .ag-paging-panel': {
        fontSize: '10px',
      },
      '& .ag-paging-button': {
        fontSize: '10px',
        padding: '2px 4px',
      },
    },
  },
  '@global': {
    '.ag-paging-panel': {
      display: 'flex',
      justifyContent: 'center !important', 
      margin: '0px !important',
      padding: '0px !important', 
    },
    '.ag-paging-panel > *': {
      margin: '0px !important',
    },
    '.ag-paging-button': {
      margin: '0 5px !important',
      fontSize: '12px !important',
    },
    '@media (max-width: 320px)': {
      '.ag-paging-panel': {
        flexDirection: 'column',
      },
      '.ag-paging-button': {
        margin: '2px 0px !important',
        padding: '4px !important',
        fontSize: '10px !important',
      },
    },
  },
}));

export default useStyles;
