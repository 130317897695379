import React from 'react'
import CreateJobDescription from './CreateJobDescription'
import JobDescriptionStepper from './JobDescriptionStepper'

function JobDescription() {
  return (
    <div>
      {/* <CreateJobDescription /> */}
      <JobDescriptionStepper />
    </div>
  )
}

export default JobDescription