import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Container, Divider } from '@mui/material';
import { green } from '@mui/material/colors';
import IndividualHeader from '../IndividualHeader';

const questions = [
    { id: 1, name: 'React App', type: 'Frontend' },
    { id: 2, name: 'FizzBuzz', type: 'Coding' },
    { id: 3, name: 'Good URI Design', type: 'Multiple Choice' },
];

const AssessmentTestPage = () => {
    const [timer, setTimer] = useState(3000); // example timer in seconds
    const [answeredCount, setAnsweredCount] = useState(0);

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => setTimer(timer - 1), 1000);
            return () => clearInterval(interval);
        }
    }, [timer]);

    const handleSolve = () => {
        setAnsweredCount(answeredCount + 1);
    };

    return (
        <>
            <IndividualHeader />
            <Container maxWidth="xl" sx={{ mt: { xs: 8, md: 12 }, mb: { xs: 6, md: 10 } }}>
            {/* Header with Timer and Answered Count */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 4,
                    backgroundColor: '#1A202C',
                    color: '#fff',
                    p: 2,
                    borderRadius: '8px',
                }}
            >
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Sample Test</Typography>
                <Typography variant="body2">
                    Answered: {answeredCount} / {questions.length} | {Math.floor(timer / 60)} mins
                </Typography>
            </Box>

            {/* Question List */}
            <Box sx={{ backgroundColor: '#f7f7f7', borderRadius: '8px', p: 2 }}>
                <Typography variant="h6" sx={{ color: '#2c3e50', fontWeight: 'bold', mb: 2 }}>
                    Questions
                </Typography>
                <Divider sx={{ mb: 2 }} />
                {questions.map((question) => (
                    <Box
                        key={question.id}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2,
                            p: 2,
                            border: '1px solid #e0e0e0',
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                        }}
                    >
                        <Box>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                {question.id}. {question.name}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {question.type}
                            </Typography>
                        </Box>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#ff2600',
                                // '&:hover': { backgroundColor: '#ff2688' },
                                borderRadius: '12px',
                                color: 'white',
                                fontWeight: 'bold',
                                minWidth: '100px',
                            }}
                            onClick={handleSolve}
                        >
                            Solve
                        </Button>
                    </Box>
                ))}
            </Box>

            {/* Submit Button */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
                <Button
                    variant="outlined"
                    sx={{
                        borderColor: '#ff2600',
                        color: '#ff2600',
                        fontWeight: 'bold',
                        borderRadius: '12px',
                        '&:hover': { borderColor: '#ff2600', backgroundColor: 'rgba(255, 38, 0, 0.1)' },
                    }}
                    onClick={() => alert('Test Submitted')}
                >
                    Submit Test
                </Button>
            </Box>
        </Container>
        </>
    );
};

export default AssessmentTestPage;
