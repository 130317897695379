// VerifyEmail.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CircularProgress, Box, Typography, Button } from '@mui/material';
import CommonApi from '../../../apis/CommonApi';

const VerifyEmail = () => {
    const navigate = useNavigate();

    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        <Typography variant="h4" gutterBottom>
          Email Verified Successfully!
        </Typography>
        <Typography variant="body1" gutterBottom>
          Your email has been verified. You can now access all features.
        </Typography>
        <Button variant="contained" color="primary" onClick={() => navigate('/login')}>
          Go to Login
        </Button>
      </Box>
    );
};

export default VerifyEmail;
