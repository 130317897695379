import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './v2/App'; // Ensure the path is correct

const rootElement = document.getElementById('root'); // Make sure this ID matches the HTML file

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement); // Correct usage for React 18
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  console.error("Root element not found"); // This will log if the root element isn't found
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
