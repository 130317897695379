// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import CommonApi from '../../apis/CommonApi';
// import Main from '../admin/Main';

// const CandidateInterviewPage = () => {
//   const { token } = useParams();
//   const [jobDescription, setJobDescription] = useState('');
//   const [resume, setResume] = useState(null);

//   useEffect(() => {
//     const fetchInterviewDetails = async () => {
//       try {
//         const { jobDescription, resume } = await CommonApi.getInterviewDetails(token);
//         setJobDescription(jobDescription);
//         setResume(resume);
//       } catch (error) {
//         console.error('Error fetching interview details:', error);
//       }
//     };

//     fetchInterviewDetails();
//   }, [token]);

//   return (
//     <div>
//       <Main jobDescription={jobDescription} resume={resume} />
//     </div>
//   );
// };

// export default CandidateInterviewPage;


import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CommonApi from '../../apis/CommonApi';
import Main from '../admin/Main';
import { Modal, Box, Typography, Button, TextField } from '@mui/material';

const CandidateInterviewPage = () => {
  const { token } = useParams();
  const [jobDescription, setJobDescription] = useState('');
  const [resume, setResume] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [uniqueKey, setUniqueKey] = useState('');
  const [isKeyValid, setIsKeyValid] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchInterviewDetails = async () => {
      try {
        const { jobDescription, resume } = await CommonApi.getInterviewDetails(token);
        setJobDescription(jobDescription);
        setResume(resume);
      } catch (error) {
        console.error('Error fetching interview details:', error);
      }
    };
    fetchInterviewDetails();
  }, [token]);

  const handleSystemTest = () => {
    // Perform basic system checks (e.g., microphone and camera)
    // This is a placeholder for actual tests, which might involve checking permissions or media devices
    console.log('System test completed');
  };

  const handleKeySubmit = async () => {
    try {
      // Verify the unique key using an API call
      console.log('Submitting unique key:', uniqueKey); // Log the submitted key
      const response = await CommonApi.verifyInterviewKey(uniqueKey);
      console.log('Response from verifyInterviewKey:', response); // Log the response
  
      if (response.isValid) {
        setIsKeyValid(true);
        setShowModal(false); // Close modal only if key is valid
      } else {
        setError(response.error || 'Invalid key. Please try again.');
      }
    } catch (err) {
      console.error('Error verifying key:', err);
      setError('Error verifying key. Please try again.');
    }
  };
  

  return (
    <div>
      <Main jobDescription={jobDescription} resume={resume} isInterviewStarted={isKeyValid} />
      <Modal open={showModal}>
        <Box p={4} bgcolor="background.paper" borderRadius={3} boxShadow={3}>
          <Typography variant="h6">System Test & Key Verification</Typography>
          <Button onClick={handleSystemTest} variant="contained" color="primary">
            Start System Test
          </Button>
          <TextField
            label="Enter Unique Interview Key"
            value={uniqueKey}
            onChange={(e) => setUniqueKey(e.target.value)}
            fullWidth
            margin="normal"
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button onClick={handleKeySubmit} variant="contained" color="primary">
            Submit Key
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default CandidateInterviewPage;
