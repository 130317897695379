import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import SearchCandidates from './SearchCandidate';
import CreateJobByEmployer from './CreateJobByEmployer';
import IndividualHeader from '../individual/IndividualHeader';

function EmployerHomePage() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <IndividualHeader />

      {/* Main Container */}
      <Box sx={{ mt: '64px' }}> {/* Adjusts space below the header */}
        {/* Tabs for Navigation */}
        <Box sx={{ borderBottom: '1px solid #e0e0e0', backgroundColor: 'white' }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Employer tabs"
            // centered
            variant="standard"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#FF6F61', // Tab indicator color
                height: '3px',
              },
            }}
            sx={{
              '.MuiTab-root': {
                fontWeight: 'bold',
                fontSize: '16px',
                textTransform: 'none',
                fload: 'left',
                color: '#333',
                minWidth: '150px',
                '&:hover': { color: '#FF6F61' },
              },
              '.Mui-selected': { color: '#FF6F61' },
            }}
          >
            <Tab label="Search Candidates" />
            <Tab label="Create Job" />
          </Tabs>
        </Box>

        {/* Tab Content */}
        <Box sx={{ px: { xs: 2, md: 4 }, py: 3 }}>
          {selectedTab === 0 && (
            <Box>
              <SearchCandidates />
            </Box>
          )}
          {selectedTab === 1 && (
            <Box>
              <CreateJobByEmployer />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
}

export default EmployerHomePage;
