import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { Card, CardContent, CardHeader, Box, Typography } from '@mui/material';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

const RoleDistribution = ({ data }) => {
  const roles = [
    { name: 'Company Owner', value: data.companyOwner },
    { name: 'Company Admin', value: data.companyAdmin },
    { name: 'Company User', value: data.companyUser },
  ];

  const totalRoles = roles.reduce((acc, role) => acc + role.value, 0);

  if (totalRoles === 0) {
    return (
      <Card sx={{ height: '100%' }}>
        <CardHeader title="Role Distribution" />
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            No data available
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title="Role Distribution" />
      <CardContent>
        <Box sx={{ height: 300 }}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={roles}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                label={({ name, value }) => `${name}: ${value}`}
              >
                {roles.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default RoleDistribution;
