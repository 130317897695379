import  APIURL  from "../../../config/development";
import BaseApi from "../../BaseApi";


// https://workforcenew.techoptima.com/api/v1/employee/index?request_id=1234&employee_id=a2039e5e-06d0-4248-8b09-c4e25e67db49

class IndividualEmployeeAPI {
    IndividualEmployee(params, id, token) {
        return BaseApi.getWithParams(APIURL.API_URL + `employee/index?request_id=${params}&id=${id}`, token)
    }
}

export default new IndividualEmployeeAPI()