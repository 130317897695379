// IntervieweeRegistration.js
import React, { useState } from 'react';
import { Box, Button, Container, Typography, Paper, TextField, Link } from '@mui/material';
import CommonApi from '../../../apis/CommonApi';
import { useNavigate } from 'react-router-dom';
import LocalStorage from '../../../utils/LocalStorage';

const IntervieweeRegistration = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match');
      return;
    }

    setLoading(true);
    try {
      const response = await CommonApi.registerInterviewee(formData);
      if (response && response.data) {
        alert('Interviewee registered successfully!');
        LocalStorage.setAccessToken(response.data.token); // Save the token in local storage
        navigate('/interviewee-login'); // Redirect to interviewee login page after successful registration
      }
    } catch (error) {
      console.error('Error registering interviewee:', error);
      alert('Error registering interviewee. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h5" gutterBottom>
          Interviewee Registration
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              label="Username"
              variant="outlined"
              name="username"
              fullWidth
              value={formData.username}
              onChange={handleChange}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Email"
              variant="outlined"
              name="email"
              type="email"
              fullWidth
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Password"
              variant="outlined"
              name="password"
              type="password"
              fullWidth
              value={formData.password}
              onChange={handleChange}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Confirm Password"
              variant="outlined"
              name="confirmPassword"
              type="password"
              fullWidth
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
          </Box>
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            {loading ? 'Registering...' : 'Register'}
          </Button>
          <Box mt={2}>
            <Typography variant="body1">
              Already have an account?{' '}
              <Link href="/interviewee-login" underline="hover">
                Login here
              </Link>
            </Typography>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default IntervieweeRegistration;
