// BrownMons 
const blue = "#0C75EB"
const red = '#E51A1A'
const orange = '#D97706'
const darkSkyBlue = "#054CA6"; //  color is for save button BG




// ------------------- Button Background Colors ------------------------
const btnBgBlue = {
    shade1:'#E8F3FF', // Edit Icon, Add Pair  Buttons Background.
    shade2:'#F2F7FF', // View Icon Button Background.
    shade3:'#D1E1FF', // Browse Button Background.
    shade4:'#0C75EB', // Common Blue Background.
}

const btnBgRed = {  // PopUps
    shade1:'#FFF0F0', // Delete Icon Button Background.
    shade2:'#E51A1A', // Yes, Cancel || Yes, Delete || Yes Deactivate Buttons Background.
    shade3:'#FD4646', // Prompt 'Yes' Button Background.
}

const btnBgGreen = {
    shade1:'#4ABE43' // Verified Button Background.
}

const btnBgGrey = {
    shade1:'#EAECF0', // Browse Pin Icon, Mute Notification Buttons .
    shade2:'#9D9E9F', // Browse Button Background.
    shade3:'#E5E5E5', // Prompt 'Close' Button Background.
    shade4:'#E2E5E6', // Add Button Background.

}


// -------------- Button Text Colors -------------------

const btnTxtWhite = '#FFFFFF' // white Text.

const btnTxtBlack = {
  shade1:'#404040', // Text Color for Back, Re-Open Buttons.
  shade2:'#092333', // Text Color for Cancel Button.
  shade3:'#333333', // Text Color for Next Button.
  shade4:'#171717', // Text Color for Edit Button.
}

const btnTxtLightGrey = '#CCCCCC' // Text Color for Prev Button.

const btnTxtBlue = {
  shade1:'#318CF1', // View Button text, Radio Button Color.
  shade2:'#0C75EB', // Text Color for Load, Onboard, Invite, Cancel, Browse Buttons.
}




// -------------------- Button Border Colors ----------------------
const btnBorder = {
  blue:'#0C75EB',       // Border Color for Load, Onboard, invite Buttons.
  LightBlue:'#318CF1',  // Border Color for View Button.
  red:'#E51A1A',        // Border Color for Cancel Button.
  grey:'#C7CCD3',       // Border Color for Back, Filter, Cloud Download, Home Buttons.
  LightGrey1:'#E7E7E7', // Border Color for Edit, Retry Buttons.
  LightGrey2:'#F1F1F1', // Border Color for Page Buttons.
  green:'#4ABE43',      // Border Color for Verified Button.

}



const btnStroke = {
  red:'#FD4646',       // '-' Icon.
  redMain:'#E51A1A',   // Delete Icon.
  blue:'#5DA5F5',      // Browse Pin Icon.
  blueMain:'#0C75EB',  // View Icon.
  LightBlue:'#3A90ED', // Edit Icon.
  grey:'#344054',      // Filter, Cloud Download Icons.
  lightRed:'#FF4B55',  // Filter Icon Indication Dot.
  white:'#FFFFFF',     // White Color Stroke.
  black:'#000000',     // Bell Icon.
  dark:'#4F4F4F',      // X Close Icon.
}



export { blue, red, orange, darkSkyBlue, btnBgBlue, btnBgRed, btnBgGreen, btnBgGrey, btnTxtWhite, btnTxtBlack, btnTxtLightGrey, btnTxtBlue, btnBorder ,btnStroke }