// import { useTheme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { blue, btnTxtBlack } from "../../theme"
import { useTheme } from "@mui/material"

const TextStyles = makeStyles((theme) => ({
    mediumLabel: {
        fontSize: "12px !important",
        fontFamily: "Nunito Sans, sans-serif",
        letterSpacing: '0px',
        color: "#707070 !important",
        textTransform: 'capitalize !important',
        // fontWeight: '500 !important',
        opacity: 1
    },
    largeBlack: {
        fontSize: "22px !important",
        fontFamily: "Nunito Sans, sans-serif !important",
        fontWeight: "500 !important",
        color: "rgba(38, 38, 38, 1) !important"
    },
    largeLabel: {
        fontSize: "17px !important",
        fontFamily: "Nunito Sans, sans-serif",
        letterSpacing: '0px',
        color: "#707070 !important",
        opacity: 1,
        textTransform: 'capitalize !important',
    },
    largeBlue: {
        fontSize: "17px !important",
        fontFamily: "Nunito Sans, sans-serif",
        letterSpacing: '0px',
        color: `${blue} !important`,
        opacity: 1,
        textTransform: 'capitalize !important',
    },
    smallBlue: {
        font: "12px Nunito Sans, sans-serif !important",
        letterSpacing: '0px',
        color: `${blue} !important`,
        fontWeight: `${600} !important`,
        opacity: 1,
        [useTheme().breakpoints.down('lg')]: {
            font: "9px Poppins, sans-serif !important",
        }
    },
    smallBlack: {
        font: '12px Nunito Sans, sans-serif !important',
        letterSpacing: '0px',
        opacity: 1,
        fontWeight: '500 !important',
        color: `${btnTxtBlack.shade1} !important`
    },
    verySmallBoldBlack: {
        font: '11px Nunito Sans, sans-serif !important',
        letterSpacing: '0px',
        color: `${btnTxtBlack.shade1} !important`,
        fontWeight: `${600} !important`,
        opacity: 1
    },
    // smallGrayLabel: {
    //     font: "10px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#cbcbcb !important",
    //     opacity: 1,
    // },

    // mediumBlack: {
    //     font: '12px Poppins !important',
    //     letterSpacing: '0px',
    //     opacity: 1,
    //     // fontWeight: '500 !important',
    //     color: "#323232 !important",
    // },
    // largeBlack: {
    //     font: '15px Poppins !important',
    //     letterSpacing: '0px',
    //     opacity: 1,
    //     // fontWeight: '500 !important',
    //     color: "#323232 !important",
    // },   
    // smallBoldBlack: {
    //     font: '11px Poppins !important',
    //     letterSpacing: '0px',
    //     color: "#2A3042 !important",
    //     fontWeight: `${500} !important`,
    //     opacity: 1
    // },
    // mediumLessBoldBlack: {
    //     font: '12px Poppins !important',
    //     letterSpacing: '0px',
    //     color: "#2A3042 !important",
    //     fontWeight: `${500} !important`,
    //     opacity: 1
    // },
    // mediumBoldBlack: {
    //     font: '13px Poppins !important',
    //     letterSpacing: '0px',
    //     color: "#2A3042 !important",
    //     fontWeight: `${600} !important`,
    //     opacity: 1,
    //     textTransform: 'capitalize !important'
    // },
    // mediumBoldPrimary: {
    //     font: '12px Poppins !important',
    //     letterSpacing: '0px',
    //     color: "#2A3042 !important",
    //     fontWeight: `${600} !important`,
    //     opacity: 1
    // },
    // largeBoldBlack: {
    //     font: '18px Poppins !important',
    //     letterSpacing: '0px',
    //     color: "#2A3042 !important",
    //     fontWeight: `${600} !important`,
    //     opacity: 1
    // },
    // veryLargeBoldBlack: {
    //     font: '19px Poppins !important',
    //     letterSpacing: '0px',
    //     color: "#323232 !important",
    //     fontWeight: `${700} !important`,
    //     opacity: 1
    // },

    // smallRed: {
    //     font: "10px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#FF5656 !important",
    //     opacity: 1
    // },
    // mediumRed: {
    //     font: "12px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#FF5656 !important",
    //     opacity: 1
    // },
    // mediumBoldRed: {
    //     font: "12px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#FF5656 !important",
    //     fontWeight: `${500} !important`,
    //     opacity: 1
    // },
    // largeRed: {
    //     font: "14px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#FF5656 !important",
    //     opacity: 1
    // },
    // verySmallGreen: {
    //     font: "9px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#28A745 !important",
    //     // fontWeight:`${600} !important`,
    //     opacity: 1
    // },
    // smallGreen: {
    //     font: "10px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#28A745 !important",
    //     // fontWeight:`${600} !important`,
    //     opacity: 1
    // },
    // smallBoldGreen: {
    //     font: "10px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#28A745 !important",
    //     fontWeight: `${600} !important`,
    //     opacity: 1
    // },
    // mediumGreen: {
    //     font: "12px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#28A745 !important",
    //     opacity: 1
    // },
    // largeGreen: {
    //     font: "14px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#28A745 !important",
    //     opacity: 1
    // },

    // smallYellow: {
    //     font: "10px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#F9A828 !important",
    //     opacity: 1
    // },
    // mediumYellow: {
    //     font: "12px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#F9A828 !important",
    //     opacity: 1
    // },
    // largeYellow: {
    //     font: "14px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#F9A828 !important",
    //     opacity: 1
    // },    
    // mediumBlue: {
    //     font: "12px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#054CA6 !important",
    //     opacity: 1
    // }, 

    // smallLightBlue: {
    //     font: "10px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#0260D8 !important",
    //     fontWeight: `${600} !important`,
    //     opacity: 1
    // },
    // mediumLightBlue: {
    //     font: "12px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#0260D8 !important",
    //     opacity: 1
    // },
    // largeLightBlue: {
    //     font: "14px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#0260D8 !important",
    //     opacity: 1
    // },
    // smallWhite: {
    //     font: "10px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#FFFFFF !important",
    //     opacity: 1
    // },
    // mediumWhite: {
    //     font: "12px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#FFFFFF !important",
    //     opacity: 1
    // },
    // largeWhite: {
    //     font: "17px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: "#FFFFFF !important",
    //     opacity: 1
    // },

    // smallOrange: {
    //     font: '10px Poppins !important',
    //     letterSpacing: '0px',
    //     color: '#EE8A73 !important',
    //     opacity: 1
    // },
    // mediumOrange: {
    //     font: "12px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: '#EE8A73 !important',
    //     opacity: 1
    // },
    // largeOrange: {
    //     font: "14px Poppins, sans-serif !important",
    //     letterSpacing: '0px',
    //     color: '#EE8A73 !important',
    //     opacity: 1
    // },
    // smallBoldOrange: {
    //     font: '10px Poppins !important',
    //     letterSpacing: '0px',
    //     color: '#EE8A73 !important',
    //     fontWeight: `${600} !important`,
    //     opacity: 1
    // },

    // smallBoldBlue: {
    //     font: '11px Poppins !important',
    //     letterSpacing: '0px',
    //     color: "#054CA6 !important",
    //     fontWeight: `${500} !important`,
    //     opacity: 1
    // },
    // mediumBoldBlue: {
    //     font: '13px Poppins !important',
    //     letterSpacing: '0px',
    //     color: "#054CA6 !important",
    //     fontWeight: `${600} !important`,
    //     opacity: 1
    // },
    // largeBoldBlue: {
    //     font: '15px Poppins !important',
    //     letterSpacing: '0px',
    //     color: "#054CA6 !important",
    //     fontWeight: `${600} !important`,
    //     opacity: 1
    // },
    red: {
        font: '11px Poppins !important',
        color: '#e72929',
    },
    sidebarText: {
        fontSize: "16px", fontFamily: "Nunito Sans, sans-serif", fontWeight: "500", color: "#FFFFFF"
    }
}))

export default TextStyles
