// import React, { useState } from 'react';
// import { Box, Button, TextField, Typography, Container, Grid, Paper } from '@mui/material';
// import CommonApi from '../../apis/CommonApi';
// import LocalStorage from '../../utils/LocalStorage';
// import { useNavigate } from 'react-router-dom'; // Import useNavigate

// const EmployerRegistration = () => {
//   const [formData, setFormData] = useState({
//     companyName: '',
//     email: '',
//     phone: '',
//     password: '',
//     confirmPassword: '',
//   });

//   const navigate = useNavigate(); // Initialize navigate

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (formData.password !== formData.confirmPassword) {
//       alert('Passwords do not match');
//       return;
//     }
//     try {
//       const response = await CommonApi.registerEmployer(formData);
//       LocalStorage.setAccessToken(response.data.token);
//       alert('Registration successful!');
//       navigate('/employer-dashboard'); // Redirect to dashboard or job description creation page
//     } catch (error) {
//       console.error('Error during registration:', error);
//       alert('Registration failed. Please try again.');
//     }
//   };

//   return (
//     <Container>
//       <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
//         <Typography variant="h5" gutterBottom>
//           Employer Registration
//         </Typography>
//         <form onSubmit={handleSubmit}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Company Name"
//                 name="companyName"
//                 value={formData.companyName}
//                 onChange={handleChange}
//                 fullWidth
//                 required
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Email"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//                 fullWidth
//                 required
//                 type="email"
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Phone"
//                 name="phone"
//                 value={formData.phone}
//                 onChange={handleChange}
//                 fullWidth
//                 required
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Password"
//                 name="password"
//                 value={formData.password}
//                 onChange={handleChange}
//                 fullWidth
//                 required
//                 type="password"
//               />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField
//                 label="Confirm Password"
//                 name="confirmPassword"
//                 value={formData.confirmPassword}
//                 onChange={handleChange}
//                 fullWidth
//                 required
//                 type="password"
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <Button type="submit" variant="contained" color="primary">
//                 Register
//               </Button>
//             </Grid>
//           </Grid>
//         </form>
//       </Paper>
//     </Container>
//   );
// };

// export default EmployerRegistration;


import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Container, Grid, Paper, Link } from '@mui/material';
import CommonApi from '../../apis/CommonApi';
import LocalStorage from '../../utils/LocalStorage';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const EmployerRegistration = () => {
  const [formData, setFormData] = useState({
    companyName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  });

  const navigate = useNavigate(); // Initialize navigate

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match');
      return;
    }
    try {
      const response = await CommonApi.registerEmployer(formData);
      if (response.status === 201) { // Check if registration is successful
        alert('Registration successful! Please log in to continue.');
        navigate('/login'); // Redirect to login page after successful registration
      }
    } catch (error) {
      console.error('Error during registration:', error);
      alert('Registration failed. Please try again.');
    }
  };

  return (
    <Container>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h5" gutterBottom>
          Employer Registration
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Company Name"
                name="companyName"
                value={formData.companyName}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                required
                type="email"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                fullWidth
                required
                type="password"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Confirm Password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                fullWidth
                required
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Register
              </Button>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '20px' }}>
              <Typography>
                Already have an account?{' '}
                <Link component="button" variant="body2" onClick={() => navigate('/login')}>
                  Login Here
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default EmployerRegistration;
