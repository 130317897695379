// src/components/Features.js
import React from 'react';
import { useTheme } from '../context/ThemeContext'; // Import the custom ThemeContext
import Particles from '../styles/magicui/Particles'; // Ensure the correct import path

const Features = () => {
  const { theme } = useTheme(); // Access the current theme

  const features = [
    {
      title: 'Automated Job Description Creation',
      description: 'Easily create detailed job descriptions with role requirements, responsibilities, and qualifications.',
    },
    {
      title: 'AI-Powered Resume Parsing',
      description: 'Automatically extract key information from resumes, including contact details, education, skills, and experience.',
    },
    {
      title: 'Job Matching and Recommendations',
      description: 'Match candidate resumes with job descriptions using AI, providing match percentages for quick insights.',
    },
    {
      title: 'Interactive AI Interviews',
      description: 'Conduct AI-driven interviews that analyze resumes and job descriptions to ask relevant questions to candidates.',
    },
    {
      title: 'Company and User Management',
      description: 'Manage company profiles and user roles, including company owners, admins, and regular users.',
    },
    {
      title: 'Integrated Communication Tools',
      description: 'Send welcome emails, notifications, and other communications directly from the platform to candidates and users.',
    },
  ];

  return (
    <section
      id="features"
      className={`relative py-16 ${
        theme === 'dark' 
          ? 'bg-gray-900 text-white' 
          : 'bg-gradient-to-b from-white via-gray-50 to-gray-100 text-gray-900'
      } transition-colors duration-300 overflow-hidden`}
    >
      {/* Adding Particles as a background */}
      <Particles
        className="absolute inset-0"
        quantity={100} 
        staticity={40} 
        ease={50} 
        size={0.5} 
        color={theme === 'dark' ? '#ffffff' : '#b0b0b0'} // Softer color for light theme
      />
      <h2 className="text-3xl font-bold text-center mb-6 relative z-10">What's Included</h2>
      <p className={`text-center mb-12 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'} relative z-10`}>
        Explore the comprehensive features of our AI-driven recruitment platform.
      </p>
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 px-4 relative z-10">
        {features.map((feature, index) => (
          <div 
            key={index} 
            className={`p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow ${
              theme === 'dark' ? 'bg-gray-800' : 'bg-white'
            } border ${
              theme === 'dark' ? 'border-gray-700' : 'border-gray-200'
            }`}
          >
            <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
            <p className={`${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;
