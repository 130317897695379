import { TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl';
// @material-ui/core components
import Check from '@mui/icons-material/Check'
// @material-ui/icons
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

// core components
import validator from 'validator'
import Text from '../customText/Text';
import InputStyles from './InputStyles';

export default function Input(props) {
  const classes = InputStyles()
  const [isError, setError] = React.useState(false)
  const [emptyMesaage, setMessage] = React.useState()
  const {
    smallInput,
    formControlProps,
    labelText,
    id,
    required,
    inputProps,
    error,
    success,
    placeholder,
    errorMsg,
    maxLength,
    minLength,
    isHttp,
    isMandatory,
    handleChange,
    onKeyPress,
    isNumber,
    mobile,
    password,
    email,
    emailphone,
    formInput,
    fontSize,
    forgotForm,
    formInput2,
    createInvoice,
    className,
    formInputWhite,
    formInputWhiteTextArea,
    tabInput,
    borderInput,
    formInputText,
    commentHeight,
    selectDate,
    largeFormInput,
    employeeFormInput,
    clientInput,
    onKeyDown,
    disabled,
    clientInputDisabled,
  } = props

  const underlineClasses = classNames({
    [classes.underlineError]: error || isError,
    [classes.underlineSuccess]: success && (!error || !isError),
    [classes.underline]: true,
    [classes.marginTop]: true,

  })
  // const marginTop = classNames({
  //   [classes.marginTop]: labelText,
  // })

  const formControlClasses = classNames({
    [' ' + classes.formControl]: formControlProps,
  })

  const fieldClasses = classNames({
    [classes.formInput]: formInput,
    [classes.borderInput]: borderInput,
    [classes.tabInput]: tabInput,
    [classes.formInputWhite]: formInputWhite,
    [classes.fontSize]: fontSize,
    [classes.forgotForm]: forgotForm,
    [classes.formInput2]: formInput2,
    [classes.createInvoice]: createInvoice,
    [classes.formInputWhiteTextArea]: formInputWhiteTextArea,
    [classes.tabInput]: tabInput,
    [classes.formInputText]: formInputText,
    [className]: className,
    [classes.commentHeight]:commentHeight,
    [classes.smallInput]: smallInput,
    [classes.selectDate]: selectDate,
    [classes.largeFormInput]: largeFormInput,
    [classes.employeeFormInput]: employeeFormInput,
    [classes.clientInput]: clientInput,
    [classes.clientInputDisabled]: clientInputDisabled,
    
  })

  const onChange = (event) => {
    if (isNumber) {
      if (isNaN(event.target.value)) {
        return
      }
    }

    if (isMandatory == 'true' && event.target.value.length == 0) {
      setError(true)
      setMessage(errorMsg)
      handleChange(event, true)
    } else {
      setError(false)
      handleChange(event, false)
    }

    if (mobile == 'true') {
      if (event.target.value.length != 10) {
        setError(true)
        setMessage(errorMsg)
        handleChange(event, true)
      }
    }
    if (password == 'true') {
      if (event.target.value.length < 6) {
        setError(true)
        setMessage(errorMsg)
        handleChange(event, true)
      }
    }

    if (email == 'true') {
      if (validator.isEmail(event.target.value)) {
        setError(false)
        handleChange(event, false)
      }
      else {
        setError(true)
        setMessage(errorMsg)
        handleChange(event, true)
      }
    }
    if (emailphone == 'true') {
      if (validator.isEmail(event.target.value)) {
        setError(false)
        handleChange(event, false)
      }
      else if (event.target.value.length != 10) {
        setError(true)
        setMessage(errorMsg)
        handleChange(event, true)
      }
    }

    if (maxLength) {
      if (event.target.value.length > parseInt(maxLength)) {
        setError(true)
        setMessage(errorMsg)
        handleChange(event, true)
      }
    } else if (minLength) {
      if (event.target.value.length < parseInt(minLength)) {
        setError(true)
        setMessage(errorMsg)
        handleChange(event, true)
      }
    } else {
      if (handleChange) {
        handleChange(event, false)
      }
    }
    if (isHttp) {
      var link = event.target.value
      var check = link.substring(0, 4)
      if (check == 'http' || check == 'HTTP') {
      } else {
        setError(true)
        setMessage(
          'Please Add (http) else (https) before the URL, For Ex:- http://google.com'
        )
      }
    }
  }

  // const handleInput = (event) => {
  //   if (isMandatory && event.target.value.length == 0) {
  //     setError(true)
  //     setMessage(errorMsg)
  //     handleChange(event, true)
  //   } else {
  //     setError(false)
  //     handleChange(event, false)
  //   }
  // }

  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.className + formControlClasses}
    >
      <TextField
        label={labelText}
        required={required}
        placeholder={placeholder}
        // variant={outlined && 'outlined'}
        variant="standard"
        margin="dense"
        InputLabelProps={{
          underline: underlineClasses,
        }}
        InputProps={{ classes: { input: fieldClasses }, disableUnderline: true, }}
        id={id}
        {...inputProps}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        disabled={disabled}
        className={classes.root}
      />

      {error || isError ? (
        <React.Fragment>
          {/* <Clear className={classes.feedback + ' ' + classes.labelRootError} /> */}
          <Text red > {emptyMesaage} </Text>
        </React.Fragment>
      ) : success ? (
        <Check className={classes.feedback + ' ' + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  )
}

Input.propTypes = {
  labelText: PropTypes.node,
  required: PropTypes.node,
  labelProps: PropTypes.object,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object.isRequired,
  error: PropTypes.bool,
  success: PropTypes.bool,
  formInput: PropTypes.bool,
  createInvoice: PropTypes.bool,
  forgotForm: PropTypes.bool,
  tabInput: PropTypes.bool,
  errorMsg: PropTypes.string,
  isMandatory: PropTypes.string,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  mobile: PropTypes.string,
  password: PropTypes.string,
  email: PropTypes.string,
  emailphone: PropTypes.string,
  handleChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  isNumber: PropTypes.bool,
  outlined: PropTypes.bool,
  className: PropTypes.string,
  fontSize: PropTypes.bool,
  boxsize: PropTypes.bool,
  formInputWhite: PropTypes.bool,
  formInputWhiteTextArea: PropTypes.bool,
  borderInput: PropTypes.bool,
  formInputText: PropTypes.bool,
  commentHeight:PropTypes.bool,
  smallInput:PropTypes.bool,
  onKeyDown:PropTypes.func,
  selectDate:PropTypes.bool,
  largeFormInput:PropTypes.bool
}