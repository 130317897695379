// import MyProfile from "../layouts/MyProfile";
// import ForgotPassword from "../layouts/forgotPassword/ForgotPassword";
// import CompanyAdminDashboard from "../views/companyAdmin/CompanyAdminDashboard";
// import CompanyAdminUsers from "../views/companyAdmin/CompanyUser/CompanyAdminUsers";
// import JobDescription from "../views/companyAdmin/JobDescription/JobDescription";
// import JobDetails from "../views/admin/employees/jobDetails/JobDetails";
// import Jobs from "../views/companyAdmin/JobDescription/Jobs";
// import ParsedResumes from "../views/admin/employees/jobDetails/ParsedResumes";
// import Main from "../views/admin/Main";
// import ChatbotPage from "../views/admin/ChatbotPage";
// // import AdminDashboard from "../views/companyAdmin/CompanyAdminDashboard";
// // import Login from "../layouts/login/Login";

// const CompanyAdminRoutes = [
// //   {
// //     id: "admin",
// //     path: "/*",
// //     element: <Login />,
// //     name: "",
// //     slug: "dashboard",
// //     access: true,
// //   },
// //   {
// //     id: "admin",
// //     path: "/",
// //     element: <Login />,
// //     name: "",
// //     slug: "dashboard",
// //     access: true,
// //   },
//   {
//     path: "/companyadmindashboard",
//     element: <CompanyAdminDashboard />,
//     slug: "companyadmindashboard",
//     access: true,
//   },
//   {
//     path: "/jobdescription",
//     element: <JobDescription />,
//     slug: "companyadmindashboard",
//     access: true,
//   },
//   {
//     // id: "admin",
//     path: "/forgot-password",
//     element: <ForgotPassword />,
//     name: "",
//     slug: "companyadmindashboard",
//     access: true
//   },
//   {
//     path: "/my-profile",
//     element: <MyProfile />,
//     name: "",
//     slug: "companyadmindashboard",
//     access: true
//   },
//   {
//     path: "/users",
//     element: <CompanyAdminUsers />,
//     name: "",
//     slug: "dashboard",
//     access: true
//   },
//   {
//     id: "admin",
//     path: "/job_details",
//     element: <JobDetails />,
//     name: "",
//     slug: "dashboard",
//     access: true
//   },
//   {
//     id: "admin",
//     path: "/jobs",
//     element: <Jobs />,
//     name: "",
//     slug: "dashboard",
//     access: true
//   },
//   {
//     id: "admin",
//     path: "/parsed-resumes",
//     element: <ParsedResumes />,
//     name: "",
//     slug: "dashboard",
//     access: true
//   },
//   {
//     path: "/chatbot",
//     element: <ChatbotPage />,  // Add the new route
//     name: "Chatbot",
//     slug: "dashboard",
//     access: true
//   }
//   // Other company routes
//   // "/parsed-resumes"
// ];

// export default CompanyAdminRoutes;


import MyProfile from "../layouts/MyProfile";
import ForgotPassword from "../layouts/forgotPassword/ForgotPassword";
import CompanyAdminDashboard from "../views/companyAdmin/CompanyAdminDashboard";
import CompanyAdminUsers from "../views/companyAdmin/CompanyUser/CompanyAdminUsers";
// import JobDescription from "../views/companyAdmin/JobDescription/JobDescription";
import JobDetails from "../views/admin/employees/jobDetails/JobDetails";
import Jobs from "../views/companyAdmin/JobDescription/Jobs";
import ParsedResumes from "../views/admin/employees/jobDetails/ParsedResumes";
import Main from "../views/admin/Main";
import ChatbotPage from "../views/admin/ChatbotPage";
// import JobDescriptions from "../views/admin/employees/jobDescription/JobDescription";
// import AdminDashboard from "../views/companyAdmin/CompanyAdminDashboard";
// import Login from "../layouts/login/Login";
import JobDescriptions from "../views/admin/employees/jobDescription/JobDescription";
import JobDescriptionStepper from "../views/companyAdmin/JobDescription/JobDescriptionStepper";

const CompanyAdminRoutes = [
//   {
//     id: "admin",
//     path: "/*",
//     element: <Login />,
//     name: "",
//     slug: "dashboard",
//     access: true,
//   },
//   {
//     id: "admin",
//     path: "/",
//     element: <Login />,
//     name: "",
//     slug: "dashboard",
//     access: true,
//   },
  {
    path: "/dashboard",
    element: <JobDetails/>,
    slug: "dashboard",
    access: true,
  },
  // {
  //   path: "/jobdescription",
  //   element: <JobDescription />,
  //   slug: "companyadmindashboard",
  //   access: true,
  // },
  {
    // id: "admin",
    path: "/forgot-password",
    element: <ForgotPassword />,
    name: "",
    slug: "companyadmindashboard",
    access: true
  },
  {
    path: "/my-profile",
    element: <MyProfile />,
    name: "",
    slug: "companyadmindashboard",
    access: true
  },
  {
    path: "/users",
    element: <CompanyAdminUsers />,
    name: "",
    slug: "dashboard",
    access: true
  },
  // {
  //   id: "admin",
  //   path: "/job_details",
  //   element: <JobDetails />,
  //   name: "",
  //   slug: "dashboard",
  //   access: true
  // },
  // {
  //   id: "admin",
  //   path: "/jobdescription",
  //   element: <JobDescription />,
  //   name: "",
  //   slug: "dashboard",
  //   access: true
  // },
  {
    id: "admin",
    path: "/parsed-resumes",
    element: <ParsedResumes />,
    name: "",
    slug: "dashboard",
    access: true
  },
  {
    path: "/chatbot",
    element: <ChatbotPage />,  // Add the new route
    name: "Chatbot",
    slug: "dashboard",
    access: true
  },
  {
    path: "/jobdescription",
    element: <JobDescriptions/>,  // Add the new route
    name: "Chatbot",
    slug: "dashboard",
    access: true
  },
  {
    id: "admin",
    path: "/jobdescriptionstepper",  // Add the new route here
    element: <JobDescriptionStepper />, // Component for JobDescriptionStepper
    name: "Job Description Stepper",
    slug: "dashboard",
    access: true
  },
  // {
  //   path: "/job_details",
  //   element: <JobDetails />,  // Add the new route
  //   name: "Views",
  //   slug: "Views",
  //   access: true
  // }
  // Other company routes
  // "/parsed-resumes"
];

export default CompanyAdminRoutes;