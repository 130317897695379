import React, { useRef, useState } from 'react';
import { Box, Typography, Button, IconButton, Paper } from '@mui/material';
import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import CommonApi from "../../../apis/CommonApi";
import LocalStorage from "../../../utils/LocalStorage";

const UpdateResume = () => {
    const fileInputRef = useRef(null);

    // State to store resume information
    const [resumeInfo, setResumeInfo] = useState({
        name: "Resume.docx", // Default name, update after upload
        uploadDate: "May 20, 2019", // Default date, update after upload
    });

    const handleUpdateResumeClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const formData = new FormData();
                formData.append('resume', file);

                const response = await CommonApi.uploadResume(formData);
                console.log('Resume uploaded successfully:', response);

                // Update resume info with the new file name and current date
                setResumeInfo({
                    name: file.name,
                    uploadDate: new Date().toLocaleDateString('en-US', {
                        year: 'numeric', month: 'long', day: 'numeric'
                    }),
                });

                // Handle success message or other UI feedback
            } catch (error) {
                console.error("Error uploading resume:", error);
                // Handle error feedback
            }
        }
    };

    return (
        <Paper
            elevation={1}
            sx={{
                boxShadow: 'none'
            }}
        >
            {/* Header Section with Resume Information and Icons */}
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Box>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Resume</Typography>
                    <Typography variant="body2" color="textSecondary">{resumeInfo.name}</Typography>
                    <Typography variant="body2" color="textSecondary">Uploaded on {resumeInfo.uploadDate}</Typography>
                </Box>
                <Box>
                    <IconButton color="primary">
                        <DownloadIcon />
                    </IconButton>
                    <IconButton color="primary">
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </Box>

            {/* Update Resume Section */}
            <Box
                sx={{
                    border: '1px dashed #ccc',
                    borderRadius: '8px',
                    padding: '16px',
                    textAlign: 'center',
                    width: '100%',
                    mt: 2,
                }}
            >
                <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                        borderRadius: '20px',
                        padding: '6px 16px',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        marginBottom: '8px',
                    }}
                    onClick={handleUpdateResumeClick}
                >
                    Upload resume
                </Button>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept=".doc,.docx,.rtf,.pdf"
                />
                <Typography variant="body2" color="textSecondary">
                    Supported Formats: doc, docx, rtf, pdf, up to 2 MB
                </Typography>
            </Box>
        </Paper>
    );
};

export default UpdateResume;
