
// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import {
//   Box,
//   Typography,
//   IconButton,
//   CircularProgress,
//   Link,
//   Paper,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   TextField,
//   RadioGroup,
//   FormControlLabel,
//   Radio,
//   MenuItem,
//   Button,
//   Grid,
//   Avatar,
// } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
// import PhoneIcon from '@mui/icons-material/Phone';
// import EmailIcon from '@mui/icons-material/Email';
// import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
// import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CommonApi from '../../../apis/CommonApi';
// import LocalStorage from '../../../utils/LocalStorage';

// const ProfileSection = () => {
//   const [open, setOpen] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const [saving, setSaving] = useState(false);
//   const [isEmailVerified, setIsEmailVerified] = useState(false);
//   const [completionPercentage, setCompletionPercentage] = useState(0);
//   const [accountInfo, setAccountInfo] = useState({
//     name: '',
//     workStatus: '',
//     experienceYears: '',
//     salary: '',
//     location: '',
//     mobileNumber: '',
//     email: '',
//     profileImage: '',
//   });
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchAccountInfo = async () => {
//       try {
//         const token = LocalStorage.getAccessToken();
//         const response = await CommonApi.getAccountInformation(token);
//         setAccountInfo(response); // Set account information
//         setIsEmailVerified(response.isEmailVerified); // Set email verification status
//         setCompletionPercentage(response.profileCompletion);
//       } catch (error) {
//         console.error("Error fetching account information:", error);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchAccountInfo();
//   }, []);

//   const handleClickOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   const handleSave = async () => {
//     setSaving(true);
//     const token = LocalStorage.getAccessToken();
//     try {
//       await CommonApi.updateIndividualAccountInfo(token, accountInfo);
//       setOpen(false);
//     } catch (error) {
//       console.error("Error updating account information:", error);
//     } finally {
//       setSaving(false);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setAccountInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
//   };

//   const handleImageUpload = async (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const formData = new FormData();
//       formData.append("profileImage", file);

//       try {
//         const token = LocalStorage.getAccessToken();
//         const response = await CommonApi.uploadProfileImage(token, formData);
//         setAccountInfo((prev) => ({ ...prev, profileImage: response.profileImage }));
//         setCompletionPercentage((prev) => prev + 5);
//       } catch (error) {
//         console.error("Error uploading profile image:", error);
//       }
//     }
//   };

//   const handleVerifyEmail = async () => {
//     try {
//       const token = LocalStorage.getAccessToken();
//       await CommonApi.sendVerificationEmail({ email: accountInfo.email, name: accountInfo.name });
//       alert("Verification email sent! Please check your inbox.");
//     } catch (error) {
//       console.error("Error sending verification email:", error);
//     }
//   };

//   const checkEmailVerificationStatus = async () => {
//     try {
//       const token = LocalStorage.getAccessToken();
//       const response = await CommonApi.getEmailVerificationStatus(token);
//       if (response.isEmailVerified) {
//         setIsEmailVerified(true);
//         navigate('/verify-email-success'); // Redirect to the success page upon verification
//       }
//     } catch (error) {
//       console.error("Error checking email verification status:", error);
//     }
//   };

//   if (loading) {
//     return <CircularProgress />;
//   }

//   return (
//     <>
//       <Paper elevation={3} sx={{ boxShadow: 'none', p: 2 }}>
//         <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
//           Account Information
//         </Typography>

//         <Box display="flex" flexDirection="row" alignItems="center" mb={3}>
//           <Box sx={{ flexGrow: 1 }}>
//             <Box display="flex" alignItems="center">
//               <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 1 }}>{accountInfo.name}</Typography>
//               <IconButton size="small" color="primary" onClick={handleClickOpen}>
//                 <EditIcon fontSize="small" />
//               </IconButton>
//             </Box>
//             <Typography variant="subtitle2" color="textSecondary">
//               {accountInfo.workStatus}
//             </Typography>
//           </Box>

//           {/* Profile Picture with Circular Progress */}
//           <Box position="relative" display="flex" justifyContent="center" alignItems="center" sx={{ ml: 2 }}>
//             <CircularProgress
//               variant="determinate"
//               value={completionPercentage}
//               size={80}
//               thickness={5}
//               sx={{ color: completionPercentage >= 100 ? 'green' : 'orange' }}
//             />
//             <Avatar
//               src={accountInfo.profileImage || "/path/to/default-image.jpg"}
//               alt={accountInfo.name}
//               sx={{
//                 width: 64,
//                 height: 64,
//                 position: 'absolute'
//               }}
//             />
//             <input
//               accept="image/*"
//               style={{ display: 'none' }}
//               id="upload-profile-image"
//               type="file"
//               onChange={handleImageUpload}
//             />
//             <label htmlFor="upload-profile-image">
//               <IconButton component="span" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
//                 <AddAPhotoIcon />
//               </IconButton>
//             </label>
//             <Typography
//               variant="caption"
//               component="div"
//               color="textSecondary"
//               sx={{
//                 position: 'absolute',
//                 bottom: -10,
//                 fontWeight: 'bold'
//               }}
//             >
//               {`${completionPercentage}%`}
//             </Typography>
//           </Box>
//         </Box>

//         {/* Other Account Information Fields */}
//         <Grid container spacing={2}>
//           <Grid item xs={12} sm={6}>
//             <Box display="flex" alignItems="center" mb={1}>
//               <LocationOnIcon fontSize="small" color="action" />
//               <Typography variant="body2" sx={{ ml: 1 }}>{accountInfo.location}</Typography>
//             </Box>
//             <Box display="flex" alignItems="center" mb={1}>
//               <CalendarTodayIcon fontSize="small" color="action" />
//               <Typography variant="body2" sx={{ ml: 1 }}>{accountInfo.experienceYears}</Typography>
//             </Box>
//             <Box display="flex" alignItems="center" mb={1}>
//               <CurrencyRupeeIcon fontSize="small" color="action" />
//               <Typography variant="body2" sx={{ ml: 1 }}>{accountInfo.salary}</Typography>
//             </Box>
//           </Grid>
//           <Grid item xs={12} sm={6}>
//             <Box display="flex" alignItems="center" mb={1}>
//               <PhoneIcon fontSize="small" color="action" />
//               <Typography variant="body2" sx={{ ml: 1 }}>{accountInfo.mobileNumber}</Typography>
//             </Box>
//             <Box display="flex" alignItems="center" mb={1}>
//               <EmailIcon fontSize="small" color="action" />
//               <Typography variant="body2" sx={{ ml: 1 }}>{accountInfo.email}</Typography>
//               {!isEmailVerified ? (
//                 <Link
//                   component="button"
//                   variant="body2"
//                   color="primary"
//                   onClick={handleVerifyEmail}
//                   sx={{ ml: 1 }}
//                 >
//                   Verify
//                 </Link>
//               ) : (
//                 <CheckCircleIcon color="success" sx={{ ml: 1 }} />
//               )}
//             </Box>
//           </Grid>
//         </Grid>
//       </Paper>

//       {/* Dialog for Editing Account Information */}
//       <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
//         <DialogTitle>Edit Account Information</DialogTitle>
//         <DialogContent>
//           <Box mt={2}>
//             <TextField label="Name" fullWidth name="name" value={accountInfo.name} onChange={handleChange} sx={{ mb: 2 }} />
//             <Typography>Work status</Typography>
//             <RadioGroup row name="workStatus" value={accountInfo.workStatus} onChange={handleChange} sx={{ mb: 2 }}>
//               <FormControlLabel value="Fresher" control={<Radio />} label="Fresher" />
//               <FormControlLabel value="Experienced" control={<Radio />} label="Experienced" />
//             </RadioGroup>
//             <TextField select label="Total experience" fullWidth name="experienceYears" value={accountInfo.experienceYears} onChange={handleChange} sx={{ mb: 2 }}>
//               <MenuItem value="6 Years">6 Years</MenuItem>
//               <MenuItem value="5 Years">5 Years</MenuItem>
//             </TextField>
//             <TextField label="Current salary" fullWidth name="salary" value={accountInfo.salary} onChange={handleChange} sx={{ mb: 2 }} />
//             <TextField label="Current location" fullWidth name="location" value={accountInfo.location} onChange={handleChange} sx={{ mb: 2 }} />
//             <TextField label="Mobile number" fullWidth name="mobileNumber" value={accountInfo.mobileNumber} onChange={handleChange} sx={{ mb: 2 }} />
//             <TextField label="Email address" fullWidth name="email" value={accountInfo.email} onChange={handleChange} sx={{ mb: 2 }} />
//           </Box>
//           <Box display="flex" justifyContent="flex-end" mt={2}>
//             <Button onClick={handleClose} color="secondary" sx={{ mr: 1 }}>
//               Cancel
//             </Button>
//             <Button onClick={handleSave} variant="contained" color="primary" disabled={saving}>
//               {saving ? <CircularProgress size={24} /> : 'Save'}
//             </Button>
//           </Box>
//         </DialogContent>
//       </Dialog>
//     </>
//   );
// };

// export default ProfileSection;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Link,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Button,
  Grid,
  Avatar,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CommonApi from '../../../apis/CommonApi';
import LocalStorage from '../../../utils/LocalStorage';

const ProfileSection = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [accountInfo, setAccountInfo] = useState({
    name: '',
    workStatus: '',
    experienceYears: '',
    salary: '',
    location: '',
    mobileNumber: '',
    email: '',
    profileImage: '',
  });
  const navigate = useNavigate();

  // Fetch account information and email verification status when the component mounts
  useEffect(() => {
    const fetchAccountInfo = async () => {
      try {
        const token = LocalStorage.getAccessToken();
        const response = await CommonApi.getAccountInformation(token);
        setAccountInfo(response); // Set account information
        setIsEmailVerified(response.isEmailVerified); // Set email verification status
        setCompletionPercentage(response.profileCompletion);
      } catch (error) {
        console.error("Error fetching account information:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccountInfo();
  }, []);

  // Function to open the dialog
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Function to save changes
  const handleSave = async () => {
    setSaving(true);
    const token = LocalStorage.getAccessToken();
    try {
      await CommonApi.updateIndividualAccountInfo(token, accountInfo);
      setOpen(false);
    } catch (error) {
      console.error("Error updating account information:", error);
    } finally {
      setSaving(false);
    }
  };

  // Handle changes in account information form fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAccountInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  // Handle profile image upload
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("profileImage", file);

      try {
        const token = LocalStorage.getAccessToken();
        const response = await CommonApi.uploadProfileImage(token, formData);
        setAccountInfo((prev) => ({ ...prev, profileImage: response.profileImage }));
        setCompletionPercentage((prev) => prev + 5);
      } catch (error) {
        console.error("Error uploading profile image:", error);
      }
    }
  };

  // Function to send verification email
  const handleVerifyEmail = async () => {
    try {
      const token = LocalStorage.getAccessToken();
      await CommonApi.sendVerificationEmail({ email: accountInfo.email, name: accountInfo.name });
      alert("Verification email sent! Please check your inbox.");
    } catch (error) {
      console.error("Error sending verification email:", error);
    }
  };

  // Function to check email verification status after email is verified
  const checkEmailVerificationStatus = async () => {
    try {
      const token = LocalStorage.getAccessToken();
      const response = await CommonApi.getEmailVerificationStatus(token);
      setIsEmailVerified(response.isEmailVerified); // Update the email verification status
    } catch (error) {
      console.error("Error checking email verification status:", error);
    }
  };

  // UseEffect to check the email verification status on component mount or after verifying email
  useEffect(() => {
    checkEmailVerificationStatus();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Paper elevation={3} sx={{ boxShadow: 'none', p: 2 }}>
        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
          Account Information
        </Typography>

        <Box display="flex" flexDirection="row" alignItems="center" mb={3}>
          <Box sx={{ flexGrow: 1 }}>
            <Box display="flex" alignItems="center">
              <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 1 }}>{accountInfo.name}</Typography>
              <IconButton size="small" color="primary" onClick={handleClickOpen}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Box>
            <Typography variant="subtitle2" color="textSecondary">
              {accountInfo.workStatus}
            </Typography>
          </Box>

          {/* Profile Picture with Circular Progress */}
          <Box position="relative" display="flex" justifyContent="center" alignItems="center" sx={{ ml: 2 }}>
            <CircularProgress
              variant="determinate"
              value={completionPercentage}
              size={80}
              thickness={5}
              sx={{ color: completionPercentage >= 100 ? 'green' : 'orange' }}
            />
            <Avatar
              src={accountInfo.profileImage || "/path/to/default-image.jpg"}
              alt={accountInfo.name}
              sx={{
                width: 64,
                height: 64,
                position: 'absolute'
              }}
            />
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="upload-profile-image"
              type="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="upload-profile-image">
              <IconButton component="span" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <AddAPhotoIcon />
              </IconButton>
            </label>
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
              sx={{
                position: 'absolute',
                bottom: -10,
                fontWeight: 'bold'
              }}
            >
              {`${completionPercentage}%`}
            </Typography>
          </Box>
        </Box>

        {/* Other Account Information Fields */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" mb={1}>
              <LocationOnIcon fontSize="small" color="action" />
              <Typography variant="body2" sx={{ ml: 1 }}>{accountInfo.location}</Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={1}>
              <CalendarTodayIcon fontSize="small" color="action" />
              <Typography variant="body2" sx={{ ml: 1 }}>{accountInfo.experienceYears}</Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={1}>
              <CurrencyRupeeIcon fontSize="small" color="action" />
              <Typography variant="body2" sx={{ ml: 1 }}>{accountInfo.salary}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center" mb={1}>
              <PhoneIcon fontSize="small" color="action" />
              <Typography variant="body2" sx={{ ml: 1 }}>{accountInfo.mobileNumber}</Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={1}>
              <EmailIcon fontSize="small" color="action" />
              <Typography variant="body2" sx={{ ml: 1 }}>{accountInfo.email}</Typography>
              {!isEmailVerified ? (
                <Link
                  component="button"
                  variant="body2"
                  color="primary"
                  onClick={handleVerifyEmail}
                  sx={{ ml: 1 }}
                >
                  Verify
                </Link>
              ) : (
                <CheckCircleIcon color="success" sx={{ ml: 1 }} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {/* Dialog for Editing Account Information */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Edit Account Information</DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <TextField label="Name" fullWidth name="name" value={accountInfo.name} onChange={handleChange} sx={{ mb: 2 }} />
            <Typography>Work status</Typography>
            <RadioGroup row name="workStatus" value={accountInfo.workStatus} onChange={handleChange} sx={{ mb: 2 }}>
              <FormControlLabel value="Fresher" control={<Radio />} label="Fresher" />
              <FormControlLabel value="Experienced" control={<Radio />} label="Experienced" />
            </RadioGroup>
            <TextField select label="Total experience" fullWidth name="experienceYears" value={accountInfo.experienceYears} onChange={handleChange} sx={{ mb: 2 }}>
              <MenuItem value="6 Years">6 Years</MenuItem>
              <MenuItem value="5 Years">5 Years</MenuItem>
            </TextField>
            <TextField label="Current salary" fullWidth name="salary" value={accountInfo.salary} onChange={handleChange} sx={{ mb: 2 }} />
            <TextField label="Current location" fullWidth name="location" value={accountInfo.location} onChange={handleChange} sx={{ mb: 2 }} />
            <TextField label="Mobile number" fullWidth name="mobileNumber" value={accountInfo.mobileNumber} onChange={handleChange} sx={{ mb: 2 }} />
            <TextField label="Email address" fullWidth name="email" value={accountInfo.email} onChange={handleChange} sx={{ mb: 2 }} />
          </Box>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button onClick={handleClose} color="secondary" sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button onClick={handleSave} variant="contained" color="primary" disabled={saving}>
              {saving ? <CircularProgress size={24} /> : 'Save'}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ProfileSection;
