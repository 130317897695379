// import React, { useState } from 'react';
// import JobDescriptionsTable from '../../../../components/listOfJobs/ListOfJobs';
// import { Box, Typography } from '@mui/material';
// import UploadIcon from "@mui/icons-material/Upload"; 
// import DescriptionIcon from "@mui/icons-material/Description"; 
// import useStyles from './JobDescriptionStyles'; 
// import ChatWithGPT from '../../../../components/ChatWithGPT';
// const JobDescriptions = () => {
//   const classes = useStyles();
//   const [showChatGPT, setShowChatGPT] = useState(false); // State to control the visibility of ChatGPT

//   const handleGenerateJobDescription = () => {
//     setShowChatGPT(true); // Update state to show ChatGPT
//   };

//   return (
//     <div className={classes.container}>
//       <Box className={classes.leftSide}>
//         <JobDescriptionsTable />
//       </Box>

//       <Box className={classes.rightSide}>

//         {/* <Typography variant="h6" className={classes.title}>
//           {showChatGPT ? 'Chat with AI for Job Description' : 'Create New Job Description'}
//         </Typography> */}

        
//         {!showChatGPT ? (
//           <>
//             <Typography variant="h6" className={classes.title}>
//               Create New Job Description
//             </Typography>
//             <Box className={classes.box}>
//               <div className={classes.iconWrapper}>
//                 <DescriptionIcon fontSize="small" className={classes.icon} />
//                 <Typography 
//                   variant="body1" 
//                   fontWeight="bold" 
//                   onClick={handleGenerateJobDescription} 
//                   style={{ cursor: 'pointer' }} // Cursor change to pointer
//                 >
//                   Generate Job Description
//                 </Typography>
//               </div>
//               <Typography className={classes.descriptionText}>
//                 Create the job description manually by filling all the input fields.
//               </Typography>
//             </Box>
            
//             <Box className={classes.box}>
//               <div className={classes.iconWrapper}>
//                 <UploadIcon fontSize="small" className={classes.icon} />
//                 <Typography variant="body1" fontWeight="bold">
//                   Upload
//                 </Typography>
//               </div>
//               <Typography className={classes.descriptionText}>
//                 Create the job description manually by filling all the input fields.
//               </Typography>
//             </Box>
//           </>
//         ) : (
//           <ChatWithGPT /> // Render ChatWithGPT component when showChatGPT is true
//         )}
//       </Box>
//     </div>
//   );
// };

// export default JobDescriptions; 

import React, { useState } from 'react';
import JobDescriptionsTable from '../../../../components/listOfJobs/ListOfJobs';
import { Box, Typography, CircularProgress } from '@mui/material';
import UploadIcon from "@mui/icons-material/Upload";
import DescriptionIcon from "@mui/icons-material/Description";
import useStyles from './JobDescriptionStyles';
import ChatWithGPT from '../../../../components/ChatWithGPT';
import CommonApi from '../../../../apis/CommonApi'; // Import the API

const JobDescriptions = () => {
  const classes = useStyles();
  const [showChatGPT, setShowChatGPT] = useState(false); // State to control the visibility of ChatGPT
  const [parsedJobDetails, setParsedJobDetails] = useState(null); // Store parsed job details
  const [loading, setLoading] = useState(false); // Loading state for file upload
  const [error, setError] = useState(null); // Error state

  // Function to handle file upload and parsing
  const handleFileUpload = async (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    if (!file) return;

    setLoading(true);
    setError(null);
    const formData = new FormData();
    formData.append('jobDescriptionFile', file); // Append the uploaded file

    try {
      // Call API to parse job description from file
      const response = await CommonApi.parseJobDescriptionFromFile(formData);
      setParsedJobDetails(response.details); // Store the parsed job details
    } catch (error) {
      console.error('Error parsing job description:', error);
      setError('Failed to parse job description. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateJobDescription = () => {
    setShowChatGPT(true); // Update state to show ChatGPT
  };

  return (
    <div className={classes.container}>
      <Box className={classes.leftSide}>
        <JobDescriptionsTable />
      </Box>

      <Box className={classes.rightSide}>
        {!showChatGPT ? (
          <>
            <Typography variant="h6" className={classes.title}>
              Create New Job Description
            </Typography>

            <Box className={classes.box}>
              <div className={classes.iconWrapper}>
                <DescriptionIcon fontSize="small" className={classes.icon} />
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  onClick={handleGenerateJobDescription}
                  style={{ cursor: 'pointer' }}
                >
                  Generate Job Description
                </Typography>
              </div>
              <Typography className={classes.descriptionText}>
                Generate the job description manually by filling all the input fields.
              </Typography>
            </Box>

            <Box className={classes.box}>
              <div className={classes.iconWrapper}>
                <UploadIcon fontSize="small" className={classes.icon} />
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  component="label"
                  style={{ cursor: 'pointer' }}
                >
                  Upload Job Description
                  <input
                    type="file"
                    accept=".pdf"
                    hidden
                    onChange={handleFileUpload} // Trigger file upload and parsing on file selection
                  />
                </Typography>
              </div>
              <Typography className={classes.descriptionText}>
                Upload a job description file and parse it automatically.
              </Typography>
            </Box>

            {/* Display loading state */}
            {loading && (
              <Box mt={2}>
                <CircularProgress />
              </Box>
            )}

            {/* Display parsed job details */}
            {parsedJobDetails && (
              <Box mt={4}>
                <Typography variant="h6">Parsed Job Description:</Typography>
                <pre>{JSON.stringify(parsedJobDetails, null, 2)}</pre>
              </Box>
            )}

            {/* Display error message */}
            {error && (
              <Box mt={2}>
                <Typography color="error">{error}</Typography>
              </Box>
            )}
          </>
        ) : (
          <ChatWithGPT />
        )}
      </Box>
    </div>
  );
};

export default JobDescriptions;

