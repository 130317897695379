import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Modal,
  Box,
  Button,
  Typography,
  TextField,
  Container,
  Grid,
  IconButton
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactMarkdown from 'react-markdown';
import IndividualHeader from './IndividualHeader';
import IndividualFooter from './IndividualFooter';

const JobDescriptionDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const job = location.state?.job;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resume, setResume] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 500,
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

  const handleUploadResume = () => {
    setIsModalOpen(true);
  };

  const handleFileChange = (event) => {
    setResume(event.target.files[0]);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setResume(null);
  };

  const handleResumeSubmit = () => {
    if (resume) {
      alert(`Resume for ${job.title} uploaded successfully!`);
      closeModal();
    } else {
      alert('Please upload a resume.');
    }
  };

  const handleSearchSubmit = (event) => {
    if (event.key === 'Enter' && searchTerm) {
      navigate(`/results?query=${searchTerm}`, { replace: true });
    }
  };

  return (
    <>
      <IndividualHeader />
      <Container maxWidth="xl" sx={{ mt: { xs: 8, md: 12 }, mb: { xs: 6, md: 10 } }}>
        {/* Top Section */}
        <Box
          sx={{
            py: { xs: 4, md: 8 },
            px: { xs: 2, sm: 4 },
            mb: 5,
            background: "linear-gradient(to right, #ffefe0, #ffe0e0)",
            borderRadius: 2,
            textAlign: "center",
            overflow: "hidden",
            position: 'relative'
          }}
        >
          <IconButton
            onClick={() => navigate(-1)}
            sx={{
              position: 'absolute',
              top: { xs: 8, md: 20 },
              left: { xs: 8, md: 20 },
              bgcolor: 'rgba(255, 255, 255, 0.8)',
              '&:hover': { bgcolor: 'rgba(255, 255, 255, 1)' },
            }}
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>

          <Typography
            variant="h3"
            fontWeight="bold"
            gutterBottom
            color="textPrimary"
            sx={{ fontSize: { xs: "1.8rem", md: "2.5rem" } }}
          >
            Find Your Next Career Today
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mb: 3, fontSize: { xs: "0.9rem", md: "1rem" } }}
          >
            Discover exciting job opportunities that fit your skills and career goals. Our advanced job search helps you find the best matches quickly.
          </Typography>

          {/* Search Box */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 3,
              width: "100%",
              maxWidth: "800px",
              mx: "auto",
            }}
          >
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Search for a job"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleSearchSubmit}
              sx={{
                bgcolor: "#ffffff",
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#d0d4d9",
                  },
                  "&:hover fieldset": {
                    borderColor: "#a8b2bd",
                  },
                },
              }}
            />
          </Box>
        </Box>

        <Grid container spacing={4}>
          {/* Main Content Area */}
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                p: 4,
                bgcolor: '#fafafa',
                borderRadius: 2,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'linear-gradient(135deg, #ffe0e0, #ffefef)',
                  zIndex: -1,
                },
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  color: 'rgb(255, 38, 0)',
                  fontWeight: 'bold',
                  fontSize: { xs: '1.5rem', md: '2rem' },
                }}
              >
                {job.title}
              </Typography>

              <Box sx={{ color: '#333', mb: 3 }}>
                <ReactMarkdown>{job.fullDescription}</ReactMarkdown>
              </Box>

              {/* Additional Job Info */}
              <Typography variant="body2" sx={{ mb: 1, color: '#555' }}>
                <strong>Location:</strong> {job.location}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1, color: '#555' }}>
                <strong>Job Type:</strong> {job.jobType}
              </Typography>
              <Typography variant="body2" sx={{ mb: 1, color: '#555' }}>
                <strong>Salary:</strong> {job.salary}
              </Typography>

              {/* Buttons */}
              <Box mt={4} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
                <Button
                  variant="contained"
                  onClick={handleUploadResume}
                  sx={{
                    bgcolor: 'rgb(255, 38, 0)',
                    color: '#fff',
                    '&:hover': { bgcolor: 'rgb(220, 34, 0)' },
                    width: { xs: '100%', md: 'auto' },
                  }}
                >
                  Upload Resume
                </Button>
                <Button
                  variant="contained"
                  onClick={() => alert("Saved for later!")}
                  sx={{
                    bgcolor: '#6a1b9a',
                    color: '#fff',
                    '&:hover': { bgcolor: '#4a148c' },
                    width: { xs: '100%', md: 'auto' },
                  }}
                >
                  Apply Later
                </Button>
              </Box>

              {/* Resume Upload Modal */}
              <Modal open={isModalOpen} onClose={closeModal} aria-labelledby="upload-resume-modal-title" aria-describedby="upload-resume-modal-description">
                <Box sx={modalStyle}>
                  <Typography id="upload-resume-modal-title" variant="h6" component="h2" sx={{ mb: 2, color: 'rgb(255, 38, 0)' }}>
                    Upload Resume for {job?.title}
                  </Typography>
                  <input type="file" onChange={handleFileChange} style={{ display: 'block', marginBottom: '16px', width: '100%' }} />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button onClick={closeModal} color="inherit" variant="outlined">
                      Cancel
                    </Button>
                    <Button onClick={handleResumeSubmit} variant="contained" sx={{ bgcolor: 'rgb(255, 38, 0)', '&:hover': { bgcolor: 'rgb(220, 34, 0)' } }}>
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
          </Grid>

          {/* Sidebar Section */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                position: { md: "sticky" },
                top: { md: "20px" },
              }}
            >

              {/* Related Careers Section */}
              <Box
                sx={{
                  p: 3,
                  bgcolor: "#f9fafc",
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
                  mb: 3,
                }}
              >
                <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>
                  Related Careers
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {["UI Designer", "UX Researcher", "Graphic Designer", "Web Designer", "Product Designer", "Webflow Designer"].map((career, index) => (
                    <Button key={index} variant="outlined" sx={{ borderRadius: 2, textTransform: "none", borderColor: "#c5c9cc", color: "rgb(255, 38, 0)", "&:hover": { backgroundColor: "#ffe0e0" } }}>
                      {career}
                    </Button>
                  ))}
                </Box>
              </Box>

              {/* Subscribe for Updates Section */}
              <Box
                sx={{
                  p: 3,
                  bgcolor: "#f9fafc",
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
                }}
              >
                <Typography variant="h6" fontWeight="bold">
                  Subscribe for Updates
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1, mb: 2 }}>
                  Stay updated with the latest job openings tailored to your preferences.
                </Typography>
                <TextField variant="outlined" placeholder="Enter your email" fullWidth sx={{ bgcolor: "#ffffff", borderRadius: 2 }} />
                <Button variant="contained" fullWidth sx={{ mt: 2, bgcolor: "rgb(255, 38, 0)", "&:hover": { bgcolor: "rgb(220, 34, 0)" } }}>
                  Subscribe
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <IndividualFooter />
    </>
  );
};

export default JobDescriptionDetails;
