import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  CircularProgress,
  Alert,
  Typography,
  Card,
  CardContent,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CommonApi from '../../apis/CommonApi';
import CompanyOverview from './dashboardComponents/CompanyOverview';
import GeographicalDistribution from './dashboardComponents/GeographicalDistribution';
import CompanySizeDistribution from './dashboardComponents/CompanySizeDistribution';
import IndustryDistribution from './dashboardComponents/IndustryDistribution';
import ModeOfWorkDistribution from './dashboardComponents/ModeOfWorkDistribution';
import RoleDistribution from './dashboardComponents/RoleDistribution';
import JobApplicationsOverview from './dashboardComponents/JobApplicationsOverview';
import '../../views/admin/dashboardComponents/chartSetup';

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const data = await CommonApi.getDashboardData();
        setDashboardData(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (loading) return <Box textAlign="center"><CircularProgress /></Box>;
  if (error) return <Alert severity="error">{error.message}</Alert>;

  return (
    <Box p={3} sx={{ backgroundColor: '#f7f8fa', marginLeft: sidebarOpen ? '260px' : '75px', transition: 'margin-left 0.3s' }}>
      {/* Header with Filter, Export, and More Options */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Platform Owner Dashboard</Typography>
        <Box>
          <Button variant="outlined" sx={{ mr: 2 }}>Filter</Button>
          <Button variant="outlined" sx={{ mr: 2 }}>Export</Button>
          <IconButton onClick={handleMenuOpen}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose}>Option 1</MenuItem>
            <MenuItem onClick={handleMenuClose}>Option 2</MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* Main Content */}
      <Grid container spacing={3}>
        {/* Summary Cards */}
        <Grid item xs={12} md={3}>
          <Card sx={{ background: 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)', color: '#fff' }}>
            <CardContent>
              <Typography variant="h6">Total Companies</Typography>
              <Typography variant="h4">{dashboardData.overview.totalCompanies}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={{ background: 'linear-gradient(135deg, #ff6a00 0%, #ee0979 100%)', color: '#fff' }}>
            <CardContent>
              <Typography variant="h6">Total Users</Typography>
              <Typography variant="h4">{dashboardData.overview.totalUsers}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={{ background: 'linear-gradient(135deg, #1d976c 0%, #93f9b9 100%)', color: '#fff' }}>
            <CardContent>
              <Typography variant="h6">Total Jobs</Typography>
              <Typography variant="h4">{dashboardData.overview.totalJobs || 'N/A'}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}>
          <Card sx={{ background: 'linear-gradient(135deg, #f953c6 0%, #b91d73 100%)', color: '#fff' }}>
            <CardContent>
              <Typography variant="h6">Total Hired</Typography>
              <Typography variant="h4">{dashboardData.overview.totalApplications || 'N/A'}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Detailed Charts */}
        <Grid item xs={12} md={6} lg={4}>
          <GeographicalDistribution data={dashboardData.geographicalDistribution} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <CompanySizeDistribution data={dashboardData.companySizeDistribution} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <ModeOfWorkDistribution data={dashboardData.modeOfWorkDistribution} />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <IndustryDistribution data={dashboardData.industryDistribution} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <RoleDistribution data={dashboardData.roleDistribution} />
        </Grid>
        <Grid item xs={12}>
          <JobApplicationsOverview data={dashboardData.jobApplicationsOverview} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
