// import { v4 as uuid } from 'uuid';

// class LocalStorage {
//   uid() {
//     const id = uuid();
//     return id;
//   }

//   // getItem(key) {
//   //   try {
//   //     return localStorage.getItem(key);
//   //   } catch (error) {
//   //     console.error(`Error getting item from localStorage: ${error}`);
//   //     return null;
//   //   }
//   // }

//   // setItem(key, value) {
//   //   try {
//   //     localStorage.setItem(key, value);
//   //   } catch (error) {
//   //     console.error(`Error setting item in localStorage: ${error}`);
//   //   }
//   // }

//   getURLNAME() {
//     var temp = window.location.hostname;
//     return temp;
//   }

//   setUserId(userId) {
//     localStorage.setItem('userId', userId);
//   }

//   // Get User ID from Local Storage
//   getUserId() {
//     return localStorage.getItem('userId');
//   }
//   setUserData(data) {
//     const local_data = JSON.stringify(data);
//     return localStorage.setItem('UserData', local_data);
//   }

//   getUserData() {
//     const str = localStorage.getItem('UserData');
//     return str ? JSON.parse(str) : null;
//   }

//   removeUserData() {
//     return localStorage.removeItem('UserData');
//   }

//   getAccessToken() {
//     const userData = this.getUserData();
//     return userData ? userData.token : '';
//   }

//   setAccessToken(token) {
//     localStorage.setItem('token', token);
//   }

//   removeAccessToken() {
//     return localStorage.removeItem('token');
//   }

//   getCandidateProfileId() {
//     const userData = this.getUserData();
//     return userData ? userData.candidateProfileId : null;
//   }

//   setRolesData(data) {
//     const local_data = JSON.stringify(data);
//     return localStorage.setItem('RolesData', local_data);
//   }

//   getRolesData() {
//     const str = localStorage.getItem('RolesData');
//     return str ? JSON.parse(str) : null;
//   }

//   removeRolesData() {
//     return localStorage.removeItem('RolesData');
//   }

//   setDateFormat(data) {
//     return localStorage.setItem('date_format', data);
//   }

//   getDateFormat() {
//     return localStorage.getItem('date_format');
//   }

//   removeDateFormat() {
//     return localStorage.removeItem('date_format');
//   }

//   setEmployeeId(data) {
//     return localStorage.setItem('request_id', data);
//   }

//   getEmployeeId() {
//     return localStorage.getItem('request_id');
//   }

//   setCompanyData(data) {
//     const local_data = JSON.stringify(data);
//     return localStorage.setItem('CompanyData', local_data);
//   }

//   getCompanyData() {
//     const str = localStorage.getItem('CompanyData');
//     return str ? JSON.parse(str) : null;
//   }

//   setSessionFlag() {
//     sessionStorage.setItem('isLoggedIn', 'true');
//   }

//   getSessionFlag() {
//     return sessionStorage.getItem('isLoggedIn');
//   }

//   removeSessionFlag() {
//     sessionStorage.removeItem('isLoggedIn');
//   }

//   clearAll() {
//     localStorage.clear();
//     sessionStorage.clear();
//   }

//   //job description

//   setjobDescription() {
//     return localStorage.setItem('request_id');
//   }
// }

// export default new LocalStorage();

import { v4 as uuid } from 'uuid';

class LocalStorage {
  uid() {
    const id = uuid();
    return id;
  }

  // getItem(key) {
  //   try {
  //     return localStorage.getItem(key);
  //   } catch (error) {
  //     console.error(`Error getting item from localStorage: ${error}`);
  //     return null;
  //   }
  // }

  // setItem(key, value) {
  //   try {
  //     localStorage.setItem(key, value);
  //   } catch (error) {
  //     console.error(`Error setting item in localStorage: ${error}`);
  //   }
  // }

  getURLNAME() {
    var temp = window.location.hostname;
    return temp;
  }

  setUserId(userId) {
    localStorage.setItem('userId', userId);
  }

  // Get User ID from Local Storage
  getUserId() {
    return localStorage.getItem('userId');
  }
  setUserData(data) {
    const local_data = JSON.stringify(data);
    return localStorage.setItem('UserData', local_data);
  }

  getUserData() {
    const str = localStorage.getItem('UserData');
    return str ? JSON.parse(str) : null;
  }

  removeUserData() {
    return localStorage.removeItem('UserData');
  }

  getAccessToken() {
    const userData = this.getUserData();
    return userData ? userData.token : '';
  }

  setAccessToken(token) {
    localStorage.setItem('token', token);
  }

  removeAccessToken() {
    return localStorage.removeItem('token');
  }

  getCandidateProfileId() {
    const userData = this.getUserData();
    return userData ? userData.candidateProfileId : null;
  }

  setRolesData(data) {
    const local_data = JSON.stringify(data);
    return localStorage.setItem('RolesData', local_data);
  }

  getRolesData() {
    const str = localStorage.getItem('RolesData');
    return str ? JSON.parse(str) : null;
  }

  removeRolesData() {
    return localStorage.removeItem('RolesData');
  }

  setDateFormat(data) {
    return localStorage.setItem('date_format', data);
  }

  getDateFormat() {
    return localStorage.getItem('date_format');
  }

  removeDateFormat() {
    return localStorage.removeItem('date_format');
  }

  setEmployeeId(data) {
    return localStorage.setItem('request_id', data);
  }

  getEmployeeId() {
    return localStorage.getItem('request_id');
  }

  setCompanyData(data) {
    const local_data = JSON.stringify(data);
    return localStorage.setItem('CompanyData', local_data);
  }

  getCompanyData() {
    const str = localStorage.getItem('CompanyData');
    return str ? JSON.parse(str) : null;
  }

  setSessionFlag() {
    sessionStorage.setItem('isLoggedIn', 'true');
  }

  getSessionFlag() {
    return sessionStorage.getItem('isLoggedIn');
  }

  removeSessionFlag() {
    sessionStorage.removeItem('isLoggedIn');
  }

  clearAll() {
    localStorage.clear();
    sessionStorage.clear();
  }

  //job description

  setjobDescription() {
    return localStorage.setItem('request_id');
  }

  setRedirectPath(path) {
    localStorage.setItem('redirectPath', path);
  }

  getRedirectPath() {
    return localStorage.getItem('redirectPath');
  }

  static removeRedirectPath() {
    localStorage.removeItem('redirectPath');
  }

}

export default new LocalStorage();