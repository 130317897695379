import React, { useState } from "react";
import { Container, Box, Tabs, Tab, Slide } from "@mui/material";
import { styled } from "@mui/material/styles";
import JobDescription from "./JobDescription";
import JobDetails from '../../admin/employees/jobDetails/JobDetails'
import { BorderBottom, BorderRightRounded } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const jobStyles = makeStyles(() => ({
  tabHeaderBox: {
    display: "flex",
    justifyContent: "flex-start",
    position: "relative",
    width: "100%",
    // borderBottom: "2px solid #0c75eb",
  },
}));

// Styled Tab component to achieve the desired look
const StyledTab = styled(Tab)(({ theme }) => ({
  border: "1px solid #e9e9e9", // Border around each tab
  "&:not(:first-of-type)": {
    marginLeft: -1, // Merge borders to avoid double borders
  },
  backgroundColor: "#white", // Default background color for tabs
  color: "#666", // Default text color
  textTransform: "none", // Keep text as it is without transformation
  minWidth: "100px", // Set minimum width for consistent spacing
  minHeight: "40px", // Set consistent height for the tabs
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderBottom: "2px solid #0c75eb",
  [`&.Mui-selected`]: {
    backgroundColor: "white", // White background for the selected tab
    fontWeight: "bold", // Bold text for the selected tab
    color: "black", // Black color for the active tab text
    border: "2px solid #0c75eb",
    borderBottom: "none",
    '&:before':{
      borderRight: "2px solid #0c75eb",
    }
  },
  "&:hover": {
    backgroundColor: "#white",
  },
}));

const Jobs = () => {
  const classes = jobStyles();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Container
        sx={{
          maxWidth: "100% !important",
          padding: "0px 80px 0px 80px !important",
        }}
      >
        <Box display="flex" flexDirection="column" mt={2} mb={2}>
          <Box className={classes.tabHeaderBox}>
            <Box width="3%" borderBottom="2px solid #0c75eb"></Box>
            <Tabs
              value={tabIndex}
              onChange={handleTabChange}
              aria-label="Job tabs"
              sx={{
                minHeight: "40px",
                [`& .MuiTabs-indicator`]: {
                  display: "none", // Hide the default tab indicator
                },
              }}
            >
              <StyledTab disableRipple label="CreateJobDescription" />
              <StyledTab disableRipple label="View" />
            </Tabs>
            <Box width="74%" borderBottom="2px solid #0c75eb"></Box>
          </Box>
        </Box>

        {tabIndex === 0 && (
          <Box padding="20px 0px" mt={2}>
            <JobDescription />
          </Box>
        )}

        {tabIndex === 1 && (
          <Box padding="10px 0px" mt={2}>
            <JobDetails />
          </Box>
        )}

        {/* Add further content for additional tabs if needed */}
      </Container>
    </Slide>
  );
};

export default Jobs;