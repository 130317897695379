import React, { useState } from 'react';
import { Box, Typography, Button, Grid, Container, Input } from '@mui/material';
import IndividualHeader from '../IndividualHeader';
import { useNavigate } from 'react-router-dom';

const AssessmentMain = () => {
    const [fileName, setFileName] = useState('');
    const navigate = useNavigate();

    const handleStartAssessment = () => {
        navigate('/assessment-start');
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            // Additional processing of the file can be added here if needed
        }
    };

    return (
        <>
            <IndividualHeader />
            <Container maxWidth="xl" sx={{ mt: { xs: 8, md: 8 }, mb: { xs: 6, md: 10 } }}>
                <Box sx={{ padding: 4 }}>
                    {/* <Typography variant="p" gutterBottom sx={{ fontWeight: '400', color: '#6c757d', textAlign: 'center' }}>
                        Assessment Tests
                    </Typography> */}
                    <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'left', marginBottom: 4, textAlign: 'center' }}>
                        Get Your Assessment
                    </Typography>

                    {/* Banner Section */}
                    <Box
                        sx={{
                            background: 'linear-gradient(90deg, #ff2600 0%, #0f08cd 100%)',
                            color: 'white',
                            padding: 4,
                            borderRadius: 2,
                            marginBottom: 6,
                        }}
                    >
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: '500' }}>
                                    Stand out from the crowd
                                </Typography>
                                <Typography variant="body1">Get certified in technical skills by taking the HackerRank Certification Test</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: '500' }}>
                                    Standardised Assessment
                                </Typography>
                                <Typography variant="body1">Assessments are organized around specific skills and are carefully curated based on years of recruiting data from 2000+ companies.</Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h6" gutterBottom sx={{ fontWeight: '500' }}>
                                    Enrich your profile
                                </Typography>
                                <Typography variant="body1">Upon successfully clearing an assessment, you can promote yourself using the HackerRank certificate to peers and employers.</Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Resume Upload Section */}
                    <Box sx={{ padding: 4, textAlign: 'center', border: '1px solid #e0e0e0', borderRadius: 2, marginBottom: 6 }}>
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Upload Your Resume
                        </Typography>
                        <Typography variant="body1" sx={{ color: '#6c757d', marginBottom: 3 }}>
                            Upload your resume to get personalized suggestions and find relevant assessment based on your skills and experience.
                        </Typography>
                        <Input
                            type="file"
                            onChange={handleFileChange}
                            sx={{ display: 'none' }}
                            id="resume-upload"
                        />
                        <label htmlFor="resume-upload">
                            <Button
                                variant="contained"
                                component="span"
                                sx={{
                                    backgroundColor: '#ff2600',
                                    color: '#fff',
                                    borderRadius: '12px',
                                    paddingX: 3,
                                    '&:hover': {
                                        backgroundColor: '#e02400',
                                    },
                                }}
                            >
                                Upload Resume
                            </Button>
                        </label>
                        {fileName && (
                            <Typography variant="body2" sx={{ color: '#6c757d', marginTop: 1 }}>
                                Uploaded: {fileName}
                            </Typography>
                        )}
                    </Box>

                    {/* Get Your Roles Certified Section */}
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: 3, textAlign: 'center' }}>
                        Get Your Roles Certified
                    </Typography>

                    <Grid container spacing={3}>
                        {['Frontend Developer (React)', 'Software Engineer', 'Software Engineer Intern'].map((role) => (
                            <Grid item xs={12} sm={4} key={role}>
                                <Box
                                    sx={{
                                        padding: 3,
                                        border: '1px solid #e0e0e0',
                                        borderRadius: 2,
                                        textAlign: 'center',
                                        backgroundColor: '#f8f9fa',
                                        transition: 'transform 0.3s, box-shadow 0.3s',
                                        '&:hover': {
                                            transform: 'translateY(-3px)',
                                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                        },
                                    }}
                                >
                                    <Typography variant="subtitle1" sx={{ fontWeight: '500', marginBottom: 1 }}>
                                        {role}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        onClick={handleStartAssessment}
                                        sx={{ marginTop: 2, fontWeight: 'normal', backgroundColor: '#ff2600', color: '#fff', borderRadius: '12px' }}
                                    >
                                        Start Assessment
                                    </Button>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Get Your Skills Certified Section */}
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginTop: 6, marginBottom: 3, textAlign: 'center' }}>
                        Get Your Skills Certified
                    </Typography>

                    <Grid container spacing={3}>
                        {['Problem Solving', 'Python', 'JavaScript'].map((skill) => (
                            <Grid item xs={12} sm={4} key={skill}>
                                <Box
                                    sx={{
                                        padding: 3,
                                        border: '1px solid #e0e0e0',
                                        borderRadius: 2,
                                        textAlign: 'center',
                                        backgroundColor: '#f8f9fa',
                                        transition: 'transform 0.3s, box-shadow 0.3s',
                                        '&:hover': {
                                            transform: 'translateY(-3px)',
                                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                        },
                                    }}
                                >
                                    <Typography variant="subtitle1" sx={{ fontWeight: '500', marginBottom: 1 }}>
                                        {skill}
                                    </Typography>
                                    <Button
                                        variant="outlined"
                                        sx={{ marginTop: 2, fontWeight: 'bold', backgroundColor: '#ff2600', color: '#fff', borderRadius: '12px', borderColor: '#ff2600' }}
                                    >
                                        Get Certified
                                    </Button>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </>
    );
};

export default AssessmentMain;
