// import React, { useEffect, useState } from "react";
// import {
//   Container,
//   Paper,
//   Typography,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Box,
//   TextField,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   IconButton,
// } from "@mui/material";
// import CommonApi from "../../../apis/CommonApi";
// import LocalStorage from "../../../utils/LocalStorage";
// import { useNavigate } from 'react-router-dom';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';

// const CompanyAdminUsers = () => {
//   const [users, setUsers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [open, setOpen] = useState(false);
//   const [editMode, setEditMode] = useState(false);
//   const [selectedUserId, setSelectedUserId] = useState(null);
//   const [newUserData, setNewUserData] = useState({
//     name: '',
//     email: '',
//     phoneNumber: '',
//     address: {
//       country: '',
//       state: '',
//       city: '',
//     },
//     zipCode: '',
//     isActive: true,
//   });
//   const navigate = useNavigate();
//   const [currentUser, setCurrentUser] = useState(null);

//   const fetchUsers = async () => {
//     const token = LocalStorage.getAccessToken();
//     const user = LocalStorage.getUserData();
//     setCurrentUser(user);
//     console.log("Fetched user from local storage:", user); // Log user data

//     if (!user || !user.user.company || !user.user.company._id) {
//       setError(new Error("No company associated with this user."));
//       setLoading(false);
//       return;
//     }
//     try {
//       const response = await CommonApi.getProfilesByCompany(user.user.company._id, token);
//       if (response && Array.isArray(response)) {
//         setUsers(response);
//       } else {
//         setUsers([]);
//       }
//       setLoading(false);
//     } catch (error) {
//       setError(error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchUsers();
//   }, []);

//   const handleAddUser = async () => {
//     const token = LocalStorage.getAccessToken();
//     const user = LocalStorage.getUserData();
//     const newUserDataWithCompany = {
//       ...newUserData,
//       company: user.user.company._id,
//       role: user.user.role === 'companyOwner' ? 'companyAdmin' : 'companyUser',
//     };
//     try {
//       if (editMode) {
//         await CommonApi.updateCandidateProfile(selectedUserId, newUserDataWithCompany, token);
//       } else {
//         await CommonApi.createCandidateProfile(newUserDataWithCompany, token); // Use createCandidateProfile
//       }
//       fetchUsers(); // Refresh the list after adding a new user
//       handleClose();
//     } catch (error) {
//       console.error("Error adding user:", error);
//     }
//   };

//   const handleDeleteUser = async (id) => {
//     const token = LocalStorage.getAccessToken();
//     try {
//       await CommonApi.deleteCandidateProfile(id, token);
//       fetchUsers(); // Refresh the list after deleting a user
//     } catch (error) {
//       console.error("Error deleting user:", error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     const [mainName, subName] = name.split('.');
//     if (subName) {
//       setNewUserData((prevData) => ({
//         ...prevData,
//         [mainName]: {
//           ...prevData[mainName],
//           [subName]: value,
//         },
//       }));
//     } else {
//       setNewUserData((prevData) => ({
//         ...prevData,
//         [name]: value
//       }));
//     }
//   };

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setEditMode(false);
//     setSelectedUserId(null);
//     setNewUserData({
//       name: '',
//       email: '',
//       phoneNumber: '',
//       address: {
//         country: '',
//         state: '',
//         city: '',
//       },
//       zipCode: '',
//       role: 'companyUser',
//       isActive: true,
//     });
//   };

//   const handleEditUser = (user) => {
//     setNewUserData({
//       name: user.name,
//       email: user.email,
//       phoneNumber: user.phoneNumber,
//       address: user.address,
//       zipCode: user.zipCode,
//       role: user.role,
//       isActive: user.isActive,
//     });
//     setSelectedUserId(user._id);
//     setEditMode(true);
//     handleClickOpen();
//   };

//   if (loading) return <Typography>Loading...</Typography>;
//   if (error) return <Typography>Error: {error.message}</Typography>;

//   return (
//     <Container>
//       <Box display="flex" justifyContent="space-between" alignItems="center" paddingTop="20px" paddingBottom="20px">
//         <Typography variant="h6">Users</Typography>
//         <Button variant="contained" color="primary" onClick={handleClickOpen}>
//           Add User
//         </Button>
//       </Box>
//       <Paper>
//         <TableContainer>
//           <Table>
//             <TableHead sx={{backgroundColor: '#d4e4f8 !important'}}>
//               <TableRow>
//                 <TableCell sx={{color: 'grey !important'}}>Name</TableCell>
//                 <TableCell sx={{color: 'grey !important'}}>Email</TableCell>
//                 <TableCell sx={{color: 'grey !important'}}>Phone</TableCell>
//                 <TableCell sx={{color: 'grey !important'}}>Address</TableCell>
//                 <TableCell sx={{color: 'grey !important'}}>Role</TableCell>
//                 <TableCell sx={{color: 'grey !important'}}>Zip Code</TableCell>
//                 <TableCell sx={{color: 'grey !important'}}>Active</TableCell>
//                 <TableCell sx={{color: 'grey !important'}}>Actions</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {users.map((user) => (
//                 <TableRow key={user._id}>
//                   <TableCell>{user.name}</TableCell>
//                   <TableCell>{user.email}</TableCell>
//                   <TableCell>{user.phoneNumber}</TableCell>
//                   <TableCell>{`${user.address.country}, ${user.address.state}, ${user.address.city}`}</TableCell>
//                   <TableCell>{user.role}</TableCell>
//                   <TableCell>{user.zipCode}</TableCell>
//                   <TableCell>{user.isActive ? "Yes" : "No"}</TableCell>
//                   <TableCell>
//                     {user.role !== 'companyOwner' && (
//                       <>
//                         <IconButton onClick={() => handleEditUser(user)}>
//                           <EditIcon sx={{color:'#1976d2'}} />
//                         </IconButton>
//                         <IconButton onClick={() => handleDeleteUser(user._id)}>
//                           <DeleteIcon sx={{color: '#ED6157'}} />
//                         </IconButton>
//                       </>
//                     )}
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Paper>
//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>{editMode ? "Edit User" : "Add User"}</DialogTitle>
//         <DialogContent>
//           <TextField
//             margin="dense"
//             name="name"
//             label="Name"
//             type="text"
//             fullWidth
//             value={newUserData.name}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="email"
//             label="Email"
//             type="email"
//             fullWidth
//             value={newUserData.email}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="phoneNumber"
//             label="Phone Number"
//             type="text"
//             fullWidth
//             value={newUserData.phoneNumber}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="address.country"
//             label="Country"
//             type="text"
//             fullWidth
//             value={newUserData.address.country}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="address.state"
//             label="State"
//             type="text"
//             fullWidth
//             value={newUserData.address.state}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="address.city"
//             label="City"
//             type="text"
//             fullWidth
//             value={newUserData.address.city}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="zipCode"
//             label="Zip Code"
//             type="text"
//             fullWidth
//             value={newUserData.zipCode}
//             onChange={handleChange}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={handleAddUser} color="primary">
//             {editMode ? "Update" : "Add"}
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Container>
//   );
// };

// export default CompanyAdminUsers;

// import React, { useEffect, useState } from "react";
// import {
//   Container,
//   Paper,
//   Typography,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Box,
//   TextField,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   IconButton,
//   Menu,
//   MenuItem,
// } from "@mui/material";
// import CommonApi from "../../../apis/CommonApi";
// import LocalStorage from "../../../utils/LocalStorage";
// import { useNavigate } from 'react-router-dom';
// import MoreVertIcon from '@mui/icons-material/MoreVert';

// const CompanyAdminUsers = () => {
//   const [users, setUsers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [open, setOpen] = useState(false);
//   const [editMode, setEditMode] = useState(false);
//   const [selectedUserId, setSelectedUserId] = useState(null);
//   const [newUserData, setNewUserData] = useState({
//     name: '',
//     email: '',
//     phoneNumber: '',
//     address: {
//       country: '',
//       state: '',
//       city: '',
//     },
//     zipCode: '',
//     isActive: true,
//   });
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [currentUser, setCurrentUser] = useState(null);
//   const navigate = useNavigate();

//   const fetchUsers = async () => {
//     const token = LocalStorage.getAccessToken();
//     const user = LocalStorage.getUserData();
//     setCurrentUser(user);

//     if (!user || !user.user.company || !user.user.company._id) {
//       setError(new Error("No company associated with this user."));
//       setLoading(false);
//       return;
//     }
//     try {
//       const response = await CommonApi.getProfilesByCompany(user.user.company._id, token);
//       if (response && Array.isArray(response)) {
//         setUsers(response);
//       } else {
//         setUsers([]);
//       }
//       setLoading(false);
//     } catch (error) {
//       setError(error);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchUsers();
//   }, []);

//   const handleAddUser = async () => {
//     const token = LocalStorage.getAccessToken();
//     const user = LocalStorage.getUserData();
//     const newUserDataWithCompany = {
//       ...newUserData,
//       company: user.user.company._id,
//       role: user.user.role === 'companyOwner' ? 'companyAdmin' : 'companyUser',
//     };
//     try {
//       if (editMode) {
//         await CommonApi.updateCandidateProfile(selectedUserId, newUserDataWithCompany, token);
//       } else {
//         await CommonApi.createCandidateProfile(newUserDataWithCompany, token); // Use createCandidateProfile
//       }
//       fetchUsers(); // Refresh the list after adding a new user
//       handleClose();
//     } catch (error) {
//       console.error("Error adding user:", error);
//     }
//   };

//   const handleDeleteUser = async (id) => {
//     const token = LocalStorage.getAccessToken();
//     try {
//       await CommonApi.deleteCandidateProfile(id, token);
//       fetchUsers(); // Refresh the list after deleting a user
//     } catch (error) {
//       console.error("Error deleting user:", error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     const [mainName, subName] = name.split('.');
//     if (subName) {
//       setNewUserData((prevData) => ({
//         ...prevData,
//         [mainName]: {
//           ...prevData[mainName],
//           [subName]: value,
//         },
//       }));
//     } else {
//       setNewUserData((prevData) => ({
//         ...prevData,
//         [name]: value
//       }));
//     }
//   };

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setEditMode(false);
//     setSelectedUserId(null);
//     setNewUserData({
//       name: '',
//       email: '',
//       phoneNumber: '',
//       address: {
//         country: '',
//         state: '',
//         city: '',
//       },
//       zipCode: '',
//       role: 'companyUser',
//       isActive: true,
//     });
//   };

//   const handleEditUser = (user) => {
//     setNewUserData({
//       name: user.name,
//       email: user.email,
//       phoneNumber: user.phoneNumber,
//       address: user.address,
//       zipCode: user.zipCode,
//       role: user.role,
//       isActive: user.isActive,
//     });
//     setSelectedUserId(user._id);
//     setEditMode(true);
//     handleClickOpen();
//   };

//   const handleMenuClick = (event, user) => {
//     setAnchorEl(event.currentTarget);
//     setSelectedUserId(user._id);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   if (loading) return <Typography>Loading...</Typography>;
//   if (error) return <Typography>Error: {error.message}</Typography>;

//   return (
//     <Container>
//       <Box display="flex" justifyContent="space-between" alignItems="center" paddingTop="20px" paddingBottom="20px">
//         <Typography variant="h6">Users</Typography>
//         <Button variant="contained" color="primary" onClick={handleClickOpen} sx={{ backgroundColor:'#FF5200', borderRadius: ''}}>
//           Add User
//         </Button>
//       </Box>
//       <Paper>
//         <TableContainer sx={{ boxShadow: '0 4px 10px rgba(255, 82, 0, 0.5)', borderRadius: '8px' }}
//         >
//           <Table>
//             <TableHead sx={{ backgroundColor: '#FF5200 !important' }}>
//               <TableRow>
//                 <TableCell sx={{ fontWeight: 'bold !important' , color: 'white'}}>Name</TableCell>
//                 <TableCell sx={{ fontWeight: 'bold !important' , color: 'white'}}>Email</TableCell>
//                 <TableCell sx={{ fontWeight: 'bold !important' , color: 'white'}}>Phone</TableCell>
//                 <TableCell sx={{ fontWeight: 'bold !important' , color: 'white'}}>Address</TableCell>
//                 <TableCell sx={{ fontWeight: 'bold !important' , color: 'white'}}>Role</TableCell>
//                 <TableCell sx={{ fontWeight: 'bold !important' , color: 'white'}}>Zip Code</TableCell>
//                 <TableCell sx={{ fontWeight: 'bold !important' , color: 'white'}}>Active</TableCell>
//                 <TableCell sx={{ fontWeight: 'bold !important' , color: 'white'}}>Actions</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {users.map((user) => (
//                 <TableRow key={user._id}>
//                   <TableCell>{user.name}</TableCell>
//                   <TableCell>{user.email}</TableCell>
//                   <TableCell>{user.phoneNumber}</TableCell>
//                   <TableCell>{`${user.address.country}, ${user.address.state}, ${user.address.city}`}</TableCell>
//                   <TableCell>{user.role}</TableCell>
//                   <TableCell>{user.zipCode}</TableCell>
//                   <TableCell>{user.isActive ? "Yes" : "No"}</TableCell>
//                   <TableCell>
//                     <IconButton onClick={(event) => handleMenuClick(event, user)}>
//                       <MoreVertIcon />
//                     </IconButton>
//                     <Menu
//                       anchorEl={anchorEl}
//                       open={Boolean(anchorEl) && selectedUserId === user._id}
//                       onClose={handleMenuClose}
//                     >
//                       <MenuItem onClick={() => handleEditUser(user)}>Edit</MenuItem>
//                       <MenuItem onClick={() => handleDeleteUser(user._id)}>Delete</MenuItem>
//                     </Menu>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//       </Paper>
//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>{editMode ? "Edit User" : "Add User"}</DialogTitle>
//         <DialogContent>
//           <TextField
//             margin="dense"
//             name="name"
//             label="Name"
//             type="text"
//             fullWidth
//             value={newUserData.name}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="email"
//             label="Email"
//             type="email"
//             fullWidth
//             value={newUserData.email}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="phoneNumber"
//             label="Phone Number"
//             type="text"
//             fullWidth
//             value={newUserData.phoneNumber}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="address.country"
//             label="Country"
//             type="text"
//             fullWidth
//             value={newUserData.address.country}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="address.state"
//             label="State"
//             type="text"
//             fullWidth
//             value={newUserData.address.state}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="address.city"
//             label="City"
//             type="text"
//             fullWidth
//             value={newUserData.address.city}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="zipCode"
//             label="Zip Code"
//             type="text"
//             fullWidth
//             value={newUserData.zipCode}
//             onChange={handleChange}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={handleAddUser} color="primary">
//             {editMode ? "Update" : "Add"}
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Container>
//   );
// };

// export default CompanyAdminUsers;


// import React, { useEffect, useState } from "react";
// import {
//   Container,
//   Paper,
//   Typography,
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Box,
//   TextField,
//   IconButton,
// } from "@mui/material";
// import { AgGridReact } from "ag-grid-react";
// import "ag-grid-community/styles/ag-grid.css";
// import "ag-grid-community/styles/ag-theme-alpine.css";
// import CommonApi from "../../../apis/CommonApi";
// import LocalStorage from "../../../utils/LocalStorage";
// import { useNavigate } from "react-router-dom";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import classNames from "classnames";
// import { borderBottomColor, styled, textTransform } from '@mui/system';


// const CompanyAdminUsers = () => {
//   const [users, setUsers] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [open, setOpen] = useState(false);
//   const [editMode, setEditMode] = useState(false);
//   const [selectedUserId, setSelectedUserId] = useState(null);
//   const [currentUser, setCurrentUser] = useState(null);
//   const [newUserData, setNewUserData] = useState({
//     name: "",
//     email: "",
//     phoneNumber: "",
//     address: {
//       country: "",
//       state: "",
//       city: "",
//     },
//     zipCode: "",
//     isActive: true,
//   });
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchUsers();
//   }, []);

//   const fetchUsers = async () => {
//     const token = LocalStorage.getAccessToken();
//     const user = LocalStorage.getUserData();
//     setCurrentUser(user);

//     if (!user || !user.user.company || !user.user.company._id) {
//       setError(new Error("No company associated with this user."));
//       setLoading(false);
//       return;
//     }
//     try {
//       const response = await CommonApi.getProfilesByCompany(
//         user.user.company._id,
//         token
//       );
//       if (response && Array.isArray(response)) {
//         setUsers(response);
//       } else {
//         setUsers([]);
//       }
//       setLoading(false);
//     } catch (error) {
//       setError(error);
//       setLoading(false);
//     }
//   };

//   const handleEditUser = (user) => {
//     setNewUserData({
//       name: user.name,
//       email: user.email,
//       phoneNumber: user.phoneNumber,
//       address: user.address,
//       zipCode: user.zipCode,
//       role: user.role,
//       isActive: user.isActive,
//     });
//     setSelectedUserId(user._id);
//     setEditMode(true);
//     setOpen(true);
//   };

//   const handleDeleteUser = async (id) => {
//     const token = LocalStorage.getAccessToken();
//     try {
//       await CommonApi.deleteCandidateProfile(id, token);
//       fetchUsers();
//     } catch (error) {
//       console.error("Error deleting user:", error);
//     }
//   };

//   const handleAddUser = async () => {
//     const token = LocalStorage.getAccessToken();
//     const user = LocalStorage.getUserData();
//     const newUserDataWithCompany = {
//       ...newUserData,
//       company: user.user.company._id,
//       role: user.user.role === "companyOwner" ? "companyAdmin" : "companyUser",
//     };
//     try {
//       if (editMode) {
//         await CommonApi.updateCandidateProfile(
//           selectedUserId,
//           newUserDataWithCompany,
//           token
//         );
//       } else {
//         await CommonApi.createCandidateProfile(newUserDataWithCompany, token);
//       }
//       fetchUsers();
//       handleClose();
//     } catch (error) {
//       console.error("Error adding user:", error);
//     }
//   };

//   const handleClose = () => {
//     setOpen(false);
//     setEditMode(false);
//     setSelectedUserId(null);
//     setNewUserData({
//       name: "",
//       email: "",
//       phoneNumber: "",
//       address: {
//         country: "",
//         state: "",
//         city: "",
//       },
//       zipCode: "",
//       role: "companyUser",
//       birthDate: "",
//       isActive: true,
//     });
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     const [mainName, subName] = name.split(".");
//     if (subName) {
//       setNewUserData((prevData) => ({
//         ...prevData,
//         [mainName]: {
//           ...prevData[mainName],
//           [subName]: value,
//         },
//       }));
//     } else {
//       setNewUserData((prevData) => ({
//         ...prevData,
//         [name]: value,
//       }));
//     }
//   };

//   const ActionCellRenderer = (props) => {
//     return (
//       <div>
//         <IconButton onClick={() => handleEditUser(props.data)}>
//           <EditIcon sx={{ color: "#1976d2" }} />
//         </IconButton>
//         <IconButton onClick={() => handleDeleteUser(props.data._id)}>
//           <DeleteIcon sx={{ color: "#ED6157" }} />
//         </IconButton>
//       </div>
//     );
//   };

//   const StyledAgGrid = styled(AgGridReact)(({ theme }) => ({
//     '& .ag-header-row': {
//       backgroundColor: '#ff2600', 
//     },
//     '& .ag-header-cell': {
//       color: 'white', 
//       fontWeight: 'bold', 
//     },
//     '& .ag-row':{
//       borderColor: '#ff2600'
//     },
//     '& .ag-layout-normal':{
//       borderColor: '#ff2600'
//     }
//   }));

//   const [columnDefs] = useState([
//     { headerName: "Name", field: "name", filter: true, floatingFilter: true },
//     { headerName: "Email", field: "email", filter: true, floatingFilter: true },
//     {
//       headerName: "Phone",
//       field: "phoneNumber",
//       filter: true,
//       floatingFilter: true,
//     },
//     {
//       headerName: "Address",
//       field: "address",
//       valueGetter: (params) =>
//         `${params.data.address.country}, ${params.data.address.state}, ${params.data.address.city}`,
//       filter: true,
//       floatingFilter: true,
//     },
//     { headerName: "Role", field: "role", filter: true, floatingFilter: true },
//     {
//       headerName: "Zip Code",
//       field: "zipCode",
//       filter: true,
//       floatingFilter: true,
//     },
//     {
//       headerName: "Active",
//       field: "isActive",
//       valueGetter: (params) => (params.data.isActive ? "Yes" : "No"),
//       filter: true,
//       floatingFilter: true,
//     },
//     {
//       headerName: "Actions",
//       field: "actions",
//       cellRenderer: ActionCellRenderer,
//       editable: false,
//       colId: "actions",
//       minWidth: 150,
//     },
//   ]);

//   return (
//     <Container>
//       <Box
//         display="flex"
//         justifyContent="space-between"
//         alignItems="center"
//         paddingTop="20px"
//         paddingBottom="20px"
//       >
//         <Typography variant="h6" sx={{ fontWeight:'bold' }} > All Users</Typography>
//         <Button
//           variant="contained"
//           color="primary"
//           onClick={() => setOpen(true)}
//           sx={{ backgroundColor: "#FF2600", textTransform: 'capitalize' }}
//         >
//           Add User
//         </Button>
//       </Box>
//       <Paper>
//         <div className="ag-theme-alpine" style={{ height: 500, width: "100%" }}>
//           <StyledAgGrid
//             rowData={users}
//             columnDefs={columnDefs}
//             defaultColDef={{ sortable: true, filter: true }}
//             frameworkComponents={{ ActionCellRenderer }}
//             suppressClickEdit={true}
//             // className={classNames.agTable}
//           />
//         </div>
//       </Paper>
//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle>{editMode ? "Edit User" : "Add User"}</DialogTitle>
//         <DialogContent>
//           <TextField
//             margin="dense"
//             name="name"
//             label="Name"
//             type="text"
//             fullWidth
//             value={newUserData.name}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="email"
//             label="Email"
//             type="email"
//             fullWidth
//             value={newUserData.email}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="phoneNumber"
//             label="Phone Number"
//             type="text"
//             fullWidth
//             value={newUserData.phoneNumber}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="address.country"
//             label="Country"
//             type="text"
//             fullWidth
//             value={newUserData.address.country}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="address.state"
//             label="State"
//             type="text"
//             fullWidth
//             value={newUserData.address.state}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="birthDate"
//             label="Birth Date"
//             type="date"
//             fullWidth
//             value={newUserData.birthDate}
//             onChange={handleChange}
//             InputLabelProps={{
//               shrink: true, // Ensure the label stays above the input
//             }}
//           />
//           <TextField
//             margin="dense"
//             name="address.city"
//             label="City"
//             type="text"
//             fullWidth
//             value={newUserData.address.city}
//             onChange={handleChange}
//           />
//           <TextField
//             margin="dense"
//             name="zipCode"
//             label="Zip Code"
//             type="text"
//             fullWidth
//             value={newUserData.zipCode}
//             onChange={handleChange}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={handleAddUser} color="primary">
//             {editMode ? "Update" : "Add"}
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Container>
//   );
// };

// export default CompanyAdminUsers;

import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  IconButton,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import CommonApi from "../../../apis/CommonApi";
import LocalStorage from "../../../utils/LocalStorage";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from '@mui/system';
import PlusIcon from '../../../assets/svg/user-plus.svg'; // Ensure this path is correct for your project structure


const CompanyAdminUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [newUserData, setNewUserData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    address: {
      country: "",
      state: "",
      city: "",
    },
    zipCode: "",
    isActive: true,
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const token = LocalStorage.getAccessToken();
    const user = LocalStorage.getUserData();
    setCurrentUser(user);

    if (!user || !user.user.company || !user.user.company._id) {
      setError(new Error("No company associated with this user."));
      setLoading(false);
      return;
    }
    try {
      const response = await CommonApi.getProfilesByCompany(
        user.user.company._id,
        token
      );
      if (response && Array.isArray(response)) {
        setUsers(response);
      } else {
        setUsers([]);
      }
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const handleEditUser = (user) => {
    setNewUserData({
      name: user.name,
      email: user.email,
      phoneNumber: user.phoneNumber,
      address: user.address,
      zipCode: user.zipCode,
      role: user.role,
      isActive: user.isActive,
    });
    setSelectedUserId(user._id);
    setEditMode(true);
    setOpen(true);
  };

  const handleDeleteUser = async (id) => {
    const token = LocalStorage.getAccessToken();
    try {
      await CommonApi.deleteCandidateProfile(id, token);
      fetchUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleAddUser = async () => {
    const token = LocalStorage.getAccessToken();
    const user = LocalStorage.getUserData();
    const newUserDataWithCompany = {
      ...newUserData,
      company: user.user.company._id,
      role: user.user.role === "companyOwner" ? "companyAdmin" : "companyUser",
    };
    try {
      if (editMode) {
        await CommonApi.updateCandidateProfile(
          selectedUserId,
          newUserDataWithCompany,
          token
        );
      } else {
        await CommonApi.createCandidateProfile(newUserDataWithCompany, token);
      }
      fetchUsers();
      handleClose();
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    setSelectedUserId(null);
    setNewUserData({
      name: "",
      email: "",
      phoneNumber: "",
      address: {
        country: "",
        state: "",
        city: "",
      },
      zipCode: "",
      role: "companyUser",
      birthDate: "",
      isActive: true,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const [mainName, subName] = name.split(".");
    if (subName) {
      setNewUserData((prevData) => ({
        ...prevData,
        [mainName]: {
          ...prevData[mainName],
          [subName]: value,
        },
      }));
    } else {
      setNewUserData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const ActionCellRenderer = (props) => {
    return (
      <div>
        <IconButton onClick={() => handleEditUser(props.data)}>
          <EditIcon sx={{ color: "#1976d2" }} />
        </IconButton>
        <IconButton onClick={() => handleDeleteUser(props.data._id)}>
          <DeleteIcon sx={{ color: "#ED6157" }} />
        </IconButton>
      </div>
    );
  };

  const StyledAgGrid = styled(AgGridReact)(({ theme }) => ({
    '& .ag-header-row': {
      backgroundColor: '#ff2600', 
    },
    '& .ag-header-cell': {
      color: 'white', 
      fontWeight: 'bold', 
    },
    '& .ag-row':{
      borderColor: '#ff2600'
    },
    '& .ag-layout-normal':{
      borderColor: '#ff2600'
    }
  }));
  
  const StyledContainer = styled(Container)({
    margin: '0px !important',
    paddingLeft: '40px !important',
    paddingRight: '40px !important',
    maxWidth: '100% !important'
  });
  const [columnDefs] = useState([
    { headerName: "Name", field: "name", filter: true, floatingFilter: true },
    { headerName: "Email", field: "email", filter: true, floatingFilter: true },
    {
      headerName: "Phone",
      field: "phoneNumber",
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Address",
      field: "address",
      valueGetter: (params) =>
        `${params.data.address.country}, ${params.data.address.state}, ${params.data.address.city}`,
      filter: true,
      floatingFilter: true,
    },
    { headerName: "Role", field: "role", filter: true, floatingFilter: true },
    {
      headerName: "Zip Code",
      field: "zipCode",
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Active",
      field: "isActive",
      valueGetter: (params) => (params.data.isActive ? "Yes" : "No"),
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: ActionCellRenderer,
      editable: false,
      colId: "actions",
      minWidth: 150,
    },
  ]);

  return (
    <StyledContainer >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingTop="20px"
        paddingBottom="20px"
      >
        <Typography variant="h6" sx={{ fontWeight:'bold' }} > All Users</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
          sx={{ backgroundColor: "#FF2600", textTransform: 'capitalize', display: "flex", alignItems: "center" }}
        >
          <img src={PlusIcon} alt="Add User Icon" style={{ marginRight: "8px" }} />
          Add User
        </Button>
      </Box>
      <Paper>
        <div className="ag-theme-alpine" style={{ height: 500, width: "100%" }}>
          <StyledAgGrid
            rowData={users}
            columnDefs={columnDefs}
            defaultColDef={{ sortable: true, filter: true }}
            frameworkComponents={{ ActionCellRenderer }}
            pagination={true}  
            paginationPageSize={8}
            paginationAutoPageSize={true}
            />
        </div>
      </Paper>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editMode ? "Edit User" : "Add User"}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="name"
            label="Name"
            type="text"
            fullWidth
            value={newUserData.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={newUserData.email}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="phoneNumber"
            label="Phone Number"
            type="text"
            fullWidth
            value={newUserData.phoneNumber}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="address.country"
            label="Country"
            type="text"
            fullWidth
            value={newUserData.address.country}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="address.state"
            label="State"
            type="text"
            fullWidth
            value={newUserData.address.state}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="birthDate"
            label="Birth Date"
            type="date"
            fullWidth
            value={newUserData.birthDate}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true, // Ensure the label stays above the input
            }}
          />
          <TextField
            margin="dense"
            name="address.city"
            label="City"
            type="text"
            fullWidth
            value={newUserData.address.city}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="zipCode"
            label="Zip Code"
            type="text"
            fullWidth
            value={newUserData.zipCode}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddUser} color="primary">
            {editMode ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
};

export default CompanyAdminUsers;
