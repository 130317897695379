import React from 'react';
import { Box, Typography, Grid, Paper, Container, IconButton, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountInfo from './AccountInfo';
import LoginInfo from './LoginInfo';
import AccountSecurity from './AccountSecurity';
import SocialLogins from './SocialLogins';
import EmailPreferences from './EmailPreferences';
import LoginActivity from './LoginActivity';
import PrivacyPreferences from './PrivacyPreferences';
import CloseAccount from './CloseAccount';
import IndividualHeader from '../IndividualHeader';
import IndividualFooter from '../IndividualFooter';
import TwoStepVerification from './TwoStepVerification';
import UpdateResume from './UpdateResume';
import QuickLinksSidebar from './QuickLinksSidebar';
import KeySkills from './KeySkills';
import Employment from './Employment';
import Education from './Education';
import { useNavigate } from 'react-router-dom';



const AccountSettings = () => {

    const navigate = useNavigate()
  const handleBackClick = () => {
    navigate('/search');
  };

  return (
    <div style={{ width: '100%' }}>
      <IndividualHeader />

      {/* Sticky Header Text */}
      <Box sx={{ position: 'sticky', top: 0, zIndex: 2, py: 2, px: 2, width: '100%', bgcolor: '#f5f6f7' }}>
        <Container maxWidth={false} sx={{ px: { xs: 2, sm: 3 }, mt: 9, display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={handleBackClick}
            color="primary"
            sx={{ mr: 1, display: 'flex', alignItems: 'center', textDecoration: 'none' }}
          >
            <ArrowBackIcon fontSize="small" />
            <Typography variant="body2" color="primary" sx={{ ml: 0.5 }}>
              Back
            </Typography>
          </IconButton>
          <Box>
            <Typography variant="h6" component="h1" sx={{ fontWeight: 'bold', color: '#333' }}>
              Account Settings
            </Typography>
            <Typography variant="body2" sx={{ color: '#a1a1a1' }}>
              View and update your account details, profile, and more.
            </Typography>
          </Box>
        </Container>
      </Box>

      <Container maxWidth={false} sx={{ bgcolor: '#f5f6f7', px: { xs: 2, sm: 3 }, mb: 4 }}>
        <Grid container spacing={4}>

          {/* Left Sidebar - Quick Links */}
          <Grid item xs={12} md={3} lg={2} sx={{ display: { xs: 'none', md: 'block' } }}>
            <QuickLinksSidebar />
          </Grid>

          {/* Main Content */}
          <Grid item xs={12} md={9} lg={10}>
            <Grid container spacing={4}>

              {/* Make these items full width (xs=12) on md screens (900-1200px) */}
              <Grid item xs={12} md={12} lg={7} id="account-info">
                <Paper elevation={1} sx={{ p: { xs: 2, sm: 3 }, borderRadius: '8px', width: '100%', height:"100%" }}>
                  <AccountInfo />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} lg={5} id="account-security">
                <Paper elevation={1} sx={{ p: { xs: 2, sm: 3 }, borderRadius: '8px', width: '100%', height:"100%" }}>
                  <AccountSecurity />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
            <LoginInfo />
        </Grid>
        <Grid item xs={12} md={6}>
            <TwoStepVerification />
        </Grid>

              {/* Full-Width Boxes */}
              <Grid item xs={12} id="social-logins">
                <Paper elevation={1} sx={{ p: { xs: 2, sm: 3 }, borderRadius: '8px', width: '100%' }}>
                  <SocialLogins />
                </Paper>
              </Grid>

              {/* Additional Sections */}
              <Grid item xs={12} id="key-skills">
                <Paper elevation={1} sx={{ p: { xs: 2, sm: 3 }, borderRadius: '8px', width: '100%' }}>
                  <KeySkills />
                </Paper>
              </Grid>
              <Grid item xs={12} id="employment">
                <Paper elevation={1} sx={{ p: { xs: 2, sm: 3 }, borderRadius: '8px', width: '100%' }}>
                  <Employment />
                </Paper>
              </Grid>
              <Grid item xs={12} id="education">
                <Paper elevation={1} sx={{ p: { xs: 2, sm: 3 }, borderRadius: '8px', width: '100%' }}>
                  <Education />
                </Paper>
              </Grid>

              {/* Existing Sections */}
              <Grid item xs={12} id="update-resume">
                <Paper elevation={1} sx={{ p: { xs: 2, sm: 3 }, borderRadius: '8px', width: '100%' }}>
                  <UpdateResume />
                </Paper>
              </Grid>
              <Grid item xs={12} id="email-preferences">
                <Paper elevation={1} sx={{ p: { xs: 2, sm: 3 }, borderRadius: '8px', width: '100%' }}>
                  <EmailPreferences />
                </Paper>
              </Grid>
              <Grid item xs={12} id="login-activity">
                <Paper elevation={1} sx={{ p: { xs: 2, sm: 3 }, borderRadius: '8px', width: '100%' }}>
                  <LoginActivity />
                </Paper>
              </Grid>
              <Grid item xs={12} id="privacy-preferences">
                <Paper elevation={1} sx={{ p: { xs: 2, sm: 3 }, borderRadius: '8px', width: '100%' }}>
                  <PrivacyPreferences />
                </Paper>
              </Grid>
              <Grid item xs={12} id="close-account">
                <Paper elevation={1} sx={{ p: { xs: 2, sm: 3 }, borderRadius: '8px', width: '100%' }}>
                  <CloseAccount />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <IndividualFooter />
    </div>
  );
};

export default AccountSettings;
