// // src/components/Interviewee/IntervieweeDashboard.js
// import React from "react";
// import { Button, Grid, Typography } from "@mui/material";
// import { useNavigate } from "react-router-dom";

// const IntervieweeDashboard = () => {
//   const navigate = useNavigate();

//   const handleProfileView = () => {
//     navigate("/interviewee-profile");
//   };

//   const handleJobApplicationsView = () => {
//     navigate("/interviewee-job-applications");
//   };

//   return (
//     <Grid container spacing={3} justifyContent="center">
//       <Grid item xs={12} md={8}>
//         <Typography variant="h4" align="center" gutterBottom>
//           Interviewee Dashboard
//         </Typography>
//         <Button
//           variant="contained"
//           color="primary"
//           fullWidth
//           style={{ marginBottom: 20 }}
//           onClick={handleProfileView}
//         >
//           View Profile
//         </Button>
//         <Button
//           variant="contained"
//           color="secondary"
//           fullWidth
//           onClick={handleJobApplicationsView}
//         >
//           Attend Interview
//         </Button>
//       </Grid>
//     </Grid>
//   );
// };

// export default IntervieweeDashboard;


// src/interviewee/pages/IntervieweeDashboard.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

function IntervieweeDashboard() {
  const navigate = useNavigate();

  const handleUploadClick = () => {
    navigate('/interviewee-upload'); // Navigate to IntervieweeUpload component
  };

  return (
    <div>
      <h1>Interviewee Dashboard</h1>
      <button onClick={handleUploadClick}>
        Upload Resume and Job Description
      </button>
    </div>
  );
}

export default IntervieweeDashboard;
