// src/layouts/employerDashboard/EmployerDashboard.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Typography } from '@mui/material';

const EmployerDashboard = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Employer Dashboard
      </Typography>
      <Button variant="contained" color="primary" component={Link} to="/job-description">
        Create Job Description
      </Button>
      <Button variant="contained" color="secondary" component={Link} to="/resume-upload" style={{ marginLeft: '10px' }}>
        Upload Resumes
      </Button>
    </Container>
  );
};

export default EmployerDashboard;
