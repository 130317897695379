import React, { useState } from 'react';
import { Box, Typography, Button, LinearProgress, IconButton, TextField, Divider } from '@mui/material';
import { CloudUpload, Close } from '@mui/icons-material';
import { styled } from '@mui/system';

const FileUploadContainer = styled(Box)(({ theme }) => ({
  border: '2px dashed #E0E0E0',
  borderRadius: '8px',
  padding: '20px',
  textAlign: 'center',
  backgroundColor: '#F9FAFB',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const ChatContainer = styled(Box)(({ theme }) => ({
  maxHeight: '400px',
  minHeight: '400px',
  overflowY: 'auto',
  border: '1px solid #E0E0E0',
  borderRadius: '8px',
  padding: '20px',
  backgroundColor: '#FFFFFF',
}));

const OrDivider = styled(Divider)(({ theme }) => ({
  '&::before': {
    borderTop: '1px dashed #C4C4C4',
  },
  '&::after': {
    borderTop: '1px dashed #C4C4C4',
  },
  '&::before, &::after': {
    content: '""',
    flexGrow: 1,
  },
  position: 'relative',
  margin: theme.spacing(2, 0),
  width: '100%',
  '&::before': {
    content: '"Or"',
    position: 'absolute',
    top: '-15px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#FFF',
    padding: '0 8px',
    color: '#888888',
  },
}));

function CreateJobByEmployer() {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [chatInput, setChatInput] = useState('');
  const [chatMessages, setChatMessages] = useState([
    { sender: 'bot', text: 'Hello! How can I help you create a job listing today?' },
  ]);

  const handleFileUpload = () => {
    setIsUploading(true);
    setUploadProgress(0);

    const uploadInterval = setInterval(() => {
      setUploadProgress((prev) => {
        if (prev >= 100) {
          clearInterval(uploadInterval);
          setIsUploading(false);
          return 100;
        }
        return prev + 10;
      });
    }, 300);
  };

  const handleChatSubmit = () => {
    if (chatInput.trim()) {
      setChatMessages([...chatMessages, { sender: 'user', text: chatInput }]);
      setChatInput('');
      setTimeout(() => {
        setChatMessages((prev) => [
          ...prev,
          { sender: 'bot', text: 'Thank you! Let me assist you with that.' },
        ]);
      }, 1000);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems="stretch"
      justifyContent="center"
      gap={4}
      p={2}
      sx={{ width: '100%' }}
    >
      {/* File Upload Section */}
      <Box flex={1} minHeight={{ xs: 'auto', md: '400px' }} mb={{ xs: 0, md: 0 }}>
        <FileUploadContainer>
          <CloudUpload sx={{ fontSize: 40, color: '#1976D2', alignSelf: 'center' }} />
          <Typography variant="h6" sx={{ mt: 1 }}>
            Import a custom job description
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Maximum file size: 50 MB
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Supported format: .CSV or XLSX
          </Typography>
          <Button variant="contained" color="primary" onClick={handleFileUpload} disabled={isUploading}>
            {isUploading ? 'Uploading...' : 'Upload File'}
          </Button>

          {isUploading && (
            <Box mt={2} display="flex" alignItems="center" width="100%">
              <Typography variant="body2">classification_v3.xlsx</Typography>
              <LinearProgress
                variant="determinate"
                value={uploadProgress}
                sx={{ width: '100%', ml: 2, mr: 2 }}
              />
              <IconButton size="small" onClick={() => setIsUploading(false)}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          )}
        </FileUploadContainer>
      </Box>

      {/* OR Divider */}
      <Box display={{ xs: 'none', md: 'block' }} flex="0 0 auto">
        <OrDivider orientation="vertical" flexItem />
      </Box>

      {/* Chatbot Section */}
      <Box flex={1} display="flex" flexDirection="column">
        <Box mb={2}>
          <Typography variant="h6">Create a Job Using Chatbot</Typography>
          <Typography variant="body2" color="textSecondary">
            Ask the chatbot for help in creating a job listing.
          </Typography>
        </Box>
        <ChatContainer>
          {chatMessages.map((message, index) => (
            <Box key={index} mb={2} textAlign={message.sender === 'user' ? 'right' : 'left'}>
              <Typography
                sx={{
                  display: 'inline-block',
                  backgroundColor: message.sender === 'user' ? '#1976D2' : '#F1F1F1',
                  color: message.sender === 'user' ? '#FFF' : '#000',
                  borderRadius: '12px',
                  padding: '8px 12px',
                  maxWidth: '80%',
                }}
              >
                {message.text}
              </Typography>
            </Box>
          ))}
        </ChatContainer>
        
        {/* Responsive Chat Input and Button */}
        <Box
          mt={2}
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          gap={1}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type your message..."
            value={chatInput}
            onChange={(e) => setChatInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleChatSubmit()}
            sx={{ flexGrow: 1 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleChatSubmit}
            sx={{
              width: { xs: '100%', sm: 'auto' },
              minWidth: '100px',
              height: { xs: '56px !important', sm: '100%' },
              bgcolor: '#1976D2',
              '&:hover': { bgcolor: '#115293' },
            }}
          >
            Send
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default CreateJobByEmployer;
