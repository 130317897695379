// src/data/jobDescriptions.js

const jobDescriptions = [
  {
    id: 1,
    title: 'Software Engineer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 1,
    title: 'Software Developer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 2,
    title: 'Data Scientist',
    description: 'Analyzes data to help organizations make informed decisions.',
    fullDescription: `
      As a Data Scientist, you will analyze large sets of data to extract valuable insights. You will work with stakeholders to define problems and develop solutions using statistical analysis, machine learning, and data visualization techniques.
      
      Responsibilities:
      - Collect, clean, and process large datasets.
      - Build predictive models to solve business problems.
      - Collaborate with engineering and product teams to implement solutions.
      - Present findings to stakeholders in an understandable format.
      - Stay up-to-date with new data science methodologies.

      Qualifications:
      - Master’s degree in Data Science, Statistics, or related field.
      - Proficiency in Python and SQL.
      - Experience with machine learning frameworks (e.g., TensorFlow, Scikit-learn).
      - Strong statistical and analytical skills.
      - Ability to communicate complex results in a clear manner.

      Additional Information:
      - Location: New York, NY
      - Job Type: Full-time
      - Salary: $100,000 - $120,000 per year
    `,
    location: 'New York, NY',
    jobType: 'Full-time',
    salary: '$100,000 - $120,000 per year'
  },
  {
    id: 3,
    title: 'Product Manager',
    description: 'Oversees the development and launch of products.',
    fullDescription: `
      As a Product Manager, you will manage the development and lifecycle of products from ideation to launch. You will collaborate with engineering, marketing, and sales teams to define product strategy and ensure customer needs are met.

      Responsibilities:
      - Define and communicate product vision and strategy.
      - Conduct market research to identify customer needs.
      - Collaborate with development teams to define product requirements.
      - Monitor product performance and make adjustments as needed.
      - Drive product launch and manage cross-functional team activities.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Proven experience in product management.
      - Strong understanding of market research and analytics.
      - Excellent leadership and communication skills.
      - Ability to work in a fast-paced environment.

      Additional Information:
      - Location: San Francisco, CA
      - Job Type: Full-time
      - Salary: $120,000 - $140,000 per year
    `,
    location: 'San Francisco, CA',
    jobType: 'Full-time',
    salary: '$120,000 - $140,000 per year'
  },
  {
    id: 4,
    title: 'UI/UX Designer',
    description: 'Designs user interfaces and experiences for applications.',
    fullDescription: `
      As a UI/UX Designer, you will design intuitive and visually appealing user interfaces. You will work with product and engineering teams to ensure a seamless user experience across all devices and platforms.

      Responsibilities:
      - Design user interfaces for web and mobile applications.
      - Create wireframes, prototypes, and user flow diagrams.
      - Conduct user research and usability testing.
      - Collaborate with developers to ensure design consistency.
      - Stay updated on industry trends and best practices.

      Qualifications:
      - Bachelor’s degree in Design or related field.
      - Proficiency in design tools (e.g., Figma, Adobe XD).
      - Strong understanding of user-centered design principles.
      - Excellent visual design skills and attention to detail.
      - Portfolio showcasing UI/UX design work.

      Additional Information:
      - Location: Remote
      - Job Type: Part-time
      - Salary: $50,000 - $70,000 per year
    `,
    location: 'Remote',
    jobType: 'Part-time',
    salary: '$50,000 - $70,000 per year'
  },
  {
    id: 5,
    title: 'Business Analyst',
    description: 'Evaluates business data to provide insights and improve performance.',
    fullDescription: `
      As a Business Analyst, you will analyze business processes and data to provide actionable insights that improve performance. You will work with stakeholders to identify business needs and translate them into technical requirements.

      Responsibilities:
      - Gather and analyze data on business operations and processes.
      - Identify areas for improvement and propose solutions.
      - Develop reports and dashboards to track performance.
      - Collaborate with IT and business teams to implement changes.
      - Communicate findings to management and stakeholders.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Strong analytical and problem-solving skills.
      - Experience with data analysis tools (e.g., Excel, Tableau).
      - Excellent communication and presentation skills.
      - Ability to work independently and manage multiple projects.

      Additional Information:
      - Location: Chicago, IL
      - Job Type: Full-time
      - Salary: $80,000 - $100,000 per year
    `,
    location: 'Chicago, IL',
    jobType: 'Full-time',
    salary: '$80,000 - $100,000 per year'
  },

  {
    id: 1,
    title: 'Software Engineer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 1,
    title: 'Software Developer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 2,
    title: 'Data Scientist',
    description: 'Analyzes data to help organizations make informed decisions.',
    fullDescription: `
      As a Data Scientist, you will analyze large sets of data to extract valuable insights. You will work with stakeholders to define problems and develop solutions using statistical analysis, machine learning, and data visualization techniques.
      
      Responsibilities:
      - Collect, clean, and process large datasets.
      - Build predictive models to solve business problems.
      - Collaborate with engineering and product teams to implement solutions.
      - Present findings to stakeholders in an understandable format.
      - Stay up-to-date with new data science methodologies.

      Qualifications:
      - Master’s degree in Data Science, Statistics, or related field.
      - Proficiency in Python and SQL.
      - Experience with machine learning frameworks (e.g., TensorFlow, Scikit-learn).
      - Strong statistical and analytical skills.
      - Ability to communicate complex results in a clear manner.

      Additional Information:
      - Location: New York, NY
      - Job Type: Full-time
      - Salary: $100,000 - $120,000 per year
    `,
    location: 'New York, NY',
    jobType: 'Full-time',
    salary: '$100,000 - $120,000 per year'
  },
  {
    id: 3,
    title: 'Product Manager',
    description: 'Oversees the development and launch of products.',
    fullDescription: `
      As a Product Manager, you will manage the development and lifecycle of products from ideation to launch. You will collaborate with engineering, marketing, and sales teams to define product strategy and ensure customer needs are met.

      Responsibilities:
      - Define and communicate product vision and strategy.
      - Conduct market research to identify customer needs.
      - Collaborate with development teams to define product requirements.
      - Monitor product performance and make adjustments as needed.
      - Drive product launch and manage cross-functional team activities.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Proven experience in product management.
      - Strong understanding of market research and analytics.
      - Excellent leadership and communication skills.
      - Ability to work in a fast-paced environment.

      Additional Information:
      - Location: San Francisco, CA
      - Job Type: Full-time
      - Salary: $120,000 - $140,000 per year
    `,
    location: 'San Francisco, CA',
    jobType: 'Full-time',
    salary: '$120,000 - $140,000 per year'
  },
  {
    id: 4,
    title: 'UI/UX Designer',
    description: 'Designs user interfaces and experiences for applications.',
    fullDescription: `
      As a UI/UX Designer, you will design intuitive and visually appealing user interfaces. You will work with product and engineering teams to ensure a seamless user experience across all devices and platforms.

      Responsibilities:
      - Design user interfaces for web and mobile applications.
      - Create wireframes, prototypes, and user flow diagrams.
      - Conduct user research and usability testing.
      - Collaborate with developers to ensure design consistency.
      - Stay updated on industry trends and best practices.

      Qualifications:
      - Bachelor’s degree in Design or related field.
      - Proficiency in design tools (e.g., Figma, Adobe XD).
      - Strong understanding of user-centered design principles.
      - Excellent visual design skills and attention to detail.
      - Portfolio showcasing UI/UX design work.

      Additional Information:
      - Location: Remote
      - Job Type: Part-time
      - Salary: $50,000 - $70,000 per year
    `,
    location: 'Remote',
    jobType: 'Part-time',
    salary: '$50,000 - $70,000 per year'
  },
  {
    id: 5,
    title: 'Business Analyst',
    description: 'Evaluates business data to provide insights and improve performance.',
    fullDescription: `
      As a Business Analyst, you will analyze business processes and data to provide actionable insights that improve performance. You will work with stakeholders to identify business needs and translate them into technical requirements.

      Responsibilities:
      - Gather and analyze data on business operations and processes.
      - Identify areas for improvement and propose solutions.
      - Develop reports and dashboards to track performance.
      - Collaborate with IT and business teams to implement changes.
      - Communicate findings to management and stakeholders.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Strong analytical and problem-solving skills.
      - Experience with data analysis tools (e.g., Excel, Tableau).
      - Excellent communication and presentation skills.
      - Ability to work independently and manage multiple projects.

      Additional Information:
      - Location: Chicago, IL
      - Job Type: Full-time
      - Salary: $80,000 - $100,000 per year
    `,
    location: 'Chicago, IL',
    jobType: 'Full-time',
    salary: '$80,000 - $100,000 per year'
  },

  {
    id: 1,
    title: 'Software Engineer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 1,
    title: 'Software Developer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 2,
    title: 'Data Scientist',
    description: 'Analyzes data to help organizations make informed decisions.',
    fullDescription: `
      As a Data Scientist, you will analyze large sets of data to extract valuable insights. You will work with stakeholders to define problems and develop solutions using statistical analysis, machine learning, and data visualization techniques.
      
      Responsibilities:
      - Collect, clean, and process large datasets.
      - Build predictive models to solve business problems.
      - Collaborate with engineering and product teams to implement solutions.
      - Present findings to stakeholders in an understandable format.
      - Stay up-to-date with new data science methodologies.

      Qualifications:
      - Master’s degree in Data Science, Statistics, or related field.
      - Proficiency in Python and SQL.
      - Experience with machine learning frameworks (e.g., TensorFlow, Scikit-learn).
      - Strong statistical and analytical skills.
      - Ability to communicate complex results in a clear manner.

      Additional Information:
      - Location: New York, NY
      - Job Type: Full-time
      - Salary: $100,000 - $120,000 per year
    `,
    location: 'New York, NY',
    jobType: 'Full-time',
    salary: '$100,000 - $120,000 per year'
  },
  {
    id: 3,
    title: 'Product Manager',
    description: 'Oversees the development and launch of products.',
    fullDescription: `
      As a Product Manager, you will manage the development and lifecycle of products from ideation to launch. You will collaborate with engineering, marketing, and sales teams to define product strategy and ensure customer needs are met.

      Responsibilities:
      - Define and communicate product vision and strategy.
      - Conduct market research to identify customer needs.
      - Collaborate with development teams to define product requirements.
      - Monitor product performance and make adjustments as needed.
      - Drive product launch and manage cross-functional team activities.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Proven experience in product management.
      - Strong understanding of market research and analytics.
      - Excellent leadership and communication skills.
      - Ability to work in a fast-paced environment.

      Additional Information:
      - Location: San Francisco, CA
      - Job Type: Full-time
      - Salary: $120,000 - $140,000 per year
    `,
    location: 'San Francisco, CA',
    jobType: 'Full-time',
    salary: '$120,000 - $140,000 per year'
  },
  {
    id: 4,
    title: 'UI/UX Designer',
    description: 'Designs user interfaces and experiences for applications.',
    fullDescription: `
      As a UI/UX Designer, you will design intuitive and visually appealing user interfaces. You will work with product and engineering teams to ensure a seamless user experience across all devices and platforms.

      Responsibilities:
      - Design user interfaces for web and mobile applications.
      - Create wireframes, prototypes, and user flow diagrams.
      - Conduct user research and usability testing.
      - Collaborate with developers to ensure design consistency.
      - Stay updated on industry trends and best practices.

      Qualifications:
      - Bachelor’s degree in Design or related field.
      - Proficiency in design tools (e.g., Figma, Adobe XD).
      - Strong understanding of user-centered design principles.
      - Excellent visual design skills and attention to detail.
      - Portfolio showcasing UI/UX design work.

      Additional Information:
      - Location: Remote
      - Job Type: Part-time
      - Salary: $50,000 - $70,000 per year
    `,
    location: 'Remote',
    jobType: 'Part-time',
    salary: '$50,000 - $70,000 per year'
  },
  {
    id: 5,
    title: 'Business Analyst',
    description: 'Evaluates business data to provide insights and improve performance.',
    fullDescription: `
      As a Business Analyst, you will analyze business processes and data to provide actionable insights that improve performance. You will work with stakeholders to identify business needs and translate them into technical requirements.

      Responsibilities:
      - Gather and analyze data on business operations and processes.
      - Identify areas for improvement and propose solutions.
      - Develop reports and dashboards to track performance.
      - Collaborate with IT and business teams to implement changes.
      - Communicate findings to management and stakeholders.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Strong analytical and problem-solving skills.
      - Experience with data analysis tools (e.g., Excel, Tableau).
      - Excellent communication and presentation skills.
      - Ability to work independently and manage multiple projects.

      Additional Information:
      - Location: Chicago, IL
      - Job Type: Full-time
      - Salary: $80,000 - $100,000 per year
    `,
    location: 'Chicago, IL',
    jobType: 'Full-time',
    salary: '$80,000 - $100,000 per year'
  },

  {
    id: 1,
    title: 'Software Engineer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 1,
    title: 'Software Developer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 2,
    title: 'Data Scientist',
    description: 'Analyzes data to help organizations make informed decisions.',
    fullDescription: `
      As a Data Scientist, you will analyze large sets of data to extract valuable insights. You will work with stakeholders to define problems and develop solutions using statistical analysis, machine learning, and data visualization techniques.
      
      Responsibilities:
      - Collect, clean, and process large datasets.
      - Build predictive models to solve business problems.
      - Collaborate with engineering and product teams to implement solutions.
      - Present findings to stakeholders in an understandable format.
      - Stay up-to-date with new data science methodologies.

      Qualifications:
      - Master’s degree in Data Science, Statistics, or related field.
      - Proficiency in Python and SQL.
      - Experience with machine learning frameworks (e.g., TensorFlow, Scikit-learn).
      - Strong statistical and analytical skills.
      - Ability to communicate complex results in a clear manner.

      Additional Information:
      - Location: New York, NY
      - Job Type: Full-time
      - Salary: $100,000 - $120,000 per year
    `,
    location: 'New York, NY',
    jobType: 'Full-time',
    salary: '$100,000 - $120,000 per year'
  },
  {
    id: 3,
    title: 'Product Manager',
    description: 'Oversees the development and launch of products.',
    fullDescription: `
      As a Product Manager, you will manage the development and lifecycle of products from ideation to launch. You will collaborate with engineering, marketing, and sales teams to define product strategy and ensure customer needs are met.

      Responsibilities:
      - Define and communicate product vision and strategy.
      - Conduct market research to identify customer needs.
      - Collaborate with development teams to define product requirements.
      - Monitor product performance and make adjustments as needed.
      - Drive product launch and manage cross-functional team activities.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Proven experience in product management.
      - Strong understanding of market research and analytics.
      - Excellent leadership and communication skills.
      - Ability to work in a fast-paced environment.

      Additional Information:
      - Location: San Francisco, CA
      - Job Type: Full-time
      - Salary: $120,000 - $140,000 per year
    `,
    location: 'San Francisco, CA',
    jobType: 'Full-time',
    salary: '$120,000 - $140,000 per year'
  },
  {
    id: 4,
    title: 'UI/UX Designer',
    description: 'Designs user interfaces and experiences for applications.',
    fullDescription: `
      As a UI/UX Designer, you will design intuitive and visually appealing user interfaces. You will work with product and engineering teams to ensure a seamless user experience across all devices and platforms.

      Responsibilities:
      - Design user interfaces for web and mobile applications.
      - Create wireframes, prototypes, and user flow diagrams.
      - Conduct user research and usability testing.
      - Collaborate with developers to ensure design consistency.
      - Stay updated on industry trends and best practices.

      Qualifications:
      - Bachelor’s degree in Design or related field.
      - Proficiency in design tools (e.g., Figma, Adobe XD).
      - Strong understanding of user-centered design principles.
      - Excellent visual design skills and attention to detail.
      - Portfolio showcasing UI/UX design work.

      Additional Information:
      - Location: Remote
      - Job Type: Part-time
      - Salary: $50,000 - $70,000 per year
    `,
    location: 'Remote',
    jobType: 'Part-time',
    salary: '$50,000 - $70,000 per year'
  },
  {
    id: 5,
    title: 'Business Analyst',
    description: 'Evaluates business data to provide insights and improve performance.',
    fullDescription: `
      As a Business Analyst, you will analyze business processes and data to provide actionable insights that improve performance. You will work with stakeholders to identify business needs and translate them into technical requirements.

      Responsibilities:
      - Gather and analyze data on business operations and processes.
      - Identify areas for improvement and propose solutions.
      - Develop reports and dashboards to track performance.
      - Collaborate with IT and business teams to implement changes.
      - Communicate findings to management and stakeholders.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Strong analytical and problem-solving skills.
      - Experience with data analysis tools (e.g., Excel, Tableau).
      - Excellent communication and presentation skills.
      - Ability to work independently and manage multiple projects.

      Additional Information:
      - Location: Chicago, IL
      - Job Type: Full-time
      - Salary: $80,000 - $100,000 per year
    `,
    location: 'Chicago, IL',
    jobType: 'Full-time',
    salary: '$80,000 - $100,000 per year'
  },

  {
    id: 1,
    title: 'Software Engineer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 1,
    title: 'Software Developer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 2,
    title: 'Data Scientist',
    description: 'Analyzes data to help organizations make informed decisions.',
    fullDescription: `
      As a Data Scientist, you will analyze large sets of data to extract valuable insights. You will work with stakeholders to define problems and develop solutions using statistical analysis, machine learning, and data visualization techniques.
      
      Responsibilities:
      - Collect, clean, and process large datasets.
      - Build predictive models to solve business problems.
      - Collaborate with engineering and product teams to implement solutions.
      - Present findings to stakeholders in an understandable format.
      - Stay up-to-date with new data science methodologies.

      Qualifications:
      - Master’s degree in Data Science, Statistics, or related field.
      - Proficiency in Python and SQL.
      - Experience with machine learning frameworks (e.g., TensorFlow, Scikit-learn).
      - Strong statistical and analytical skills.
      - Ability to communicate complex results in a clear manner.

      Additional Information:
      - Location: New York, NY
      - Job Type: Full-time
      - Salary: $100,000 - $120,000 per year
    `,
    location: 'New York, NY',
    jobType: 'Full-time',
    salary: '$100,000 - $120,000 per year'
  },
  {
    id: 3,
    title: 'Product Manager',
    description: 'Oversees the development and launch of products.',
    fullDescription: `
      As a Product Manager, you will manage the development and lifecycle of products from ideation to launch. You will collaborate with engineering, marketing, and sales teams to define product strategy and ensure customer needs are met.

      Responsibilities:
      - Define and communicate product vision and strategy.
      - Conduct market research to identify customer needs.
      - Collaborate with development teams to define product requirements.
      - Monitor product performance and make adjustments as needed.
      - Drive product launch and manage cross-functional team activities.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Proven experience in product management.
      - Strong understanding of market research and analytics.
      - Excellent leadership and communication skills.
      - Ability to work in a fast-paced environment.

      Additional Information:
      - Location: San Francisco, CA
      - Job Type: Full-time
      - Salary: $120,000 - $140,000 per year
    `,
    location: 'San Francisco, CA',
    jobType: 'Full-time',
    salary: '$120,000 - $140,000 per year'
  },
  {
    id: 4,
    title: 'UI/UX Designer',
    description: 'Designs user interfaces and experiences for applications.',
    fullDescription: `
      As a UI/UX Designer, you will design intuitive and visually appealing user interfaces. You will work with product and engineering teams to ensure a seamless user experience across all devices and platforms.

      Responsibilities:
      - Design user interfaces for web and mobile applications.
      - Create wireframes, prototypes, and user flow diagrams.
      - Conduct user research and usability testing.
      - Collaborate with developers to ensure design consistency.
      - Stay updated on industry trends and best practices.

      Qualifications:
      - Bachelor’s degree in Design or related field.
      - Proficiency in design tools (e.g., Figma, Adobe XD).
      - Strong understanding of user-centered design principles.
      - Excellent visual design skills and attention to detail.
      - Portfolio showcasing UI/UX design work.

      Additional Information:
      - Location: Remote
      - Job Type: Part-time
      - Salary: $50,000 - $70,000 per year
    `,
    location: 'Remote',
    jobType: 'Part-time',
    salary: '$50,000 - $70,000 per year'
  },
  {
    id: 5,
    title: 'Business Analyst',
    description: 'Evaluates business data to provide insights and improve performance.',
    fullDescription: `
      As a Business Analyst, you will analyze business processes and data to provide actionable insights that improve performance. You will work with stakeholders to identify business needs and translate them into technical requirements.

      Responsibilities:
      - Gather and analyze data on business operations and processes.
      - Identify areas for improvement and propose solutions.
      - Develop reports and dashboards to track performance.
      - Collaborate with IT and business teams to implement changes.
      - Communicate findings to management and stakeholders.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Strong analytical and problem-solving skills.
      - Experience with data analysis tools (e.g., Excel, Tableau).
      - Excellent communication and presentation skills.
      - Ability to work independently and manage multiple projects.

      Additional Information:
      - Location: Chicago, IL
      - Job Type: Full-time
      - Salary: $80,000 - $100,000 per year
    `,
    location: 'Chicago, IL',
    jobType: 'Full-time',
    salary: '$80,000 - $100,000 per year'
  },

  {
    id: 1,
    title: 'Software Engineer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 1,
    title: 'Software Developer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 2,
    title: 'Data Scientist',
    description: 'Analyzes data to help organizations make informed decisions.',
    fullDescription: `
      As a Data Scientist, you will analyze large sets of data to extract valuable insights. You will work with stakeholders to define problems and develop solutions using statistical analysis, machine learning, and data visualization techniques.
      
      Responsibilities:
      - Collect, clean, and process large datasets.
      - Build predictive models to solve business problems.
      - Collaborate with engineering and product teams to implement solutions.
      - Present findings to stakeholders in an understandable format.
      - Stay up-to-date with new data science methodologies.

      Qualifications:
      - Master’s degree in Data Science, Statistics, or related field.
      - Proficiency in Python and SQL.
      - Experience with machine learning frameworks (e.g., TensorFlow, Scikit-learn).
      - Strong statistical and analytical skills.
      - Ability to communicate complex results in a clear manner.

      Additional Information:
      - Location: New York, NY
      - Job Type: Full-time
      - Salary: $100,000 - $120,000 per year
    `,
    location: 'New York, NY',
    jobType: 'Full-time',
    salary: '$100,000 - $120,000 per year'
  },
  {
    id: 3,
    title: 'Product Manager',
    description: 'Oversees the development and launch of products.',
    fullDescription: `
      As a Product Manager, you will manage the development and lifecycle of products from ideation to launch. You will collaborate with engineering, marketing, and sales teams to define product strategy and ensure customer needs are met.

      Responsibilities:
      - Define and communicate product vision and strategy.
      - Conduct market research to identify customer needs.
      - Collaborate with development teams to define product requirements.
      - Monitor product performance and make adjustments as needed.
      - Drive product launch and manage cross-functional team activities.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Proven experience in product management.
      - Strong understanding of market research and analytics.
      - Excellent leadership and communication skills.
      - Ability to work in a fast-paced environment.

      Additional Information:
      - Location: San Francisco, CA
      - Job Type: Full-time
      - Salary: $120,000 - $140,000 per year
    `,
    location: 'San Francisco, CA',
    jobType: 'Full-time',
    salary: '$120,000 - $140,000 per year'
  },
  {
    id: 4,
    title: 'UI/UX Designer',
    description: 'Designs user interfaces and experiences for applications.',
    fullDescription: `
      As a UI/UX Designer, you will design intuitive and visually appealing user interfaces. You will work with product and engineering teams to ensure a seamless user experience across all devices and platforms.

      Responsibilities:
      - Design user interfaces for web and mobile applications.
      - Create wireframes, prototypes, and user flow diagrams.
      - Conduct user research and usability testing.
      - Collaborate with developers to ensure design consistency.
      - Stay updated on industry trends and best practices.

      Qualifications:
      - Bachelor’s degree in Design or related field.
      - Proficiency in design tools (e.g., Figma, Adobe XD).
      - Strong understanding of user-centered design principles.
      - Excellent visual design skills and attention to detail.
      - Portfolio showcasing UI/UX design work.

      Additional Information:
      - Location: Remote
      - Job Type: Part-time
      - Salary: $50,000 - $70,000 per year
    `,
    location: 'Remote',
    jobType: 'Part-time',
    salary: '$50,000 - $70,000 per year'
  },
  {
    id: 5,
    title: 'Business Analyst',
    description: 'Evaluates business data to provide insights and improve performance.',
    fullDescription: `
      As a Business Analyst, you will analyze business processes and data to provide actionable insights that improve performance. You will work with stakeholders to identify business needs and translate them into technical requirements.

      Responsibilities:
      - Gather and analyze data on business operations and processes.
      - Identify areas for improvement and propose solutions.
      - Develop reports and dashboards to track performance.
      - Collaborate with IT and business teams to implement changes.
      - Communicate findings to management and stakeholders.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Strong analytical and problem-solving skills.
      - Experience with data analysis tools (e.g., Excel, Tableau).
      - Excellent communication and presentation skills.
      - Ability to work independently and manage multiple projects.

      Additional Information:
      - Location: Chicago, IL
      - Job Type: Full-time
      - Salary: $80,000 - $100,000 per year
    `,
    location: 'Chicago, IL',
    jobType: 'Full-time',
    salary: '$80,000 - $100,000 per year'
  },

  {
    id: 1,
    title: 'Software Engineer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 1,
    title: 'Software Developer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 2,
    title: 'Data Scientist',
    description: 'Analyzes data to help organizations make informed decisions.',
    fullDescription: `
      As a Data Scientist, you will analyze large sets of data to extract valuable insights. You will work with stakeholders to define problems and develop solutions using statistical analysis, machine learning, and data visualization techniques.
      
      Responsibilities:
      - Collect, clean, and process large datasets.
      - Build predictive models to solve business problems.
      - Collaborate with engineering and product teams to implement solutions.
      - Present findings to stakeholders in an understandable format.
      - Stay up-to-date with new data science methodologies.

      Qualifications:
      - Master’s degree in Data Science, Statistics, or related field.
      - Proficiency in Python and SQL.
      - Experience with machine learning frameworks (e.g., TensorFlow, Scikit-learn).
      - Strong statistical and analytical skills.
      - Ability to communicate complex results in a clear manner.

      Additional Information:
      - Location: New York, NY
      - Job Type: Full-time
      - Salary: $100,000 - $120,000 per year
    `,
    location: 'New York, NY',
    jobType: 'Full-time',
    salary: '$100,000 - $120,000 per year'
  },
  {
    id: 3,
    title: 'Product Manager',
    description: 'Oversees the development and launch of products.',
    fullDescription: `
      As a Product Manager, you will manage the development and lifecycle of products from ideation to launch. You will collaborate with engineering, marketing, and sales teams to define product strategy and ensure customer needs are met.

      Responsibilities:
      - Define and communicate product vision and strategy.
      - Conduct market research to identify customer needs.
      - Collaborate with development teams to define product requirements.
      - Monitor product performance and make adjustments as needed.
      - Drive product launch and manage cross-functional team activities.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Proven experience in product management.
      - Strong understanding of market research and analytics.
      - Excellent leadership and communication skills.
      - Ability to work in a fast-paced environment.

      Additional Information:
      - Location: San Francisco, CA
      - Job Type: Full-time
      - Salary: $120,000 - $140,000 per year
    `,
    location: 'San Francisco, CA',
    jobType: 'Full-time',
    salary: '$120,000 - $140,000 per year'
  },
  {
    id: 4,
    title: 'UI/UX Designer',
    description: 'Designs user interfaces and experiences for applications.',
    fullDescription: `
      As a UI/UX Designer, you will design intuitive and visually appealing user interfaces. You will work with product and engineering teams to ensure a seamless user experience across all devices and platforms.

      Responsibilities:
      - Design user interfaces for web and mobile applications.
      - Create wireframes, prototypes, and user flow diagrams.
      - Conduct user research and usability testing.
      - Collaborate with developers to ensure design consistency.
      - Stay updated on industry trends and best practices.

      Qualifications:
      - Bachelor’s degree in Design or related field.
      - Proficiency in design tools (e.g., Figma, Adobe XD).
      - Strong understanding of user-centered design principles.
      - Excellent visual design skills and attention to detail.
      - Portfolio showcasing UI/UX design work.

      Additional Information:
      - Location: Remote
      - Job Type: Part-time
      - Salary: $50,000 - $70,000 per year
    `,
    location: 'Remote',
    jobType: 'Part-time',
    salary: '$50,000 - $70,000 per year'
  },
  {
    id: 5,
    title: 'Business Analyst',
    description: 'Evaluates business data to provide insights and improve performance.',
    fullDescription: `
      As a Business Analyst, you will analyze business processes and data to provide actionable insights that improve performance. You will work with stakeholders to identify business needs and translate them into technical requirements.

      Responsibilities:
      - Gather and analyze data on business operations and processes.
      - Identify areas for improvement and propose solutions.
      - Develop reports and dashboards to track performance.
      - Collaborate with IT and business teams to implement changes.
      - Communicate findings to management and stakeholders.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Strong analytical and problem-solving skills.
      - Experience with data analysis tools (e.g., Excel, Tableau).
      - Excellent communication and presentation skills.
      - Ability to work independently and manage multiple projects.

      Additional Information:
      - Location: Chicago, IL
      - Job Type: Full-time
      - Salary: $80,000 - $100,000 per year
    `,
    location: 'Chicago, IL',
    jobType: 'Full-time',
    salary: '$80,000 - $100,000 per year'
  },

  {
    id: 1,
    title: 'Software Engineer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 1,
    title: 'Software Developer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 2,
    title: 'Data Scientist',
    description: 'Analyzes data to help organizations make informed decisions.',
    fullDescription: `
      As a Data Scientist, you will analyze large sets of data to extract valuable insights. You will work with stakeholders to define problems and develop solutions using statistical analysis, machine learning, and data visualization techniques.
      
      Responsibilities:
      - Collect, clean, and process large datasets.
      - Build predictive models to solve business problems.
      - Collaborate with engineering and product teams to implement solutions.
      - Present findings to stakeholders in an understandable format.
      - Stay up-to-date with new data science methodologies.

      Qualifications:
      - Master’s degree in Data Science, Statistics, or related field.
      - Proficiency in Python and SQL.
      - Experience with machine learning frameworks (e.g., TensorFlow, Scikit-learn).
      - Strong statistical and analytical skills.
      - Ability to communicate complex results in a clear manner.

      Additional Information:
      - Location: New York, NY
      - Job Type: Full-time
      - Salary: $100,000 - $120,000 per year
    `,
    location: 'New York, NY',
    jobType: 'Full-time',
    salary: '$100,000 - $120,000 per year'
  },
  {
    id: 3,
    title: 'Product Manager',
    description: 'Oversees the development and launch of products.',
    fullDescription: `
      As a Product Manager, you will manage the development and lifecycle of products from ideation to launch. You will collaborate with engineering, marketing, and sales teams to define product strategy and ensure customer needs are met.

      Responsibilities:
      - Define and communicate product vision and strategy.
      - Conduct market research to identify customer needs.
      - Collaborate with development teams to define product requirements.
      - Monitor product performance and make adjustments as needed.
      - Drive product launch and manage cross-functional team activities.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Proven experience in product management.
      - Strong understanding of market research and analytics.
      - Excellent leadership and communication skills.
      - Ability to work in a fast-paced environment.

      Additional Information:
      - Location: San Francisco, CA
      - Job Type: Full-time
      - Salary: $120,000 - $140,000 per year
    `,
    location: 'San Francisco, CA',
    jobType: 'Full-time',
    salary: '$120,000 - $140,000 per year'
  },
  {
    id: 4,
    title: 'UI/UX Designer',
    description: 'Designs user interfaces and experiences for applications.',
    fullDescription: `
      As a UI/UX Designer, you will design intuitive and visually appealing user interfaces. You will work with product and engineering teams to ensure a seamless user experience across all devices and platforms.

      Responsibilities:
      - Design user interfaces for web and mobile applications.
      - Create wireframes, prototypes, and user flow diagrams.
      - Conduct user research and usability testing.
      - Collaborate with developers to ensure design consistency.
      - Stay updated on industry trends and best practices.

      Qualifications:
      - Bachelor’s degree in Design or related field.
      - Proficiency in design tools (e.g., Figma, Adobe XD).
      - Strong understanding of user-centered design principles.
      - Excellent visual design skills and attention to detail.
      - Portfolio showcasing UI/UX design work.

      Additional Information:
      - Location: Remote
      - Job Type: Part-time
      - Salary: $50,000 - $70,000 per year
    `,
    location: 'Remote',
    jobType: 'Part-time',
    salary: '$50,000 - $70,000 per year'
  },
  {
    id: 5,
    title: 'Business Analyst',
    description: 'Evaluates business data to provide insights and improve performance.',
    fullDescription: `
      As a Business Analyst, you will analyze business processes and data to provide actionable insights that improve performance. You will work with stakeholders to identify business needs and translate them into technical requirements.

      Responsibilities:
      - Gather and analyze data on business operations and processes.
      - Identify areas for improvement and propose solutions.
      - Develop reports and dashboards to track performance.
      - Collaborate with IT and business teams to implement changes.
      - Communicate findings to management and stakeholders.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Strong analytical and problem-solving skills.
      - Experience with data analysis tools (e.g., Excel, Tableau).
      - Excellent communication and presentation skills.
      - Ability to work independently and manage multiple projects.

      Additional Information:
      - Location: Chicago, IL
      - Job Type: Full-time
      - Salary: $80,000 - $100,000 per year
    `,
    location: 'Chicago, IL',
    jobType: 'Full-time',
    salary: '$80,000 - $100,000 per year'
  },

  {
    id: 1,
    title: 'Software Engineer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 1,
    title: 'Software Developer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 2,
    title: 'Data Scientist',
    description: 'Analyzes data to help organizations make informed decisions.',
    fullDescription: `
      As a Data Scientist, you will analyze large sets of data to extract valuable insights. You will work with stakeholders to define problems and develop solutions using statistical analysis, machine learning, and data visualization techniques.
      
      Responsibilities:
      - Collect, clean, and process large datasets.
      - Build predictive models to solve business problems.
      - Collaborate with engineering and product teams to implement solutions.
      - Present findings to stakeholders in an understandable format.
      - Stay up-to-date with new data science methodologies.

      Qualifications:
      - Master’s degree in Data Science, Statistics, or related field.
      - Proficiency in Python and SQL.
      - Experience with machine learning frameworks (e.g., TensorFlow, Scikit-learn).
      - Strong statistical and analytical skills.
      - Ability to communicate complex results in a clear manner.

      Additional Information:
      - Location: New York, NY
      - Job Type: Full-time
      - Salary: $100,000 - $120,000 per year
    `,
    location: 'New York, NY',
    jobType: 'Full-time',
    salary: '$100,000 - $120,000 per year'
  },
  {
    id: 3,
    title: 'Product Manager',
    description: 'Oversees the development and launch of products.',
    fullDescription: `
      As a Product Manager, you will manage the development and lifecycle of products from ideation to launch. You will collaborate with engineering, marketing, and sales teams to define product strategy and ensure customer needs are met.

      Responsibilities:
      - Define and communicate product vision and strategy.
      - Conduct market research to identify customer needs.
      - Collaborate with development teams to define product requirements.
      - Monitor product performance and make adjustments as needed.
      - Drive product launch and manage cross-functional team activities.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Proven experience in product management.
      - Strong understanding of market research and analytics.
      - Excellent leadership and communication skills.
      - Ability to work in a fast-paced environment.

      Additional Information:
      - Location: San Francisco, CA
      - Job Type: Full-time
      - Salary: $120,000 - $140,000 per year
    `,
    location: 'San Francisco, CA',
    jobType: 'Full-time',
    salary: '$120,000 - $140,000 per year'
  },
  {
    id: 4,
    title: 'UI/UX Designer',
    description: 'Designs user interfaces and experiences for applications.',
    fullDescription: `
      As a UI/UX Designer, you will design intuitive and visually appealing user interfaces. You will work with product and engineering teams to ensure a seamless user experience across all devices and platforms.

      Responsibilities:
      - Design user interfaces for web and mobile applications.
      - Create wireframes, prototypes, and user flow diagrams.
      - Conduct user research and usability testing.
      - Collaborate with developers to ensure design consistency.
      - Stay updated on industry trends and best practices.

      Qualifications:
      - Bachelor’s degree in Design or related field.
      - Proficiency in design tools (e.g., Figma, Adobe XD).
      - Strong understanding of user-centered design principles.
      - Excellent visual design skills and attention to detail.
      - Portfolio showcasing UI/UX design work.

      Additional Information:
      - Location: Remote
      - Job Type: Part-time
      - Salary: $50,000 - $70,000 per year
    `,
    location: 'Remote',
    jobType: 'Part-time',
    salary: '$50,000 - $70,000 per year'
  },
  {
    id: 5,
    title: 'Business Analyst',
    description: 'Evaluates business data to provide insights and improve performance.',
    fullDescription: `
      As a Business Analyst, you will analyze business processes and data to provide actionable insights that improve performance. You will work with stakeholders to identify business needs and translate them into technical requirements.

      Responsibilities:
      - Gather and analyze data on business operations and processes.
      - Identify areas for improvement and propose solutions.
      - Develop reports and dashboards to track performance.
      - Collaborate with IT and business teams to implement changes.
      - Communicate findings to management and stakeholders.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Strong analytical and problem-solving skills.
      - Experience with data analysis tools (e.g., Excel, Tableau).
      - Excellent communication and presentation skills.
      - Ability to work independently and manage multiple projects.

      Additional Information:
      - Location: Chicago, IL
      - Job Type: Full-time
      - Salary: $80,000 - $100,000 per year
    `,
    location: 'Chicago, IL',
    jobType: 'Full-time',
    salary: '$80,000 - $100,000 per year'
  },

  {
    id: 1,
    title: 'Software Engineer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 1,
    title: 'Software Developer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 2,
    title: 'Data Scientist',
    description: 'Analyzes data to help organizations make informed decisions.',
    fullDescription: `
      As a Data Scientist, you will analyze large sets of data to extract valuable insights. You will work with stakeholders to define problems and develop solutions using statistical analysis, machine learning, and data visualization techniques.
      
      Responsibilities:
      - Collect, clean, and process large datasets.
      - Build predictive models to solve business problems.
      - Collaborate with engineering and product teams to implement solutions.
      - Present findings to stakeholders in an understandable format.
      - Stay up-to-date with new data science methodologies.

      Qualifications:
      - Master’s degree in Data Science, Statistics, or related field.
      - Proficiency in Python and SQL.
      - Experience with machine learning frameworks (e.g., TensorFlow, Scikit-learn).
      - Strong statistical and analytical skills.
      - Ability to communicate complex results in a clear manner.

      Additional Information:
      - Location: New York, NY
      - Job Type: Full-time
      - Salary: $100,000 - $120,000 per year
    `,
    location: 'New York, NY',
    jobType: 'Full-time',
    salary: '$100,000 - $120,000 per year'
  },
  {
    id: 3,
    title: 'Product Manager',
    description: 'Oversees the development and launch of products.',
    fullDescription: `
      As a Product Manager, you will manage the development and lifecycle of products from ideation to launch. You will collaborate with engineering, marketing, and sales teams to define product strategy and ensure customer needs are met.

      Responsibilities:
      - Define and communicate product vision and strategy.
      - Conduct market research to identify customer needs.
      - Collaborate with development teams to define product requirements.
      - Monitor product performance and make adjustments as needed.
      - Drive product launch and manage cross-functional team activities.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Proven experience in product management.
      - Strong understanding of market research and analytics.
      - Excellent leadership and communication skills.
      - Ability to work in a fast-paced environment.

      Additional Information:
      - Location: San Francisco, CA
      - Job Type: Full-time
      - Salary: $120,000 - $140,000 per year
    `,
    location: 'San Francisco, CA',
    jobType: 'Full-time',
    salary: '$120,000 - $140,000 per year'
  },
  {
    id: 4,
    title: 'UI/UX Designer',
    description: 'Designs user interfaces and experiences for applications.',
    fullDescription: `
      As a UI/UX Designer, you will design intuitive and visually appealing user interfaces. You will work with product and engineering teams to ensure a seamless user experience across all devices and platforms.

      Responsibilities:
      - Design user interfaces for web and mobile applications.
      - Create wireframes, prototypes, and user flow diagrams.
      - Conduct user research and usability testing.
      - Collaborate with developers to ensure design consistency.
      - Stay updated on industry trends and best practices.

      Qualifications:
      - Bachelor’s degree in Design or related field.
      - Proficiency in design tools (e.g., Figma, Adobe XD).
      - Strong understanding of user-centered design principles.
      - Excellent visual design skills and attention to detail.
      - Portfolio showcasing UI/UX design work.

      Additional Information:
      - Location: Remote
      - Job Type: Part-time
      - Salary: $50,000 - $70,000 per year
    `,
    location: 'Remote',
    jobType: 'Part-time',
    salary: '$50,000 - $70,000 per year'
  },
  {
    id: 5,
    title: 'Business Analyst',
    description: 'Evaluates business data to provide insights and improve performance.',
    fullDescription: `
      As a Business Analyst, you will analyze business processes and data to provide actionable insights that improve performance. You will work with stakeholders to identify business needs and translate them into technical requirements.

      Responsibilities:
      - Gather and analyze data on business operations and processes.
      - Identify areas for improvement and propose solutions.
      - Develop reports and dashboards to track performance.
      - Collaborate with IT and business teams to implement changes.
      - Communicate findings to management and stakeholders.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Strong analytical and problem-solving skills.
      - Experience with data analysis tools (e.g., Excel, Tableau).
      - Excellent communication and presentation skills.
      - Ability to work independently and manage multiple projects.

      Additional Information:
      - Location: Chicago, IL
      - Job Type: Full-time
      - Salary: $80,000 - $100,000 per year
    `,
    location: 'Chicago, IL',
    jobType: 'Full-time',
    salary: '$80,000 - $100,000 per year'
  },

  {
    id: 1,
    title: 'Software Engineer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 1,
    title: 'Software Developer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 2,
    title: 'Data Scientist',
    description: 'Analyzes data to help organizations make informed decisions.',
    fullDescription: `
      As a Data Scientist, you will analyze large sets of data to extract valuable insights. You will work with stakeholders to define problems and develop solutions using statistical analysis, machine learning, and data visualization techniques.
      
      Responsibilities:
      - Collect, clean, and process large datasets.
      - Build predictive models to solve business problems.
      - Collaborate with engineering and product teams to implement solutions.
      - Present findings to stakeholders in an understandable format.
      - Stay up-to-date with new data science methodologies.

      Qualifications:
      - Master’s degree in Data Science, Statistics, or related field.
      - Proficiency in Python and SQL.
      - Experience with machine learning frameworks (e.g., TensorFlow, Scikit-learn).
      - Strong statistical and analytical skills.
      - Ability to communicate complex results in a clear manner.

      Additional Information:
      - Location: New York, NY
      - Job Type: Full-time
      - Salary: $100,000 - $120,000 per year
    `,
    location: 'New York, NY',
    jobType: 'Full-time',
    salary: '$100,000 - $120,000 per year'
  },
  {
    id: 3,
    title: 'Product Manager',
    description: 'Oversees the development and launch of products.',
    fullDescription: `
      As a Product Manager, you will manage the development and lifecycle of products from ideation to launch. You will collaborate with engineering, marketing, and sales teams to define product strategy and ensure customer needs are met.

      Responsibilities:
      - Define and communicate product vision and strategy.
      - Conduct market research to identify customer needs.
      - Collaborate with development teams to define product requirements.
      - Monitor product performance and make adjustments as needed.
      - Drive product launch and manage cross-functional team activities.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Proven experience in product management.
      - Strong understanding of market research and analytics.
      - Excellent leadership and communication skills.
      - Ability to work in a fast-paced environment.

      Additional Information:
      - Location: San Francisco, CA
      - Job Type: Full-time
      - Salary: $120,000 - $140,000 per year
    `,
    location: 'San Francisco, CA',
    jobType: 'Full-time',
    salary: '$120,000 - $140,000 per year'
  },
  {
    id: 4,
    title: 'UI/UX Designer',
    description: 'Designs user interfaces and experiences for applications.',
    fullDescription: `
      As a UI/UX Designer, you will design intuitive and visually appealing user interfaces. You will work with product and engineering teams to ensure a seamless user experience across all devices and platforms.

      Responsibilities:
      - Design user interfaces for web and mobile applications.
      - Create wireframes, prototypes, and user flow diagrams.
      - Conduct user research and usability testing.
      - Collaborate with developers to ensure design consistency.
      - Stay updated on industry trends and best practices.

      Qualifications:
      - Bachelor’s degree in Design or related field.
      - Proficiency in design tools (e.g., Figma, Adobe XD).
      - Strong understanding of user-centered design principles.
      - Excellent visual design skills and attention to detail.
      - Portfolio showcasing UI/UX design work.

      Additional Information:
      - Location: Remote
      - Job Type: Part-time
      - Salary: $50,000 - $70,000 per year
    `,
    location: 'Remote',
    jobType: 'Part-time',
    salary: '$50,000 - $70,000 per year'
  },
  {
    id: 5,
    title: 'Business Analyst',
    description: 'Evaluates business data to provide insights and improve performance.',
    fullDescription: `
      As a Business Analyst, you will analyze business processes and data to provide actionable insights that improve performance. You will work with stakeholders to identify business needs and translate them into technical requirements.

      Responsibilities:
      - Gather and analyze data on business operations and processes.
      - Identify areas for improvement and propose solutions.
      - Develop reports and dashboards to track performance.
      - Collaborate with IT and business teams to implement changes.
      - Communicate findings to management and stakeholders.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Strong analytical and problem-solving skills.
      - Experience with data analysis tools (e.g., Excel, Tableau).
      - Excellent communication and presentation skills.
      - Ability to work independently and manage multiple projects.

      Additional Information:
      - Location: Chicago, IL
      - Job Type: Full-time
      - Salary: $80,000 - $100,000 per year
    `,
    location: 'Chicago, IL',
    jobType: 'Full-time',
    salary: '$80,000 - $100,000 per year'
  },

  {
    id: 1,
    title: 'Software Engineer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 1,
    title: 'Software Developer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 2,
    title: 'Data Scientist',
    description: 'Analyzes data to help organizations make informed decisions.',
    fullDescription: `
      As a Data Scientist, you will analyze large sets of data to extract valuable insights. You will work with stakeholders to define problems and develop solutions using statistical analysis, machine learning, and data visualization techniques.
      
      Responsibilities:
      - Collect, clean, and process large datasets.
      - Build predictive models to solve business problems.
      - Collaborate with engineering and product teams to implement solutions.
      - Present findings to stakeholders in an understandable format.
      - Stay up-to-date with new data science methodologies.

      Qualifications:
      - Master’s degree in Data Science, Statistics, or related field.
      - Proficiency in Python and SQL.
      - Experience with machine learning frameworks (e.g., TensorFlow, Scikit-learn).
      - Strong statistical and analytical skills.
      - Ability to communicate complex results in a clear manner.

      Additional Information:
      - Location: New York, NY
      - Job Type: Full-time
      - Salary: $100,000 - $120,000 per year
    `,
    location: 'New York, NY',
    jobType: 'Full-time',
    salary: '$100,000 - $120,000 per year'
  },
  {
    id: 3,
    title: 'Product Manager',
    description: 'Oversees the development and launch of products.',
    fullDescription: `
      As a Product Manager, you will manage the development and lifecycle of products from ideation to launch. You will collaborate with engineering, marketing, and sales teams to define product strategy and ensure customer needs are met.

      Responsibilities:
      - Define and communicate product vision and strategy.
      - Conduct market research to identify customer needs.
      - Collaborate with development teams to define product requirements.
      - Monitor product performance and make adjustments as needed.
      - Drive product launch and manage cross-functional team activities.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Proven experience in product management.
      - Strong understanding of market research and analytics.
      - Excellent leadership and communication skills.
      - Ability to work in a fast-paced environment.

      Additional Information:
      - Location: San Francisco, CA
      - Job Type: Full-time
      - Salary: $120,000 - $140,000 per year
    `,
    location: 'San Francisco, CA',
    jobType: 'Full-time',
    salary: '$120,000 - $140,000 per year'
  },
  {
    id: 4,
    title: 'UI/UX Designer',
    description: 'Designs user interfaces and experiences for applications.',
    fullDescription: `
      As a UI/UX Designer, you will design intuitive and visually appealing user interfaces. You will work with product and engineering teams to ensure a seamless user experience across all devices and platforms.

      Responsibilities:
      - Design user interfaces for web and mobile applications.
      - Create wireframes, prototypes, and user flow diagrams.
      - Conduct user research and usability testing.
      - Collaborate with developers to ensure design consistency.
      - Stay updated on industry trends and best practices.

      Qualifications:
      - Bachelor’s degree in Design or related field.
      - Proficiency in design tools (e.g., Figma, Adobe XD).
      - Strong understanding of user-centered design principles.
      - Excellent visual design skills and attention to detail.
      - Portfolio showcasing UI/UX design work.

      Additional Information:
      - Location: Remote
      - Job Type: Part-time
      - Salary: $50,000 - $70,000 per year
    `,
    location: 'Remote',
    jobType: 'Part-time',
    salary: '$50,000 - $70,000 per year'
  },
  {
    id: 5,
    title: 'Business Analyst',
    description: 'Evaluates business data to provide insights and improve performance.',
    fullDescription: `
      As a Business Analyst, you will analyze business processes and data to provide actionable insights that improve performance. You will work with stakeholders to identify business needs and translate them into technical requirements.

      Responsibilities:
      - Gather and analyze data on business operations and processes.
      - Identify areas for improvement and propose solutions.
      - Develop reports and dashboards to track performance.
      - Collaborate with IT and business teams to implement changes.
      - Communicate findings to management and stakeholders.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Strong analytical and problem-solving skills.
      - Experience with data analysis tools (e.g., Excel, Tableau).
      - Excellent communication and presentation skills.
      - Ability to work independently and manage multiple projects.

      Additional Information:
      - Location: Chicago, IL
      - Job Type: Full-time
      - Salary: $80,000 - $100,000 per year
    `,
    location: 'Chicago, IL',
    jobType: 'Full-time',
    salary: '$80,000 - $100,000 per year'
  },

  {
    id: 1,
    title: 'Software Engineer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 1,
    title: 'Software Developer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 2,
    title: 'Data Scientist',
    description: 'Analyzes data to help organizations make informed decisions.',
    fullDescription: `
      As a Data Scientist, you will analyze large sets of data to extract valuable insights. You will work with stakeholders to define problems and develop solutions using statistical analysis, machine learning, and data visualization techniques.
      
      Responsibilities:
      - Collect, clean, and process large datasets.
      - Build predictive models to solve business problems.
      - Collaborate with engineering and product teams to implement solutions.
      - Present findings to stakeholders in an understandable format.
      - Stay up-to-date with new data science methodologies.

      Qualifications:
      - Master’s degree in Data Science, Statistics, or related field.
      - Proficiency in Python and SQL.
      - Experience with machine learning frameworks (e.g., TensorFlow, Scikit-learn).
      - Strong statistical and analytical skills.
      - Ability to communicate complex results in a clear manner.

      Additional Information:
      - Location: New York, NY
      - Job Type: Full-time
      - Salary: $100,000 - $120,000 per year
    `,
    location: 'New York, NY',
    jobType: 'Full-time',
    salary: '$100,000 - $120,000 per year'
  },
  {
    id: 3,
    title: 'Product Manager',
    description: 'Oversees the development and launch of products.',
    fullDescription: `
      As a Product Manager, you will manage the development and lifecycle of products from ideation to launch. You will collaborate with engineering, marketing, and sales teams to define product strategy and ensure customer needs are met.

      Responsibilities:
      - Define and communicate product vision and strategy.
      - Conduct market research to identify customer needs.
      - Collaborate with development teams to define product requirements.
      - Monitor product performance and make adjustments as needed.
      - Drive product launch and manage cross-functional team activities.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Proven experience in product management.
      - Strong understanding of market research and analytics.
      - Excellent leadership and communication skills.
      - Ability to work in a fast-paced environment.

      Additional Information:
      - Location: San Francisco, CA
      - Job Type: Full-time
      - Salary: $120,000 - $140,000 per year
    `,
    location: 'San Francisco, CA',
    jobType: 'Full-time',
    salary: '$120,000 - $140,000 per year'
  },
  {
    id: 4,
    title: 'UI/UX Designer',
    description: 'Designs user interfaces and experiences for applications.',
    fullDescription: `
      As a UI/UX Designer, you will design intuitive and visually appealing user interfaces. You will work with product and engineering teams to ensure a seamless user experience across all devices and platforms.

      Responsibilities:
      - Design user interfaces for web and mobile applications.
      - Create wireframes, prototypes, and user flow diagrams.
      - Conduct user research and usability testing.
      - Collaborate with developers to ensure design consistency.
      - Stay updated on industry trends and best practices.

      Qualifications:
      - Bachelor’s degree in Design or related field.
      - Proficiency in design tools (e.g., Figma, Adobe XD).
      - Strong understanding of user-centered design principles.
      - Excellent visual design skills and attention to detail.
      - Portfolio showcasing UI/UX design work.

      Additional Information:
      - Location: Remote
      - Job Type: Part-time
      - Salary: $50,000 - $70,000 per year
    `,
    location: 'Remote',
    jobType: 'Part-time',
    salary: '$50,000 - $70,000 per year'
  },
  {
    id: 5,
    title: 'Business Analyst',
    description: 'Evaluates business data to provide insights and improve performance.',
    fullDescription: `
      As a Business Analyst, you will analyze business processes and data to provide actionable insights that improve performance. You will work with stakeholders to identify business needs and translate them into technical requirements.

      Responsibilities:
      - Gather and analyze data on business operations and processes.
      - Identify areas for improvement and propose solutions.
      - Develop reports and dashboards to track performance.
      - Collaborate with IT and business teams to implement changes.
      - Communicate findings to management and stakeholders.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Strong analytical and problem-solving skills.
      - Experience with data analysis tools (e.g., Excel, Tableau).
      - Excellent communication and presentation skills.
      - Ability to work independently and manage multiple projects.

      Additional Information:
      - Location: Chicago, IL
      - Job Type: Full-time
      - Salary: $80,000 - $100,000 per year
    `,
    location: 'Chicago, IL',
    jobType: 'Full-time',
    salary: '$80,000 - $100,000 per year'
  },

  {
    id: 1,
    title: 'Software Engineer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 1,
    title: 'Software Developer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 2,
    title: 'Data Scientist',
    description: 'Analyzes data to help organizations make informed decisions.',
    fullDescription: `
      As a Data Scientist, you will analyze large sets of data to extract valuable insights. You will work with stakeholders to define problems and develop solutions using statistical analysis, machine learning, and data visualization techniques.
      
      Responsibilities:
      - Collect, clean, and process large datasets.
      - Build predictive models to solve business problems.
      - Collaborate with engineering and product teams to implement solutions.
      - Present findings to stakeholders in an understandable format.
      - Stay up-to-date with new data science methodologies.

      Qualifications:
      - Master’s degree in Data Science, Statistics, or related field.
      - Proficiency in Python and SQL.
      - Experience with machine learning frameworks (e.g., TensorFlow, Scikit-learn).
      - Strong statistical and analytical skills.
      - Ability to communicate complex results in a clear manner.

      Additional Information:
      - Location: New York, NY
      - Job Type: Full-time
      - Salary: $100,000 - $120,000 per year
    `,
    location: 'New York, NY',
    jobType: 'Full-time',
    salary: '$100,000 - $120,000 per year'
  },
  {
    id: 3,
    title: 'Product Manager',
    description: 'Oversees the development and launch of products.',
    fullDescription: `
      As a Product Manager, you will manage the development and lifecycle of products from ideation to launch. You will collaborate with engineering, marketing, and sales teams to define product strategy and ensure customer needs are met.

      Responsibilities:
      - Define and communicate product vision and strategy.
      - Conduct market research to identify customer needs.
      - Collaborate with development teams to define product requirements.
      - Monitor product performance and make adjustments as needed.
      - Drive product launch and manage cross-functional team activities.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Proven experience in product management.
      - Strong understanding of market research and analytics.
      - Excellent leadership and communication skills.
      - Ability to work in a fast-paced environment.

      Additional Information:
      - Location: San Francisco, CA
      - Job Type: Full-time
      - Salary: $120,000 - $140,000 per year
    `,
    location: 'San Francisco, CA',
    jobType: 'Full-time',
    salary: '$120,000 - $140,000 per year'
  },
  {
    id: 4,
    title: 'UI/UX Designer',
    description: 'Designs user interfaces and experiences for applications.',
    fullDescription: `
      As a UI/UX Designer, you will design intuitive and visually appealing user interfaces. You will work with product and engineering teams to ensure a seamless user experience across all devices and platforms.

      Responsibilities:
      - Design user interfaces for web and mobile applications.
      - Create wireframes, prototypes, and user flow diagrams.
      - Conduct user research and usability testing.
      - Collaborate with developers to ensure design consistency.
      - Stay updated on industry trends and best practices.

      Qualifications:
      - Bachelor’s degree in Design or related field.
      - Proficiency in design tools (e.g., Figma, Adobe XD).
      - Strong understanding of user-centered design principles.
      - Excellent visual design skills and attention to detail.
      - Portfolio showcasing UI/UX design work.

      Additional Information:
      - Location: Remote
      - Job Type: Part-time
      - Salary: $50,000 - $70,000 per year
    `,
    location: 'Remote',
    jobType: 'Part-time',
    salary: '$50,000 - $70,000 per year'
  },
  {
    id: 5,
    title: 'Business Analyst',
    description: 'Evaluates business data to provide insights and improve performance.',
    fullDescription: `
      As a Business Analyst, you will analyze business processes and data to provide actionable insights that improve performance. You will work with stakeholders to identify business needs and translate them into technical requirements.

      Responsibilities:
      - Gather and analyze data on business operations and processes.
      - Identify areas for improvement and propose solutions.
      - Develop reports and dashboards to track performance.
      - Collaborate with IT and business teams to implement changes.
      - Communicate findings to management and stakeholders.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Strong analytical and problem-solving skills.
      - Experience with data analysis tools (e.g., Excel, Tableau).
      - Excellent communication and presentation skills.
      - Ability to work independently and manage multiple projects.

      Additional Information:
      - Location: Chicago, IL
      - Job Type: Full-time
      - Salary: $80,000 - $100,000 per year
    `,
    location: 'Chicago, IL',
    jobType: 'Full-time',
    salary: '$80,000 - $100,000 per year'
  },

  {
    id: 1,
    title: 'Software Engineer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 1,
    title: 'Software Developer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 2,
    title: 'Data Scientist',
    description: 'Analyzes data to help organizations make informed decisions.',
    fullDescription: `
      As a Data Scientist, you will analyze large sets of data to extract valuable insights. You will work with stakeholders to define problems and develop solutions using statistical analysis, machine learning, and data visualization techniques.
      
      Responsibilities:
      - Collect, clean, and process large datasets.
      - Build predictive models to solve business problems.
      - Collaborate with engineering and product teams to implement solutions.
      - Present findings to stakeholders in an understandable format.
      - Stay up-to-date with new data science methodologies.

      Qualifications:
      - Master’s degree in Data Science, Statistics, or related field.
      - Proficiency in Python and SQL.
      - Experience with machine learning frameworks (e.g., TensorFlow, Scikit-learn).
      - Strong statistical and analytical skills.
      - Ability to communicate complex results in a clear manner.

      Additional Information:
      - Location: New York, NY
      - Job Type: Full-time
      - Salary: $100,000 - $120,000 per year
    `,
    location: 'New York, NY',
    jobType: 'Full-time',
    salary: '$100,000 - $120,000 per year'
  },
  {
    id: 3,
    title: 'Product Manager',
    description: 'Oversees the development and launch of products.',
    fullDescription: `
      As a Product Manager, you will manage the development and lifecycle of products from ideation to launch. You will collaborate with engineering, marketing, and sales teams to define product strategy and ensure customer needs are met.

      Responsibilities:
      - Define and communicate product vision and strategy.
      - Conduct market research to identify customer needs.
      - Collaborate with development teams to define product requirements.
      - Monitor product performance and make adjustments as needed.
      - Drive product launch and manage cross-functional team activities.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Proven experience in product management.
      - Strong understanding of market research and analytics.
      - Excellent leadership and communication skills.
      - Ability to work in a fast-paced environment.

      Additional Information:
      - Location: San Francisco, CA
      - Job Type: Full-time
      - Salary: $120,000 - $140,000 per year
    `,
    location: 'San Francisco, CA',
    jobType: 'Full-time',
    salary: '$120,000 - $140,000 per year'
  },
  {
    id: 4,
    title: 'UI/UX Designer',
    description: 'Designs user interfaces and experiences for applications.',
    fullDescription: `
      As a UI/UX Designer, you will design intuitive and visually appealing user interfaces. You will work with product and engineering teams to ensure a seamless user experience across all devices and platforms.

      Responsibilities:
      - Design user interfaces for web and mobile applications.
      - Create wireframes, prototypes, and user flow diagrams.
      - Conduct user research and usability testing.
      - Collaborate with developers to ensure design consistency.
      - Stay updated on industry trends and best practices.

      Qualifications:
      - Bachelor’s degree in Design or related field.
      - Proficiency in design tools (e.g., Figma, Adobe XD).
      - Strong understanding of user-centered design principles.
      - Excellent visual design skills and attention to detail.
      - Portfolio showcasing UI/UX design work.

      Additional Information:
      - Location: Remote
      - Job Type: Part-time
      - Salary: $50,000 - $70,000 per year
    `,
    location: 'Remote',
    jobType: 'Part-time',
    salary: '$50,000 - $70,000 per year'
  },
  {
    id: 5,
    title: 'Business Analyst',
    description: 'Evaluates business data to provide insights and improve performance.',
    fullDescription: `
      As a Business Analyst, you will analyze business processes and data to provide actionable insights that improve performance. You will work with stakeholders to identify business needs and translate them into technical requirements.

      Responsibilities:
      - Gather and analyze data on business operations and processes.
      - Identify areas for improvement and propose solutions.
      - Develop reports and dashboards to track performance.
      - Collaborate with IT and business teams to implement changes.
      - Communicate findings to management and stakeholders.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Strong analytical and problem-solving skills.
      - Experience with data analysis tools (e.g., Excel, Tableau).
      - Excellent communication and presentation skills.
      - Ability to work independently and manage multiple projects.

      Additional Information:
      - Location: Chicago, IL
      - Job Type: Full-time
      - Salary: $80,000 - $100,000 per year
    `,
    location: 'Chicago, IL',
    jobType: 'Full-time',
    salary: '$80,000 - $100,000 per year'
  },

  {
    id: 1,
    title: 'Software Engineer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 1,
    title: 'Software Developer',
    description: 'Develops and maintains software applications.',
    fullDescription: `
      As a Software Engineer, you will be responsible for designing, developing, and maintaining software applications. You will work closely with cross-functional teams to understand requirements, develop solutions, and ensure high-quality deliverables.
      
      Responsibilities:
      - Write clean, scalable, and efficient code.
      - Collaborate with team members to design and implement new features.
      - Debug and resolve software defects.
      - Perform code reviews and contribute to best practices.
      - Stay updated with emerging trends in software development.

      Qualifications:
      - Bachelor’s degree in Computer Science or related field.
      - Proficiency in JavaScript, Python, or Java.
      - Experience with front-end and back-end technologies.
      - Strong problem-solving and analytical skills.
      - Excellent communication and teamwork skills.

      Additional Information:
      - Location: Remote
      - Job Type: Full-time
      - Salary: $70,000 - $90,000 per year
    `,
    location: 'Remote',
    jobType: 'Full-time',
    salary: '$70,000 - $90,000 per year'
  },
  {
    id: 2,
    title: 'Data Scientist',
    description: 'Analyzes data to help organizations make informed decisions.',
    fullDescription: `
      As a Data Scientist, you will analyze large sets of data to extract valuable insights. You will work with stakeholders to define problems and develop solutions using statistical analysis, machine learning, and data visualization techniques.
      
      Responsibilities:
      - Collect, clean, and process large datasets.
      - Build predictive models to solve business problems.
      - Collaborate with engineering and product teams to implement solutions.
      - Present findings to stakeholders in an understandable format.
      - Stay up-to-date with new data science methodologies.

      Qualifications:
      - Master’s degree in Data Science, Statistics, or related field.
      - Proficiency in Python and SQL.
      - Experience with machine learning frameworks (e.g., TensorFlow, Scikit-learn).
      - Strong statistical and analytical skills.
      - Ability to communicate complex results in a clear manner.

      Additional Information:
      - Location: New York, NY
      - Job Type: Full-time
      - Salary: $100,000 - $120,000 per year
    `,
    location: 'New York, NY',
    jobType: 'Full-time',
    salary: '$100,000 - $120,000 per year'
  },
  {
    id: 3,
    title: 'Product Manager',
    description: 'Oversees the development and launch of products.',
    fullDescription: `
      As a Product Manager, you will manage the development and lifecycle of products from ideation to launch. You will collaborate with engineering, marketing, and sales teams to define product strategy and ensure customer needs are met.

      Responsibilities:
      - Define and communicate product vision and strategy.
      - Conduct market research to identify customer needs.
      - Collaborate with development teams to define product requirements.
      - Monitor product performance and make adjustments as needed.
      - Drive product launch and manage cross-functional team activities.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Proven experience in product management.
      - Strong understanding of market research and analytics.
      - Excellent leadership and communication skills.
      - Ability to work in a fast-paced environment.

      Additional Information:
      - Location: San Francisco, CA
      - Job Type: Full-time
      - Salary: $120,000 - $140,000 per year
    `,
    location: 'San Francisco, CA',
    jobType: 'Full-time',
    salary: '$120,000 - $140,000 per year'
  },
  {
    id: 4,
    title: 'UI/UX Designer',
    description: 'Designs user interfaces and experiences for applications.',
    fullDescription: `
      As a UI/UX Designer, you will design intuitive and visually appealing user interfaces. You will work with product and engineering teams to ensure a seamless user experience across all devices and platforms.

      Responsibilities:
      - Design user interfaces for web and mobile applications.
      - Create wireframes, prototypes, and user flow diagrams.
      - Conduct user research and usability testing.
      - Collaborate with developers to ensure design consistency.
      - Stay updated on industry trends and best practices.

      Qualifications:
      - Bachelor’s degree in Design or related field.
      - Proficiency in design tools (e.g., Figma, Adobe XD).
      - Strong understanding of user-centered design principles.
      - Excellent visual design skills and attention to detail.
      - Portfolio showcasing UI/UX design work.

      Additional Information:
      - Location: Remote
      - Job Type: Part-time
      - Salary: $50,000 - $70,000 per year
    `,
    location: 'Remote',
    jobType: 'Part-time',
    salary: '$50,000 - $70,000 per year'
  },
  {
    id: 5,
    title: 'Business Analyst',
    description: 'Evaluates business data to provide insights and improve performance.',
    fullDescription: `
      As a Business Analyst, you will analyze business processes and data to provide actionable insights that improve performance. You will work with stakeholders to identify business needs and translate them into technical requirements.

      Responsibilities:
      - Gather and analyze data on business operations and processes.
      - Identify areas for improvement and propose solutions.
      - Develop reports and dashboards to track performance.
      - Collaborate with IT and business teams to implement changes.
      - Communicate findings to management and stakeholders.

      Qualifications:
      - Bachelor’s degree in Business or related field.
      - Strong analytical and problem-solving skills.
      - Experience with data analysis tools (e.g., Excel, Tableau).
      - Excellent communication and presentation skills.
      - Ability to work independently and manage multiple projects.

      Additional Information:
      - Location: Chicago, IL
      - Job Type: Full-time
      - Salary: $80,000 - $100,000 per year
    `,
    location: 'Chicago, IL',
    jobType: 'Full-time',
    salary: '$80,000 - $100,000 per year'
  },
];

export default jobDescriptions;
