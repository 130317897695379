// src/components/PromoSection.js
import React from "react";
import { useTheme } from "../context/ThemeContext"; // Import the custom ThemeContext
import ShineBorder from "../styles/magicui/ShineBorder";

const PromoSection = () => {
  const { theme } = useTheme(); // Access the current theme

  // Define shadow and gradient colors based on the theme
  const shadowColor =
    theme === "dark" ? "rgba(0, 0, 0, 0.7)" : "rgba(0, 0, 0, 0.2)";
  const gradientColor =
    theme === "dark" ? "rgba(0, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.6)";

  return (
    <section
      className="relative py-20"
      style={{
        backgroundColor: theme === "dark" ? "#111827" : "#f9fafb",
        color: theme === "dark" ? "white" : "black",
      }}
    >
      <div className="container mx-auto mt-10 flex justify-center">
        <ShineBorder
          className="relative max-w-full h-auto rounded-lg overflow-hidden transition-shadow duration-500"
          borderRadius={16}
          borderWidth={2}
          duration={10}
          color={theme === "dark" ? ["#A07CFE", "#FE8FB5", "#FFBE7B"] : ["#ffaa40", "#FFBE7B", "#9c40ff"]}
        >
          <img
            src={require("../assets/promo3.png")} // Ensure the correct path to your image
            alt="Showcase"
            className="rounded-xl"
            style={{
              borderRadius: "16px",
              filter: `drop-shadow(0 15px 25px ${shadowColor})`, // Adds a theme-aware shadow
            }}
          />
          {/* Adding a gradient overlay to blend the shadow more naturally */}
          <div
            className="absolute inset-0"
            style={{
              background: `linear-gradient(to top, ${gradientColor} 20%, transparent 70%)`, // Subtle gradient effect
              borderRadius: "16px",
              bottom: "0", // Position gradient to cover only the bottom shadow area
            }}
          ></div>
        </ShineBorder>
      </div>
    </section>
  );
};

export default PromoSection;
