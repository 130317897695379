// import React, { useState } from 'react';
// import { useTheme } from '../context/ThemeContext';
// import { Switch } from '@headlessui/react';
// import { Link, useNavigate } from 'react-router-dom';

// const Navbar = () => {
//   const { theme, toggleTheme } = useTheme();
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [isMenuOpen, setIsMenuOpen] = useState(false); // State for hamburger menu
//   const navigate = useNavigate(); // Use navigate hook

//   // Toggle dropdown visibility
//   const handleDropdownToggle = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   // Toggle mobile menu
//   const handleMenuToggle = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   // Handle login button click
//   const handleLoginClick = () => {
//     navigate('/login'); // Navigate to the login page
//   };

//   return (
//     <header className={`flex justify-between items-center p-6 ${theme === 'dark' ? 'bg-gray-900' : 'bg-white'} text-white`}>
//       <h1 className={`text-xl font-bold ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>TechOptima</h1>
      
//       {/* Hamburger Menu for smaller screens */}
//       <button 
//         className="block md:hidden" 
//         onClick={handleMenuToggle}
//         aria-label="Toggle navigation"
//       >
//         <span className="text-2xl">&#9776;</span> {/* Hamburger icon */}
//       </button>
      
//       {/* Navigation Links */}
//       <nav className={`md:flex items-center space-x-6 relative ${isMenuOpen ? 'block' : 'hidden'} md:block`}>
//         <Link to="/" className={`hover:text-gray-400 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
//           Features
//         </Link>
//         <Link to="/pricing" className={`hover:text-gray-400 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
//           Pricing
//         </Link>
//         <Link to="/employer-login" className={`hover:text-gray-400 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
//           Employer
//         </Link>

//         {/* Interviewee Dropdown */}
//         <div className="relative">
//           <button
//             onClick={handleDropdownToggle}
//             className={`hover:text-gray-400 ${theme === 'dark' ? 'text-white' : 'text-gray-900'} focus:outline-none`}
//             aria-haspopup="true"
//             aria-expanded={isDropdownOpen}
//           >
//             Interviewee
//           </button>
//           {isDropdownOpen && (
//             <div
//               className={`absolute right-0 mt-2 w-48 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-50 ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'}`}
//               role="menu"
//               aria-orientation="vertical"
//               aria-labelledby="options-menu"
//               style={{ zIndex: 50 }} // Ensure dropdown is above other elements
//             >
//               <div className="py-1" role="none">
//                 <Link
//                   to="/mockup-interview"
//                   className={`block px-4 py-2 text-sm ${theme === 'dark' ? 'text-white hover:bg-gray-700' : 'text-gray-900 hover:bg-gray-100'}`}
//                   role="menuitem"
//                 >
//                   Mock Interview
//                 </Link>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Login Button */}
//         <button
//           onClick={handleLoginClick}
//           className={`hover:text-gray-400 ${theme === 'dark' ? 'text-white' : 'text-gray-900'} focus:outline-none`}
//         >
//           Login
//         </button>

//         {/* Toggle Switch for Dark/Light Mode */}
//         <Switch
//           checked={theme === 'dark'}
//           onChange={toggleTheme}
//           className={`${
//             theme === 'dark' ? 'bg-blue-600' : 'bg-gray-300'
//           } relative inline-flex items-center h-6 rounded-full w-11 transition-colors duration-200 focus:outline-none`}
//         >
//           <span
//             className={`${
//               theme === 'dark' ? 'translate-x-6' : 'translate-x-1'
//             } inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-200`}
//           />
//         </Switch>
//       </nav>
//     </header>
//   );
// };

// export default Navbar;
import React, { useState } from 'react';
import { useTheme } from '../context/ThemeContext';
import { Switch } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = () => {
  const { theme, toggleTheme } = useTheme();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isEmployerDropdownOpen, setIsEmployerDropdownOpen] = useState(false); // State for Employer dropdown
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for hamburger menu
  const navigate = useNavigate();

  // Toggle dropdown visibility
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Toggle Employer dropdown visibility
  const handleEmployerDropdownToggle = () => {
    setIsEmployerDropdownOpen(!isEmployerDropdownOpen);
  };

  // Toggle mobile menu
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Handle login button click
  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <header className={`flex justify-between items-center p-6 ${theme === 'dark' ? 'bg-gray-900' : 'bg-white'} text-white`}>
      <h1 className={`text-xl font-bold ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>TechOptima</h1>
      
      {/* Hamburger Menu for smaller screens */}
      <button 
        className="block md:hidden" 
        onClick={handleMenuToggle}
        aria-label="Toggle navigation"
      >
        <span className="text-2xl">&#9776;</span> {/* Hamburger icon */}
      </button>
      
      {/* Navigation Links */}
      <nav className={`md:flex items-center space-x-6 relative ${isMenuOpen ? 'block' : 'hidden'} md:block`}>
        <Link to="/" className={`hover:text-gray-400 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
          Features
        </Link>
        <Link to="/pricing" className={`hover:text-gray-400 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
          Pricing
        </Link>

        {/* Employer Dropdown */}
        <div className="relative">
          <button
            onClick={handleEmployerDropdownToggle}
            className={`hover:text-gray-400 ${theme === 'dark' ? 'text-white' : 'text-gray-900'} focus:outline-none`}
            aria-haspopup="true"
            aria-expanded={isEmployerDropdownOpen}
          >
            Employer
          </button>
          {isEmployerDropdownOpen && (
            <div
              className={`absolute right-0 mt-2 w-48 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-50 ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'}`}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="py-1" role="none">
                <Link
                  to="/employer-homepage"
                  className={`block px-4 py-2 text-sm ${theme === 'dark' ? 'text-white hover:bg-gray-700' : 'text-gray-900 hover:bg-gray-100'}`}
                  role="menuitem"
                >
                  Dashboard
                </Link>
              </div>
              <div className="py-1" role="none">
                <Link
                  to="/employer-jobs"
                  className={`block px-4 py-2 text-sm ${theme === 'dark' ? 'text-white hover:bg-gray-700' : 'text-gray-900 hover:bg-gray-100'}`}
                  role="menuitem"
                >
                  Job Postings
                </Link>
              </div>
            </div>
          )}
        </div>

        {/* Interviewee Dropdown */}
        <div className="relative">
          <button
            onClick={handleDropdownToggle}
            className={`hover:text-gray-400 ${theme === 'dark' ? 'text-white' : 'text-gray-900'} focus:outline-none`}
            aria-haspopup="true"
            aria-expanded={isDropdownOpen}
          >
            Interviewee
          </button>
          {isDropdownOpen && (
            <div
              className={`absolute right-0 mt-2 w-48 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-50 ${theme === 'dark' ? 'bg-gray-800' : 'bg-white'}`}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="py-1" role="none">
                <Link
                  to="/interviewee-registration"
                  className={`block px-4 py-2 text-sm ${theme === 'dark' ? 'text-white hover:bg-gray-700' : 'text-gray-900 hover:bg-gray-100'}`}
                  role="menuitem"
                >
                  Mock Interview
                </Link>
              </div>
              <div className="py-1" role="none">
                <Link
                  to="/search"
                  className={`block px-4 py-2 text-sm ${theme === 'dark' ? 'text-white hover:bg-gray-700' : 'text-gray-900 hover:bg-gray-100'}`}
                  role="menuitem"
                >
                  Search
                </Link>
              </div>
            </div>
          )}
        </div>

        {/* Login Button */}
        <button
          onClick={handleLoginClick}
          className={`hover:text-gray-400 ${theme === 'dark' ? 'text-white' : 'text-gray-900'} focus:outline-none`}
        >
          Login
        </button>

        {/* Toggle Switch for Dark/Light Mode */}
        <Switch
          checked={theme === 'dark'}
          onChange={toggleTheme}
          className={`${
            theme === 'dark' ? 'bg-blue-600' : 'bg-gray-300'
          } relative inline-flex items-center h-6 rounded-full w-11 transition-colors duration-200 focus:outline-none`}
        >
          <span
            className={`${
              theme === 'dark' ? 'translate-x-6' : 'translate-x-1'
            } inline-block w-4 h-4 transform bg-white rounded-full transition-transform duration-200`}
          />
        </Switch>
      </nav>
    </header>
  );
};

export default Navbar;
