// EmailVerificationSuccess.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const EmailVerificationSuccess = () => {
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
      <Typography variant="h4" gutterBottom>
        Email Verified Successfully!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Your email has been verified. You can now access all features.
      </Typography>
      <Button variant="contained" color="primary" onClick={() => navigate('/account-settings')}>
        Go to Account Settings
      </Button>
    </Box>
  );
};

export default EmailVerificationSuccess;
