import React, { useState } from "react";
import { Box, Button, Container, Typography, Paper } from "@mui/material";
import CommonApi from "../../../apis/CommonApi";
import LocalStorage from "../../../utils/LocalStorage";

const IntervieweeUpload = () => {
  const [resumeFile, setResumeFile] = useState(null);
  const [jobDescriptionFile, setJobDescriptionFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [parsedResumeId, setParsedResumeId] = useState(null);
  const [interviewStarted, setInterviewStarted] = useState(false);
  const [chatResponse, setChatResponse] = useState(null);
  const [userInput, setUserInput] = useState("");
  const [parsedData, setParsedData] = useState(null);
  const [jobDescription, setJobDescription] = useState('');

  // Handle file selection for resume
  const handleResumeChange = (e) => {
    setResumeFile(e.target.files[0]);
  };

  // Handle file selection for job description
  const handleJobDescriptionChange = (e) => {
    setJobDescriptionFile(e.target.files[0]);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all required fields are selected
    if (!resumeFile || !jobDescriptionFile) {
      alert('Please select both a resume and a job description file to upload.');
      return;
    }

    setUploading(true);

    // Create form data to be sent to the API
    const formData = new FormData();
    formData.append('resume', resumeFile); 
    formData.append('jobDescription', jobDescriptionFile); 
    formData.append('intervieweeId', LocalStorage.getUserData().id); // Ensure intervieweeId is passed

    try {
      // Call the API to upload the files
      const token = LocalStorage.getAccessToken();
      const response = await CommonApi.uploadIntervieweeFiles(formData, token);

      if (response) {
        alert('Files uploaded successfully!');
        setParsedResumeId(response.parsedResumeId); // Set the parsedResumeId from response
        setParsedData(response.parsedData); 
        setJobDescription(response.jobDescription); 
        setResumeFile(null);
        setJobDescriptionFile(null);
      } else {
        alert('Failed to upload files. Please try again.');
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      alert('Error uploading files. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  // Start the interview with the chatbot
  const startInterview = async () => {
    if (!parsedResumeId) {
      alert("Please upload the resume and job description first.");
      return;
    }
    setInterviewStarted(true);
    try {
      const response = await CommonApi.initializeChatbotInterview({
        intervieweeId: LocalStorage.getUserData().id,
        parsedResumeId,
      });
      setChatResponse(response.initialChatResponse);
    } catch (error) {
      console.error("Error starting interview:", error);
      alert("Error starting interview. Please try again.");
    }
  };

  // Continue the interview based on user input
  const continueInterview = async () => {
    try {
      const response = await CommonApi.continueChatbotInterview({
        intervieweeId: LocalStorage.getUserData().id,
        parsedResumeId,
        userInput,
      });
      setChatResponse(response.chatResponse);
      setUserInput("");
    } catch (error) {
      console.error("Error continuing interview:", error);
      alert("Error continuing interview. Please try again.");
    }
  };

  return (
    <Container>
      <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
        <Typography variant="h5" gutterBottom>
          Upload Resume and Job Description
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <input
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleResumeChange}
            />
            <Typography variant="subtitle1">
              Selected Resume:{" "}
              {resumeFile ? resumeFile.name : "No file selected"}
            </Typography>
          </Box>
          <Box mb={2}>
            <input
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleJobDescriptionChange}
            />
            <Typography variant="subtitle1">
              Selected Job Description:{" "}
              {jobDescriptionFile
                ? jobDescriptionFile.name
                : "No file selected"}
            </Typography>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={uploading}
          >
            {uploading ? "Uploading..." : "Upload Files"}
          </Button>
        </form>
        
        {/* Display parsed data */}
        {parsedData && (
          <Box mt={2}>
            <Typography variant="h6">Parsed Resume Data:</Typography>
            <Typography><strong>Name:</strong> {parsedData.name}</Typography>
            <Typography><strong>Email:</strong> {parsedData.email}</Typography>
            <Typography><strong>Phone:</strong> {parsedData.phone}</Typography>
            <Typography><strong>Skills:</strong> {parsedData.skills}</Typography>
            <Typography><strong>Experience:</strong> {parsedData.experience}</Typography>
            <Typography><strong>Projects:</strong> {parsedData.projects}</Typography>
            <Typography><strong>Achievements:</strong> {parsedData.achievements}</Typography>
          </Box>
        )}

        {/* Display job description */}
        {jobDescription && (
          <Box mt={2}>
            <Typography variant="h6">Job Description:</Typography>
            <Paper elevation={1} style={{ padding: '10px', backgroundColor: '#f5f5f5' }}>
              <Typography>{jobDescription}</Typography>
            </Paper>
          </Box>
        )}

        {/* Show Start Interview Button */}
        {parsedResumeId && (
          <Button
            variant="contained"
            color="secondary"
            onClick={startInterview}
            style={{ marginTop: '10px' }}
          >
            Start Interview
          </Button>
        )}

        {interviewStarted && (
          <Box mt={2}>
            <Typography variant="subtitle1">Chatbot Response:</Typography>
            <Paper
              elevation={1}
              style={{ padding: "10px", backgroundColor: "#f5f5f5" }}
            >
              <Typography>{chatResponse}</Typography>
            </Paper>
            <Box mt={2}>
              <textarea
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                placeholder="Type your response here..."
                rows={4}
                style={{ width: "100%", padding: "10px" }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={continueInterview}
                style={{ marginTop: "10px" }}
              >
                Continue Interview
              </Button>
            </Box>
          </Box>
        )}
      </Paper>
    </Container>
  );
};

export default IntervieweeUpload;
