// Frontend Route
import CandidateInterviewPage from "../views/CandidateInterviewPage/CandidateInterviewPage";

const CandidateRoutes = [
    // Other routes...
    {
        path: "/chatbot",
        element: <CandidateInterviewPage />,
        name: "Interview",
        access: true,
    },
    // Other routes...
];

export default CandidateRoutes;
