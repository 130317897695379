import React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const PaginationComponent = ({ count, page, onChange }) => {
  return (
    <Stack spacing={2}>
      <Pagination count={count} page={page} variant="outlined" shape="rounded" onChange={onChange} />
    </Stack>
  );
};

export default PaginationComponent;
