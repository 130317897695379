import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Avatar, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from '../../assets/techoptima-01.jpg';

const IndividualHeader = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const isDropdownOpen = Boolean(anchorEl);

    const handleDropdownOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDropdownClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="fixed" color="inherit" elevation={1} sx={{ top: 0 }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                
                {/* Logo */}
                <Box component={Link} to="/" sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={Logo} alt="Optima AI Hire" style={{ height: '55px', marginRight: '10px' }} />
                </Box>

                {/* Navigation Links */}
                <Box sx={{ display: 'flex', gap: 3 }}>
                    <Button component={Link} to="/individual-dashboard" color="inherit">
                        Dashboard
                    </Button>
                    {/* <Button component={Link} to="/hire" color="inherit">
                        Hire a Professional
                    </Button> */}
                    <Button component={Link} to="/assessment-main" color="inherit">
                        Assesment
                    </Button>
                </Box>

                {/* User Profile Dropdown */}
                <IconButton color="inherit" onClick={handleDropdownOpen}>
                    <Avatar sx={{ bgcolor: 'orange' }}>N</Avatar>
                </IconButton>
                
                <Menu
                    anchorEl={anchorEl}
                    open={isDropdownOpen}
                    onClose={handleDropdownClose}
                    PaperProps={{
                        style: { width: 200 },
                    }}
                >
                    <MenuItem disabled>
                        <Typography variant="subtitle1">The Failure</Typography>
                    </MenuItem>
                    <MenuItem disabled>
                        <Typography variant="body2" color="textSecondary">nilurdr@gmail.com</Typography>
                    </MenuItem>
                    <MenuItem component={Link} to="/account-settings" onClick={handleDropdownClose}>
                        Account Settings
                    </MenuItem>
                    <MenuItem onClick={() => {
                        handleDropdownClose();
                        alert('Logging out');
                    }}>
                        Log Out
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
};

export default IndividualHeader;
