import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Dialog, DialogTitle, DialogContent, TextField, Button, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CommonApi from '../../../apis/CommonApi';

const suggestedSkills = [
  'UI Development', 'User Interface Designing', 'Ui/UX', 'Web Designing', 'UX', 
  'Front End', 'Ux Design', 'Photoshop', 'Visual Design', 'Adobe Xd'
];

const KeySkills = () => {
  const [open, setOpen] = useState(false);
  const [skills, setSkills] = useState([]);
  const [newSkill, setNewSkill] = useState("");
  const [error, setError] = useState(null);

  // Fetch skills from backend on component mount
  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const response = await CommonApi.getSkills(); 
        if (response.skills) {
          setSkills(response.skills);
        }
      } catch (error) {
        console.error("Error fetching skills:", error);
       
      }
    };
    fetchSkills();
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddSkill = () => {
    if (newSkill && !skills.some((s) => s.skill === newSkill)) {
      setSkills([...skills, { skill: newSkill, type: skills.length < 5 ? "primary" : "secondary" }]);
      setNewSkill("");
    }
  };

  const handleSaveSkills = async () => {
    try {
      setError(null);
      const response = await CommonApi.updateSkills(skills); 
      console.log("Skills updated:", response);
      handleClose();
    } catch (error) {
      console.error("Error updating skills:", error);
      setError("Failed to update skills. Please try again.");
    }
  };

  // Separate primary and secondary skills
  const primarySkills = skills.slice(0, 5);
  const secondarySkills = skills.slice(5);

  return (
    <Box sx={{ mb: 4 }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Key skills</Typography>
        <IconButton size="small" color="primary" onClick={handleOpen}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Box>

      {/* Primary Skills Section */}
      <Box display="flex" gap={4}>
        <Box flex={1}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2 }}>Primary Skills</Typography>
          <Box display="flex" flexDirection="row" gap={1} mb={2}>
            {primarySkills.map((skillObj) => (
              <Typography key={skillObj.skill} variant="body1" sx={{ color: 'text.primary' }}>
                {skillObj.skill}
              </Typography>
            ))}
          </Box>
        </Box>

        {/* Secondary Skills Section */}
        <Box flex={1}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2 }}>Secondary Skills</Typography>
          <Box display="flex" flexDirection="row" gap={1} mb={2}>
            {secondarySkills.map((skillObj) => (
              <Typography key={skillObj.skill} variant="body1" sx={{ color: 'text.primary' }}>
                {skillObj.skill}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>

      {/* Edit Skills Modal */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          Key skills
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            Add skills that best define your expertise, for example, Direct Marketing, Oracle, Java, etc. (Minimum 1)
          </Typography>
          
          {/* Display skills in the modal */}
          <Box display="flex" gap={1} flexWrap="wrap" mb={2}>
            {skills.map((skillObj) => (
              <Chip key={skillObj.skill} label={skillObj.skill} variant="outlined" onDelete={() => setSkills(skills.filter(s => s.skill !== skillObj.skill))} />
            ))}
          </Box>

          {/* Input to add a new skill */}
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Add skills"
            value={newSkill}
            onChange={(e) => setNewSkill(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleAddSkill()}
            sx={{ mb: 2 }}
          />
          <Button onClick={handleAddSkill} variant="outlined">Add Skill</Button>

          {/* Suggested skills */}
          <Typography variant="body2" color="textSecondary" sx={{ mb: 1, mt: 2 }}>
            Or you can select from the suggested set of skills
          </Typography>
          <Box display="flex" gap={1} flexWrap="wrap" mb={3}>
            {suggestedSkills.map((suggestedSkill) => (
              <Button
                key={suggestedSkill}
                variant="text"
                color="primary"
                onClick={() => !skills.some((s) => s.skill === suggestedSkill) && setSkills([...skills, { skill: suggestedSkill, type: skills.length < 5 ? "primary" : "secondary" }])}
              >
                {suggestedSkill}
              </Button>
            ))}
          </Box>

          <Box display="flex" justifyContent="space-between" mt={3}>
            <Button onClick={handleClose} color="primary">Cancel</Button>
            <Button variant="contained" color="primary" onClick={handleSaveSkills}>Save</Button>
          </Box>
          {error && (
            <Typography color="error" variant="body2" mt={2}>
              {error}
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default KeySkills;
