import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonApi from '../../../apis/CommonApi';
import { TextField, Button, Typography } from '@mui/material'; // Use Material-UI components


const IntervieweeLogin = () => {
    const [formData, setFormData] = useState({
      email: '',
      password: ''
    });
    const navigate = useNavigate();
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await CommonApi.loginInterviewee(formData);
        if (response.status === 200) {
          alert('Login successful!');
          navigate('/interviewee-dashboard');
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.error('Error during login:', error);
        alert('Login failed!');
      }
    };
  
    return (
      <div style={{ padding: '20px' }}>
        <Typography variant="h4" gutterBottom>
          Interviewee Login
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
        </form>
      </div>
    );
  };
  
  export default IntervieweeLogin;
  