// src/components/ParsedResumesSection.js
import React from 'react';
import { useTheme } from '../context/ThemeContext'; // Import the custom ThemeContext
import parsedResumesImage from '../assets/resumestatus2.png'; // Adjust path as necessary
import GridPattern from '../styles/magicui/GridPattern';

const ParsedResumesSection = () => {
  const { theme } = useTheme(); // Access the current theme

  // Define styles based on the theme
  const backgroundColor =
    theme === 'dark'
      ? 'bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700'
      : 'bg-gradient-to-r from-blue-100 via-blue-50 to-white';
  const textColor = theme === 'dark' ? 'text-white' : 'text-gray-800';
  const shadowColor = theme === 'dark' ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.2)';

  return (
    <section className={`relative py-16 ${backgroundColor} overflow-hidden`}>
      {/* GridPattern Component for the background effect */}
      <GridPattern
        width={40}
        height={40}
        numSquares={60}
        maxOpacity={0.1}
        duration={5}
        repeatDelay={0.5}
        className="absolute inset-0 z-0" // Adjust z-index to ensure it's behind the content
      />
      <div className="container mx-auto text-center relative z-10">
        <h2 className={`text-3xl font-bold mb-6 ${textColor}`}>
          See How We Process Resumes
        </h2>
        <p className={`text-lg mb-8 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-600'}`}>
          Our platform provides a seamless resume processing experience with a clear visual flow of steps.
        </p>
        <div className="relative mx-auto max-w-5xl p-8 bg-white/5 rounded-3xl shadow-lg overflow-hidden">
          <div className="relative rounded-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
            <img
              src={parsedResumesImage}
              alt="Parsed Resumes"
              className="w-full rounded-lg transition-transform transform hover:scale-105 duration-500 ease-in-out"
              style={{
                filter: `drop-shadow(0 20px 40px ${shadowColor})`, // Theme-aware shadow effect
              }}
            />
            {/* Optional: Subtle overlay effect for added depth */}
            <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-transparent to-transparent opacity-30 rounded-lg"></div>
          </div>
        </div>
      </div>
      {/* Bottom section with icons and descriptions */}
      <div className="flex justify-center mt-12 space-x-8">
        <div className="text-center p-4 bg-white/10 rounded-lg shadow-md hover:bg-white/20 transition-colors duration-300">
          <h4 className="text-lg font-bold text-gray-700 dark:text-white">AI Resume Parsing</h4>
          <p className="text-gray-500 dark:text-gray-400">Automatically extracts key details.</p>
        </div>
        <div className="text-center p-4 bg-white/10 rounded-lg shadow-md hover:bg-white/20 transition-colors duration-300">
          <h4 className="text-lg font-bold text-gray-700 dark:text-white">Job Matching</h4>
          <p className="text-gray-500 dark:text-gray-400">Finds best candidates fast.</p>
        </div>
        <div className="text-center p-4 bg-white/10 rounded-lg shadow-md hover:bg-white/20 transition-colors duration-300">
          <h4 className="text-lg font-bold text-gray-700 dark:text-white">Automated Interviews</h4>
          <p className="text-gray-500 dark:text-gray-400">Conducts AI-driven interviews.</p>
        </div>
      </div>
    </section>
  );
};

export default ParsedResumesSection;
