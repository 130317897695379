import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  // container: {
  //   padding: '20px',
  // },
  stepper: {
    backgroundColor: 'transparent',
    padding: 0,
  },
  stepIcon: {
    color: '#bdbdbd', // Default color of the steps
    "&$stepIconActive": {
      color: '#4A148C', // Active step color
    },
    "&$stepIconCompleted": {
      color: '#4A148C', // Completed step color
    },
  },
  stepIconActive: {}, // Required for custom styling
  stepIconCompleted: {}, // Required for custom styling
  stepConnector: {
    top: 22, // Aligns the connector with the stepper dots
    "& .MuiStepConnector-line": {
      borderColor: '#bdbdbd', // Default connector color
      borderTopWidth: 4,
    },
    "&.MuiStepConnector-active .MuiStepConnector-line": {
      borderColor: '#4A148C', // Active connector color
    },
    "&.MuiStepConnector-completed .MuiStepConnector-line": {
      borderColor: '#4CAF50', // Green color for completed steps
      borderTopWidth: 4, // Thickness of the progress line
    },
  },
  buttonGroup: {
    borderRadius: '8px', // Apply border-radius to the entire group
    overflow: 'hidden', // Ensure rounded corners display correctly
    '& > .MuiButton-root': {
      borderRadius: '0 !important', // Remove individual button border-radius
      color: '#fff', // Button text color
      backgroundColor: '#333', // Button background color
      padding: '8px 16px', // Padding for buttons
      '&:not(:last-child)': {
        marginRight: '0px', // Remove space between buttons
      },
      '&:hover': {
        backgroundColor: '#555', // Darker shade on hover
      },
    },
    '& > .MuiButtonGroup-grouped': {
      '&:not(:last-child)': {
        borderRight: '1px solid #fff', // Optional: Border between buttons
      },
    },
  },
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  positionsInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    padding: '15px 25px',
    borderRadius: '10px',
    marginBottom: '20px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  },
  positionsText: {
    color: '#333',
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '0 15px',
  },
  divider: {
    height: '20px',
    width: '2px',
    backgroundColor: '#4A148C',
    margin: '0 10px',
  },
  stepper: {
    backgroundColor: 'transparent',
    padding: 0,
  },
  stepIcon: {
    color: '#bdbdbd',
    "&$stepIconActive": {
      color: '#4A148C',
    },
    "&$stepIconCompleted": {
      color: '#4CAF50',
    },
  },
  stepIconActive: {},
  stepIconCompleted: {},
  stepConnector: {
    top: 22,
    '& .MuiStepConnector-line': {
      borderColor: '#bdbdbd',
      borderTopWidth: 4,
    },
    '&.MuiStepConnector-active .MuiStepConnector-line': {
      borderColor: '#4A148C',
    },
    '&.MuiStepConnector-completed .MuiStepConnector-line': {
      borderColor: '#4CAF50',
      borderTopWidth: 4,
    },
  },
}));

export default useStyles;