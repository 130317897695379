
import React, { useEffect, useState } from "react"; 
import {
  Container,
  Paper,
  Button,
  IconButton,
  Tooltip,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom";
import CommonApi from "../../../../apis/CommonApi.js";
import LocalStorage from "../../../../utils/LocalStorage.js";
import useStyles from "./JobDetailsStyles.js";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useDropzone } from "react-dropzone";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { saveAs } from "file-saver";
import { styled } from "@mui/system";
import 'ag-grid-enterprise';


function JobDetails() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [jobsData, setJobsData] = useState([]);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [parsingDialogOpen, setParsingDialogOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [updatedJob, setUpdatedJob] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const navigate = useNavigate();

  const onDrop = (acceptedFiles) => {
    setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 1024 * 1024 * 5,
    accept: "application/pdf",
  });

  const fetchJobs = async () => {
    const token = LocalStorage.getAccessToken();
    const companyData = LocalStorage.getCompanyData();

    try {
      const response = await CommonApi.getJobsByCompany(companyData._id, token);
      setJobsData(response.jobs || []);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const openUploadDialog = (jobId) => {
    setSelectedJob(jobId);
    setUploadDialogOpen(true);
  };

  const handleMoreClick = async (jobId) => {
    const token = LocalStorage.getAccessToken();
    try {
      const job = jobsData.find((job) => job._id === jobId);
      if (!job) {
        console.error("Job not found for job ID:", jobId);
        return;
      }
      const candidates = await CommonApi.getResumesByJobId(jobId, token);
      navigate("/parsed-resumes", { state: { jobId, job, candidates } });
    } catch (error) {
      console.error("Error fetching resumes:", error);
    }
  };

  const handleEditClick = (job) => {
    setSelectedJob(job);
    setUpdatedJob(job);
    setEditDialogOpen(true);
  };

  const saveUpdatedJob = async () => {
    const token = LocalStorage.getAccessToken();
    const positions = parseInt(updatedJob.numberOfPositions, 10); 
    if (isNaN(positions)) {
      console.error(
        "Invalid number for positions:",
        updatedJob.numberOfPositions
      );
      return;
    }

    // const jobToUpdate = { ...updatedJob, numberOfPositions: positions };

    // try {
    //   const response = await CommonApi.updateJob(
    //     selectedJob._id,
    //     jobToUpdate,
    //     token
    //   );
    //   console.error("Invalid number for positions:", updatedJob.numberOfPositions);
    //   return; 
    // }
    const jobToUpdate = { ...updatedJob, numberOfPositions: positions }; 

    try {
      await CommonApi.updateJob(selectedJob._id, jobToUpdate, token);
      setJobsData((prevJobsData) =>
        prevJobsData.map((job) =>
          job._id === selectedJob._id ? { ...job, ...jobToUpdate } : job
        )
      );
      setEditDialogOpen(false);
    } catch (error) {
      console.error("Error updating job:", error);
    }
  };

  const handleUploadClick = async (jobId) => {
    setUploadDialogOpen(false); // Close the upload dialog when the user clicks "Upload"
    setParsingDialogOpen(true); // Show parsing dialog when upload starts

    const token = LocalStorage.getAccessToken();
    const user = LocalStorage.getUserData();

    if (!user || !user.candidateProfileId) {
      console.error("Candidate profile ID is missing");
      setParsingDialogOpen(false); // Close parsing popup on error
      return;
    }

    const formData = new FormData();
    if (uploadedFiles.length === 0) {
      console.error("No files uploaded");
      setParsingDialogOpen(false); // Close parsing popup on error
      return;
    }

    uploadedFiles.forEach((file) => formData.append("resumes", file));
    formData.append("jobDescriptionId", jobId);
    formData.append("candidateProfileId", user.candidateProfileId);

    try {
      const response = await CommonApi.parseResume(formData, token);

      if (response && response.results) {
        // Fetch updated jobs data to get the new application count
        await fetchJobs();

        setSuccessMessage(
          `Resume parsed successfully. Job ID ${jobId} has been updated.`
        );
        setUploadedFiles([]); // Clear the uploaded files
      } else {
        throw new Error("Invalid response from the server.");
      }
    } catch (error) {
      console.error("Error parsing resumes:", error);
      setSuccessMessage("Failed to parse resumes. Please try again.");
    } finally {
      setParsingDialogOpen(false); // Close the parsing dialog after parsing
    }
  };

  const updateJobApplicationCount = async (jobId, parsedResumesCount) => {
    try {
      const token = LocalStorage.getAccessToken();
      const job = jobsData.find((job) => job._id === jobId);
      if (!job) throw new Error("Job not found.");

      const updatedData = {
        roleName: job.roleName,
        address: {
          country: job.address?.country || "",
          state: job.address?.state || "",
          city: job.address?.city || "",
        },
        experience: Number(job.experience) || 0,
        modeOfWork: job.modeOfWork || "",
        domain: job.domain || "",
        education: job.education || "",
        description: job.description || "",
        client: job.client || "",
        numberOfPositions: Number(job.numberOfPositions) || 0,
        application: parsedResumesCount, // Update with the new parsed resume count
      };

      await CommonApi.updateJob(jobId, updatedData, token);
      console.log(`Job application count updated to ${parsedResumesCount}`);
    } catch (error) {
      console.error("Error updating job application count:", error);
    }
  };

  const handleCellClick = (job) => {
    handleMoreClick(job._id);
  };

  const handleExportClick = (job) => {
    const primarySkills = job.primarySkills
      ? job.primarySkills.join(", ")
      : "n/a";
    const secondarySkills = job.secondarySkills
      ? job.secondarySkills.join(", ")
      : "n/a";

    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `Job Description for ${job.roleName}`,
                  bold: true,
                  size: 28,
                }),
              ],
            }),
            new Paragraph(`Client: ${job.client}`),
            new Paragraph(`Role: ${job.roleName}`),
            new Paragraph(`Domain: ${job.domain}`),
            new Paragraph(`Education: ${job.education}`),
            new Paragraph(`Experience: ${job.experience}`),
            new Paragraph(`Level: ${job.level}`),
            new Paragraph(`Mode of Work: ${job.modeOfWork}`),
            new Paragraph(`Number of Positions: ${job.numberOfPositions}`),
            new Paragraph(`Number of Applications: ${job.application}`),
            new Paragraph(`Status: ${job.status}`),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Primary Skills: ",
                  bold: true,
                }),
                new TextRun(primarySkills),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Secondary Skills: ",
                  bold: true,
                }),
                new TextRun(secondarySkills),
              ],
            }),
            new Paragraph(`Description: ${job.description}`),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${job.roleName}_jobdescription.docx`);
      console.log("Document created successfully");
    });
  };

  // const handleUploadClick = async (jobId) => {
  //   setLoading(true);
  //   const token = LocalStorage.getAccessToken();
  //   const user = LocalStorage.getUserData();

  //   if (!user || !user.candidateProfileId) {
  //     console.error("Candidate profile ID is missing");
  //     setLoading(false);
  //     return;
  //   }

  //   const formData = new FormData();
  //   if (uploadedFiles.length === 0) {
  //     console.error("No files uploaded");
  //     setLoading(false);
  //     return;
  //   }

  //   uploadedFiles.forEach((file) => formData.append("resumes", file));
  //   formData.append("jobDescriptionId", jobId);
  //   formData.append("candidateProfileId", user.candidateProfileId);

  //   try {
  //     const response = await CommonApi.parseResume(formData, token);
  //     if (response && response.results) {
  //       const parsedResumesCount = response.results.length;
  //       await updateJobApplicationCount(jobId, parsedResumesCount);
  //       setUploadDialogOpen(false);
  //       setUploadedFiles([]);
  //     } else {
  //       throw new Error("Invalid response from the server.");
  //     }
  //   } catch (error) {
  //     console.error("Error parsing resumes:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const updateJobApplicationCount = async (jobId, parsedResumesCount) => {
  //   const token = LocalStorage.getAccessToken();
  //   try {
  //     const job = jobsData.find((job) => job._id === jobId);
  //     if (!job) {
  //       throw new Error("Job not found.");
  //     }
  //     const updatedData = {
  //       roleName: job.roleName,
  //       address: {
  //         country: job.address?.country || '',
  //         state: job.address?.state || '',
  //         city: job.address?.city || '',
  //       },
  //       experience: Number(job.experience) || 0,
  //       modeOfWork: job.modeOfWork || '',
  //       domain: job.domain || '',
  //       education: job.education || '',
  //       description: job.description || '',
  //       client: job.client || '',
  //       numberOfPositions: Number(job.numberOfPositions) || 0,
  //       application: parsedResumesCount,
  //     };
  //     await CommonApi.updateJob(jobId, updatedData, token);
  //     console.log(`Job application count updated to ${parsedResumesCount}`);
  //   } catch (error) {
  //     console.error("Error updating job application count:", error);
  //   }
  // };


  const StyledAgGrid = styled(AgGridReact)(({ theme }) => ({
    '& .ag-header-row': {
      backgroundColor: '#ff2600', // Custom header background color
    },
    '& .ag-header-cell': {
      color: 'white', // Optional: Change header text color
      fontWeight: 'bold', // Optional: Make header text bold
    },
    '& .ag-row':{
      borderColor: '#ff2600',
    },
    '& .ag-layout-normal':{
      borderColor: '#ff2600'
      //  border: '1px solid #ff2600'
    }
  }));


  const ActionCellRenderer = (params) => (
    <div>
      <Tooltip title="Edit">
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            handleEditClick(params.data);
          }}
        >
          <EditIcon color="primary" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Upload Resume">
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            openUploadDialog(params.data._id);
          }}
        >
          <UploadIcon color="action" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Export Job Description">
        <IconButton
          onClick={(event) => {
            event.stopPropagation();
            handleExportClick(params.data);
          }}
        >
          <FileDownloadIcon color="secondary" />
        </IconButton>
      </Tooltip>
    </div>
  );

  const columnDefs = [
    {
      headerName: "Role",
      field: "roleName",
      sortable: true,
      filter: "agSetColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      cellRenderer: (params) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => handleCellClick(params.data)}
        >
          {params.value}
        </span>
      ),
    },
    {
      headerName: "Client",
      field: "client",
      sortable: true,
      filter: "agSetColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        applyMiniFilterWhileTyping: true,
      },
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Domain",
      field: "domain",
      sortable: true,
      filter: "agSetColumnFilter",
      menuTabs: ["filterMenuTab"],
      filterParams: {
        applyMiniFilterWhileTyping: true,
        buttons: ["clear", "reset", "apply"],
      },
    },
    {
      headerName: "Number of Positions",
      field: "numberOfPositions",
      sortable: true,
      filter: "agSetColumnFilter",
      menuTabs: ["filterMenuTab"],
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Number of Applications",
      field: "application",
      sortable: true,
      filter: "agSetColumnFilter", 
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Filled",
      field: "filledPositions",
      sortable: true,
      filter: "agSetColumnFilter", 
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Open",
      field: "openPositions",
      sortable: true,
      filter: "agSetColumnFilter",
      menuTabs: ["filterMenuTab"],
    },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: ActionCellRenderer,
    },
  ];

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    sortable: true,
    filter: true,
    suppressMenuHide: false,
    headerCheckboxSelection: true, 
    headerCheckboxSelectionFilteredOnly: true, 
  };

  return (
    <Container className={classes.jobDetailsContent}>
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="20vh"
        >
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ ml: 2 }}>
            Loading jobs...
          </Typography>
        </Box>
      )}
      {!loading && (
        <>
          <div style={{ textAlign: 'right', padding: '10px', paddingRight: '0px',
 }}>
            <Button 
              variant="outlined" 
              onClick={() => navigate('/jobdescription')}
              className={classes.CreateJobDesBtn}
              style={{
                textTransform: "capitalize",
                backgroundColor: "#ff2600",
                color: "white",
                border: "1px solid #ff2600",
                textAlign: "center",
                padding: "8px",
              }}
            >
              Create Job Description
            </Button>
          </div>
          <Paper>
            <div
              style={{
                backgroundColor: "#FF2600",
                color: "#fff",
                padding: "10px",
                paddingLeft: "15px",
                borderTopRightRadius: "5px",
                borderTopLeftRadius: "5px",
              }}
            >
              List of Job Descriptions
            </div>

            <div className={classes.tableContent}>
              <div className="ag-theme-alpine" style={{ height: 500, width: '100%' }}>
                <AgGridReact
                  rowData={jobsData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  pagination={true}  // Enable pagination
                  paginationPageSize={8} // Set number of rows per page
                  onGridReady={(params) => params.api.sizeColumnsToFit()}
                  frameworkComponents={{ ActionCellRenderer }}
                  editType="fullRow"
                  paginationAutoPageSize={true} // Automatically adjust pagination size
                  paginationComponentFramework={(props) => (
                    <div className={classes.paginationWrapper}>
                      {props.paginationControls}
                    </div>
                  )}
                />
              </div>
            </div>
          </Paper>

          {/* Edit Job Dialog */}
          <Dialog
            open={editDialogOpen}
            onClose={() => setEditDialogOpen(false)}
          >
            <DialogTitle>Edit Job</DialogTitle>
            <DialogContent>
              <TextField
                fullWidth
                label="Role Name"
                name="roleName"
                value={updatedJob.roleName || ""}
                onChange={(e) =>
                  setUpdatedJob({ ...updatedJob, roleName: e.target.value })
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Client"
                name="client"
                value={updatedJob.client || ""}
                onChange={(e) =>
                  setUpdatedJob({ ...updatedJob, client: e.target.value })
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Domain"
                name="domain"
                value={updatedJob.domain || ""}
                onChange={(e) =>
                  setUpdatedJob({ ...updatedJob, domain: e.target.value })
                }
                margin="normal"
              />
              <TextField
                fullWidth
                label="Number of Positions"
                name="numberOfPositions"
                type="number"
                value={updatedJob.numberOfPositions || ""}
                onChange={(e) =>
                  setUpdatedJob({
                    ...updatedJob,
                    numberOfPositions: e.target.value,
                  })
                }
                margin="normal"
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setEditDialogOpen(false)}
                color="secondary"
              >
                Cancel
              </Button>
              <Button onClick={saveUpdatedJob} color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>

          {/* Upload Resume Dialog */}
          <Dialog
            open={uploadDialogOpen}
            onClose={() => setUploadDialogOpen(false)}
          >
            <DialogTitle>Upload Resume</DialogTitle>
            <DialogContent>
              <Box
                {...getRootProps()}
                border="1px dashed grey"
                p={3}
                mb={2}
                textAlign="center"
              >
                <input {...getInputProps()} />
                <Typography>
                  Drag 'n' drop resumes here, or click to select files
                </Typography>
              </Box>
              <Box>
                {uploadedFiles.length > 0 &&
                  uploadedFiles.map((file, index) => (
                    <Typography key={index}>{file.name}</Typography>
                  ))}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setUploadDialogOpen(false)}
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleUploadClick(selectedJob)}
                color="primary"
              >
                Upload
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Container>
  );
}

export default JobDetails;
