// src/components/HeroSection.js
import React from 'react';
import { useTheme } from '../context/ThemeContext'; // Import the custom ThemeContext
// import GridPattern from '../styles/magicui/GridPattern';
import Ripple from '../styles/magicui/Ripple';

const HeroSection = () => {
  const { theme } = useTheme(); // Access the current theme

  return (
    <section
      className={`relative text-center py-20 overflow-hidden ${
        theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'
      }`}
    >
      {/* Use the GridPattern component to add a background grid effect */}
      <Ripple
        width={40}
        height={40}
        numSquares={60}
        maxOpacity={0.3}
        duration={5}
        className="absolute inset-0"
      />
      <div className="container mx-auto px-6 relative z-10">
        <h2 className="text-5xl font-bold mb-4">
          Welcome to <span className="text-orange-500">Optima AI Hire</span>
        </h2>
        <p className={`text-lg mb-6 max-w-2xl mx-auto ${theme === 'dark' ? 'text-gray-300' : 'text-gray-700'}`}>
          Streamline your hiring process with AI-driven insights and automated interviews.
        </p>
        <button className="bg-orange-500 text-white py-3 px-6 rounded-full shadow-md hover:bg-orange-400 transition-colors">
          Get Started
        </button>
      </div>
    </section>
  );
};

export default HeroSection;
