import { Typography } from '@mui/material'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import TextStyles from './TextStyles'

function Text(props) {
    const classes = TextStyles()
    const { children,
        smallLabel, mediumLabel, largeLabel,
        smallBlack, mediumBlack, largeBlack,
        smallBoldBlack, mediumBoldBlack, mediumBoldPrimary, largeBoldBlack,
        smallRed, mediumRed, largeRed,
        mediumBoldRed,
        smallGreen, mediumGreen, largeGreen,
        smallYellow, mediumYellow, largeYellow,
        smallBlue, mediumBlue, largeBlue,
        smallWhite, mediumWhite, largeWhite,
        veryLargeBoldBlack, smallGrayLabel,
        smallLightBlue, mediumLightBlue, largeLightBlue,
        verySmallLabel,
        smallOrange, mediumOrange, largeOrange, className,
        smallBoldBlue, mediumBoldBlue, largeBoldBlue,
        smallBoldGreen, smallBoldOrange, verySmallGreen, verySmallBoldBlack,
        mediumLessBoldBlack, red, sidebarText,
        ...rest
    } = props

    /**
     * When you called this function just you have to pass defined props for styles for typogrophy
     * ----------
     * if prop is not avaliable create new class in CustomTextStyles file for styles and add that class in 
     * >props 
     * >btnClasses
     * >Text.propTypes
     * ------------
     * ------------
     * @parem children :- content of the Text excepted type `node`
     * */

    const btnClasses = classNames({
        [className]: className,
        [classes.smallLabel]: smallLabel,
        [classes.mediumLabel]: mediumLabel,
        [classes.largeLabel]: largeLabel,
        [classes.smallBlack]: smallBlack,
        [classes.mediumBlack]: mediumBlack,
        [classes.largeBlack]: largeBlack,
        [classes.smallBoldBlack]: smallBoldBlack,
        [classes.mediumBoldBlack]: mediumBoldBlack,
        [classes.mediumBoldPrimary]: mediumBoldPrimary,
        [classes.largeBoldBlack]: largeBoldBlack,
        [classes.smallRed]: smallRed,
        [classes.mediumRed]: mediumRed,
        [classes.largeRed]: largeRed,
        [classes.smallGreen]: smallGreen,
        [classes.mediumGreen]: mediumGreen,
        [classes.largeGreen]: largeGreen,
        [classes.smallYellow]: smallYellow,
        [classes.mediumYellow]: mediumYellow,
        [classes.largeYellow]: largeYellow,
        [classes.smallBlue]: smallBlue,
        [classes.mediumBlue]: mediumBlue,
        [classes.largeBlue]: largeBlue,
        [classes.smallWhite]: smallWhite,
        [classes.mediumWhite]: mediumWhite,
        [classes.largeWhite]: largeWhite,
        [classes.smallOrange]: smallOrange,
        [classes.mediumOrange]: mediumOrange,
        [classes.largeOrange]: largeOrange,
        [classes.veryLargeBoldBlack]: veryLargeBoldBlack,
        [classes.smallGrayLabel]: smallGrayLabel,
        [classes.smallLightBlue]: smallLightBlue,
        [classes.mediumLightBlue]: mediumLightBlue,
        [classes.largeLightBlue]: largeLightBlue,
        [classes.verySmallLabel]: verySmallLabel,
        [classes.smallBoldBlue]: smallBoldBlue,
        [classes.mediumBoldBlue]: mediumBoldBlue,
        [classes.largeBoldBlue]: largeBoldBlue,
        [classes.smallBoldGreen]: smallBoldGreen,
        [classes.smallBoldOrange]: smallBoldOrange,
        [classes.verySmallGreen]: verySmallGreen,
        [classes.verySmallBoldBlack]: verySmallBoldBlack,
        [classes.mediumBoldRed]: mediumBoldRed,
        [classes.mediumLessBoldBlack]: mediumLessBoldBlack,
        [classes.red]: red,
    })
    return (
        <React.Fragment>
            <Typography {...rest} className={btnClasses} component={'div'}>
                {children}
            </Typography>
        </React.Fragment>
    )
}

export default Text

Text.propTypes = {
    variant: PropTypes.oneOf([
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'h7',
        'subtitle1',
        'subtitle2',
        'body1',
        'body2',
        'caption',
        'button',
        'overline',
    ]),
    className: PropTypes.string,
    children: PropTypes.node,
    smallLabel: PropTypes.bool,
    mediumLabel: PropTypes.bool,
    largeLabel: PropTypes.bool,
    smallBlack: PropTypes.bool,
    mediumBlack: PropTypes.bool,
    largeBlack: PropTypes.bool,
    smallBoldBlack: PropTypes.bool,
    mediumBoldBlack: PropTypes.bool,
    mediumBoldPrimary: PropTypes.bool,
    largeBoldBlack: PropTypes.bool,
    smallRed: PropTypes.bool,
    mediumRed: PropTypes.bool,
    largeRed: PropTypes.bool,
    smallGreen: PropTypes.bool,
    mediumGreen: PropTypes.bool,
    largeGreen: PropTypes.bool,
    smallYellow: PropTypes.bool,
    mediumYellow: PropTypes.bool,
    largeYellow: PropTypes.bool,
    smallBlue: PropTypes.bool,
    mediumBlue: PropTypes.bool,
    largeBlue: PropTypes.bool,
    smallWhite: PropTypes.bool,
    mediumWhite: PropTypes.bool,
    largeWhite: PropTypes.bool,
    smallOrange: PropTypes.bool,
    mediumOrange: PropTypes.bool,
    largeOrange: PropTypes.bool,
    veryLargeBoldBlack: PropTypes.bool,
    smallGrayLabel: PropTypes.bool,
    smallLightBlue: PropTypes.bool,
    mediumLightBlue: PropTypes.bool,
    largeLightBlue: PropTypes.bool,
    verySmallLabel: PropTypes.bool,
    smallBoldBlue: PropTypes.bool,
    mediumBoldBlue: PropTypes.bool,
    largeBoldBlue: PropTypes.bool,
    smallBoldGreen: PropTypes.bool,
    smallBoldOrange: PropTypes.bool,
    verySmallGreen: PropTypes.bool,
    verySmallBoldBlack: PropTypes.bool,
    mediumBoldRed: PropTypes.bool,
    mediumLessBoldBlack: PropTypes.bool,
    sidebarText: PropTypes.bool,
    red: PropTypes.bool,
}

