import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Stepper,
  Step,
  StepLabel,
  StepButton,
  StepConnector,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MuiButton,
  CircularProgress,
  Box,
  Card,
  CardContent,
  Divider,
  Chip,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import CommonApi from "../../../../apis/CommonApi.js";
import LocalStorage from "../../../../utils/LocalStorage.js";
import useStyles from "./ParsedResumesStyles.js";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import DoneIcon from '@mui/icons-material/Done';
import AssignmentIcon from '@mui/icons-material/Assignment';

// Step labels
const statusSteps = [
  "Applied",
  "Visa Verification",
  "Shortlisted",
  "Schedule Interview",
  "Invited for the Interview",
  "Document Verification",
  "Selected",
  "Send Offer Letter",
];

// Step connector with custom styles
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,#4CAF50 0%,#4CAF50 50%,#4CAF50 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,#4CAF50 0%,#4CAF50 50%,#4CAF50 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#bdbdbd",
    borderRadius: 1,
  },
})(StepConnector);

// Custom step icon component to handle rejection and completion display
const CustomStepIcon = ({ active, completed, icon, isRejected }) => {
  if (isRejected) {
    return <CancelOutlinedIcon color="error" />;
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 24,
        height: 24,
        borderRadius: "50%",
        marginLeft: '22px',
        backgroundColor: completed ? "#333" : active ? "#fff" : "transparent",
        color: completed ? "#fff" : active ? "#333" : "#bdbdbd",
        border: completed
          ? "none"
          : active
          ? "2px solid #333"
          : "1px solid #bdbdbd",
      }}
    >
      {icon}
    </div>
  );
};

const CircularProgressWithLabel = ({ value }) => {
  const percentage = Math.round(value);
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={100}
        size={40}
        thickness={5}
        sx={{ color: "#e0e0e0" }}
      />
      <CircularProgress
        variant="determinate"
        value={percentage}
        size={40}
        thickness={5}
        sx={{
          position: "absolute",
          left: 0,
          strokeLinecap: "round",
          color:
            percentage >= 75 ? "green" : percentage >= 50 ? "orange" : "red",
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
          fontSize="12px"
          fontWeight="bold"
        >
          {`${percentage}%`}
        </Typography>
      </Box>
    </Box>
  );
};

const ParsedResumes = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [job, setJob] = useState(location.state?.job || null);
  const { jobId, jobDescription } = location.state || {
    jobId: null,
    job: null,
    jobDescription: "N/A",
  };
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [rejectionDialogOpen, setRejectionDialogOpen] = useState(false);
  const [startInterview, setStartInterview] = useState(false);

  const handleBackClick = () => {
    navigate(-1); // Navigate to the previous page
  };

  useEffect(() => {
    const fetchResumes = async () => {
      const token = LocalStorage.getAccessToken();
      try {
        const response = await CommonApi.getResumesByJobId(jobId, token);
        const updatedCandidates = response.map((candidate) => ({
          ...candidate,
          status: "Applied",
          rejectedAt: null,
        }));
        setCandidates(updatedCandidates || []);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    if (jobId) {
      fetchResumes();
    } else {
      setLoading(false);
      setError(new Error("Job ID not found."));
    }
  }, [jobId]);
  console.log("job", job);

  const handleInterviewClick = (resume) => {
    console.log("Navigating to the chatbot with the following details:");
    console.log("Job ID:", jobId); // Ensure jobId is correctly set
    console.log("Job:", job); // Check if job is defined
    console.log("Resume:", resume);

    setStartInterview(true); // Set the state to true when the interview button is clicked
    navigate("/chatbot", {
      state: {
        job, // Pass the job object instead of jobDescription
        resume, // Pass the resume object correctly
        jobTitle: job?.roleName || "Unknown Title", // Add jobTitle if available
        requiredSkills: job?.primarySkills || [], // Ensure requiredSkills is available
        name: resume.name,
        email: resume.email,
        phone: resume.phone,
        skills: resume.skills,
        experience: resume.experience,
        education: resume.education,
        projects: resume.projects,
        startInterview: true,
      },
    });
  };

  const handleButtonClick = (event, candidate) => {
    if (candidate.rejectedAt !== null) {
      setRejectionDialogOpen(true);
      return;
    }
    setAnchorEl(event.currentTarget);
    setSelectedCandidate(candidate);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedCandidate(null);
  };

  const handleSendEmail = async (email, name, jobTitle, matchPercentage) => {
    console.log("Sending email with fields:", {
      email,
      name,
      jobTitle,
      matchPercentage,
    });

    if (
      !email ||
      !name ||
      !jobTitle ||
      matchPercentage === undefined ||
      matchPercentage === null
    ) {
      console.error("Missing required fields:", {
        email,
        name,
        jobTitle,
        matchPercentage,
      });
      alert("Missing required fields for sending email.");
      return;
    }

    try {
      const emailData = {
        email: email,
        name: name,
        jobTitle: jobTitle !== "N/A" ? jobTitle : "Job Title Not Provided",
        matchPercentage: matchPercentage.toString(),
      };
      const token = LocalStorage.getAccessToken();
      await CommonApi.sendShortlistedEmail(emailData, token);
      alert(`Shortlist email sent to ${email}`);
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send email");
    }
  };

  const handleStepClick = async (candidate, stepIndex) => {
    if (candidate.rejectedAt !== null) {
      setRejectionDialogOpen(true);
      return;
    }

    const newStatus = statusSteps[stepIndex];
    const token = LocalStorage.getAccessToken();

    try {
      // Update candidate status using the existing API
      await CommonApi.updateResumeStatus(candidate._id, newStatus, token);
      setCandidates((prev) =>
        prev.map((c) =>
          c._id === candidate._id ? { ...c, status: newStatus } : c
        )
      );

      // Update filled positions
      if (newStatus === "Selected" || newStatus === "Send Offer Letter") {
        setJob((prevJob) => ({
          ...prevJob,
          filledPositions: (prevJob.filledPositions || 0) + 1,
        }));
      } else if (
        candidate.status === "Selected" ||
        candidate.status === "Send Offer Letter"
      ) {
        setJob((prevJob) => ({
          ...prevJob,
          filledPositions: (prevJob.filledPositions || 0) - 1,
        }));
      }

      const jobTitle = jobDescription || "N/A";

      if (newStatus === "Schedule Interview") {
        const interviewDetails = {
          candidateEmail: candidate.email,
          candidateName: candidate.name,
          jobId,
          interviewType: "Technical",
          interviewerEmail: "Optima-AI Chatbot",
          interviewDate: new Date().toISOString(),
        };
        await CommonApi.scheduleInterview(interviewDetails, token);
        alert("Interview scheduled and email sent to the candidate");
      } else if (newStatus === "Shortlisted") {
        await handleSendEmail(
          candidate.email,
          candidate.name,
          jobTitle,
          candidate.matchPercentage
        );
      } else {
        alert(`${newStatus} status updated for ${candidate.name}`);
      }
    } catch (error) {
      console.error("Error updating status or sending email:", error);
      alert("Failed to update status or send email. Please try again.");
    }
  };

  const handleRejectCandidate = async (candidate) => {
    const token = LocalStorage.getAccessToken();

    try {
      await CommonApi.updateResumeStatus(candidate._id, "Rejected", token);
      setCandidates((prev) =>
        prev.map((c) =>
          c._id === candidate._id
            ? { ...c, status: "Rejected", rejectedAt: c.status }
            : c
        )
      );
      alert(`Candidate ${candidate.name} has been rejected.`);
    } catch (error) {
      console.error("Error rejecting candidate:", error);
      alert("Failed to reject the candidate. Please try again.");
    }
  };

  const handleMenuClick = async (action) => {
    if (selectedCandidate) {
      if (action === "Reject") {
        await handleRejectCandidate(selectedCandidate);
      } else {
        await handleStepClick(selectedCandidate, statusSteps.indexOf(action));
      }
      handleClose();
    }
  };

  const renderStepper = (candidate) => {
    const activeStep = statusSteps.indexOf(candidate.status);
    const rejectedStep = candidate.rejectedAt
      ? statusSteps.indexOf(candidate.rejectedAt)
      : -1;

    return (
      <Stepper
        alternativeLabel
        activeStep={candidate.rejectedAt !== null ? rejectedStep : activeStep}
        connector={<ColorlibConnector />}
        className={classes.stepper}
      >
        {statusSteps.map((label, index) => (
          <Step
            key={label}
            completed={index <= activeStep && candidate.rejectedAt === null}
          >
            <StepButton
              disabled={candidate.rejectedAt !== null}
              onClick={() => handleStepClick(candidate, index)}
            >
              <StepLabel
                StepIconComponent={(props) => (
                  <CustomStepIcon
                    {...props}
                    isRejected={index === rejectedStep}
                    completed={index <= activeStep}
                    active={index === activeStep}
                  />
                )}
              >
                {label}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    );
  };

  const handleRejectionDialogClose = () => {
    setRejectionDialogOpen(false);
  };

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography>Error: {error.message}</Typography>;

  // Job position tracking
  const totalPositions = job?.numberOfPositions || 0;
  const filledPositions = job?.filledPositions || 0;
  const openPositions = totalPositions - filledPositions;

  return (
    <Container className={classes.container}>
      <Box display="flex" alignItems="center" mb={3}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={handleBackClick}
          className={classes.backButton}
        >
          Back
        </Button>
        {/* <Typography variant="h4" className={classes.pageHeading}>
          Parsed Resumes
        </Typography> */}
      </Box>
      {/* Job Positions Information */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          my: 2,
          px: 2,
          py: 1,
          backgroundColor: "#ffe7e2",
          borderRadius: "8px",
          boxShadow: "0 2px 4px #ffe7e2",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          // sx={{
          //   my: 2,
          //   px: 2,
          //   py: 1,
          //   backgroundColor: "#f9f9f9",
          //   borderRadius: "8px",
          //   boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          // }}
        >
          <Chip
            icon={<AssignmentIcon />}
            label={`Total Positions: ${totalPositions}`}
            sx={{
              backgroundColor: "#e0f7fa",
              color: "#00796b",
              fontWeight: 600,
              mr: 2,
            }}
          />
          <Chip
            icon={<DoneIcon />}
            label={`Filled: ${filledPositions}`}
            sx={{
              backgroundColor: "#e8f5e9",
              color: "#2e7d32",
              fontWeight: 600,
              mr: 2,
            }}
          />
          <Chip
            icon={<LockOpenIcon />}
            label={`Open: ${openPositions}`}
            sx={{
              backgroundColor: "#ffebee",
              color: "#c62828",
              fontWeight: 600,
            }}
          />
        </Box>
      </Box>
      <List>
        {candidates.map((candidate, index) => (
          <Card
            key={candidate._id}
            variant="outlined"
            sx={{
              mb: 2,
              borderRadius: 2,
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s, box-shadow 0.3s",
              "&:hover": {
                transform: "translateY(-4px)",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <CardContent>
              <ListItem>
                <ListItemText
                  primary={candidate.name}
                  secondary={
                    <>
                      <Box display="flex" alignItems="center">
                        <Typography
                          component="span"
                          style={{ marginRight: "30px", marginBottom: "10px" }}
                        >
                          {`Email: ${candidate.email}`}
                        </Typography>
                        <Typography
                          component="span"
                          style={{ marginRight: "30px", marginBottom: "10px" }}
                        >
                          {`Phone: ${candidate.phone}`}
                        </Typography>
                        <Typography
                          component="span"
                          style={{ marginBottom: "10px" }}
                        >
                          {`Match:`}
                        </Typography>
                        <Box ml={1} mb={1} display="flex" alignItems="center">
                          <CircularProgressWithLabel
                            value={candidate.matchPercentage}
                          />
                        </Box>
                      </Box>
                      {renderStepper(candidate)}
                    </>
                  }
                />

                <ButtonGroup
                  variant="contained"
                  aria-label="outlined primary button group"
                  className={classes.buttonGroup}
                >
                  <Button onClick={() => handleInterviewClick(candidate)}>
                    Interview
                  </Button>
                  <Button
                    onClick={(event) => handleButtonClick(event, candidate)}
                  >
                    Actions
                  </Button>
                </ButtonGroup>
              </ListItem>
            </CardContent>
          </Card>
        ))}
      </List>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {statusSteps.map((action) => (
          <MenuItem key={action} onClick={() => handleMenuClick(action)}>
            {action}
          </MenuItem>
        ))}
        <MenuItem onClick={() => handleMenuClick("Reject")}>Reject</MenuItem>
      </Menu>

      {/* Rejection Dialog */}
      <Dialog open={rejectionDialogOpen} onClose={handleRejectionDialogClose}>
        <DialogTitle>Action Not Allowed</DialogTitle>
        <DialogContent>
          <Typography>
            This candidate has been rejected. No further actions can be taken.
          </Typography>
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleRejectionDialogClose} color="primary">
            Close
          </MuiButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ParsedResumes;
