import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";

const InputStyles = makeStyles((theme) => ({
  disabled: {
    backgroundColor: 'transparent !important',
  },
  underline: {
    '&:hover:not($disabled):before,&:before': {
      borderColor: 'grey !important',
      borderWidth: '1px !important',
    },
    '&:after': {
      borderColor: '#FFB400',
    },
  },

  underlineError: {
    '&:after': {
      borderColor: 'darkred',
    },
  },
  underlineSuccess: {
    '&:after': {
      borderColor: 'darkgreen',
    },
  },
  labelRoot: {
    color: 'black',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '1.42857',
    letterSpacing: 'unset',
  },
  feedback: {
    position: 'absolute',
    top: '18px',
    right: '0',
    zIndex: '2',
    display: 'block',
    width: '24px',
    height: '24px',
    textAlign: 'center',
    pointerEvents: 'none',
  },
  marginTop: {
    marginTop: '16px',
  },
  formControl: {
    paddingBottom: '10px',
    margin: '0px 0 0 0',
    position: 'relative',
    verticalAlign: 'unset',
  },
  formInput: {
    font: '14px Nunito !important',
    background: "#f4f4f4 !important",
    opacity: 1,
    borderRadius: '3px',
    height: '22px !important',
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    "&:focus": {
      border: '1px solid #4285F4 !important'
    }
  },
  borderInput: {
    font: '14px Nunito !important',
    background: '#FFFFFF !important',
    opacity: 1,
    border: '1px solid #D1D1D1 !important',
    borderRadius: '3px',
    height: '25px !important',
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    width: '100%',
  },
  smallInput: {
    font: '14px Nunito !important',
    background: '#FFFFFF !important',
    opacity: 1,
    border: '1px solid #D1D1D1 !important',
    borderRadius: '3px',
    height: '18px !important',
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    width: '80% !important',
  },
  formInputWhite: {
    background: "#FFFF 0% 0% no-repeat padding-box !important",
    opacity: 1,
    borderRadius: '3px',
    height: '30px !important',
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
  },
  fontSize: {
    font: " normal normal normal 12px/30px Nunito !important",
    letterSpacing: "0px !important",
    color: "#707070",
    opacity: "1",
  },
  forgotForm: {
    background: '#FFFFFF 0% 0% no-repeat padding-box !important',
    opacity: 1,
    border: '1px solid #1A181E !important',
    borderRadius: '50px',
    width: '100%',
    height: '32px ! important',
    [useTheme().breakpoints.down("sm")]: {
      height: "30px !important", // Hide the image on small screens if necessary
    },
    [useTheme().breakpoints.down("md")]: {
        height: "38px !important", // Hide the image on small screens if necessary
      },
      [useTheme().breakpoints.down("lg")]: {
        height: "28px !important", // Hide the image on small screens if necessary
      },
  },
  boxsize: {
    width: " 478px !important",
    height: " 45px !important",
    background: "#f4f4f4 0% 0% no-repeat padding-box !important",
    borderRadius: "3px !important",
    opacity: 1,
  },
  formInputWhiteTextArea: {
    background: "#FFFF 0% 0% no-repeat padding-box !important",
    opacity: 1,
    borderRadius: '3px',
    height: '30px !important',
    paddingBottom: "150px !important",
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
  },
  createInvoice: {
    background: '#FFFFFF 0% 0% no-repeat padding-box !important',
    opacity: 1,
    textAlign: 'center',
    width: '196px !important',
    height: '7px !important',
  },
  tabInput: {
    textAlign: "center"
  },
  //// FormInput Text Align Center
  formInputText: {
    textAlign: 'center',
    font: '14px Nunito !important',
    background: "#f4f4f4 !important",
    opacity: 1,
    borderRadius: '3px',
    height: '22px !important',
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    "&:focus": {
      border: '1px solid #4285F4 !important'
    }
  },
  commentHeight:{
    width:'100%',
    font: '14px Nunito !important',
    padding:"5px 10px !important",
    background: "#f4f4f4 !important",
    opacity: 1,
    borderRadius: '3px !important',
    "&:focus": {
      border: '1px solid #4285F4 !important'
    }
  },
  selectDate: {
    font: '9px Nunito !important',
    background: "#FFFFFF !important",
    opacity: 1,
    borderRadius: '3px',
    height: '22px !important',
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    border: '1px solid #E3E3E3 !important'
},
largeFormInput: {
    font: '14px Nunito !important',
    background: "#f4f4f4 !important",
    opacity: 1,
    borderRadius: '3px',
    height: '25px !important',
    paddingLeft: '10px !important',
    paddingRight: '10px !important',
    "&:focus": {
        border: '1px solid #4285F4 !important'
    }
  },
  employeeFormInput: {
    font: '14px Nunito !important',
    background: "#ffffff !important",
    // opacity: 1,
    borderRadius: '8px',
    border: '1px solid #C7CCD3 !important',
    // height: '54px !important',
    color: 'red ',
    padding: '18.5px, 12px, 18.5px, 12px !important',
    "&:focus": {
      border: '1px solid #4285F4 !important'
    }
  },
  clientInput: {
    font: '16px Nunito Sans, sans-serif !important',
    background: "#FFFFFF !important",
    opacity: 1,
    borderRadius: '8px !important',
    border: `1px solid grey !important`,
    height: '20px !important',
    "&:disabled": {
      font: '16px Nunito Sans, sans-serif !important',
      background: "#FAFAFA !important",
      opacity: 1,
      borderRadius: '8px !important',
      border: 'none !important',
      height: '20px !important',
    },
   
  },
//   ".css-1pw81iq-MuiInputBase-root-MuiFilledInput-root.Mui-disabled": {
//     backgroundColor: 'none !important',
// }
// root: {
//   "& .MuiInputBase-root-MuiFilledInput-root.Mui-disabled": {
//         backgroundColor: 'none !important',
//     }
// },

// "&css-1pw81iq-MuiInputBase-root-MuiFilledInput-root.Mui-disabled": {
//   backgroundColor: 'none !important'
// }
root: {
  "& .MuiFilledInput-root": {
    background: "none !important"
  }
}

}));

export default InputStyles
 
