import React, { useState, useEffect } from 'react';
import { Box, Typography, Link, IconButton, Dialog, DialogTitle, DialogContent, TextField, Button, RadioGroup, FormControlLabel, Radio, Grid, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CommonApi from '../../../apis/CommonApi';

const Education = () => {
    const [open, setOpen] = useState(false);
    const [educationData, setEducationData] = useState({
        educationLevel: "",
        university: "",
        course: "",
        specialization: "",
        courseType: "full-time",
        startingYear: "",
        endingYear: "",
        gradingSystem: ""
    });
    const [educationList, setEducationList] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch initial education data when component mounts
        fetchEducationData();
    }, []);

    const fetchEducationData = async () => {
        try {
            const data = await CommonApi.getAccountInformation();
            setEducationList(data.education || []);
        } catch (error) {
            console.error("Error fetching education data:", error);
        }
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEducationData({ ...educationData, [name]: value });
    };

    const handleSaveEducation = async () => {
        try {
            setError(null);
            const response = await CommonApi.updateEducation(educationData);
            console.log("Education updated:", response);

            // Fetch the updated data to reflect it on the frontend
            fetchEducationData();

            handleClose();
        } catch (error) {
            console.error("Error updating education:", error);
            setError("Failed to update education. Please try again.");
        }
    };

    return (
        <Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Education</Typography>
                <Link href="#" variant="body2" color="primary" onClick={handleOpen}>Add education</Link>
            </Box>
            <Box mb={2}>
                {educationList.map((education, index) => (
                    <Box key={index} mb={2}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{education.educationLevel}</Typography>
                        <Typography variant="body2" color="textSecondary">{education.university}</Typography>
                        <Typography variant="body2" color="textSecondary">{education.startingYear} - {education.endingYear}</Typography>
                        <IconButton size="small" color="primary">
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </Box>
                ))}
            </Box>

            {/* Education Dialog */}
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Education
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                        Details like course, university, and more, help recruiters identify your educational background.
                    </Typography>

                    <TextField fullWidth label="Education" name="educationLevel" variant="outlined" select value={educationData.educationLevel} onChange={handleChange} sx={{ mb: 2 }}>
                        <MenuItem value="High School">High School</MenuItem>
                        <MenuItem value="Undergraduate">Undergraduate</MenuItem>
                        <MenuItem value="Graduate">Graduate</MenuItem>
                        <MenuItem value="Doctorate">Doctorate</MenuItem>
                    </TextField>

                    <TextField fullWidth label="University/Institute" name="university" variant="outlined" value={educationData.university} onChange={handleChange} sx={{ mb: 2 }} />
                    
                    <TextField fullWidth label="Course" name="course" variant="outlined" select value={educationData.course} onChange={handleChange} sx={{ mb: 2 }}>
                        <MenuItem value="Bachelor of Science">Bachelor of Science</MenuItem>
                        <MenuItem value="Bachelor of Arts">Bachelor of Arts</MenuItem>
                        <MenuItem value="Master of Science">Master of Science</MenuItem>
                    </TextField>

                    <TextField fullWidth label="Specialization" name="specialization" variant="outlined" select value={educationData.specialization} onChange={handleChange} sx={{ mb: 2 }}>
                        <MenuItem value="Computer Science">Computer Science</MenuItem>
                        <MenuItem value="Mathematics">Mathematics</MenuItem>
                        <MenuItem value="Physics">Physics</MenuItem>
                    </TextField>

                    <RadioGroup row name="courseType" value={educationData.courseType} onChange={handleChange} sx={{ mb: 2 }}>
                        <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>Course type</Typography>
                        <FormControlLabel value="full-time" control={<Radio />} label="Full time" />
                        <FormControlLabel value="part-time" control={<Radio />} label="Part time" />
                        <FormControlLabel value="distance" control={<Radio />} label="Correspondence/Distance learning" />
                    </RadioGroup>

                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Starting year" name="startingYear" variant="outlined" select value={educationData.startingYear} onChange={handleChange}>
                                <MenuItem value="2020">2020</MenuItem>
                                <MenuItem value="2021">2021</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Ending year" name="endingYear" variant="outlined" select value={educationData.endingYear} onChange={handleChange}>
                                <MenuItem value="2022">2022</MenuItem>
                                <MenuItem value="2023">2023</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>

                    <TextField fullWidth label="Grading system" name="gradingSystem" variant="outlined" select value={educationData.gradingSystem} onChange={handleChange}>
                        <MenuItem value="Percentage">Percentage</MenuItem>
                        <MenuItem value="CGPA">CGPA</MenuItem>
                        <MenuItem value="GPA">GPA</MenuItem>
                    </TextField>

                    <Box display="flex" justifyContent="space-between" mt={3}>
                        <Button onClick={handleClose} color="primary">Cancel</Button>
                        <Button variant="contained" color="primary" onClick={handleSaveEducation}>Save</Button>
                    </Box>
                    {error && (
                        <Typography color="error" variant="body2" mt={2}>
                            {error}
                        </Typography>
                    )}
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default Education;
