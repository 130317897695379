import socket from './socket';  // Assuming you have a socket.js file that initializes the connection
import LocalStorage from './LocalStorage';

const initSocketConnection = () => {
  const loggedInUser = LocalStorage.getUserData();
  const loggedInUserEmail = loggedInUser?.user?.email || '';
  const loggedInUserRole = loggedInUser?.user?.role || '';

  if (loggedInUserEmail) {
    console.log(`Initializing socket connection for: ${loggedInUserEmail}`);
    socket.connect();
    socket.emit("userOnline", loggedInUserEmail, loggedInUserRole);
  } else {
    console.error("No loggedInUserEmail found during socket initialization");
  }

  return socket;
};

const handleSocketDisconnection = () => {
  const loggedInUserEmail = LocalStorage.getUserData()?.user?.email || '';
  if (loggedInUserEmail) {
    socket.emit("logout", loggedInUserEmail);
    socket.disconnect();
  }
};

export { initSocketConnection, handleSocketDisconnection };