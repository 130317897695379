// src/components/FAQ.js
import React from 'react';
import { useTheme } from '../context/ThemeContext'; // Import the custom ThemeContext
import Meteors from '../styles/magicui/Meteor';

const FAQ = () => {
  const { theme } = useTheme(); // Access the current theme

  const faqs = [
    {
      question: 'How does the AI-powered resume parsing work?',
      answer:
        'Our AI-powered resume parsing automatically extracts key details such as name, contact information, education, skills, and work experience from uploaded resumes, allowing for quick and accurate candidate profiling.',
    },
    {
      question: 'What types of job descriptions can I create?',
      answer:
        'You can create detailed job descriptions that include role requirements, responsibilities, qualifications, and more. Our platform provides customizable templates to help you get started quickly.',
    },
    {
      question: 'How does the job matching feature work?',
      answer:
        'Our job matching feature compares the parsed resume details with job descriptions using AI algorithms to calculate a match percentage. This helps you identify the most suitable candidates based on the job requirements.',
    },
    {
      question: 'Can I conduct interviews using AI?',
      answer:
        'Yes, our platform includes Optima AI Chatbot, which analyzes both resumes and job descriptions to conduct interactive and relevant interviews with candidates. This feature helps streamline the interview process by automating initial screening questions.',
    },
    {
      question: 'What types of notifications can I send?',
      answer:
        'You can send a variety of notifications including welcome emails to new users, interview scheduling, and status updates to candidates. Our platform supports automated and manual communication directly from your dashboard.',
    },
    {
      question: 'How does user management work?',
      answer:
        'Company Owners can manage their company profiles, add users with different roles such as admins and regular users, and oversee the entire recruitment process. This ensures that the right permissions and access are granted based on user roles.',
    },
  ];

  return (
    <section className={`relative py-16 ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'} transition-colors duration-300 overflow-hidden`}>
      <Meteors number={20} /> {/* Adding the meteors as a background effect */}
      <h2 className="text-3xl font-bold text-center mb-6 relative z-10">Frequently Asked Questions</h2>
      <p className={`text-center mb-12 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-700'} relative z-10`}>
        Here are some common questions about our platform. Need more help? <a href="#" className={`hover:underline ${theme === 'dark' ? 'text-orange-500' : 'text-orange-600'}`}>Contact us.</a>
      </p>
      <div className="container mx-auto space-y-4 px-4 relative z-10">
        {faqs.map((faq, index) => (
          <details key={index} className={`${theme === 'dark' ? 'bg-gray-800' : 'bg-gray-200'} p-4 rounded-lg`}>
            <summary className="cursor-pointer font-semibold">
              {faq.question}
            </summary>
            <p className={`${theme === 'dark' ? 'text-gray-400' : 'text-gray-700'} mt-2`}>
              {faq.answer}
            </p>
          </details>
        ))}
      </div>
    </section>
  );
};

export default FAQ;
