import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

function MockupInterview() {
  const location = useLocation();
  const { job, resume } = location.state || { job: null, resume: null };
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(true);
  const [speaking, setSpeaking] = useState(false);
  const [response, setResponse] = useState('');
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false); // State for processing

  useEffect(() => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      setError("Browser does not support speech recognition.");
      return;
    }
    SpeechRecognition.startListening({ continuous: true });
    setIsListening(true);
  }, []);

  const speakText = (text) => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.onstart = () => {
      SpeechRecognition.stopListening();
      setIsListening(false);
      setSpeaking(true);
    };
    utterance.onend = () => {
      SpeechRecognition.startListening({ continuous: true });
      setIsListening(true);
      setSpeaking(false);
    };
    synth.speak(utterance);
  };

  useEffect(() => {
    if (isListening && resume && resume.name && job) {
      const greetCandidate = () => {
        const greeting = `Hello ${resume.name}, welcome to your mock interview! Let's begin by discussing your skills in ${resume.primarySkills?.join(', ') || 'various areas'}.`;
        setResponse(greeting);
        speakText(greeting);
      };
      greetCandidate();
    }
  }, [isListening, resume, job]);

  useEffect(() => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      return;
    }

    const handleSpeechEnd = async () => {
      if (!isListening || transcript.trim().length < 5) {
        return;
      }

      setProcessing(true); // Start processing
      try {
        const botResponse = `You said: ${transcript}. Let's talk more about your experience.`;
        setResponse(botResponse);
        speakText(botResponse);
      } catch (error) {
        setError('Failed to process your input.');
      } finally {
        resetTranscript();
        setProcessing(false); // End processing
      }
    };

    handleSpeechEnd();
  }, [transcript, isListening, resetTranscript]);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 relative">
      <div 
        className={`absolute top-20 w-36 h-36 rounded-full bg-black ${speaking ? "animate-pulse scale-110" : "scale-100"}`}
        title={speaking ? "Responding..." : "Waiting for response"}
      />
      {processing && <p className="absolute top-16 text-lg">Processing...</p>} {/* Simple processing text */}
      {error && <p className="text-red-500">{error}</p>}
      <p className="mt-4 text-lg">{response}</p>
    </div>
  );
}

export default MockupInterview;
