import React, { useState } from 'react';
import UserProfileStyles from '../UserProfileStyles';
import { Box, Typography, Tabs, Tab } from '@mui/material';

import GeneralDetails from '../generaldetails/GeneralDetails';
// import ContactDetails from './ContactDetails';
// import EmergencyContact from './EmergencyContact';
// import CurrentAddress from './CurrentAddress';
// import EmploymentDetails from './EmploymentDetails';

// import name from '../../';

// import { useNavigate } from "react-router-dom";


const generalDetailsTab = ["General Details", "Contact Details", "Emergency Contact", "Current Address", "Employment Details"];

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function GeneralDetailsComponent(props) {    
    const { individualData, setIndividualData } = props;


    const classes = UserProfileStyles();
    const [tab, setTab] = useState(0);

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };


    return (
        <Box sx={{ width: '100%', minHeight: "76vh", boxShadow: "0px 0px 2px 0px #919EAB4D", borderRadius: "6px" }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
                <Tabs value={tab} onChange={handleChangeTab} aria-label="basic tabs example">
                    {
                        generalDetailsTab.map((item, key) => (
                            <Tab key={key} label={item} {...a11yProps(0)} className={classes.tabTitle} />
                        ))
                    }
                </Tabs>
            </Box>
            <CustomTabPanel value={tab} index={0}>
                <GeneralDetails individualData={individualData} setIndividualData={setIndividualData} />
            </CustomTabPanel>
            {/* <CustomTabPanel value={tab} index={1}>
                <ContactDetails />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={2}>
                <EmergencyContact />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={3}>
                <CurrentAddress />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={4}>
                <EmploymentDetails />
            </CustomTabPanel> */}
        </Box>
    );
}