// src/App.js
import React from 'react';
import { ThemeProvider } from './context/ThemeContext'; // Importing ThemeProvider
import Navbar from './components/Navbar'; 
import HeroSection from './components/HeroSection'; 
import Features from './components/Features'; 
import Pricing from './components/Pricing'; 
import FAQ from './components/FAQ'; 
import RegistrationForm from './components/RegistrationForm'; 
import Footer from './components/Footer'; 
import './styles/globals.css'; // Global styles
import PromoSection from './components/PromoSection';
import ParsedResumesSection from './components/ParsedResumesSection';

function LandingPage() {
  return (
    <ThemeProvider>
      <div className="min-h-screen flex flex-col">
        <Navbar />
        <main className="flex-grow">
          <HeroSection />
          <PromoSection />
          <Features />
          <ParsedResumesSection />
          <Pricing />
          <FAQ />
          <RegistrationForm />
        </main>
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default LandingPage;
