import { makeStyles } from "@mui/styles";


const MyProfileStyles = makeStyles(() => ({

    listContainer: {
        borderRadius: '10px !important',
        boxShadow: '0px 4px 8px #abc4ff !important',
        marginBottom: '20px !important',
        backgroundColor: 'white !important',
        padding: '10px 20px 10px 20px !important',
        width: '100% !important',
        borderLeft: '8px solid #0c75eb',
        height: '100px',
        marginTop: '2% !important',
        marginBottom: '30% !important'
    },

}))

export default MyProfileStyles;
