import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import jobDescriptions from "./jobDescriptions";
import {
  Box,
  Button,
  Typography,
  Container,
  TextField,
  Paper,
  Grid,
  IconButton,
} from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import IndividualHeader from "./IndividualHeader";
import IndividualFooter from "./IndividualFooter";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SearchResults = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const initialSearchTerm = query.get("query") || "";
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [results, setResults] = useState([]);

  useEffect(() => {
    const currentQuery = new URLSearchParams(location.search).get("query") || "";
    setSearchTerm(currentQuery);

    if (currentQuery) {
      const filteredResults = jobDescriptions.filter((job) =>
        job.title.toLowerCase().includes(currentQuery.toLowerCase())
      );
      setResults(filteredResults);
    }
  }, [location.search]);

  const handleSearchSubmit = (event) => {
    if (event.key === "Enter" && searchTerm) {
      navigate(`/results?query=${searchTerm}`, { replace: true });
    }
  };

  const handleApplyClick = (job) => {
    navigate(`/job-description-details/${job.id}`, { state: { job } });
  };

  const handleApplyLater = (job) => {
    alert(`Saved ${job.title} to apply later`);
  };

  return (
    <>
      <IndividualHeader />
      <Container maxWidth="xl" sx={{ mt: { xs: 8, md: 12 }, mb: { xs: 6, md: 10 } }}>
        {/* Top Section */}
        <Box
          sx={{
            py: { xs: 4, md: 8 },
            px: { xs: 2, sm: 4 },
            mb: 5,
            background: "linear-gradient(to right, #ffefe0, #ffe0e0)",
            borderRadius: 2,
            textAlign: "center",
            overflow: "hidden",
            position: 'relative',
          }}
        >
          <IconButton
            onClick={() => navigate(-1)}
            sx={{
              position: 'absolute',
              top: { xs: 8, md: 20 },
              left: { xs: 8, md: 20 },
              bgcolor: 'rgba(255, 255, 255, 0.8)',
              '&:hover': { bgcolor: 'rgba(255, 255, 255, 1)' },
            }}
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h3"
            fontWeight="bold"
            gutterBottom
            color="textPrimary"
            sx={{ fontSize: { xs: "1.8rem", sm: "2.2rem", md: "2.5rem" } }}
          >
            Find Your Next Career Today
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mb: 3, fontSize: { xs: "0.9rem", md: "1rem" } }}
          >
            Discover exciting job opportunities that fit your skills and career
            goals. Our advanced job search helps you find the best matches
            quickly.
          </Typography>

          {/* Search Box */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 3,
              width: "100%",
              maxWidth: "800px",
              mx: "auto",
            }}
          >
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Search for a job"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleSearchSubmit}
              sx={{
                bgcolor: "#ffffff",
                borderRadius: 2,
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#d0d4d9",
                  },
                  "&:hover fieldset": {
                    borderColor: "#a8b2bd",
                  },
                },
              }}
            />
          </Box>
        </Box>

        {/* Main Content Area with Job Listings */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
              {results.length} jobs found
            </Typography>

            {results.map((job, index) => (
              <Paper
                key={index}
                elevation={2}
                sx={{
                  p: 3,
                  mb: 3,
                  borderRadius: 2,
                  border: "1px solid #e0e0e0",
                  transition: "transform 0.3s ease",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  flexDirection: { xs: "column", md: "row" },
                  "&:hover": {
                    transform: "scale(1.02)",
                    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                      {job.title}
                    </Typography>
                    {/* Bookmark icon positioned on the right in larger screens, at top in smaller screens */}
                    <IconButton
                      sx={{
                        color: "rgb(255, 38, 0)",
                        display: { xs: "block", md: "none" },
                        alignSelf: "flex-end",
                        mb: { xs: 1, md: 0 },
                      }}
                      aria-label="Bookmark"
                    >
                      <BookmarkBorderIcon />
                    </IconButton>
                  </Box>

                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    {job.fullDescription.split("\n")[1]}
                  </Typography>

                  {/* Job Info Row */}
                  <Box sx={{ display: "flex", gap: 4, mt: 2, flexWrap: "wrap" }}>
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        Location
                      </Typography>
                      <Typography variant="body2">{job.location}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        Job Type
                      </Typography>
                      <Typography variant="body2">{job.jobType}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        Salary
                      </Typography>
                      <Typography variant="body2">{job.salary}</Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Action Buttons and Bookmark */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "row", md: "column" },
                    alignItems: { xs: "center", md: "flex-end" },
                    mt: { xs: 2, md: 0 },
                    width: { xs: "100%", md: "auto" },
                  }}
                >
                  {/* Bookmark icon for larger screens, shown inline with title for smaller screens */}
                  <IconButton
                    sx={{
                      color: "rgb(255, 38, 0)",
                      display: { xs: "none", md: "block" },
                      alignSelf: { md: "flex-end" },
                      mb: { xs: 0, md: 1 },
                    }}
                    aria-label="Bookmark"
                  >
                    <BookmarkBorderIcon />
                  </IconButton>
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "rgb(255, 38, 0)",
                      color: "#fff",
                      width: { xs: "calc(50% - 4px)", md: "100%" },
                      mb: { xs: 0, md: 1 },
                      "&:hover": { bgcolor: "rgb(220, 34, 0)" },
                    }}
                    onClick={() => handleApplyClick(job)}
                  >
                    Apply
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      color: "rgb(255, 38, 0)",
                      width: { xs: "calc(50% - 4px)", md: "100%" },
                      ml: { xs: 1, md: 0 },
                      borderColor: "rgb(255, 38, 0)",
                      "&:hover": {
                        borderColor: "rgb(220, 34, 0)",
                        bgcolor: "rgba(255, 38, 0, 0.1)",
                      },
                    }}
                    onClick={() => handleApplyLater(job)}
                  >
                    Apply Later
                  </Button>
                </Box>
              </Paper>
            ))}
          </Grid>

          {/* Sticky Sidebar Section */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                position: { md: "sticky" },
                top: { md: 100 },
              }}
            >
              {/* Related Careers Section */}
              <Box
                sx={{
                  p: 3,
                  bgcolor: "#f9fafc",
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
                  mb: 3,
                }}
              >
                <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>
                  Related Careers
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {[
                    "UI Designer",
                    "UX Researcher",
                    "Graphic Designer",
                    "Web Designer",
                    "Product Designer",
                    "Webflow Designer",
                  ].map((career, index) => (
                    <Button
                      key={index}
                      variant="outlined"
                      sx={{
                        borderRadius: 2,
                        textTransform: "none",
                        borderColor: "#c5c9cc",
                        color: "rgb(255, 38, 0)",
                        "&:hover": {
                          backgroundColor: "#ffe0e0",
                        },
                      }}
                    >
                      {career}
                    </Button>
                  ))}
                </Box>
              </Box>

              {/* Subscribe for Updates Section */}
              <Box
                sx={{
                  p: 3,
                  bgcolor: "#f9fafc",
                  borderRadius: 2,
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.05)",
                }}
              >
                <Typography variant="h6" fontWeight="bold">
                  Subscribe for Updates
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mt: 1, mb: 2 }}
                >
                  Stay updated with the latest job openings tailored to your
                  preferences.
                </Typography>
                <TextField
                  variant="outlined"
                  placeholder="Enter your email"
                  fullWidth
                  sx={{
                    bgcolor: "#ffffff",
                    borderRadius: 2,
                  }}
                />
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    mt: 2,
                    bgcolor: "rgb(255, 38, 0)",
                    "&:hover": { bgcolor: "rgb(220, 34, 0)" },
                  }}
                >
                  Subscribe
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <IndividualFooter />
    </>
  );
};

export default SearchResults;

// import React, { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import { Box, Typography, Container, Paper } from '@mui/material';
// import IndividualHeader from './IndividualHeader';
// import IndividualFooter from './IndividualFooter';
// import CommonApi from '../../apis/CommonApi';
// import LocalStorage from '../../utils/LocalStorage'; // Import if needed for getting tokens

// const SearchResults = () => {
//   const [jobs, setJobs] = useState([]);
//   const location = useLocation();
//   const searchQuery = new URLSearchParams(location.search).get('query') || '';

//   // Fetch jobs from the backend when the component mounts or when the search query changes
//   useEffect(() => {
//     const fetchJobs = async () => {
//       try {
//         const token = LocalStorage.getAccessToken(); // Retrieve token if needed
//         const response = await CommonApi.getJobs(token, searchQuery); // Modify if `companyId` is needed
//         setJobs(response.data); // Assuming jobs data is under `data`
//       } catch (error) {
//         console.error("Error fetching job data:", error);
//       }
//     };

//     fetchJobs();
//   }, [searchQuery]);

//   return (
//     <>
//       <IndividualHeader />
//       <Container maxWidth="md" sx={{ minHeight: '100vh', paddingTop: 4, paddingBottom: 4 }}>
//         <Typography variant="h4" gutterBottom>
//           Search Results for "{searchQuery}"
//         </Typography>
        
//         <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center' }}>
//           {jobs.length > 0 ? (
//             jobs.map((job, index) => (
//               <Paper
//                 key={index}
//                 elevation={3}
//                 sx={{
//                   padding: 2,
//                   width: '100%',
//                   maxWidth: '400px',
//                   textAlign: 'left',
//                   cursor: 'pointer',
//                   borderRadius: '8px',
//                   transition: 'transform 0.3s ease, box-shadow 0.3s ease',
//                   '&:hover': {
//                     transform: 'scale(1.05)',
//                     boxShadow: '0px 6px 15px rgba(255, 38, 0, 0.2)',
//                   },
//                 }}
//               >
//                 <Typography variant="h6" fontWeight="bold">
//                   {job.roleName}
//                 </Typography>
//                 <Typography variant="body2" color="textSecondary">
//                   Experience: {job.experience || 'N/A'} years
//                 </Typography>
//                 <Typography variant="body2" color="textSecondary">
//                   Domain: {job.domain || 'N/A'}
//                 </Typography>
//                 <Typography variant="body2" color="textSecondary">
//                   Mode of Work: {job.modeOfWork || 'N/A'}
//                 </Typography>
//                 {/* Add other job details as needed */}
//               </Paper>
//             ))
//           ) : (
//             <Typography variant="body1" color="textSecondary">
//               No job listings found for "{searchQuery}"
//             </Typography>
//           )}
//         </Box>
//       </Container>
//       <IndividualFooter />
//     </>
//   );
// };

// export default SearchResults;
