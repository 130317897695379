import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Chip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Typography,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { useNavigate } from "react-router-dom";
import CommonApi from "../../../apis/CommonApi";
import LocalStorage from "../../../utils/LocalStorage";
import AddCompanyForm from "./AddCompanyForm";
import PaginationComponent from "../../../components/customPagination/PaginationComponent";
import CompanyCard from "./CompanyCard";
import CompanyListStyles from "./CompanyListStyles";
import CircularLoader from "../../../components/table/FilpFlopLoader";
import noCompaniesGif from "../../../assets/no-data-available.gif";

const CompanyList = () => {
  const [companies, setCompanies] = useState([]);
  const [filters, setFilters] = useState([
    { label: "All time", value: "allTime" },
    { label: "US, AU, +4", value: "regions" },
  ]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [noProfilesDialogOpen, setNoProfilesDialogOpen] = useState(false);
  const [isGridView, setIsGridView] = useState(true); // State to toggle between grid and list view
  const companiesPerPage = 10;
  const navigate = useNavigate();

  const classes = CompanyListStyles();

  const fetchCompanies = async (page, sortBy, order, searchQuery) => {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await CommonApi.getCompanies(
        token,
        page,
        companiesPerPage,
        sortBy,
        order,
        searchQuery
      );
      if (response && response.companies && Array.isArray(response.companies)) {
        setCompanies(response.companies);
        setTotalPages(response.totalPages);
      } else {
        setCompanies([]);
      }
    } catch (error) {
      setError(error);
    } 
    // finally {
    //   setLoading(false); // Stop loading
    // }
    finally {
      setTimeout(() => {
        setLoading(false); // Set loading to false after 3 seconds
      }, 2000);
    }
  };

  useEffect(() => {
    fetchCompanies(page, "createdAt", sortOrder, searchQuery);
  }, [page, sortOrder, searchQuery]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
    setPage(1);
    fetchCompanies(1, "createdAt", e.target.value, searchQuery);
  };

  const handleResetSort = () => {
    setSortOrder("desc");
    fetchCompanies(page, "createdAt", "desc", searchQuery);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    fetchCompanies(1, "createdAt", sortOrder, searchQuery);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const fetchProfiles = async (company) => {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await CommonApi.getProfilesByCompany(company._id, token);
      if (response.length === 0) {
        setNoProfilesDialogOpen(true);
      } else {
        navigate(`/companies/${company._id}/users`, {
          state: {
            users: response,
            companyName: company.companyName,
            companyId: company._id,
          },
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setNoProfilesDialogOpen(true);
      } else {
        console.error("Error fetching profiles:", error);
      }
    }
  };

  const handleCompanyClick = (company) => {
    console.log("Company details clicked:", company);
    fetchProfiles(company);
  };

  const handleNoProfilesDialogClose = () => {
    setNoProfilesDialogOpen(false);
  };

  const handleDeleteChip = (chipToDelete) => {
    setFilters((chips) => chips.filter((chip) => chip.value !== chipToDelete));
  };

  const cardColors = [
    "#ffebee",
    "#e3f2fd",
    "#f3e5f5",
    "#e8f5e9",
    "#fffde7",
    "#fbe9e7",
    "#e1f5fe",
    "#f1f8e9",
  ];

  const toggleViewMode = () => {
    setIsGridView(!isGridView);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularLoader /> {/* Show the FlipFlopLoader when loading */}
      </Box>
    );
  }

  if (error) return <Typography>Error: {error.message}</Typography>;

  return (
    <Container>
      {/* Header Section */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={2}
        p={2}
        sx={{
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
          backgroundColor: "#fff",
          borderRadius: "8px",
          border: "1px solid lightgrey",
        }}
      >
        <Box display="flex" alignItems="center">
          {filters.map((filter) => (
            <Chip
              key={filter.value}
              label={filter.label}
              onDelete={() => handleDeleteChip(filter.value)}
              sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
                padding: "5px 10px",
                marginRight: "8px",
                fontSize: "14px",
                fontWeight: "bold",
                "& .MuiChip-deleteIcon": {
                  color: "#bbb",
                },
              }}
            />
          ))}
          <Button
            startIcon={<FilterListIcon />}
            variant="outlined"
            sx={{
              borderColor: "#f5f5f5",
              color: "#555",
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              padding: "6px 12px",
              fontSize: "14px",
              textTransform: "none",
              fontWeight: "bold",
              marginRight: "16px",
              "&:hover": {
                backgroundColor: "#f0f0f0",
                borderColor: "#f0f0f0",
              },
            }}
          >
            More filters
          </Button>
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton onClick={toggleViewMode} sx={{ marginRight: "16px" }}>
            {isGridView ? <ViewListIcon /> : <ViewModuleIcon />}
          </IconButton>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              padding: "0 8px",
              marginRight: "16px",
            }}
          >
            <SearchIcon sx={{ color: "#555" }} />
            <TextField
              placeholder="Search Company"
              variant="standard"
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                disableUnderline: true,
                sx: {
                  marginLeft: "8px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#555",
                },
              }}
              sx={{
                width: "200px",
              }}
            />
          </Box>
          <FormControl
            variant="outlined"
            sx={{
              backgroundColor: "#f5f5f5",
              marginRight: "16px",
              borderRadius: "8px",
              "& .MuiOutlinedInput-root": {
                padding: "0 12px",
              },
              "& .MuiSelect-select": {
                padding: "6px 0px",
                fontSize: "14px",
                fontWeight: "bold",
                color: "#555",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            <Select
              value={sortOrder}
              onChange={handleSortChange}
              displayEmpty
              sx={{
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
              }}
            >
              <MenuItem value="desc">Newest</MenuItem>
              <MenuItem value="asc">Oldest</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#1a1a1a",
              color: "#fff",
              padding: "8px 16px",
              borderRadius: "8px",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "#333",
              },
            }}
            onClick={handleClickOpen}
          >
            + Add Company
          </Button>
        </Box>
      </Box>

      {/* Companies Grid */}
      {companies.length === 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="300px"
        >
          <img
            src={noCompaniesGif}
            alt="No companies found"
            style={{ width: "200px", marginBottom: "16px" }} // Adjust size and spacing as needed
          />
          <Typography variant="h5" color="textSecondary">
            No company found.
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            You searched "{searchQuery}" did not match any companies. Please try again.
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={4}>
          {companies.map((company, index) => (
            <Grid
              item
              xs={12}
              sm={isGridView ? 6 : 12}
              md={isGridView ? 4 : 12}
              lg={isGridView ? 4 : 12}
              key={company._id}
            >
              <CompanyCard
                company={company}
                backgroundColor={cardColors[index % cardColors.length]}
                onDetailsClick={() => handleCompanyClick(company)} // Ensure onDetailsClick is passed here
              />
            </Grid>
          ))}
        </Grid>
      )}

      {/* Pagination */}
      <Box display="flex" justifyContent="center" mt={4}>
        <PaginationComponent
          count={totalPages}
          page={page}
          onChange={handlePageChange}
        />
      </Box>

      {/* Add Company Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Company</DialogTitle>
        <DialogContent>
          <AddCompanyForm onClose={handleClose} />
        </DialogContent>
      </Dialog>

      {/* No Profiles Dialog */}
      <Dialog open={noProfilesDialogOpen} onClose={handleNoProfilesDialogClose}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ marginLeft: "10px", marginTop: "20px" }}
        >
          <WarningAmberRoundedIcon
            sx={{ color: "#ffeac2", fontSize: "2.5rem" }}
          />
          <DialogTitle sx={{ padding: "0px !important", marginLeft: "5px" }}>
            No Users
          </DialogTitle>
        </Box>
        <DialogContent>
          <Typography>No users found for this company.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNoProfilesDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CompanyList;
