// import React, { useEffect, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { useGoogleLogin } from "@react-oauth/google";
// import { MyContext } from "../../Context";
// import Grid from "@mui/material/Grid";
// import { InputBase, Link, Paper, Box, Button } from "@mui/material";
// import { LoginStyles } from "./LoginStyles";
// import CustomInput from "../../components/input/Input";
// import Text from "../../components/customText/Text";
// import { isValid, validate_emptyField } from "../../components/Validation";
// import { addErrorMsg, addSuccessMsg } from "../../utils/utils";
// import { LoadingButton } from "@mui/lab";
// import eye from "../../assets/svg/eye.svg";
// import inVisible from "../../assets/svg/NotVisible.svg";
// import GoogleIcon from "@mui/icons-material/Google";
// import MicrosoftIcon from "@mui/icons-material/Microsoft";
// import { auth, provider } from "../../utils/FirebaseConfig";
// import { signInWithPopup } from "firebase/auth";
// import TechOptima from '../../assets/svg/TO Logo.svg';
// import ShineBorder from "../../landingpage/styles/magicui/ShineBorder";
// import CommonApi from "../../apis/CommonApi";
// import LocalStorage from "../../utils/LocalStorage";
// import GifImage from "../../assets/svg/botanime.gif";
// import OrangeBg from "../../assets/svg/bg-plain.jpg";
// import { styled } from "@mui/material";
// const StyledBreadcrumb = styled(Box)(({ theme }) => ({
//   backgroundColor: "#F9A828",
//   height: theme.spacing(3),
//   color: theme.palette.text.primary,
//   fontWeight: theme.typography.fontWeightRegular,
//   "&:hover, &:focus": {
//     backgroundColor: "#054CA6",
//     color: "#FFFFFF",
//     cursor: "pointer",
//   },
// }));

// function Login() {
//   const classes = LoginStyles();
//   const navigate = useNavigate();
//   const { setGlobaldata } = useContext(MyContext);
//   const [formData, setFormData] = useState({ email: "", password: "" });
//   const [error, setError] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [isVisible, setVisible] = useState(false);

//   const changeHandler = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     handleValidate(e);
//   };

//   // const handleGoogleLogin = ()=>{
//   //   alert("Google Login functionality")
//   // }
//   const handleMicrosoftLogin = () => {
//     alert("Microsoft Login functionality here");
//   };

//   useEffect(() => {
//     const sessionFlag = LocalStorage.getSessionFlag();
//     if (!sessionFlag) {
//       LocalStorage.clearAll();
//       navigate("/login");
//     } else {
//       startInactivityTimer();
//       window.addEventListener("mousemove", resetInactivityTimer);
//       window.addEventListener("keydown", resetInactivityTimer);
//     }
//     return () => {
//       window.removeEventListener("mousemove", resetInactivityTimer);
//       window.removeEventListener("keydown", resetInactivityTimer);
//     };
//   }, [navigate]);

//   const startInactivityTimer = () => {
//     window.inactivityTimeout = setTimeout(handleLogout, 1800000);
//   };

//   const resetInactivityTimer = () => {
//     clearTimeout(window.inactivityTimeout);
//     startInactivityTimer();
//   };

//   const handleLogout = async () => {
//     await CommonApi.Logout();
//     LocalStorage.clearAll();
//     navigate("/login");
//   };

//   const handleValidate = (e) => {
//     let input = e.target;
//     switch (input.name || input.tagName) {
//       case "email":
//         error.email = validate_emptyField(input.value);
//         break;
//       case "password":
//         error.password = validate_emptyField(input.value);
//         break;
//       default:
//         break;
//     }
//     setError({ ...error });
//   };

//   const formValidations = () => {
//     let { email, password } = formData;
//     let errors = {};
//     errors.email = validate_emptyField(email);
//     errors.password = validate_emptyField(password);
//     return errors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     let errors = formValidations();
//     if (isValid(errors)) {
//       setLoading(true);
//       try {
//         const response = await CommonApi.Login(formData);
//         if (response.status === 200) {
//           const { token, userId, role, user, candidateProfileId } =
//             response.data;
//           LocalStorage.setAccessToken(token);
//           LocalStorage.setUserData({
//             token,
//             userId,
//             role,
//             user,
//             candidateProfileId,
//           });
//           LocalStorage.setCompanyData(response.data.user.company);
//           LocalStorage.setSessionFlag();
//           addSuccessMsg("Successfully logged in");

//           // Redirect based on role
//           if (role === "companyOwner" || role === "companyAdmin") {
//             navigate("/employer-dashboard");
//           } else {
//             navigate("/dashboard");
//           }
//           window.location.reload();
//         } else {
//           setLoading(false);
//           addErrorMsg(response.data.message);
//         }
//       } catch (error) {
//         setLoading(false);
//         addErrorMsg(error.message);
//       }
//     } else {
//       setError(errors);
//     }
//   };

//   const forgotPassword = () => {
//     navigate("/forgot-password");
//   };

//   const passwordVisible = () => {
//     setVisible(!isVisible);
//   };

//   const handleGoogleSuccess = async (tokenResponse) => {
//     try {
//       const { code } = tokenResponse;
//       if (!code) {
//         throw new Error("Authorization code not received from Google");
//       }

//       const response = await CommonApi.googleLogin({ code });

//       if (response && response.token) {
//         const { token, userId, role, user, companyName } = response;
//         LocalStorage.setAccessToken(token);
//         LocalStorage.setUserData({ token, userId, role, user });
//         LocalStorage.setCompanyData(response.user.company);
//         LocalStorage.setSessionFlag();
//         addSuccessMsg(`Successfully logged in as ${role} at ${companyName}`);
//         navigate("/dashboard");
//         window.location.reload();
//       } else {
//         addErrorMsg("Unexpected response from server.");
//       }
//     } catch (error) {
//       console.error("Google login failed:", error);
//       addErrorMsg("Google login failed. Try again.");
//     }
//   };

//   const handleGoogleFailure = () => {
//     addErrorMsg("Google login failed");
//   };

//   const googleLogin = useGoogleLogin({
//     onSuccess: handleGoogleSuccess,
//     onError: handleGoogleFailure,
//     scope: "openid profile email",
//     flow: "auth-code",
//   });

//   return (
//     <div
//   style={{
//     backgroundImage: `url(${OrangeBg})`,
//     minHeight: "100vh", // Full height for better layout control
//     backgroundSize: "cover",
//     backgroundPosition: "center",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     overflow: "hidden", // Hide any unwanted scroll
//   }}
// >
//   <ShineBorder
//     className={`${classes.container} relative max-w-full h-auto rounded-lg overflow-hidden transition-shadow duration-500`}
//     borderRadius={16}
//     borderWidth={8}
//     duration={10}
//     color={["#FFAA00", "#FF5200", "#FFFFFF"]}
//   >
//     <Grid className={classes.container} container>
//       <Grid
//         item
//         lg={8}
//         md={7}
//         sm={6}
//         xs={12}
//         style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: '20px !important', }}
//       >
//         <img
//           src={GifImage}
//           alt="GIF"
//           className={classes.gif}
//         />
//       </Grid>
//       <Grid
//         item
//         lg={4}
//         md={5}
//         sm={6}
//         xs={12}
//         className={classes.loginFormBox} // Use the new styling class for the form box
//       >
//         <Box style={{ width: "100%", maxWidth: "400px", textAlign: "center" }}>
//           <img
//             src={TechOptima}
//             alt="Logo"
//             style={{ width: "100px", height: "60px", display: "block", margin: "0 auto 20px auto" }}
//           />
//           <form onSubmit={handleSubmit}>
//             <Text variant="h5" style={{ marginBottom: "20px" }}>
//               Optima AI Hire
//             </Text>
//             {/* Form Fields Go Here */}
//             <Grid container>
//                    <Grid item sm={12} xs={12}>
//                      <Text label="Email Id" />
//                      <CustomInput
//                       outlined
//                       forgotForm
//                       className={classes.inputBoxes}
//                       formControlProps={{ fullWidth: true }}
//                       inputProps={{
//                         name: "email",
//                         value: formData.email,
//                         type: "text",
//                         placeholder:"Email",
//                       }}
//                       handleChange={changeHandler}
//                     />
//                     {error.email && <Text red>{error.email}</Text>}
//                   </Grid>
//                   <Grid item lg={12} sm={12} xs={12} mt="8px">
//                     <Text label="Password" />
//                     <Paper className={classes.Paper} display="flex">
//                       <InputBase
//                         className={classes.InputBase}
//                         fullWidth
//                         type={!isVisible ? "password" : "text"}
//                         name="password"
//                         placeholder="password"
//                         onChange={changeHandler}
//                         value={formData.password}
//                       />
//                       <Box className={classes.IconButton}>
//                         <span onClick={passwordVisible}>
//                           {isVisible ? (
//                             <img
//                               src={eye}
//                               alt="Visibility"
//                               className={classes.visibilityIcon}
//                             />
//                           ) : (
//                             <img
//                               src={inVisible}
//                               alt="Visibility"
//                               className={classes.notVisibleIcon}
//                             />
//                           )}
//                         </span>
//                       </Box>
//                     </Paper>
//                     {error.password && <Text red>{error.password}</Text>}
//                   </Grid>
//                   <Grid item sm={12} xs={12} textAlign="right">
//                     <Link
//                       component="button"
//                       variant="body2"
//                       onClick={forgotPassword}
//                       className={classes.forgotPassword}
//                     >
//                       Forgot Password?
//                     </Link>
//                   </Grid>
//                 </Grid>
//                 <Grid item>
//                   <LoadingButton
//                     loading={loading}
//                     onClick={handleSubmit}
//                     variant="contained"
//                     type="submit"
//                     style={{ width: '100%', marginTop: '20px', height: '42px', borderRadius: '22px', backgroundColor: '#FF5200' }}
//                   >
//                     Sign in
//                   </LoadingButton>
//                 </Grid>
//                 <Box mt={2} textAlign="center">
//                   <span>Or login with</span>
//                 </Box>
//                 <Box display="flex" justifyContent="space-between" flexDirection="column" height='100px' mt={2}>
//                   <Button
//                     variant="outlined"
//                     className={classes.otherBtns}
//                     onClick={googleLogin}
//                     startIcon={<GoogleIcon />}
//                   >
//                     Google
//                   </Button>
//                   <Button
//                     variant="outlined"
//                     className={classes.otherBtns}
//                     onClick={handleMicrosoftLogin}
//                     startIcon={<MicrosoftIcon />}
//                 >
//                     Microsoft
//                   </Button>
//                 </Box>

//                 <Box mt={3} textAlign="center">
//                 <span>
//                   Don't have an account?{" "}
//                   <Link href="/signup">Sign Up now</Link>
//                 </span>
//  </Box>
//           </form>
//         </Box>
//       </Grid>
//     </Grid>
//   </ShineBorder>
// </div>

//   );
// }

// export default Login;

// import React, { useEffect, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { useGoogleLogin } from "@react-oauth/google";
// import { MyContext } from "../../Context";
// import Grid from "@mui/material/Grid";
// import { InputBase, Link, Paper, Box, Button, TextField } from "@mui/material";
// import { LoginStyles } from "./LoginStyles";
// import Text from "../../components/customText/Text";
// import { isValid, validate_emptyField } from "../../components/Validation";
// import { addErrorMsg, addSuccessMsg } from "../../utils/utils";
// import { LoadingButton } from "@mui/lab";
// import eye from "../../assets/svg/eye.svg";
// import inVisible from "../../assets/svg/NotVisible.svg";
// import GoogleIcon from "@mui/icons-material/Google";
// import MicrosoftIcon from "@mui/icons-material/Microsoft";
// import CommonApi from "../../apis/CommonApi";
// import LocalStorage from "../../utils/LocalStorage";
// import GifImage from "../../assets/svg/botanime.gif"; // Ensure this path is correct
// import TechOptima from '../../assets/svg/TO Logo.svg';
// import { Padding } from "@mui/icons-material";

// function Login() {
//   const classes = LoginStyles();
//   const navigate = useNavigate();
//   const { setGlobaldata } = useContext(MyContext);
//   const [formData, setFormData] = useState({ email: "", password: "" });
//   const [error, setError] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [isVisible, setVisible] = useState(false);

//   const changeHandler = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     handleValidate(e);
//   };

//   const handleMicrosoftLogin = () => {
//     alert("Microsoft Login functionality here");
//   };

//   useEffect(() => {
//     const sessionFlag = LocalStorage.getSessionFlag();
//     if (!sessionFlag) {
//       LocalStorage.clearAll();
//       navigate("/login");
//     } else {
//       startInactivityTimer();
//       window.addEventListener("mousemove", resetInactivityTimer);
//       window.addEventListener("keydown", resetInactivityTimer);
//     }
//     return () => {
//       window.removeEventListener("mousemove", resetInactivityTimer);
//       window.removeEventListener("keydown", resetInactivityTimer);
//     };
//   }, [navigate]);

//   const startInactivityTimer = () => {
//     window.inactivityTimeout = setTimeout(handleLogout, 1800000);
//   };

//   const resetInactivityTimer = () => {
//     clearTimeout(window.inactivityTimeout);
//     startInactivityTimer();
//   };

//   const handleLogout = async () => {
//     await CommonApi.Logout();
//     LocalStorage.clearAll();
//     navigate("/login");
//   };

//   const handleValidate = (e) => {
//     let input = e.target;
//     switch (input.name || input.tagName) {
//       case "email":
//         error.email = validate_emptyField(input.value);
//         break;
//       case "password":
//         error.password = validate_emptyField(input.value);
//         break;
//       default:
//         break;
//     }
//     setError({ ...error });
//   };

//   const formValidations = () => {
//     let { email, password } = formData;
//     let errors = {};
//     errors.email = validate_emptyField(email);
//     errors.password = validate_emptyField(password);
//     return errors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     let errors = formValidations();
//     if (isValid(errors)) {
//       setLoading(true);
//       try {
//         const response = await CommonApi.Login(formData);
//         if (response.status === 200) {
//           const { token, userId, role, user, candidateProfileId } = response.data;
//           LocalStorage.setAccessToken(token);
//           LocalStorage.setUserData({
//             token,
//             userId,
//             role,
//             user,
//             candidateProfileId,
//           });
//           LocalStorage.setCompanyData(response.data.user.company);
//           LocalStorage.setSessionFlag();
//           addSuccessMsg("Successfully logged in");

//           if (role === "companyOwner" || role === "companyAdmin") {
//             navigate("/employer-dashboard");
//           } else {
//             navigate("/dashboard");
//           }
//           window.location.reload();
//         } else {
//           setLoading(false);
//           addErrorMsg(response.data.message);
//         }
//       } catch (error) {
//         setLoading(false);
//         addErrorMsg(error.message);
//       }
//     } else {
//       setError(errors);
//     }
//   };

//   const forgotPassword = () => {
//     navigate("/forgot-password");
//   };

//   const passwordVisible = () => {
//     setVisible(!isVisible);
//   };

//   const handleGoogleSuccess = async (tokenResponse) => {
//     try {
//       const { code } = tokenResponse;
//       if (!code) {
//         throw new Error("Authorization code not received from Google");
//       }

//       const response = await CommonApi.googleLogin({ code });

//       if (response && response.token) {
//         const { token, userId, role, user, companyName } = response;
//         LocalStorage.setAccessToken(token);
//         LocalStorage.setUserData({ token, userId, role, user });
//         LocalStorage.setCompanyData(response.user.company);
//         LocalStorage.setSessionFlag();
//         addSuccessMsg(`Successfully logged in as ${role} at ${companyName}`);
//         navigate("/dashboard");
//         window.location.reload();
//       } else {
//         addErrorMsg("Unexpected response from server.");
//       }
//     } catch (error) {
//       console.error("Google login failed:", error);
//       addErrorMsg("Google login failed. Try again.");
//     }
//   };

//   const handleGoogleFailure = () => {
//     addErrorMsg("Google login failed");
//   };

//   const googleLogin = useGoogleLogin({
//     onSuccess: handleGoogleSuccess,
//     onError: handleGoogleFailure,
//     scope: "openid profile email",
//     flow: "auth-code",
//   });

//   return (
//     <div
//       style={{
//         minHeight: "100vh",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         backgroundColor: "#fff", // No background image
//       }}
//     >
//       <Grid className={classes.container} container>
//         {/* Left Side: Animation */}
//         <Grid
//           item
//           lg={8}
//           md={7}
//           sm={6}
//           xs={12}
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <img
//             src={GifImage} // Animation GIF
//             alt="GIF"
//             className={classes.gif}
//           />
//         </Grid>

//         {/* Right Side: Login Form */}
//         <Grid
//           item
//           lg={4}
//           md={5}
//           sm={6}
//           xs={12}
//           className={classes.loginFormBox}
//         >
//           <Box style={{ width: "100%", maxWidth: "400px"}}>
//             <img
//               src={TechOptima}
//               alt="Logo"
//               style={{ width: "100px", height: "60px", marginBottom: "20px", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} // Centered logo
//             />
//             <form onSubmit={handleSubmit}>
//               <Text variant="h5" className={classes.welText} style={{ marginBottom: "20px", textAlign: "center", fontWeight: "bold", Padding: "26px !important" , fontSize: "30px" }}>
//                 Welcome to Optima AI Hire!
//               </Text>
//               <Text className={classes.paraText} style={{ fontSize: "16px",  width:'100%', paddingLeft: '55px'}}>
//                 Your smart hiring assistant, designed to streamline the recruitment process with precision and speed.
//               </Text>
//               <Grid container spacing={2} style={{ marginTop: '20px' }}>
//                 <Grid item xs={12}>
//                   <TextField
//                     required
//                     id="email"
//                     label="Email Address*"
//                     variant="standard"
//                     fullWidth
//                     name="email"
//                     value={formData.email}
//                     onChange={changeHandler}
//                     error={!!error.email}
//                     helperText={error.email}
//                     className={classes.inputFields}
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     required
//                     id="password"
//                     label="Password*"
//                     variant="standard"
//                     fullWidth
//                     type={!isVisible ? "password" : "text"}
//                     name="password"
//                     onChange={changeHandler}
//                     value={formData.password}
//                     error={!!error.password}
//                     className={classes.inputFields}
//                     helperText={error.password}
//                   />
//                 </Grid>
//                 <Grid item xs={12} style={{ textAlign: 'right' }}>
//                   <Link
//                     component="button"
//                     variant="body2"
//                     onClick={forgotPassword}
//                     className={classes.forgotPassword}
//                   >
//                     Forgot Password?
//                   </Link>
//                 </Grid>
//               </Grid>
//               <Grid item>
//                 <LoadingButton
//                   loading={loading}
//                   onClick={handleSubmit}
//                   variant="contained"
//                   type="submit"
//                   style={{ width: '76%', marginTop: '20px', height: '42px', borderRadius: '22px', backgroundColor: '#FF5200', marginLeft: "52px "}}
//                 >
//                   Log In
//                 </LoadingButton>
//               </Grid>
//               <Box mt={2} textAlign="center">
//                 <span>Or login with</span>
//               </Box>
//               <Box display="flex" justifyContent="space-between" flexDirection="column" height='100px' mt={2}>
//                 <Button
//                   variant="outlined"
//                   className={classes.otherBtns}
//                   onClick={googleLogin}
//                   startIcon={<GoogleIcon />}
//                 >
//                   Google
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   className={classes.otherBtns}
//                   onClick={handleMicrosoftLogin}
//                   startIcon={<MicrosoftIcon />}
//                 >
//                   Microsoft
//                 </Button>
//               </Box>

//               <Box mt={3} textAlign="center">
//                 <span>
//                   Don't have an account?{" "}
//                   <Link href="/signup">Sign Up now</Link>
//                 </span>
//               </Box>
//             </form>
//           </Box>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

// export default Login;

// //password visible functionlity
// import React, { useEffect, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { useGoogleLogin } from "@react-oauth/google";
// import { MyContext } from "../../Context";
// import Grid from "@mui/material/Grid";
// import { InputBase, Link, Paper, Box, Button, TextField } from "@mui/material";
// import { LoginStyles } from "./LoginStyles";
// import Text from "../../components/customText/Text";
// import { isValid, validate_emptyField } from "../../components/Validation";
// import { addErrorMsg, addSuccessMsg } from "../../utils/utils";
// import { LoadingButton } from "@mui/lab";
// import eye from "../../assets/svg/eye.svg";
// import inVisible from "../../assets/svg/NotVisible.svg";
// import GoogleIcon from "@mui/icons-material/Google";
// import MicrosoftIcon from "@mui/icons-material/Microsoft";
// import CommonApi from "../../apis/CommonApi";
// import LocalStorage from "../../utils/LocalStorage";
// import GifImage from "../../assets/svg/botanime.gif"; // Ensure this path is correct
// import TechOptima from '../../assets/svg/TO Logo.svg';

// function Login() {
//   const classes = LoginStyles();
//   const navigate = useNavigate();
//   const { setGlobaldata } = useContext(MyContext);
//   const [formData, setFormData] = useState({ email: "", password: "" });
//   const [error, setError] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [isVisible, setVisible] = useState(false);

//   const changeHandler = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     handleValidate(e);
//   };

//   const handleMicrosoftLogin = () => {
//     alert("Microsoft Login functionality here");
//   };

//   useEffect(() => {
//     const sessionFlag = LocalStorage.getSessionFlag();
//     if (!sessionFlag) {
//       LocalStorage.clearAll();
//       navigate("/login");
//     } else {
//       startInactivityTimer();
//       window.addEventListener("mousemove", resetInactivityTimer);
//       window.addEventListener("keydown", resetInactivityTimer);
//     }
//     return () => {
//       window.removeEventListener("mousemove", resetInactivityTimer);
//       window.removeEventListener("keydown", resetInactivityTimer);
//     };
//   }, [navigate]);

//   const startInactivityTimer = () => {
//     window.inactivityTimeout = setTimeout(handleLogout, 1800000);
//   };

//   const resetInactivityTimer = () => {
//     clearTimeout(window.inactivityTimeout);
//     startInactivityTimer();
//   };

//   const handleLogout = async () => {
//     await CommonApi.Logout();
//     LocalStorage.clearAll();
//     navigate("/login");
//   };

//   const handleValidate = (e) => {
//     let input = e.target;
//     switch (input.name || input.tagName) {
//       case "email":
//         error.email = validate_emptyField(input.value);
//         break;
//       case "password":
//         error.password = validate_emptyField(input.value);
//         break;
//       default:
//         break;
//     }
//     setError({ ...error });
//   };

//   const formValidations = () => {
//     let { email, password } = formData;
//     let errors = {};
//     errors.email = validate_emptyField(email);
//     errors.password = validate_emptyField(password);
//     return errors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     let errors = formValidations();
//     if (isValid(errors)) {
//       setLoading(true);
//       try {
//         const response = await CommonApi.Login(formData);
//         if (response.status === 200) {
//           const { token, userId, role, user, candidateProfileId } = response.data;
//           LocalStorage.setAccessToken(token);
//           LocalStorage.setUserData({
//             token,
//             userId,
//             role,
//             user,
//             candidateProfileId,
//           });
//           LocalStorage.setCompanyData(response.data.user.company);
//           LocalStorage.setSessionFlag();
//           addSuccessMsg("Successfully logged in");

//           if (role === "companyOwner" || role === "companyAdmin") {
//             navigate("/employer-dashboard");
//           } else {
//             navigate("/dashboard");
//           }
//           window.location.reload();
//         } else {
//           setLoading(false);
//           addErrorMsg(response.data.message);
//         }
//       } catch (error) {
//         setLoading(false);
//         addErrorMsg(error.message);
//       }
//     } else {
//       setError(errors);
//     }
//   };

//   const forgotPassword = () => {
//     navigate("/forgot-password");
//   };

//   const passwordVisible = () => {
//     setVisible(!isVisible);
//   };

//   const handleGoogleSuccess = async (tokenResponse) => {
//     try {
//       const { code } = tokenResponse;
//       if (!code) {
//         throw new Error("Authorization code not received from Google");
//       }

//       const response = await CommonApi.googleLogin({ code });

//       if (response && response.token) {
//         const { token, userId, role, user, companyName } = response;
//         LocalStorage.setAccessToken(token);
//         LocalStorage.setUserData({ token, userId, role, user });
//         LocalStorage.setCompanyData(response.user.company);
//         LocalStorage.setSessionFlag();
//         addSuccessMsg(`Successfully logged in as ${role} at ${companyName}`);
//         navigate("/dashboard");
//         window.location.reload();
//       } else {
//         addErrorMsg("Unexpected response from server.");
//       }
//     } catch (error) {
//       console.error("Google login failed:", error);
//       addErrorMsg("Google login failed. Try again.");
//     }
//   };

//   const handleGoogleFailure = () => {
//     addErrorMsg("Google login failed");
//   };

//   const googleLogin = useGoogleLogin({
//     onSuccess: handleGoogleSuccess,
//     onError: handleGoogleFailure,
//     scope: "openid profile email",
//     flow: "auth-code",
//   });

//   return (
//     <div
//       style={{
//         minHeight: "100vh",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         backgroundColor: "#fff", // No background image
//       }}
//     >
//       <Grid className={classes.container} container>
//         {/* Left Side: Animation */}
//         <Grid
//           item
//           lg={8}
//           md={7}
//           sm={6}
//           xs={12}
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <img
//             src={GifImage} // Animation GIF
//             alt="GIF"
//             className={classes.gif}
//           />
//         </Grid>

//         {/* Right Side: Login Form */}
//         <Grid
//           item
//           lg={4}
//           md={5}
//           sm={6}
//           xs={12}
//           className={classes.loginFormBox}
//         >
//           <Box style={{ width: "100%", maxWidth: "400px"}}>
//             <img
//               src={TechOptima}
//               alt="Logo"
//               style={{ width: "100px", height: "60px", marginBottom: "20px", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} // Centered logo
//             />
//             <form onSubmit={handleSubmit}>
//               <Text variant="h5" className={classes.welText} style={{ marginBottom: "20px", textAlign: "center", fontWeight: "bold", fontSize: "30px" }}>
//                 Welcome to Optima AI Hire!
//               </Text>
//               <Text className={classes.paraText} style={{ fontSize: "16px",  width:'100%', paddingLeft: '55px'}}>
//                 Your smart hiring assistant, designed to streamline the recruitment process with precision and speed.
//               </Text>
//               <Grid container spacing={2} style={{ marginTop: '20px' }}>
//                 <Grid item xs={12}>
//                   <TextField
//                     required
//                     id="email"
//                     label="Email Address*"
//                     variant="standard"
//                     fullWidth
//                     name="email"
//                     value={formData.email}
//                     onChange={changeHandler}
//                     error={!!error.email}
//                     helperText={error.email}
//                     className={classes.inputFields}
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     required
//                     id="password"
//                     label="Password*"
//                     variant="standard"
//                     fullWidth
//                     type={!isVisible ? "password" : "text"}
//                     name="password"
//                     onChange={changeHandler}
//                     value={formData.password}
//                     error={!!error.password}
//                     className={classes.inputFields}
//                     helperText={error.password}
//                     InputProps={{
//                       endAdornment: (
//                         <Box
//                           component="span"
//                           onClick={passwordVisible}
//                           style={{ cursor: "pointer", marginRight: '8px' }} // Align to right with margin
//                         >
//                           {isVisible ? (
//                             <img src={eye} alt="Visibility" style={{ height: '24px', width: '24px' }} />
//                           ) : (
//                             <img src={inVisible} alt="Not Visible" style={{ height: '24px', width: '24px' }} />
//                           )}
//                         </Box>
//                       ),
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} style={{ textAlign: 'right' }}>
//                   <Link
//                     component="button"
//                     variant="body2"
//                     onClick={forgotPassword}
//                     className={classes.forgotPassword}
//                   >
//                     Forgot Password?
//                   </Link>
//                 </Grid>
//               </Grid>
//               <Grid item>
//                 <LoadingButton
//                   loading={loading}
//                   onClick={handleSubmit}
//                   variant="contained"
//                   type="submit"
//                   style={{ width: '76%', marginTop: '20px', height: '42px', borderRadius: '22px', backgroundColor: '#FF5200', marginLeft: "52px "}}
//                 >
//                   Log In
//                 </LoadingButton>
//               </Grid>
//               <Box mt={2} textAlign="center">
//                 <span>Or login with</span>
//               </Box>
//               <Box display="flex" justifyContent="space-between" flexDirection="column" height='100px' mt={2}>
//                 <Button
//                   variant="outlined"
//                   className={classes.otherBtns}
//                   onClick={googleLogin}
//                   startIcon={<GoogleIcon />}
//                 >
//                   Google
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   className={classes.otherBtns}
//                   onClick={handleMicrosoftLogin}
//                   startIcon={<MicrosoftIcon />}
//                 >
//                   Microsoft
//                 </Button>
//               </Box>

//               <Box mt={3} textAlign="center">
//                 <span>
//                   Don't have an account?{" "}
//                   <Link href="/signup">Sign Up now</Link>
//                 </span>
//               </Box>
//             </form>
//           </Box>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

// export default Login;

// //password visibility and when we click on enter we will login forthe application
// import React, { useEffect, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { useGoogleLogin } from "@react-oauth/google";
// import { MyContext } from "../../Context";
// import Grid from "@mui/material/Grid";
// import { InputBase, Link, Paper, Box, Button, TextField } from "@mui/material";
// import { LoginStyles } from "./LoginStyles";
// import Text from "../../components/customText/Text";
// import { isValid, validate_emptyField } from "../../components/Validation";
// import { addErrorMsg, addSuccessMsg } from "../../utils/utils";
// import { LoadingButton } from "@mui/lab";
// import eye from "../../assets/svg/eye.svg";
// import inVisible from "../../assets/svg/NotVisible.svg";
// import GoogleIcon from "@mui/icons-material/Google";
// import MicrosoftIcon from "@mui/icons-material/Microsoft";
// import CommonApi from "../../apis/CommonApi";
// import LocalStorage from "../../utils/LocalStorage";
// import GifImage from "../../assets/svg/botanime.gif"; // Ensure this path is correct
// import TechOptima from '../../assets/svg/TO Logo.svg';

// function Login() {
//   const classes = LoginStyles();
//   const navigate = useNavigate();
//   const { setGlobaldata } = useContext(MyContext);
//   const [formData, setFormData] = useState({ email: "", password: "" });
//   const [error, setError] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [isVisible, setVisible] = useState(false);

//   const changeHandler = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     handleValidate(e);
//   };

//   const handleMicrosoftLogin = () => {
//     alert("Microsoft Login functionality here");
//   };

//   useEffect(() => {
//     const sessionFlag = LocalStorage.getSessionFlag();
//     if (!sessionFlag) {
//       LocalStorage.clearAll();
//       navigate("/login");
//     } else {
//       startInactivityTimer();
//       window.addEventListener("mousemove", resetInactivityTimer);
//       window.addEventListener("keydown", resetInactivityTimer);
//     }
//     return () => {
//       window.removeEventListener("mousemove", resetInactivityTimer);
//       window.removeEventListener("keydown", resetInactivityTimer);
//     };
//   }, [navigate]);

//   const startInactivityTimer = () => {
//     window.inactivityTimeout = setTimeout(handleLogout, 1800000);
//   };

//   const resetInactivityTimer = () => {
//     clearTimeout(window.inactivityTimeout);
//     startInactivityTimer();
//   };

//   const handleLogout = async () => {
//     await CommonApi.Logout();
//     LocalStorage.clearAll();
//     navigate("/login");
//   };

//   const handleValidate = (e) => {
//     let input = e.target;
//     switch (input.name || input.tagName) {
//       case "email":
//         error.email = validate_emptyField(input.value);
//         break;
//       case "password":
//         error.password = validate_emptyField(input.value);
//         break;
//       default:
//         break;
//     }
//     setError({ ...error });
//   };

//   const formValidations = () => {
//     let { email, password } = formData;
//     let errors = {};
//     errors.email = validate_emptyField(email);
//     errors.password = validate_emptyField(password);
//     return errors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     let errors = formValidations();
//     if (isValid(errors)) {
//       setLoading(true);
//       try {
//         const response = await CommonApi.Login(formData);
//         if (response.status === 200) {
//           const { token, userId, role, user, candidateProfileId } = response.data;
//           LocalStorage.setAccessToken(token);
//           LocalStorage.setUserData({
//             token,
//             userId,
//             role,
//             user,
//             candidateProfileId,
//           });
//           LocalStorage.setCompanyData(response.data.user.company);
//           LocalStorage.setSessionFlag();
//           addSuccessMsg("Successfully logged in");

//           if (role === "companyOwner" || role === "companyAdmin") {
//             navigate("/employer-dashboard");
//           } else {
//             navigate("/dashboard");
//           }
//           window.location.reload();
//         } else {
//           setLoading(false);
//           addErrorMsg(response.data.message);
//         }
//       } catch (error) {
//         setLoading(false);
//         addErrorMsg(error.message);
//       }
//     } else {
//       setError(errors);
//     }
//   };

//   const forgotPassword = () => {
//     navigate("/forgot-password");
//   };

//   const passwordVisible = () => {
//     setVisible(!isVisible);
//   };

//   const handleGoogleSuccess = async (tokenResponse) => {
//     try {
//       const { code } = tokenResponse;
//       if (!code) {
//         throw new Error("Authorization code not received from Google");
//       }

//       const response = await CommonApi.googleLogin({ code });

//       if (response && response.token) {
//         const { token, userId, role, user, companyName } = response;
//         LocalStorage.setAccessToken(token);
//         LocalStorage.setUserData({ token, userId, role, user });
//         LocalStorage.setCompanyData(response.user.company);
//         LocalStorage.setSessionFlag();
//         addSuccessMsg(`Successfully logged in as ${role} at ${companyName}`);
//         navigate("/dashboard");
//         window.location.reload();
//       } else {
//         addErrorMsg("Unexpected response from server.");
//       }
//     } catch (error) {
//       console.error("Google login failed:", error);
//       addErrorMsg("Google login failed. Try again.");
//     }
//   };

//   const handleGoogleFailure = () => {
//     addErrorMsg("Google login failed");
//   };

//   const googleLogin = useGoogleLogin({
//     onSuccess: handleGoogleSuccess,
//     onError: handleGoogleFailure,
//     scope: "openid profile email",
//     flow: "auth-code",
//   });

//   // Add the handleKeyPress function
//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       handleSubmit(e); // Call the submit handler when Enter is pressed
//     }
//   };

//   return (
//     <div
//       style={{
//         minHeight: "100vh",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         backgroundColor: "#fff", // No background image
//       }}
//     >
//       <Grid className={classes.container} container>
//         {/* Left Side: Animation */}
//         <Grid
//           item
//           lg={8}
//           md={7}
//           sm={6}
//           xs={12}
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <img
//             src={GifImage} // Animation GIF
//             alt="GIF"
//             className={classes.gif}
//           />
//         </Grid>

//         {/* Right Side: Login Form */}
//         <Grid
//           item
//           lg={4}
//           md={5}
//           sm={6}
//           xs={12}
//           className={classes.loginFormBox}
//         >
//           <Box style={{ width: "100%", maxWidth: "400px"}}>
//             <img
//               src={TechOptima}
//               alt="Logo"
//               style={{ width: "100px", height: "60px", marginBottom: "20px", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} // Centered logo
//             />
//             <form onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
//               <Text variant="h5" className={classes.welText} style={{ marginBottom: "20px", textAlign: "center", fontWeight: "bold", fontSize: "30px" }}>
//                 Welcome to Optima AI Hire!
//               </Text>
//               <Text className={classes.paraText} style={{ fontSize: "16px",  width:'100%', paddingLeft: '55px'}}>
//                 Your smart hiring assistant, designed to streamline the recruitment process with precision and speed.
//               </Text>
//               <Grid container spacing={2} style={{ marginTop: '20px' }}>
//                 <Grid item xs={12}>
//                   <TextField
//                     required
//                     id="email"
//                     label="Email Address*"
//                     variant="standard"
//                     fullWidth
//                     name="email"
//                     value={formData.email}
//                     onChange={changeHandler}
//                     error={!!error.email}
//                     helperText={error.email}
//                     className={classes.inputFields}
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     required
//                     id="password"
//                     label="Password*"
//                     variant="standard"
//                     fullWidth
//                     type={!isVisible ? "password" : "text"}
//                     name="password"
//                     onChange={changeHandler}
//                     value={formData.password}
//                     error={!!error.password}
//                     className={classes.inputFields}
//                     helperText={error.password}
//                     InputProps={{
//                       endAdornment: (
//                         <Box
//                           component="span"
//                           onClick={passwordVisible}
//                           style={{ cursor: "pointer", marginRight: '8px' }} // Align to right with margin
//                         >
//                           {isVisible ? (
//                             <img src={eye} alt="Visibility" style={{ height: '24px', width: '24px' }} />
//                           ) : (
//                             <img src={inVisible} alt="Not Visibility" style={{ height: '24px', width: '24px' }} />
//                           )}
//                         </Box>
//                       )
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} style={{ textAlign: 'right' }}>
//                   <Link
//                     component="button"
//                     variant="body2"
//                     onClick={forgotPassword}
//                     className={classes.forgotPassword}
//                   >
//                     Forgot Password?
//                   </Link>
//                 </Grid>
//               </Grid>
//               <Grid item>
//                 <LoadingButton
//                   loading={loading}
//                   onClick={handleSubmit}
//                   variant="contained"
//                   type="submit"
//                   style={{ width: '76%', marginTop: '20px', height: '42px', borderRadius: '22px', backgroundColor: '#FF5200', marginLeft: "52px "}}
//                 >
//                   Log In
//                 </LoadingButton>
//               </Grid>
//               <Box mt={2} textAlign="center">
//                 <span>Or login with</span>
//               </Box>
//               <Box display="flex" justifyContent="space-between" flexDirection="column" height='100px' mt={2}>
//                 <Button
//                   variant="outlined"
//                   className={classes.otherBtns}
//                   onClick={googleLogin}
//                   startIcon={<GoogleIcon />}
//                 >
//                   Google
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   className={classes.otherBtns}
//                   onClick={handleMicrosoftLogin}
//                   startIcon={<MicrosoftIcon />}
//                 >
//                   Microsoft
//                 </Button>
//               </Box>

//               <Box mt={3} textAlign="center">
//                 <span>
//                   Don't have an account?{" "}
//                   <Link href="/signup">Sign Up now</Link>
//                 </span>
//               </Box>
//             </form>
//           </Box>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

// export default Login;

// import React, { useEffect, useState, useContext } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import { useGoogleLogin } from "@react-oauth/google";
// import { MyContext } from "../../Context";
// import Grid from "@mui/material/Grid";
// import { Link, Box, Button, TextField } from "@mui/material";
// import { LoginStyles } from "./LoginStyles";
// import Text from "../../components/customText/Text";
// import { isValid, validate_emptyField } from "../../components/Validation";
// import { addErrorMsg, addSuccessMsg } from "../../utils/utils";
// import { LoadingButton } from "@mui/lab";
// import eye from "../../assets/svg/eye.svg";
// import inVisible from "../../assets/svg/NotVisible.svg";
// import GoogleIcon from "@mui/icons-material/Google";
// import MicrosoftIcon from "@mui/icons-material/Microsoft";
// import CommonApi from "../../apis/CommonApi";
// import LocalStorage from "../../utils/LocalStorage";
// import GifImage from "../../assets/svg/botanime.gif"; // Ensure this path is correct
// import TechOptima from '../../assets/svg/TO Logo.svg';

// function Login() {
//   const classes = LoginStyles();
//   const navigate = useNavigate();
//   const { setGlobaldata } = useContext(MyContext);
//   const [formData, setFormData] = useState({ email: "", password: "" });
//   const [error, setError] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [isVisible, setVisible] = useState(false);

//   // const location = useLocation();
//   // const redirectPath = new URLSearchParams(location.search).get("redirect") || "/dashboard"; // Define redirectPath with a fallback

//   const changeHandler = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     handleValidate(e);
//   };

//   const handleMicrosoftLogin = () => {
//     alert("Microsoft Login functionality here");
//   };

//   useEffect(() => {
//     const sessionFlag = LocalStorage.getSessionFlag();
//     if (!sessionFlag) {
//       LocalStorage.clearAll();
//       navigate("/login");
//     } else {
//       startInactivityTimer();
//       window.addEventListener("mousemove", resetInactivityTimer);
//       window.addEventListener("keydown", resetInactivityTimer);
//     }
//     return () => {
//       window.removeEventListener("mousemove", resetInactivityTimer);
//       window.removeEventListener("keydown", resetInactivityTimer);
//     };
//   }, [navigate]);

//   const startInactivityTimer = () => {
//     window.inactivityTimeout = setTimeout(handleLogout, 1800000);
//   };

//   const resetInactivityTimer = () => {
//     clearTimeout(window.inactivityTimeout);
//     startInactivityTimer();
//   };

//   const handleLogout = async () => {
//     await CommonApi.Logout();
//     LocalStorage.clearAll();
//     navigate("/login");
//   };

//   const handleValidate = (e) => {
//     let input = e.target;
//     switch (input.name || input.tagName) {
//       case "email":
//         error.email = validate_emptyField(input.value);
//         break;
//       case "password":
//         error.password = validate_emptyField(input.value);
//         break;
//       default:
//         break;
//     }
//     setError({ ...error });
//   };

//   const formValidations = () => {
//     let { email, password } = formData;
//     let errors = {};
//     errors.email = validate_emptyField(email);
//     errors.password = validate_emptyField(password);
//     return errors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     let errors = formValidations();
//     if (isValid(errors)) {
//       setLoading(true);
//       try {
//         const response = await CommonApi.Login(formData);
//         if (response.status === 200) {
//           const { token, userId, role, user, candidateProfileId } = response.data;

//           // Store user data and token in local storage
//           LocalStorage.setAccessToken(token);
//           LocalStorage.setUserData({
//             token,
//             userId,
//             role,
//             user,
//             candidateProfileId,
//           });
//           LocalStorage.setCompanyData(response.data.user.company);
//           LocalStorage.setSessionFlag();
//           addSuccessMsg("Successfully logged in");

//           // Role-based redirection
//           if (role === "candidate") {
//             navigate("/chatbot");
//           } else if (role === "companyOwner" || role === "companyAdmin") {
//             navigate("/employer-dashboard");
//           } else {
//             navigate("/dashboard"); // Default redirect for other roles
//           }

//           window.location.reload();
//         } else {
//           setLoading(false);
//           addErrorMsg(response.data.message);
//         }
//       } catch (error) {
//         setLoading(false);
//         addErrorMsg(error.message);
//       }
//     } else {
//       setError(errors);
//     }
//   };

//   const forgotPassword = () => {
//     navigate("/forgot-password");
//   };

//   const passwordVisible = () => {
//     setVisible(!isVisible);
//   };

//   const handleGoogleSuccess = async (tokenResponse) => {
//     try {
//       const { code } = tokenResponse;
//       if (!code) {
//         throw new Error("Authorization code not received from Google");
//       }

//       const response = await CommonApi.googleLogin({ code });

//       if (response && response.token) {
//         const { token, userId, role, user, companyName } = response;
//         LocalStorage.setAccessToken(token);
//         LocalStorage.setUserData({ token, userId, role, user });
//         LocalStorage.setCompanyData(response.user.company);
//         LocalStorage.setSessionFlag();
//         addSuccessMsg(`Successfully logged in as ${role} at ${companyName}`);
//         navigate("/dashboard");
//         window.location.reload();
//       } else {
//         addErrorMsg("Unexpected response from server.");
//       }
//     } catch (error) {
//       console.error("Google login failed:", error);
//       addErrorMsg("Google login failed. Try again.");
//     }
//   };

//   const handleGoogleFailure = () => {
//     addErrorMsg("Google login failed");
//   };

//   const googleLogin = useGoogleLogin({
//     onSuccess: handleGoogleSuccess,
//     onError: handleGoogleFailure,
//     scope: "openid profile email",
//     flow: "auth-code",
//   });

//   // Add the handleKeyPress function
//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       handleSubmit(e); // Call the submit handler when Enter is pressed
//     }
//   };

//   return (
//     <div
//       style={{
//         minHeight: "100vh",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         backgroundColor: "#fff", // No background image
//       }}
//     >
//       <Grid className={classes.container} container>
//         {/* Left Side: Animation */}
//         <Grid
//           item
//           lg={8}
//           md={7}
//           sm={6}
//           xs={12}
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <img
//             src={GifImage} // Animation GIF
//             alt="GIF"
//             className={classes.gif}
//           />
//         </Grid>

//         {/* Right Side: Login Form */}
//         <Grid
//           item
//           lg={4}
//           md={5}
//           sm={6}
//           xs={12}
//           className={classes.loginFormBox}
//         >
//           <Box style={{ width: "100%", maxWidth: "400px"}}>
//             <img
//               src={TechOptima}
//               alt="Logo"
//               style={{ width: "100px", height: "60px", marginBottom: "20px", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} // Centered logo
//             />
//             <form onSubmit={handleSubmit} onKeyPress={handleKeyPress}>
//               <Text variant="h5" className={classes.welText} style={{ marginBottom: "20px", textAlign: "center", fontWeight: "bold", fontSize: "22px" }}>
//                 Welcome to Optima AI Hire!
//               </Text>
//               <Text className={classes.paraText} style={{ fontSize: "16px",  width:'92%', paddingLeft: '28px'}}>
//                 Your smart hiring assistant, designed to streamline the recruitment process with precision and speed.
//               </Text>
//               <Grid container spacing={2} style={{ marginTop: '20px' }}>
//                 <Grid item xs={12}>
//                   <TextField
//                     required
//                     id="email"
//                     label="Email Address*"
//                     variant="standard"
//                     fullWidth
//                     name="email"
//                     value={formData.email}
//                     onChange={changeHandler}
//                     error={!!error.email}
//                     helperText={error.email}
//                     className={classes.inputFields}
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     required
//                     id="password"
//                     label="Password*"
//                     variant="standard"
//                     fullWidth
//                     type={!isVisible ? "password" : "text"}
//                     name="password"
//                     onChange={changeHandler}
//                     value={formData.password}
//                     error={!!error.password}
//                     className={classes.inputFields}
//                     helperText={error.password}
//                     InputProps={{
//                       endAdornment: (
//                         <Box
//                           component="span"
//                           onClick={passwordVisible}
//                           style={{ cursor: "pointer", marginRight: '8px' }} // Align to right with margin
//                         >
//                           {isVisible ? (
//                             <img src={eye} alt="Visibility" style={{ height: '24px', width: '24px' }} />
//                           ) : (
//                             <img src={inVisible} alt="Not Visibility" style={{ height: '24px', width: '24px' }} />
//                           )}
//                         </Box>
//                       )
//                     }}
//                   />
//                 </Grid>
//                 <Grid item xs={12} style={{ textAlign: 'right' }}>
//                   <Link
//                     component="button"
//                     variant="body2"
//                     onClick={forgotPassword}
//                     className={classes.forgotPassword}
//                   >
//                     Forgot Password?
//                   </Link>
//                 </Grid>
//               </Grid>
//               <Grid item>
//                 <LoadingButton
//                   loading={loading}
//                   onClick={handleSubmit}
//                   variant="contained"
//                   type="submit"
//                   style={{ width: '80%', marginTop: '20px', height: '42px', borderRadius: '22px', backgroundColor: '#FF5200', marginLeft: "30px "}}
//                 >
//                   Log In
//                 </LoadingButton>
//               </Grid>
//               <Box mt={2} textAlign="center">
//                 <span>Or login with</span>
//               </Box>
//               <Box display="flex" justifyContent="space-between" flexDirection="column" height='100px' mt={2}>
//                 <Button
//                   variant="outlined"
//                   className={classes.otherBtns}
//                   onClick={googleLogin}
//                   startIcon={<GoogleIcon />}
//                 >
//                   Google
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   className={classes.otherBtns}
//                   onClick={handleMicrosoftLogin}
//                   startIcon={<MicrosoftIcon />}
//                 >
//                   Microsoft
//                 </Button>
//               </Box>

//               <Box mt={3} textAlign="center">
//                 <span>
//                   Don't have an account?{" "}
//                   <Link href="/signup">Sign Up now</Link>
//                 </span>
//               </Box>
//             </form>
//           </Box>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

// export default Login;

// // sign up
// import React, { useEffect, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { useGoogleLogin } from "@react-oauth/google";
// import { MyContext } from "../../Context";
// import Grid from "@mui/material/Grid";
// import { Link, Box, Button, TextField } from "@mui/material";
// import { LoginStyles } from "./LoginStyles";
// import Text from "../../components/customText/Text";
// import { isValid, validate_emptyField } from "../../components/Validation";
// import { addErrorMsg, addSuccessMsg } from "../../utils/utils";
// import { LoadingButton } from "@mui/lab";
// import eye from "../../assets/svg/eye.svg";
// import inVisible from "../../assets/svg/NotVisible.svg";
// import GoogleIcon from "@mui/icons-material/Google";
// import MicrosoftIcon from "@mui/icons-material/Microsoft";
// import CommonApi from "../../apis/CommonApi";
// import LocalStorage from "../../utils/LocalStorage";
// import GifImage from "../../assets/svg/botanime.gif"; // Ensure this path is correct
// import TechOptima from '../../assets/svg/TO Logo.svg';

// function Login() {
//   const classes = LoginStyles();
//   const navigate = useNavigate();
//   const { setGlobaldata } = useContext(MyContext);
//   const [formData, setFormData] = useState({ email: "", password: "" });
//   const [error, setError] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [isVisible, setVisible] = useState(false);
//   const [isSignUp, setIsSignUp] = useState(false); // Toggle state for form view

//   const changeHandler = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     handleValidate(e);
//   };

//   const toggleForm = () => {
//     setIsSignUp(!isSignUp); // Toggle between login and sign-up
//   };

//   const handleMicrosoftLogin = () => {
//     alert("Microsoft Login functionality here");
//   };

//   useEffect(() => {
//     const sessionFlag = LocalStorage.getSessionFlag();
//     if (!sessionFlag) {
//       LocalStorage.clearAll();
//       navigate("/login");
//     } else {
//       startInactivityTimer();
//       window.addEventListener("mousemove", resetInactivityTimer);
//       window.addEventListener("keydown", resetInactivityTimer);
//     }
//     return () => {
//       window.removeEventListener("mousemove", resetInactivityTimer);
//       window.removeEventListener("keydown", resetInactivityTimer);
//     };
//   }, [navigate]);

//   const startInactivityTimer = () => {
//     window.inactivityTimeout = setTimeout(handleLogout, 1800000);
//   };

//   const resetInactivityTimer = () => {
//     clearTimeout(window.inactivityTimeout);
//     startInactivityTimer();
//   };

//   const handleLogout = async () => {
//     await CommonApi.Logout();
//     LocalStorage.clearAll();
//     navigate("/login");
//   };

//   const handleValidate = (e) => {
//     let input = e.target;
//     let newError = { ...error };

//     switch (input.name) {
//       case "email":
//         newError.email = validate_emptyField(input.value);
//         break;
//       case "password":
//         newError.password = validate_emptyField(input.value);
//         break;
//       case "fullName":
//         if (isSignUp) newError.fullName = validate_emptyField(input.value);
//         break;
//       case "mobileNumber":
//         if (isSignUp) newError.mobileNumber = validate_emptyField(input.value);
//         break;
//       default:
//         break;
//     }

//     setError(newError);
//   };

//   const formValidations = () => {
//     let errors = {};
//     if (isSignUp) {
//       errors.fullName = validate_emptyField(formData.fullName);
//       errors.mobileNumber = validate_emptyField(formData.mobileNumber);
//     }
//     errors.email = validate_emptyField(formData.email);
//     errors.password = validate_emptyField(formData.password);
//     return errors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     let errors = formValidations();
//     if (isValid(errors)) {
//       setLoading(true);
//       try {
//         const response = isSignUp ? await CommonApi.SignUp(formData) : await CommonApi.Login(formData);
//         if (response.status === 200) {
//           const { token, userId, role, user } = response.data;
//           LocalStorage.setAccessToken(token);
//           LocalStorage.setUserData({ token, userId, role, user });
//           LocalStorage.setCompanyData(response.data.user.company);
//           LocalStorage.setSessionFlag();
//           addSuccessMsg(`Successfully ${isSignUp ? 'registered' : 'logged in'}`);
//           navigate("/dashboard");
//           window.location.reload();
//         } else {
//           setLoading(false);
//           addErrorMsg(response.data.message);
//         }
//       } catch (error) {
//         setLoading(false);
//         addErrorMsg(error.message);
//       }
//     } else {
//       setError(errors);
//     }
//   };

//   const passwordVisible = () => {
//     setVisible(!isVisible);
//   };

//   const googleLogin = useGoogleLogin({
//     onSuccess: async (tokenResponse) => {
//       try {
//         const { code } = tokenResponse;
//         if (!code) {
//           throw new Error("Authorization code not received from Google");
//         }
//         const response = await CommonApi.googleLogin({ code });
//         if (response && response.token) {
//           const { token, userId, role, user } = response;
//           LocalStorage.setAccessToken(token);
//           LocalStorage.setUserData({ token, userId, role, user });
//           LocalStorage.setCompanyData(response.user.company);
//           LocalStorage.setSessionFlag();
//           addSuccessMsg(`Successfully logged in as ${role}`);
//           navigate("/dashboard");
//           window.location.reload();
//         } else {
//           addErrorMsg("Unexpected response from server.");
//         }
//       } catch (error) {
//         console.error("Google login failed:", error);
//         addErrorMsg("Google login failed. Try again.");
//       }
//     },
//     onError: () => {
//       addErrorMsg("Google login failed");
//     },
//     scope: "openid profile email",
//     flow: "auth-code",
//   });

//   return (
//     <div style={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#fff" }}>
//       <Grid className={classes.container} container>
//         {/* Left Side: Animation */}
//         <Grid item lg={8} md={7} sm={6} xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//           <img src={GifImage} alt="GIF" className={classes.gif} />
//         </Grid>

//         {/* Right Side: Login or Sign Up Form */}
//         <Grid item lg={4} md={5} sm={6} xs={12} className={classes.loginFormBox}>
//           <Box style={{ width: "100%", maxWidth: "400px" }}>
//             <img src={TechOptima} alt="Logo" style={{ width: "100px", height: "60px", marginBottom: "20px", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />

//             <form onSubmit={handleSubmit}>
//               <Text variant="h5" className={classes.welText} style={{ marginBottom: "20px", textAlign: "center", fontWeight: "bold", fontSize: "22px" }}>
//                 {isSignUp ? "Create your Account" : "Welcome to Optima AI Hire!"}
//               </Text>
//               <Text className={classes.paraText} style={{ fontSize: "16px", width: '92%', paddingLeft: '28px' }}>
//                 Your smart hiring assistant, designed to streamline the recruitment process with precision and speed.
//               </Text>

//               {/* Form fields */}
//               <Grid container spacing={2} style={{ marginTop: '20px' }}>
//                 {isSignUp && (
//                   <Grid item xs={12}>
//                     <TextField required id="fullName" label="Full Name*" variant="standard" fullWidth name="fullName" onChange={changeHandler} className={classes.inputFields} />
//                   </Grid>
//                 )}
//                 <Grid item xs={12}>
//                   <TextField required id="email" label="Email Address*" variant="standard" fullWidth name="email" value={formData.email} onChange={changeHandler} error={!!error.email} helperText={error.email} className={classes.inputFields} />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField required id="password" label="Password*" variant="standard" fullWidth type={!isVisible ? "password" : "text"} name="password" onChange={changeHandler} value={formData.password} error={!!error.password} className={classes.inputFields} helperText={error.password} InputProps={{ endAdornment: (<Box component="span" onClick={passwordVisible} style={{ cursor: "pointer", marginRight: '8px' }}>{isVisible ? <img src={eye} alt="Visibility" style={{ height: '24px', width: '24px' }} /> : <img src={inVisible} alt="Not Visible" style={{ height: '24px', width: '24px' }} />}</Box>) }} />
//                 </Grid>
//                 {isSignUp && (
//                   <Grid item xs={12}>
//                     <TextField required id="mobileNumber" label="Mobile Number*" variant="standard" fullWidth name="mobileNumber" onChange={changeHandler} className={classes.inputFields} />
//                   </Grid>
//                 )}
//                 <Grid item xs={12} style={{ textAlign: 'right' }}>
//                   {!isSignUp && (
//                     <Link component="button" variant="body2" onClick={() => navigate("/forgot-password")} className={classes.forgotPassword}>
//                       Forgot Password?
//                     </Link>
//                   )}
//                 </Grid>
//               </Grid>

//               {/* Submit Button */}
//               <Grid item>
//                 <LoadingButton loading={loading} onClick={handleSubmit} variant="contained" type="submit" style={{ width: '80%', marginTop: '20px', height: '42px', borderRadius: '22px', backgroundColor: '#FF5200', marginLeft: "30px " }}>
//                   {isSignUp ? "Register now" : "Log In"}
//                 </LoadingButton>
//               </Grid>

//               {/* Alternative login methods */}
//               <Box mt={2} textAlign="center">
//                 <span>Or {isSignUp ? "register" : "login"} with</span>
//               </Box>
//               <Box display="flex" justifyContent="space-between" flexDirection="column" height='100px' mt={2}>
//                 <Button variant="outlined" className={classes.otherBtns} onClick={googleLogin} startIcon={<GoogleIcon />}>
//                   Google
//                 </Button>
//                 <Button variant="outlined" className={classes.otherBtns} onClick={handleMicrosoftLogin} startIcon={<MicrosoftIcon />}>
//                   Microsoft
//                 </Button>
//               </Box>

//               {/* Toggle between Login and Sign Up */}
//               <Box mt={3} textAlign="center">
//                 <span>
//                   {isSignUp ? "Already have an account?" : "Don't have an account?"}{" "}
//                   <Link component="button" onClick={toggleForm}>
//                     {isSignUp ? "Log In" : "Sign Up now"}
//                   </Link>
//                 </span>
//               </Box>
//             </form>
//           </Box>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

// export default Login;

// import React, { useEffect, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { useGoogleLogin } from "@react-oauth/google";
// import { MyContext } from "../../Context";
// import Grid from "@mui/material/Grid";
// import { Link, Box, Button, TextField } from "@mui/material";
// import { LoginStyles } from "./LoginStyles";
// import Text from "../../components/customText/Text";
// import { isValid, validate_emptyField } from "../../components/Validation";
// import { addErrorMsg, addSuccessMsg } from "../../utils/utils";
// import { LoadingButton } from "@mui/lab";
// import eye from "../../assets/svg/eye.svg";
// import inVisible from "../../assets/svg/NotVisible.svg";
// import GoogleIcon from "@mui/icons-material/Google";
// import MicrosoftIcon from "@mui/icons-material/Microsoft";
// import LinkedInIcon from '@mui/icons-material/LinkedIn'; // Ensure this works or replace with your icon
// import CommonApi from "../../apis/CommonApi";
// import LocalStorage from "../../utils/LocalStorage";
// import GifImage from "../../assets/svg/botanime.gif"; // Ensure this path is correct
// import TechOptima from '../../assets/svg/TO Logo.svg';

// function Login() {
//   const classes = LoginStyles();
//   const navigate = useNavigate();
//   const { setGlobaldata } = useContext(MyContext);
//   const [formData, setFormData] = useState({ email: "", password: "", fullName: "", mobileNumber: "" });
//   const [error, setError] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [isVisible, setVisible] = useState(false);
//   const [isSignUp, setIsSignUp] = useState(false); // Toggle state for form view

//   const changeHandler = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     handleValidate(e);
//   };

//   const toggleForm = () => {
//     setIsSignUp(!isSignUp); // Toggle between login and sign-up
//   };

//   const handleMicrosoftLogin = () => {
//     alert("Microsoft Login functionality here");
//   };

//   useEffect(() => {
//     const sessionFlag = LocalStorage.getSessionFlag();
//     if (!sessionFlag) {
//       LocalStorage.clearAll();
//       navigate("/login");
//     } else {
//       startInactivityTimer();
//       window.addEventListener("mousemove", resetInactivityTimer);
//       window.addEventListener("keydown", resetInactivityTimer);
//     }
//     return () => {
//       window.removeEventListener("mousemove", resetInactivityTimer);
//       window.removeEventListener("keydown", resetInactivityTimer);
//     };
//   }, [navigate]);

//   const startInactivityTimer = () => {
//     window.inactivityTimeout = setTimeout(handleLogout, 1800000);
//   };

//   const resetInactivityTimer = () => {
//     clearTimeout(window.inactivityTimeout);
//     startInactivityTimer();
//   };

//   const handleLogout = async () => {
//     await CommonApi.Logout();
//     LocalStorage.clearAll();
//     navigate("/login");
//   };

//   const handleValidate = (e) => {
//     let input = e.target;
//     let newError = { ...error };

//     switch (input.name) {
//       case "email":
//         newError.email = validate_emptyField(input.value);
//         break;
//       case "password":
//         newError.password = validate_emptyField(input.value);
//         break;
//       case "fullName":
//         if (isSignUp) newError.fullName = validate_emptyField(input.value);
//         break;
//       case "mobileNumber":
//         if (isSignUp) newError.mobileNumber = validate_emptyField(input.value);
//         break;
//       default:
//         break;
//     }

//     setError(newError);
//   };

//   const formValidations = () => {
//     let errors = {};
//     if (isSignUp) {
//       errors.fullName = validate_emptyField(formData.fullName);
//       errors.mobileNumber = validate_emptyField(formData.mobileNumber);
//     }
//     errors.email = validate_emptyField(formData.email);
//     errors.password = validate_emptyField(formData.password);
//     return errors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     let errors = formValidations();
//     if (isValid(errors)) {
//       setLoading(true);
//       try {
//         const response = isSignUp ? await CommonApi.SignUp(formData) : await CommonApi.Login(formData);
//         if (response.status === 200) {
//           const { token, userId, role, user } = response.data;
//           LocalStorage.setAccessToken(token);
//           LocalStorage.setUserData({ token, userId, role, user });
//           LocalStorage.setCompanyData(response.data.user.company);
//           LocalStorage.setSessionFlag();
//           addSuccessMsg(`Successfully ${isSignUp ? 'registered' : 'logged in'}`);
//           navigate("/dashboard");
//           window.location.reload();
//         } else {
//           setLoading(false);
//           addErrorMsg(response.data.message);
//         }
//       } catch (error) {
//         setLoading(false);
//         addErrorMsg(error.message);
//       }
//     } else {
//       setError(errors);
//     }
//   };

//   const passwordVisible = () => {
//     setVisible(!isVisible);
//   };

//   const googleLogin = useGoogleLogin({
//     onSuccess: async (tokenResponse) => {
//       try {
//         const { code } = tokenResponse;
//         if (!code) {
//           throw new Error("Authorization code not received from Google");
//         }
//         const response = await CommonApi.googleLogin({ code });
//         if (response && response.token) {
//           const { token, userId, role, user } = response;
//           LocalStorage.setAccessToken(token);
//           LocalStorage.setUserData({ token, userId, role, user });
//           LocalStorage.setCompanyData(response.user.company);
//           LocalStorage.setSessionFlag();
//           addSuccessMsg(`Successfully logged in as ${role}`);
//           navigate("/dashboard");
//           window.location.reload();
//         } else {
//           addErrorMsg("Unexpected response from server.");
//         }
//       } catch (error) {
//         console.error("Google login failed:", error);
//         addErrorMsg("Google login failed. Try again.");
//       }
//     },
//     onError: () => {
//       addErrorMsg("Google login failed");
//     },
//     scope: "openid profile email",
//     flow: "auth-code",
//   });

//   return (
//     <div style={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#fff" }}>
//       <Grid className={classes.container} container>
//         {/* Left Side: Animation */}
//         <Grid item lg={8} md={7} sm={6} xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//           <img src={GifImage} alt="GIF" className={classes.gif} />
//         </Grid>

//         {/* Right Side: Login or Sign Up Form */}
//         <Grid item lg={4} md={5} sm={6} xs={12} className={classes.loginFormBox}>
//           <Box style={{ width: "100%", maxWidth: "400px" }}>
//             <img src={TechOptima} alt="Logo" style={{ width: "100px", height: "60px", marginBottom: "20px", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />

//             <form onSubmit={handleSubmit}>
//               <Text variant="h5" className={classes.welText} style={{ marginBottom: "20px", textAlign: "center", fontWeight: "bold", fontSize: "22px" }}>
//                 {isSignUp ? "Create your Account" : "Welcome to Optima AI Hire!"}
//               </Text>
//               <Text className={classes.paraText} style={{ fontSize: "16px", width: '92%', paddingLeft: '28px' }}>
//                 Your smart hiring assistant, designed to streamline the recruitment process with precision and speed.
//               </Text>

//               {/* Form fields */}
//               <Grid container spacing={2} style={{ marginTop: '20px' }}>
//                 {isSignUp && (
//                   <Grid item xs={12}>
//                     <TextField required id="fullName" label="Full Name*" variant="standard" fullWidth name="fullName" onChange={changeHandler} className={classes.inputFields} />
//                   </Grid>
//                 )}
//                 <Grid item xs={12}>
//                   <TextField required id="email" label="Email Address*" variant="standard" fullWidth name="email" value={formData.email} onChange={changeHandler} error={!!error.email} helperText={error.email} className={classes.inputFields} />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField required id="password" label="Password*" variant="standard" fullWidth type={!isVisible ? "password" : "text"} name="password" onChange={changeHandler} value={formData.password} error={!!error.password} className={classes.inputFields} helperText={error.password} InputProps={{ endAdornment: (<Box component="span" onClick={passwordVisible} style={{ cursor: "pointer", marginRight: '8px' }}>{isVisible ? <img src={eye} alt="Visibility" style={{ height: '24px', width: '24px' }} /> : <img src={inVisible} alt="Not Visible" style={{ height: '24px', width: '24px' }} />}</Box>) }} />
//                 </Grid>
//                 {isSignUp && (
//                   <Grid item xs={12}>
//                     <TextField required id="mobileNumber" label="Mobile Number*" variant="standard" fullWidth name="mobileNumber" onChange={changeHandler} className={classes.inputFields} />
//                   </Grid>
//                 )}
//                 <Grid item xs={12} style={{ textAlign: 'right' }}>
//                   {!isSignUp && (
//                     <Link component="button" variant="body2" onClick={() => navigate("/forgot-password")} className={classes.forgotPassword}>
//                       Forgot Password?
//                     </Link>
//                   )}
//                 </Grid>
//               </Grid>

//               {/* Submit Button */}
//               <Grid item>
//                 <LoadingButton loading={loading} onClick={handleSubmit} variant="contained" type="submit" style={{ width: '80%', marginTop: '20px', height: '42px', borderRadius: '22px', backgroundColor: '#FF5200', marginLeft: "30px " }}>
//                   {isSignUp ? "Register now" : "Log In"}
//                 </LoadingButton>
//               </Grid>

//               {/* Alternative login methods */}
//               <Box mt={2} textAlign="center">
//                 <span>Or {isSignUp ? "register" : "login"} with</span>
//               </Box>

//               {/* Conditional rendering of login methods */}
//               {isSignUp ? (
//                 <Box display="flex" justifyContent="space-between" flexDirection="column" height='100px' mt={2}>
//                   <Button variant="outlined" className={classes.otherBtns} onClick={googleLogin} startIcon={<GoogleIcon />}>
//                     Google
//                   </Button>
//                   {/* Use a text button if the icon is not available */}
//                   <Button variant="outlined" className={classes.otherBtns} onClick={() => alert("Naukri Login functionality here")}>
//                     Naukri
//                   </Button>
//                   <Button variant="outlined" className={classes.otherBtns} onClick={() => alert("LinkedIn Login functionality here")} startIcon={<LinkedInIcon />}>
//                     LinkedIn
//                   </Button>
//                 </Box>
//               ) : (
//                 <Box display="flex" justifyContent="space-between" flexDirection="column" height='100px' mt={2}>
//                   <Button variant="outlined" className={classes.otherBtn} onClick={googleLogin} startIcon={<GoogleIcon />}>
//                     Google
//                   </Button>
//                   <Button variant="outlined" className={classes.otherBtn} onClick={handleMicrosoftLogin} startIcon={<MicrosoftIcon />}>
//                     Microsoft
//                   </Button>
//                 </Box>
//               )}

//               {/* Toggle between Login and Sign Up */}
//               <Box mt={3} textAlign="center">
//                 <span>
//                   {isSignUp ? "Already have an account?" : "Don't have an account?"}{" "}
//                   <Link component="button" onClick={toggleForm}>
//                     {isSignUp ? "Log In" : "Sign Up now"}
//                   </Link>
//                 </span>
//               </Box>
//             </form>
//           </Box>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

// export default Login;

// import React, { useEffect, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { useGoogleLogin } from "@react-oauth/google";
// import { MyContext } from "../../Context";
// import Grid from "@mui/material/Grid";
// import { Link, Box, Button, TextField } from "@mui/material";
// import { LoginStyles } from "./LoginStyles";
// import Text from "../../components/customText/Text";
// import { isValid, validate_emptyField } from "../../components/Validation";
// import { addErrorMsg, addSuccessMsg } from "../../utils/utils";
// import { LoadingButton } from "@mui/lab";
// import eye from "../../assets/svg/eye.svg";
// import inVisible from "../../assets/svg/NotVisible.svg";
// import GoogleIcon from "@mui/icons-material/Google";
// import MicrosoftIcon from "@mui/icons-material/Microsoft";
// import LinkedInIcon from '@mui/icons-material/LinkedIn'; // Ensure this works or replace with your icon
// import CommonApi from "../../apis/CommonApi";
// import LocalStorage from "../../utils/LocalStorage";
// import GifImage from "../../assets/svg/botanime.gif"; // Ensure this path is correct
// import TechOptima from '../../assets/svg/TO Logo.svg';

// function Login() {
//   const classes = LoginStyles();
//   const navigate = useNavigate();
//   const { setGlobaldata } = useContext(MyContext);
//   const [formData, setFormData] = useState({ email: "", password: "", fullName: "", mobileNumber: "" });
//   const [error, setError] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [isVisible, setVisible] = useState(false);
//   const [isSignUp, setIsSignUp] = useState(false); // Toggle state for form view

//   const changeHandler = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     handleValidate(e);
//   };

//   const toggleForm = () => {
//     setIsSignUp(!isSignUp); // Toggle between login and sign-up
//   };

//   const handleMicrosoftLogin = () => {
//     alert("Microsoft Login functionality here");
//   };

//   useEffect(() => {
//     const sessionFlag = LocalStorage.getSessionFlag();
//     if (!sessionFlag) {
//       LocalStorage.clearAll();
//       navigate("/login");
//     } else {
//       startInactivityTimer();
//       window.addEventListener("mousemove", resetInactivityTimer);
//       window.addEventListener("keydown", resetInactivityTimer);
//     }
//     return () => {
//       window.removeEventListener("mousemove", resetInactivityTimer);
//       window.removeEventListener("keydown", resetInactivityTimer);
//     };
//   }, [navigate]);

//   const startInactivityTimer = () => {
//     window.inactivityTimeout = setTimeout(handleLogout, 1800000);
//   };

//   const resetInactivityTimer = () => {
//     clearTimeout(window.inactivityTimeout);
//     startInactivityTimer();
//   };

//   const handleLogout = async () => {
//     await CommonApi.Logout();
//     LocalStorage.clearAll();
//     navigate("/login");
//   };

//   const handleValidate = (e) => {
//     let input = e.target;
//     let newError = { ...error };

//     switch (input.name) {
//       case "email":
//         newError.email = validate_emptyField(input.value);
//         break;
//       case "password":
//         newError.password = validate_emptyField(input.value);
//         break;
//       case "fullName":
//         if (isSignUp) newError.fullName = validate_emptyField(input.value);
//         break;
//       case "mobileNumber":
//         if (isSignUp) newError.mobileNumber = validate_emptyField(input.value);
//         break;
//       default:
//         break;
//     }

//     setError(newError);
//   };

//   const formValidations = () => {
//     let errors = {};
//     if (isSignUp) {
//       errors.fullName = validate_emptyField(formData.fullName);
//       errors.mobileNumber = validate_emptyField(formData.mobileNumber);
//     }
//     errors.email = validate_emptyField(formData.email);
//     errors.password = validate_emptyField(formData.password);
//     return errors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     let errors = formValidations();
//     if (isValid(errors)) {
//       setLoading(true);
//       try {
//         const response = isSignUp ? await CommonApi.SignUp(formData) : await CommonApi.Login(formData);
//         if (response.status === 200) {
//           const { token, userId, role, user } = response.data;
//           LocalStorage.setAccessToken(token);
//           LocalStorage.setUserData({ token, userId, role, user });
//           LocalStorage.setCompanyData(response.data.user.company);
//           LocalStorage.setSessionFlag();
//           addSuccessMsg(`Successfully ${isSignUp ? 'registered' : 'logged in'}`);
//           navigate("/dashboard");
//           window.location.reload();
//         } else {
//           setLoading(false);
//           addErrorMsg(response.data.message);
//         }
//       } catch (error) {
//         setLoading(false);
//         addErrorMsg(error.message);
//       }
//     } else {
//       setError(errors);
//     }
//   };

//   const passwordVisible = () => {
//     setVisible(!isVisible);
//   };

//   const googleLogin = useGoogleLogin({
//     onSuccess: async (tokenResponse) => {
//       try {
//         const { code } = tokenResponse;
//         if (!code) {
//           throw new Error("Authorization code not received from Google");
//         }
//         const response = await CommonApi.googleLogin({ code });
//         if (response && response.token) {
//           const { token, userId, role, user } = response;
//           LocalStorage.setAccessToken(token);
//           LocalStorage.setUserData({ token, userId, role, user });
//           LocalStorage.setCompanyData(response.user.company);
//           LocalStorage.setSessionFlag();
//           addSuccessMsg(`Successfully logged in as ${role}`);
//           navigate("/dashboard");
//           window.location.reload();
//         } else {
//           addErrorMsg("Unexpected response from server.");
//         }
//       } catch (error) {
//         console.error("Google login failed:", error);
//         addErrorMsg("Google login failed. Try again.");
//       }
//     },
//     onError: () => {
//       addErrorMsg("Google login failed");
//     },
//     scope: "openid profile email",
//     flow: "auth-code",
//   });

//   return (
//     <div style={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#fff" }}>
//       <Grid className={classes.container} container>
//         {/* Left Side: Animation */}
//         <Grid item lg={8} md={7} sm={6} xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//           <img src={GifImage} alt="GIF" className={classes.gif} />
//         </Grid>

//         {/* Right Side: Login or Sign Up Form */}
//         <Grid item lg={4} md={5} sm={6} xs={12} className={classes.loginFormBox}>
//           <Box style={{ width: "100%", maxWidth: "400px" }}>
//             <img src={TechOptima} alt="Logo" style={{ width: "100px", height: "60px", marginBottom: "20px", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />

//             <form onSubmit={handleSubmit}>
//               <Text variant="h5" className={classes.welText} style={{ marginBottom: "20px", textAlign: "center", fontWeight: "bold", fontSize: "22px" }}>
//                 {isSignUp ? "Create your Account" : "Welcome to Optima AI Hire!"}
//               </Text>
//               <Text className={classes.paraText} style={{ fontSize: "16px", width: '92%', paddingLeft: '28px' }}>
//                 Your smart hiring assistant, designed to streamline the recruitment process with precision and speed.
//               </Text>

//               {/* Form fields */}
//               <Grid container spacing={2} style={{ marginTop: '20px' }}>
//                 {isSignUp && (
//                   <Grid item xs={12}>
//                     <TextField required id="fullName" label="Full Name*" variant="standard" fullWidth name="fullName" onChange={changeHandler} className={classes.inputFields} />
//                   </Grid>
//                 )}
//                 <Grid item xs={12}>
//                   <TextField required id="email" label="Email Address*" variant="standard" fullWidth name="email" value={formData.email} onChange={changeHandler} error={!!error.email} helperText={error.email} className={classes.inputFields} />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField required id="password" label="Password*" variant="standard" fullWidth type={!isVisible ? "password" : "text"} name="password" onChange={changeHandler} value={formData.password} error={!!error.password} className={classes.inputFields} helperText={error.password} InputProps={{ endAdornment: (<Box component="span" onClick={passwordVisible} style={{ cursor: "pointer", marginRight: '8px' }}>{isVisible ? <img src={eye} alt="Visibility" style={{ height: '24px', width: '24px' }} /> : <img src={inVisible} alt="Not Visible" style={{ height: '24px', width: '24px' }} />}</Box>) }} />
//                 </Grid>
//                 {isSignUp && (
//                   <Grid item xs={12}>
//                     <TextField required id="mobileNumber" label="Mobile Number*" variant="standard" fullWidth name="mobileNumber" onChange={changeHandler} className={classes.inputFields} />
//                   </Grid>
//                 )}
//                 <Grid item xs={12} style={{ textAlign: 'right' }}>
//                   {!isSignUp && (
//                     <Link component="button" variant="body2" onClick={() => navigate("/forgot-password")} className={classes.forgotPassword}>
//                       Forgot Password?
//                     </Link>
//                   )}
//                 </Grid>
//               </Grid>

//               {/* Submit Button */}
//               <Grid item>
//                 <LoadingButton loading={loading} onClick={handleSubmit} variant="contained" type="submit" style={{ width: '80%', marginTop: '20px', height: '42px', borderRadius: '22px', backgroundColor: '#FF5200', marginLeft: "30px " }}>
//                   {isSignUp ? "Register now" : "Log In"}
//                 </LoadingButton>
//               </Grid>

//               {/* Alternative login methods */}
//               <Box mt={2} textAlign="center">
//                 <span>Or {isSignUp ? "register" : "login"} with</span>
//               </Box>

//               {/* Conditional rendering of login methods */}
//               {isSignUp ? (
//                 <Box display="flex" justifyContent="space-between" flexDirection="column" height='100px' mt={2}>
//                   <Button variant="outlined" className={classes.otherBtns} onClick={googleLogin} startIcon={<GoogleIcon />} style={{ margin: '10px 0' }}>
//                     Google
//                   </Button>
//                   <Button variant="outlined" className={classes.otherBtns} onClick={() => alert("Naukri Login functionality here")} style={{ margin: '10px 0' }}>
//                     Naukri
//                   </Button>
//                   <Button variant="outlined" className={classes.otherBtns} onClick={() => alert("LinkedIn Login functionality here")} startIcon={<LinkedInIcon />} style={{ margin: '10px 0' }}>
//                     LinkedIn
//                   </Button>
//                 </Box>
//               ) : (
//                 <Box display="flex" justifyContent="space-between" flexDirection="column" height='100px' mt={2}>
//                   <Button variant="outlined" className={classes.otherBtns} onClick={googleLogin} startIcon={<GoogleIcon />} style={{ margin: '10px 0' }}>
//                     Google
//                   </Button>
//                   <Button variant="outlined" className={classes.otherBtns} onClick={handleMicrosoftLogin} startIcon={<MicrosoftIcon />} style={{ margin: '10px 0' }}>
//                     Microsoft
//                   </Button>
//                 </Box>
//               )}

//               {/* Toggle between Login and Sign Up */}
//               <Box mt={3} textAlign="center" >
//                 <span>
//                   {isSignUp ? "Already have an account?" : "Don't have an account?"}{" "}
//                   <Link component="button" onClick={toggleForm}>
//                     {isSignUp ? "Log In" : "Sign Up now"}
//                   </Link>
//                 </span>
//               </Box>
//             </form>
//           </Box>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

// export default Login;

// //buttons in a row for sign up
// import React, { useEffect, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { useGoogleLogin } from "@react-oauth/google";
// import { MyContext } from "../../Context";
// import Grid from "@mui/material/Grid";
// import { Link, Box, Button, TextField } from "@mui/material";
// import { LoginStyles } from "./LoginStyles";
// import Text from "../../components/customText/Text";
// import { isValid, validate_emptyField } from "../../components/Validation";
// import { addErrorMsg, addSuccessMsg } from "../../utils/utils";
// import { LoadingButton } from "@mui/lab";
// import eye from "../../assets/svg/eye.svg";
// import inVisible from "../../assets/svg/NotVisible.svg";
// import GoogleIcon from "@mui/icons-material/Google";
// import MicrosoftIcon from "@mui/icons-material/Microsoft";
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import CommonApi from "../../apis/CommonApi";
// import LocalStorage from "../../utils/LocalStorage";
// import GifImage from "../../assets/svg/botanime.gif"; // Ensure this path is correct
// import TechOptima from '../../assets/svg/TO Logo.svg';

// function Login() {
//   const classes = LoginStyles();
//   const navigate = useNavigate();
//   const { setGlobaldata } = useContext(MyContext);
//   const [formData, setFormData] = useState({ email: "", password: "", fullName: "", mobileNumber: "" });
//   const [error, setError] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [isVisible, setVisible] = useState(false);
//   const [isSignUp, setIsSignUp] = useState(false); // Toggle state for form view

//   const changeHandler = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     handleValidate(e);
//   };

//   const toggleForm = () => {
//     setIsSignUp(!isSignUp); // Toggle between login and sign-up
//   };

//   const handleMicrosoftLogin = () => {
//     alert("Microsoft Login functionality here");
//   };

//   useEffect(() => {
//     const sessionFlag = LocalStorage.getSessionFlag();
//     if (!sessionFlag) {
//       LocalStorage.clearAll();
//       navigate("/login");
//     } else {
//       startInactivityTimer();
//       window.addEventListener("mousemove", resetInactivityTimer);
//       window.addEventListener("keydown", resetInactivityTimer);
//     }
//     return () => {
//       window.removeEventListener("mousemove", resetInactivityTimer);
//       window.removeEventListener("keydown", resetInactivityTimer);
//     };
//   }, [navigate]);

//   const startInactivityTimer = () => {
//     window.inactivityTimeout = setTimeout(handleLogout, 1800000);
//   };

//   const resetInactivityTimer = () => {
//     clearTimeout(window.inactivityTimeout);
//     startInactivityTimer();
//   };

//   const handleLogout = async () => {
//     await CommonApi.Logout();
//     LocalStorage.clearAll();
//     navigate("/login");
//   };

//   const handleValidate = (e) => {
//     let input = e.target;
//     let newError = { ...error };

//     switch (input.name) {
//       case "email":
//         newError.email = validate_emptyField(input.value);
//         break;
//       case "password":
//         newError.password = validate_emptyField(input.value);
//         break;
//       case "fullName":
//         if (isSignUp) newError.fullName = validate_emptyField(input.value);
//         break;
//       case "mobileNumber":
//         if (isSignUp) newError.mobileNumber = validate_emptyField(input.value);
//         break;
//       default:
//         break;
//     }

//     setError(newError);
//   };

//   const formValidations = () => {
//     let errors = {};
//     if (isSignUp) {
//       errors.fullName = validate_emptyField(formData.fullName);
//       errors.mobileNumber = validate_emptyField(formData.mobileNumber);
//     }
//     errors.email = validate_emptyField(formData.email);
//     errors.password = validate_emptyField(formData.password);
//     return errors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     let errors = formValidations();
//     if (isValid(errors)) {
//       setLoading(true);
//       try {
//         const response = isSignUp ? await CommonApi.SignUp(formData) : await CommonApi.Login(formData);
//         if (response.status === 200) {
//           const { token, userId, role, user } = response.data;
//           LocalStorage.setAccessToken(token);
//           LocalStorage.setUserData({ token, userId, role, user });
//           LocalStorage.setCompanyData(response.data.user.company);
//           LocalStorage.setSessionFlag();
//           addSuccessMsg(`Successfully ${isSignUp ? 'registered' : 'logged in'}`);
//           navigate("/dashboard");
//           window.location.reload();
//         } else {
//           setLoading(false);
//           addErrorMsg(response.data.message);
//         }
//       } catch (error) {
//         setLoading(false);
//         addErrorMsg(error.message);
//       }
//     } else {
//       setError(errors);
//     }
//   };

//   const passwordVisible = () => {
//     setVisible(!isVisible);
//   };

//   const googleLogin = useGoogleLogin({
//     onSuccess: async (tokenResponse) => {
//       try {
//         const { code } = tokenResponse;
//         if (!code) {
//           throw new Error("Authorization code not received from Google");
//         }
//         const response = await CommonApi.googleLogin({ code });
//         if (response && response.token) {
//           const { token, userId, role, user } = response;
//           LocalStorage.setAccessToken(token);
//           LocalStorage.setUserData({ token, userId, role, user });
//           LocalStorage.setCompanyData(response.user.company);
//           LocalStorage.setSessionFlag();
//           addSuccessMsg(`Successfully logged in as ${role}`);
//           navigate("/dashboard");
//           window.location.reload();
//         } else {
//           addErrorMsg("Unexpected response from server.");
//         }
//       } catch (error) {
//         console.error("Google login failed:", error);
//         addErrorMsg("Google login failed. Try again.");
//       }
//     },
//     onError: () => {
//       addErrorMsg("Google login failed");
//     },
//     scope: "openid profile email",
//     flow: "auth-code",
//   });

//   return (
//     <div style={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#fff" }}>
//       <Grid className={classes.container} container>
//         {/* Left Side: Animation */}
//         <Grid item lg={8} md={7} sm={6} xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//           <img src={GifImage} alt="GIF" className={classes.gif} />
//         </Grid>

//         {/* Right Side: Login or Sign Up Form */}
//         <Grid item lg={4} md={5} sm={6} xs={12} className={classes.loginFormBox}>
//           <Box style={{ width: "100%", maxWidth: "400px" }}>
//             <img src={TechOptima} alt="Logo" style={{ width: "100px", height: "60px", marginBottom: "20px", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />

//             <form onSubmit={handleSubmit}>
//               <Text variant="h5" className={classes.welText} style={{ marginBottom: "20px", textAlign: "center", fontWeight: "bold", fontSize: "22px" }}>
//                 {isSignUp ? "Create your Account" : "Welcome to Optima AI Hire!"}
//               </Text>
//               <Text className={classes.paraText} style={{ fontSize: "16px", width: '92%', paddingLeft: '28px' }}>
//                 Your smart hiring assistant, designed to streamline the recruitment process with precision and speed.
//               </Text>

//               {/* Form fields */}
//               <Grid container spacing={2} style={{ marginTop: '20px' }}>
//                 {isSignUp && (
//                   <Grid item xs={12}>
//                     <TextField required id="fullName" label="Full Name*" variant="standard" fullWidth name="fullName" onChange={changeHandler} className={classes.inputFields} />
//                   </Grid>
//                 )}
//                 <Grid item xs={12}>
//                   <TextField required id="email" label="Email Address*" variant="standard" fullWidth name="email" value={formData.email} onChange={changeHandler} error={!!error.email} helperText={error.email} className={classes.inputFields} />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField required id="password" label="Password*" variant="standard" fullWidth type={!isVisible ? "password" : "text"} name="password" onChange={changeHandler} value={formData.password} error={!!error.password} className={classes.inputFields} helperText={error.password} InputProps={{ endAdornment: (<Box component="span" onClick={passwordVisible} style={{ cursor: "pointer", marginRight: '8px' }}>{isVisible ? <img src={eye} alt="Visibility" style={{ height: '24px', width: '24px' }} /> : <img src={inVisible} alt="Not Visible" style={{ height: '24px', width: '24px' }} />}</Box>) }} />
//                 </Grid>
//                 {isSignUp && (
//                   <Grid item xs={12}>
//                     <TextField required id="mobileNumber" label="Mobile Number*" variant="standard" fullWidth name="mobileNumber" onChange={changeHandler} className={classes.inputFields} />
//                   </Grid>
//                 )}
//                 <Grid item xs={12} style={{ textAlign: 'right' }}>
//                   {!isSignUp && (
//                     <Link component="button" variant="body2" onClick={() => navigate("/forgot-password")} className={classes.forgotPassword}>
//                       Forgot Password?
//                     </Link>
//                   )}
//                 </Grid>
//               </Grid>

//               {/* Submit Button */}
//               <Grid item>
//                 <LoadingButton loading={loading} onClick={handleSubmit} variant="contained" type="submit" style={{ width: '80%', marginTop: '20px', height: '42px', borderRadius: '22px', backgroundColor: '#FF5200', marginLeft: "30px " }}>
//                   {isSignUp ? "Register now" : "Log In"}
//                 </LoadingButton>
//               </Grid>

//               {/* Alternative login methods */}
//               <Box mt={2} textAlign="center">
//                 <span>Or {isSignUp ? "register" : "login"} with</span>
//               </Box>

//               {/* Conditional rendering of login methods */}
//               {isSignUp ? (
//                 <Box display="flex" justifyContent="space-between" mt={2}>
//                   <Button variant="outlined" className={classes.otherBtns} onClick={googleLogin} startIcon={<GoogleIcon />} style={{ flex: 1, marginRight: '10px', fontSize: '10px' }}>
//                     Google
//                   </Button>
//                   <Button variant="outlined" className={classes.otherBtns} onClick={() => alert("Naukri Login functionality here")} style={{ flex: 1, marginRight: '10px' ,fontSize: '10px'}}>
//                     Naukri
//                   </Button>
//                   <Button variant="outlined" className={classes.otherBtns} onClick={() => alert("LinkedIn Login functionality here")} startIcon={<LinkedInIcon />} style={{ flex: 1 ,marginRight: '40px', fontSize: '10px'}}>
//                     LinkedIn
//                   </Button>
//                 </Box>
//               ) : (
//                 <Box display="flex" justifyContent="space-between" flexDirection="column" height='100px' mt={2}>
//                   <Button variant="outlined" className={classes.otherBtns} onClick={googleLogin} startIcon={<GoogleIcon />} style={{ margin: '10px 0' }}>
//                     Google
//                   </Button>
//                   <Button variant="outlined" className={classes.otherBtns} onClick={handleMicrosoftLogin} startIcon={<MicrosoftIcon />} style={{ margin: '10px 0' }}>
//                     Microsoft
//                   </Button>
//                 </Box>
//               )}

//               {/* Toggle between Login and Sign Up */}
//               <Box mt={3} textAlign="center">
//                 <span>
//                   {isSignUp ? "Already have an account?" : "Don't have an account?"}{" "}
//                   <Link component="button" onClick={toggleForm}>
//                     {isSignUp ? "Log In" : "Sign Up now"}
//                   </Link>
//                 </span>
//               </Box>
//             </form>
//           </Box>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

// export default Login;

// import React, { useEffect, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { useGoogleLogin } from "@react-oauth/google";
// import { MyContext } from "../../Context";
// import Grid from "@mui/material/Grid";
// import { Link, Box, Button, TextField, IconButton } from "@mui/material";
// import { LoginStyles } from "./LoginStyles";
// import Text from "../../components/customText/Text";
// import { isValid, validate_emptyField } from "../../components/Validation";
// import { addErrorMsg, addSuccessMsg } from "../../utils/utils";
// import { LoadingButton } from "@mui/lab";
// import eye from "../../assets/svg/eye.svg";
// import inVisible from "../../assets/svg/NotVisible.svg";
// import GoogleIcon from "@mui/icons-material/Google";
// import MicrosoftIcon from "@mui/icons-material/Microsoft";
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import CommonApi from "../../apis/CommonApi";
// import LocalStorage from "../../utils/LocalStorage";
// import GifImage from "../../assets/svg/botanime.gif"; // Ensure this path is correct
// import TechOptima from '../../assets/svg/TO Logo.svg';

// function Login() {
//   const classes = LoginStyles();
//   const navigate = useNavigate();
//   const { setGlobaldata } = useContext(MyContext);
//   const [formData, setFormData] = useState({ email: "", password: "", fullName: "", mobileNumber: "" });
//   const [error, setError] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [isVisible, setVisible] = useState(false);
//   const [isSignUp, setIsSignUp] = useState(false); // Toggle state for form view

//   const changeHandler = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     handleValidate(e);
//   };

//   const toggleForm = () => {
//     setIsSignUp(!isSignUp); // Toggle between login and sign-up
//   };

//   const handleMicrosoftLogin = () => {
//     alert("Microsoft Login functionality here");
//   };

//   useEffect(() => {
//     const sessionFlag = LocalStorage.getSessionFlag();
//     if (!sessionFlag) {
//       LocalStorage.clearAll();
//       navigate("/login");
//     } else {
//       startInactivityTimer();
//       window.addEventListener("mousemove", resetInactivityTimer);
//       window.addEventListener("keydown", resetInactivityTimer);
//     }
//     return () => {
//       window.removeEventListener("mousemove", resetInactivityTimer);
//       window.removeEventListener("keydown", resetInactivityTimer);
//     };
//   }, [navigate]);

//   const startInactivityTimer = () => {
//     window.inactivityTimeout = setTimeout(handleLogout, 1800000);
//   };

//   const resetInactivityTimer = () => {
//     clearTimeout(window.inactivityTimeout);
//     startInactivityTimer();
//   };

//   const handleLogout = async () => {
//     await CommonApi.Logout();
//     LocalStorage.clearAll();
//     navigate("/login");
//   };

//   const handleValidate = (e) => {
//     let input = e.target;
//     let newError = { ...error };

//     switch (input.name) {
//       case "email":
//         newError.email = validate_emptyField(input.value);
//         break;
//       case "password":
//         newError.password = validate_emptyField(input.value);
//         break;
//       case "fullName":
//         if (isSignUp) newError.fullName = validate_emptyField(input.value);
//         break;
//       case "mobileNumber":
//         if (isSignUp) newError.mobileNumber = validate_emptyField(input.value);
//         break;
//       default:
//         break;
//     }

//     setError(newError);
//   };

//   const formValidations = () => {
//     let errors = {};
//     if (isSignUp) {
//       errors.fullName = validate_emptyField(formData.fullName);
//       errors.mobileNumber = validate_emptyField(formData.mobileNumber);
//     }
//     errors.email = validate_emptyField(formData.email);
//     errors.password = validate_emptyField(formData.password);
//     return errors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     let errors = formValidations();
//     if (isValid(errors)) {
//       setLoading(true);
//       try {
//         const response = isSignUp ? await CommonApi.SignUp(formData) : await CommonApi.Login(formData);
//         if (response.status === 200) {
//           const { token, userId, role, user } = response.data;
//           LocalStorage.setAccessToken(token);
//           LocalStorage.setUserData({ token, userId, role, user });
//           LocalStorage.setCompanyData(response.data.user.company);
//           LocalStorage.setSessionFlag();
//           addSuccessMsg(`Successfully ${isSignUp ? 'registered' : 'logged in'}`);
//           navigate("/dashboard");
//           window.location.reload();
//         } else {
//           setLoading(false);
//           addErrorMsg(response.data.message);
//         }
//       } catch (error) {
//         setLoading(false);
//         addErrorMsg(error.message);
//       }
//     } else {
//       setError(errors);
//     }
//   };

//   const passwordVisible = () => {
//     setVisible(!isVisible);
//   };

//   const googleLogin = useGoogleLogin({
//     onSuccess: async (tokenResponse) => {
//       try {
//         const { code } = tokenResponse;
//         if (!code) {
//           throw new Error("Authorization code not received from Google");
//         }
//         const response = await CommonApi.googleLogin({ code });
//         if (response && response.token) {
//           const { token, userId, role, user } = response;
//           LocalStorage.setAccessToken(token);
//           LocalStorage.setUserData({ token, userId, role, user });
//           LocalStorage.setCompanyData(response.user.company);
//           LocalStorage.setSessionFlag();
//           addSuccessMsg(`Successfully logged in as ${role}`);
//           navigate("/dashboard");
//           window.location.reload();
//         } else {
//           addErrorMsg("Unexpected response from server.");
//         }
//       } catch (error) {
//         console.error("Google login failed:", error);
//         addErrorMsg("Google login failed. Try again.");
//       }
//     },
//     onError: () => {
//       addErrorMsg("Google login failed");
//     },
//     scope: "openid profile email",
//     flow: "auth-code",
//   });

//   return (
//     <div style={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#fff" }}>
//       <Grid className={classes.container} container>
//         {/* Left Side: Animation */}
//         <Grid item lg={8} md={7} sm={6} xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//           <img src={GifImage} alt="GIF" className={classes.gif} />
//         </Grid>

//         {/* Right Side: Login or Sign Up Form */}
//         <Grid item lg={4} md={5} sm={6} xs={12} className={classes.loginFormBox}>
//           <Box style={{ width: "100%", maxWidth: "400px" }}>
//             <img src={TechOptima} alt="Logo" style={{ width: "100px", height: "60px", marginBottom: "20px", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />

//             <form onSubmit={handleSubmit}>
//               <Text variant="h5" className={classes.welText} style={{ marginBottom: "20px", textAlign: "center", fontWeight: "bold", fontSize: "22px" }}>
//                 {isSignUp ? "Create your Account" : "Welcome to Optima AI Hire!"}
//               </Text>
//               <Text className={classes.paraText} style={{ fontSize: "16px", width: '98%', paddingLeft: '18px' }}>
//                 Your smart hiring assistant, designed to streamline the recruitment process with precision and speed.
//               </Text>

//               {/* Form fields */}
//               <Grid container spacing={2} style={{ marginTop: '20px' }}>
//                 {isSignUp && (
//                   <Grid item xs={12}>
//                     <TextField required id="fullName" label="Full Name*" variant="standard" fullWidth name="fullName" onChange={changeHandler} className={classes.inputFields} />
//                   </Grid>
//                 )}
//                 <Grid item xs={12}>
//                   <TextField required id="email" label="Email Address*" variant="standard" fullWidth name="email" value={formData.email} onChange={changeHandler} error={!!error.email} helperText={error.email} className={classes.inputFields} />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField required id="password" label="Password*" variant="standard" fullWidth type={!isVisible ? "password" : "text"} name="password" onChange={changeHandler} value={formData.password} error={!!error.password} className={classes.inputFields} helperText={error.password} InputProps={{ endAdornment: (<Box component="span" onClick={passwordVisible} style={{ cursor: "pointer", marginRight: '8px' }}>{isVisible ? <img src={eye} alt="Visibility" style={{ height: '24px', width: '24px' }} /> : <img src={inVisible} alt="Not Visible" style={{ height: '24px', width: '24px' }} />}</Box>) }} />
//                 </Grid>
//                 {isSignUp && (
//                   <Grid item xs={12}>
//                     <TextField required id="mobileNumber" label="Mobile Number*" variant="standard" fullWidth name="mobileNumber" onChange={changeHandler} className={classes.inputFields} />
//                   </Grid>
//                 )}
//                 <Grid item xs={12} style={{ textAlign: 'right' }}>
//                   {!isSignUp && (
//                     <Link component="button" variant="body2" onClick={() => navigate("/forgot-password")} className={classes.forgotPassword}>
//                       Forgot Password?
//                     </Link>
//                   )}
//                 </Grid>
//               </Grid>

//               {/* Submit Button */}
//               <Grid item>
//                 <LoadingButton loading={loading} onClick={handleSubmit} variant="contained" type="submit" style={{ width: '80%', marginTop: '20px', height: '42px', borderRadius: '22px', backgroundColor: '#FF5200', marginLeft: "30px " }}>
//                   {isSignUp ? "Register now" : "Log In"}
//                 </LoadingButton>
//               </Grid>

//               {/* Alternative login methods */}
//               <Box mt={2} textAlign="center">
//                 <span>Or {isSignUp ? "register" : "login"} with</span>
//               </Box>

//               {/* Conditional rendering of login methods */}
//               {isSignUp && (
//                 <Box display="flex" justifyContent="center" flexDirection="row" mt={2}>
//                   <IconButton onClick={googleLogin} aria-label="Google Login" style={{ margin: '0 10px' }}>
//                     <GoogleIcon style={{ width: '24px', height: '24px' }} />
//                   </IconButton>
//                   <IconButton onClick={() => alert("Naukri Login functionality here")} aria-label="Naukri Login" style={{ margin: '0 10px' }}>
//                     <img src="/path/to/naukri/icon" alt="Naukri" style={{ width: '24px', height: '24px' }} />
//                   </IconButton>
//                   <IconButton onClick={() => alert("LinkedIn Login functionality here")} aria-label="LinkedIn Login" style={{ margin: '0 10px' }}>
//                     <LinkedInIcon style={{ width: '24px', height: '24px' }} />
//                   </IconButton>
//                 </Box>
//               )}

//               {/* Login buttons for login view */}
//               {!isSignUp && (
//                 <Box display="flex" justifyContent="space-between" flexDirection="column" height='100px' mt={2}>
//                   <Button variant="outlined" className={classes.otherBtns} onClick={googleLogin} startIcon={<GoogleIcon />} style={{ margin: '10px 0' }}>
//                     Google
//                   </Button>
//                   <Button variant="outlined" className={classes.otherBtns} onClick={handleMicrosoftLogin} startIcon={<MicrosoftIcon />} style={{ margin: '10px 0' }}>
//                     Microsoft
//                   </Button>
//                 </Box>
//               )}

//               {/* Toggle between Login and Sign Up */}
//               <Box mt={3} textAlign="center">
//                 <span>
//                   {isSignUp ? "Already have an account?" : "Don't have an account?"}{" "}
//                   <Link component="button" onClick={toggleForm}>
//                     {isSignUp ? "Log In" : "Sign Up now"}
//                   </Link>
//                 </span>
//               </Box>
//             </form>
//           </Box>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

// export default Login;

// import React, { useEffect, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { useGoogleLogin } from "@react-oauth/google";
// import { MyContext } from "../../Context";
// import Grid from "@mui/material/Grid";
// import { Link, Box, Button, TextField, IconButton, Select, MenuItem } from "@mui/material";
// import { LoginStyles } from "./LoginStyles";
// import Text from "../../components/customText/Text";
// import { isValid, validate_emptyField } from "../../components/Validation";
// import { addErrorMsg, addSuccessMsg } from "../../utils/utils";
// import { LoadingButton } from "@mui/lab";
// import eye from "../../assets/svg/eye.svg";
// import inVisible from "../../assets/svg/NotVisible.svg";
// import GoogleIcon from "@mui/icons-material/Google";
// import MicrosoftIcon from "@mui/icons-material/Microsoft";
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import CommonApi from "../../apis/CommonApi";
// import LocalStorage from "../../utils/LocalStorage";
// import GifImage from "../../assets/svg/botanime.gif"; // Ensure this path is correct
// import TechOptima from '../../assets/svg/TO Logo.svg';

// function Login() {
//   const classes = LoginStyles();
//   const navigate = useNavigate();
//   const { setGlobaldata } = useContext(MyContext);
//   const [formData, setFormData] = useState({ email: "", password: "", fullName: "", mobileNumber: "", userType: "" });
//   const [error, setError] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [isVisible, setVisible] = useState(false);
//   const [isSignUp, setIsSignUp] = useState(false); // Toggle state for form view

//   const changeHandler = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//     handleValidate(e);
//   };

//   const toggleForm = () => {
//     setIsSignUp(!isSignUp); // Toggle between login and sign-up
//   };

//   const handleMicrosoftLogin = () => {
//     alert("Microsoft Login functionality here");
//   };

//   useEffect(() => {
//     const sessionFlag = LocalStorage.getSessionFlag();
//     if (!sessionFlag) {
//       LocalStorage.clearAll();
//       navigate("/login");
//     } else {
//       startInactivityTimer();
//       window.addEventListener("mousemove", resetInactivityTimer);
//       window.addEventListener("keydown", resetInactivityTimer);
//     }
//     return () => {
//       window.removeEventListener("mousemove", resetInactivityTimer);
//       window.removeEventListener("keydown", resetInactivityTimer);
//     };
//   }, [navigate]);

//   const startInactivityTimer = () => {
//     window.inactivityTimeout = setTimeout(handleLogout, 1800000);
//   };

//   const resetInactivityTimer = () => {
//     clearTimeout(window.inactivityTimeout);
//     startInactivityTimer();
//   };

//   const handleLogout = async () => {
//     await CommonApi.Logout();
//     LocalStorage.clearAll();
//     navigate("/login");
//   };

//   const handleValidate = (e) => {
//     let input = e.target;
//     let newError = { ...error };

//     switch (input.name) {
//       case "email":
//         newError.email = validate_emptyField(input.value);
//         break;
//       case "password":
//         newError.password = validate_emptyField(input.value);
//         break;
//       case "fullName":
//         if (isSignUp) newError.fullName = validate_emptyField(input.value);
//         break;
//       case "mobileNumber":
//         if (isSignUp) newError.mobileNumber = validate_emptyField(input.value);
//         break;
//       case "userType":
//         if (isSignUp) newError.userType = validate_emptyField(input.value);
//         break;
//       default:
//         break;
//     }

//     setError(newError);
//   };

//   const formValidations = () => {
//     let errors = {};
//     if (isSignUp) {
//       errors.fullName = validate_emptyField(formData.fullName);
//       errors.mobileNumber = validate_emptyField(formData.mobileNumber);
//       errors.userType = validate_emptyField(formData.userType);
//     }
//     errors.email = validate_emptyField(formData.email);
//     errors.password = validate_emptyField(formData.password);
//     return errors;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     let errors = formValidations();
//     if (isValid(errors)) {
//       setLoading(true);
//       try {
//         const response = isSignUp ? await CommonApi.SignUp(formData) : await CommonApi.Login(formData);
//         if (response.status === 200) {
//           const { token, userId, role, user } = response.data;
//           LocalStorage.setAccessToken(token);
//           LocalStorage.setUserData({ token, userId, role, user });
//           LocalStorage.setCompanyData(response.data.user.company);
//           LocalStorage.setSessionFlag();
//           addSuccessMsg(`Successfully ${isSignUp ? 'registered' : 'logged in'}`);
//           navigate("/dashboard");
//           window.location.reload();
//         } else {
//           setLoading(false);
//           addErrorMsg(response.data.message);
//         }
//       } catch (error) {
//         setLoading(false);
//         addErrorMsg(error.message);
//       }
//     } else {
//       setError(errors);
//     }
//   };

//   const passwordVisible = () => {
//     setVisible(!isVisible);
//   };

//   const googleLogin = useGoogleLogin({
//     onSuccess: async (tokenResponse) => {
//       try {
//         const { code } = tokenResponse;
//         if (!code) {
//           throw new Error("Authorization code not received from Google");
//         }
//         const response = await CommonApi.googleLogin({ code });
//         if (response && response.token) {
//           const { token, userId, role, user } = response;
//           LocalStorage.setAccessToken(token);
//           LocalStorage.setUserData({ token, userId, role, user });
//           LocalStorage.setCompanyData(response.user.company);
//           LocalStorage.setSessionFlag();
//           addSuccessMsg(`Successfully logged in as ${role}`);
//           navigate("/dashboard");
//           window.location.reload();
//         } else {
//           addErrorMsg("Unexpected response from server.");
//         }
//       } catch (error) {
//         console.error("Google login failed:", error);
//         addErrorMsg("Google login failed. Try again.");
//       }
//     },
//     onError: () => {
//       addErrorMsg("Google login failed");
//     },
//     scope: "openid profile email",
//     flow: "auth-code",
//   });

//   return (
//     <div style={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#fff" }}>
//       <Grid className={classes.container} container>
//         {/* Left Side: Animation */}
//         <Grid item lg={8} md={7} sm={6} xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//           <img src={GifImage} alt="GIF" className={classes.gif} />
//         </Grid>

//         {/* Right Side: Login or Sign Up Form */}
//         <Grid item lg={4} md={5} sm={6} xs={12} className={classes.loginFormBox}>
//           <Box style={{ width: "100%", maxWidth: "400px" }}>
//             <img src={TechOptima} alt="Logo" style={{ width: "100px", height: "60px", marginBottom: "20px", display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />

//             <form onSubmit={handleSubmit}>
//               <Text variant="h5" className={classes.welText} style={{ marginBottom: "20px", textAlign: "center", fontWeight: "bold", fontSize: "22px" }}>
//                 {isSignUp ? "Create your Account" : "Welcome to Optima AI Hire!"}
//               </Text>
//               <Text className={classes.paraText} style={{ fontSize: "16px", width: '98%', paddingLeft: '18px' }}>
//                 Your smart hiring assistant, designed to streamline the recruitment process with precision and speed.
//               </Text>

//               {/* Form fields */}
//               <Grid container spacing={2} style={{ marginTop: '20px' }}>
//                 {isSignUp && (
//                   <Grid item xs={12}>
//                     <TextField required id="fullName" label="Full Name*" variant="standard" fullWidth name="fullName" onChange={changeHandler} className={classes.inputFields} />
//                   </Grid>
//                 )}
//                 <Grid item xs={12}>
//                   <TextField required id="email" label="Email Address*" variant="standard" fullWidth name="email" value={formData.email} onChange={changeHandler} error={!!error.email} helperText={error.email} className={classes.inputFields} />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField required id="password" label="Password*" variant="standard" fullWidth type={!isVisible ? "password" : "text"} name="password" onChange={changeHandler} value={formData.password} error={!!error.password} className={classes.inputFields} helperText={error.password} InputProps={{ endAdornment: (<Box component="span" onClick={passwordVisible} style={{ cursor: "pointer", marginRight: '8px' }}>{isVisible ? <img src={eye} alt="Visibility" style={{ height: '24px', width: '24px' }} /> : <img src={inVisible} alt="Not Visible" style={{ height: '24px', width: '24px' }} />}</Box>) }} />
//                 </Grid>
//                 {isSignUp && (
//                   <Grid item xs={12}>
//                     <TextField required id="mobileNumber" label="Mobile Number*" variant="standard" fullWidth name="mobileNumber" onChange={changeHandler} className={classes.inputFields} />
//                   </Grid>
//                 )}

//                 {/* New Select Box Below Mobile Number */}
//                 {isSignUp && (
//                   <Grid item xs={12}>
//                     <Select
//                       required
//                       name="userType"
//                       value={formData.userType}
//                       onChange={changeHandler}
//                       displayEmpty
//                       inputProps={{ 'aria-label': 'Without label' }}
//                       fullWidth
//                       style={{ width: '80%', margin: '0 auto', marginLeft: '28px' }}
//                     >
//                       <MenuItem value="" disabled>Select User Type</MenuItem>
//                       <MenuItem value="recruiter">Recruiter</MenuItem>
//                       <MenuItem value="candidate">Individual</MenuItem>
//                     </Select>
//                   </Grid>
//                 )}

//                 <Grid item xs={12} style={{ textAlign: 'right' }}>
//                   {!isSignUp && (
//                     <Link component="button" variant="body2" onClick={() => navigate("/forgot-password")} className={classes.forgotPassword}>
//                       Forgot Password?
//                     </Link>
//                   )}
//                 </Grid>
//               </Grid>

//               {/* Submit Button */}
//               <Grid item>
//                 <LoadingButton loading={loading} onClick={handleSubmit} variant="contained" type="submit" style={{ width: '80%', marginTop: '20px', height: '42px', borderRadius: '22px', backgroundColor: '#FF5200', marginLeft: "30px " }}>
//                   {isSignUp ? "Register now" : "Log In"}
//                 </LoadingButton>
//               </Grid>

//               {/* Alternative login methods */}
//               <Box mt={2} textAlign="center">
//                 <span>Or {isSignUp ? "register" : "login"} with</span>
//               </Box>

//               {/* Conditional rendering of login methods */}
//               {isSignUp && (
//                 <Box display="flex" justifyContent="center" flexDirection="row" mt={2}>
//                   <IconButton onClick={googleLogin} aria-label="Google Login" style={{ margin: '0 10px' }}>
//                     <GoogleIcon style={{ width: '24px', height: '24px' }} />
//                   </IconButton>
//                   <IconButton onClick={() => alert("Naukri Login functionality here")} aria-label="Naukri Login" style={{ margin: '0 10px' }}>
//                     <img src="/path/to/naukri/icon" alt="Naukri" style={{ width: '24px', height: '24px' }} />
//                   </IconButton>
//                   <IconButton onClick={() => alert("LinkedIn Login functionality here")} aria-label="LinkedIn Login" style={{ margin: '0 10px' }}>
//                     <LinkedInIcon style={{ width: '24px', height: '24px' }} />
//                   </IconButton>
//                 </Box>
//               )}

//               {/* Login buttons for login view */}
//               {!isSignUp && (
//                 <Box display="flex" justifyContent="space-between" flexDirection="column" height='100px' mt={2}>
//                   <Button variant="outlined" className={classes.otherBtns} onClick={googleLogin} startIcon={<GoogleIcon />} style={{ margin: '10px 0' }}>
//                     Google
//                   </Button>
//                   <Button variant="outlined" className={classes.otherBtns} onClick={handleMicrosoftLogin} startIcon={<MicrosoftIcon />} style={{ margin: '10px 0' }}>
//                     Microsoft
//                   </Button>
//                 </Box>
//               )}

//               {/* Toggle between Login and Sign Up */}
//               <Box mt={3} textAlign="center">
//                 <span>
//                   {isSignUp ? "Already have an account?" : "Don't have an account?"}{" "}
//                   <Link component="button" onClick={toggleForm}>
//                     {isSignUp ? "Log In" : "Sign Up now"}
//                   </Link>
//                 </span>
//               </Box>
//             </form>
//           </Box>
//         </Grid>
//       </Grid>
//     </div>
//   );
// }

// export default Login;
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { MyContext } from "../../Context";
import { Grid, Link, Box, Button, TextField, Divider, useMediaQuery, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { LoginStyles } from "./LoginStyles";
import Text from "../../components/customText/Text";
import { isValid, validate_emptyField } from "../../components/Validation";
import { addErrorMsg, addSuccessMsg } from "../../utils/utils";
import { LoadingButton } from "@mui/lab";
import eye from "../../assets/svg/eye.svg";
import inVisible from "../../assets/svg/NotVisible.svg";
import GoogleLogo from "../../assets/svg/google-logo.svg";
import FacebookLogo from "../../assets/svg/facebook-logo.svg";
import CommonApi from "../../apis/CommonApi";
import LocalStorage from "../../utils/LocalStorage";
import IllustrationImage from "../../assets/svg/illustration.png";
import Logo from "../../assets/svg/TO Logo.svg";

function Login() {
  const classes = LoginStyles();
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery("(min-width:900px)");
  const { setGlobaldata } = useContext(MyContext);
  const [isSignUp, setIsSignUp] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    fullName: "",
    mobileNumber: "",
    userType: "",
    workStatus: "",
  });
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    document.body.style.overflow = "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    handleValidate(e);
  };

  const handleValidate = (e) => {
    let input = e.target;
    let newError = { ...error };
    newError[input.name] = validate_emptyField(input.value);
    setError(newError);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   let errors = {
  //     email: validate_emptyField(formData.email),
  //     password: validate_emptyField(formData.password),
  //   };
  //   if (isSignUp) {
  //     errors.fullName = validate_emptyField(formData.fullName);
  //     errors.mobileNumber = validate_emptyField(formData.mobileNumber);
  //     errors.userType = validate_emptyField(formData.userType);
  //     if (formData.userType === "individual") {
  //       errors.workStatus = validate_emptyField(formData.workStatus);
  //     }
  //   }

  //   if (isValid(errors)) {
  //     setLoading(true);
  //     try {
  //       const response = isSignUp
  //         ? await CommonApi.registerIndividual(formData)
  //         : await CommonApi.loginIndividual(formData);
  
  //       if (response.status === 200 || response.status === 201) {
  //         const { token, userId, role, user } = response.data;
  //         LocalStorage.setAccessToken(token);
  //         LocalStorage.setUserData({ token, userId, role, user });
  //         addSuccessMsg(isSignUp ? "Successfully signed up" : "Successfully logged in");

  //         // Navigate based on userType after login/signup
  //         if (role === "individual") {
  //           navigate("/search"); // Redirect individual users to the search page
  //         } else {
  //           navigate("/dashboard"); // Redirect other users to the dashboard
  //         }
  //         window.location.reload();
  //       } else {
  //         setLoading(false);
  //         addErrorMsg(response.data.message);
  //       }
  //     } catch (error) {
  //       setLoading(false);
  //       addErrorMsg(error.message);
  //     }
  //   } else {
  //     setError(errors);
  //   }
  // };

  const handleSubmit = async (e) => {
  e.preventDefault();
  let errors = {
    email: validate_emptyField(formData.email),
    password: validate_emptyField(formData.password),
  };
  if (isSignUp) {
    errors.fullName = validate_emptyField(formData.fullName);
    errors.mobileNumber = validate_emptyField(formData.mobileNumber);
    errors.userType = validate_emptyField(formData.userType);
    if (formData.userType === "individual") {
      errors.workStatus = validate_emptyField(formData.workStatus);
    }
  }

  if (isValid(errors)) {
    setLoading(true);
    try {
      const response = isSignUp
        ? await CommonApi.registerIndividual(formData)
        : await CommonApi.loginIndividual(formData);

      if (response.status === 200 || response.status === 201) {
        const { token, userId, role, user } = response.data;
        LocalStorage.setAccessToken(token);
        LocalStorage.setUserData({ token, userId, role, user });
        addSuccessMsg(isSignUp ? "Successfully signed up" : "Successfully logged in");

        if (isSignUp) {
          // Redirect to login page after registration
          setIsSignUp(false);
          setLoading(false);  // Reset loading state
          navigate("/login");
        } else {
          // Redirect individual users to Search page upon login
          if (role === "individual") {
            navigate("/search");
          } else {
            navigate("/dashboard");
          }
          window.location.reload();
        }
      } else {
        setLoading(false);
        addErrorMsg(response.data.message);
      }
    } catch (error) {
      setLoading(false);
      addErrorMsg(error.message);
    }
  } else {
    setError(errors);
  }
};

  const passwordVisible = () => {
    setVisible(!isVisible);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const { code } = tokenResponse;
        const response = await CommonApi.googleLogin({ code });
        if (response && response.token) {
          const { token, userId, role, user } = response;
          LocalStorage.setAccessToken(token);
          LocalStorage.setUserData({ token, userId, role, user });
          addSuccessMsg("Successfully logged in as " + role);

          if (role === "individual") {
            navigate("/search"); // Redirect individual users to the search page
          } else {
            navigate("/dashboard"); // Redirect other users to the dashboard
          }
          window.location.reload();
        } else {
          addErrorMsg("Unexpected response from server.");
        }
      } catch (error) {
        addErrorMsg("Google login failed. Try again.");
      }
    },
    onError: () => {
      addErrorMsg("Google login failed");
    },
    scope: "openid profile email",
    flow: "auth-code",
  });

  return (
    <div className={classes.mainContainer}>
      <Box className={classes.innerContainer}>
        <Grid container>
          {isLargeScreen && (
            <Grid item lg={6} md={6} sm={12} xs={12} className={classes.leftContainer}>
              <Box className={classes.leftContent}>
                <Text variant="h3" className={classes.headerText}>
                  Simplify hiring process with Optima AI Hire.
                </Text>
                <Text className={classes.subText}>
                  Simplify your hiring process with our end-to-end solution.
                </Text>
                <img src={IllustrationImage} alt="Illustration" className={classes.illustration} />
              </Box>
            </Grid>
          )}
          <Grid item lg={6} md={6} sm={12} xs={12} className={classes.loginFormBox}>
            <Box style={{ width: "100%", maxWidth: "400px", textAlign: "center" }}>
              <img src={Logo} alt="Logo" className={classes.logo} />
              <form onSubmit={handleSubmit}>
                <Text variant="h5" className={classes.welText}>
                  {isSignUp ? "Create your account" : "Welcome Back"}
                </Text>
                <Text className={classes.paraText}>
                  {isSignUp ? "Sign up to start hiring smarter." : "Please login to your account"}
                </Text>

                <Grid container spacing={2} style={{ marginTop: "20px" }}>
                  {isSignUp && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          required
                          id="fullName"
                          label="Full Name"
                          fullWidth
                          name="fullName"
                          value={formData.fullName}
                          onChange={changeHandler}
                          error={!!error.fullName}
                          helperText={error.fullName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          id="mobileNumber"
                          label="Mobile Number"
                          fullWidth
                          name="mobileNumber"
                          value={formData.mobileNumber}
                          onChange={changeHandler}
                          error={!!error.mobileNumber}
                          helperText={error.mobileNumber}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel>User Type</InputLabel>
                          <Select
                            required
                            name="userType"
                            value={formData.userType}
                            onChange={changeHandler}
                          >
                            <MenuItem value="individual">Individual</MenuItem>
                            <MenuItem value="employer">Employer</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {formData.userType === "individual" && (
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel>Work Status</InputLabel>
                            <Select
                              required
                              name="workStatus"
                              value={formData.workStatus}
                              onChange={changeHandler}
                            >
                              <MenuItem value="Experienced">I'm experienced</MenuItem>
                              <MenuItem value="Fresher">I'm a fresher</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                    </>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="email"
                      label="Email Address"
                      fullWidth
                      name="email"
                      value={formData.email}
                      onChange={changeHandler}
                      error={!!error.email}
                      helperText={error.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      id="password"
                      label="Password"
                      fullWidth
                      type={!isVisible ? "password" : "text"}
                      name="password"
                      onChange={changeHandler}
                      value={formData.password}
                      error={!!error.password}
                      helperText={error.password}
                      InputProps={{
                        endAdornment: (
                          <Box component="span" onClick={passwordVisible} style={{ cursor: "pointer" }}>
                            {isVisible ? <img src={eye} alt="Visible" /> : <img src={inVisible} alt="Hidden" />}
                          </Box>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                <LoadingButton
                  loading={loading}
                  onClick={handleSubmit}
                  variant="contained"
                  type="submit"
                  className={classes.loginButton}
                  mt={2}
                >
                  {isSignUp ? "Sign Up" : "Login"}
                </LoadingButton>

                <Box mt={2} textAlign="center">
                  <Divider>Or {isSignUp ? "sign up" : "login"} with</Divider>
                </Box>

                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Button variant="outlined" className={classes.socialButton} startIcon={<img src={GoogleLogo} alt="Google" width="24" />} onClick={googleLogin}>
                    Google
                  </Button>
                  <Button variant="outlined" className={classes.socialButton} startIcon={<img src={FacebookLogo} alt="Facebook" width="24" />} onClick={() => alert("Microsoft login not implemented")}>
                    Microsoft
                  </Button>
                </Box>

                <Box mt={3} textAlign="center">
                  <span>
                    {isSignUp ? "Already have an account?" : "Don't have an account?"}{" "}
                    <Link component="button" onClick={() => setIsSignUp(!isSignUp)} className={classes.toggleText}>
                      {isSignUp ? "Log In" : "Sign Up"}
                    </Link>
                  </span>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Login;
