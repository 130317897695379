
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import EmployerHomePage from '../views/employer/SearchCandidate';

function EmployerRoutes() {
  return (
    <Routes>
      <Route path="/employer-homepage" element={<EmployerHomePage />} />
    </Routes>
  );
}

export default EmployerRoutes;
