import { makeStyles } from "@mui/styles";
import { grey, blue } from "@mui/material/colors";

const CompanyListStyles = makeStyles(() => ({
  listContainer: {
    borderRadius: "20px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
    backgroundColor: "#ffffff",
    padding: "16px",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.15)",
    },
  },
  listAvatar: {
    width: "50px",
    height: "50px",
    marginRight: "20px",
  },
  listDetails: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  companyName: {
    fontWeight: "bold",
    fontSize: "1.25rem",
    color: grey[900],
  },
  companyDetails: {
    fontSize: "1rem",
    color: grey[600],
  },
  listButton: {
    backgroundColor: blue[500],
    color: "#ffffff",
    textTransform: "none",
    borderRadius: "20px",
    padding: "5px 15px",
    "&:hover": {
      backgroundColor: blue[700],
    },
  },
}));

export default CompanyListStyles;
