import { makeStyles } from "@mui/styles";
import { blue } from "../../theme";
// import { addButton, saveButtonColor } from "../../../theme";

const ButtonStyles = makeStyles((theme) => ({
    mediumInvite: {
        height: '40px !important',
        background: `#FFFFFF !important`,
        color: `${blue} !important`,
        textTransform: 'capitalize !important',
        minWidth: '135px !important',
        borderRadius: '14px !important',
        border: `1px solid ${blue} !important`,
        fontSize: "13px !important",
        fontFamily: "Nunito Sans, sans-serif",
        fontWeight: "400",
        width: "150px",
        '&:hover': {
            background: `${blue} !important`,
            color: '#FFFFFF !important'
        },
    },
    onboardBtn: {
        height: '40px !important',
        // background: `${blue} !important`,
        color: `${blue} !important`,
        border: `1px solid ${blue} !important`,
        textTransform: 'capitalize !important',
        width: '120px',
        borderRadius: '12px !important',
        fontSize: "13px !important",
        fontFamily: "Nunito Sans, sans-serif",
        fontWeight: "400",
        '&:hover': {
            background: `${blue} !important`,
            color: '#FFFFFF !important'
        },
    },
    // brownMnRedCancel: {
    //     font: "12px Poppins !important",
    //     color: ` ${red} !important`,
    //     textTransform: "none !important",
    //     border: `1px solid ${red} !important`,
    //     borderRadius: "8px !important",
    //     minWidth: "85px !important",
    //     height: "32px !important",
    //     variant: "outlined",
    //     "&:hover": {
    //         border: '1px solid #404040 !important',
    //         color: '#404040 !important',
    //         transform: "scale3d(1.05, 1.05, 1)",
    //     }
    // },
    // brownMnSave: {
    //     font: "12px Poppins !important",
    //     background: ` ${blue} !important`,
    //     color: '#FFFFFF !important',
    //     textTransform: "none !important",
    //     borderRadius: "8px !important",
    //     minWidth: "85px !important",
    //     height: "32px !important",
    //     variant: "outlined",
    //     "&:hover": {
    //         border: '1px solid #404040 !important',
    //         background: '#FFFFFF !important',
    //         color: '#404040 !important',
    //         transform: "scale3d(1.05, 1.05, 1)",
    //     }
    // },
    brownMnMediumSave: {
        font: "16px Nunito Sans, sans-serif !important",
        background: ` ${blue} !important`,
        color: '#FFFFFF !important',
        textTransform: "none !important",
        borderRadius: "8px !important",
        minWidth: "150px !important",
        height: "45px !important",
        variant: "outlined",
        "&:hover": {
            border: '1px solid #404040 !important',
            background: '#FFFFFF !important',
            color: '#404040 !important',
            transform: "scale3d(1.05, 1.05, 1)",
        }
    }

}));

export default ButtonStyles;

