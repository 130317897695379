import React, { useState, useEffect } from 'react';
import {
  Box, Button, Grid, Paper, TextField, Typography, Select, MenuItem, InputLabel, FormControl, Chip
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CommonApi from '../../../apis/CommonApi';
import LocalStorage from '../../../utils/LocalStorage';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  },
  form: {
    width: '100%',
    paddingLeft: "13% !important"
  },
  submitButton: {
    backgroundColor: '#1a1a1a !important',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#333333 !important',
    },
  },
  cancelButton: {
    color: '#1a1a1a',
    marginRight: '10px',
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  uploadChip: {
    marginLeft: '10px',
  },
  textField: {
    marginBottom: '10px',
  },
  selectField: {
    marginBottom: '10px',
  },
}));

const sizeOptions = [
  "1-10",
  "11-50",
  "51-200",
  "201-500",
  "501-1000",
  "1001-5000",
  "5001-10000",
  "10000+"
];

const industryOptions = [
  "Technology",
  "Finance",
  "Healthcare",
  "Education",
  "Retail",
  "Manufacturing",
  "Real Estate",
  "Transportation",
  "Energy",
  "Telecommunications",
  "Hospitality",
  "Other"
];

const AddCompanyForm = ({ onClose }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    email: '',
    phone: '',
    industry: '',
    size: '',
    url: '',
    careerPage: '',
    country: '',
    state: '',
    city: '',
    companySummary: '',
    openPositions: '',
    contactRecruiter: '',
    posts: '',
    logo: null
  });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const countries = await CommonApi.getCountries();
        setCountries(countries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  const handleCountryChange = async (e) => {
    const country = e.target.value;
    setFormData({
      ...formData,
      country,
      state: '' // Reset state when country changes
    });
    try {
      const states = await CommonApi.getStates(country);
      setStates(states);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'logo') {
      setFormData({
        ...formData,
        logo: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleRemoveLogo = () => {
    setFormData({ ...formData, logo: null });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = LocalStorage.getAccessToken();
    const data = new FormData();
    data.append('name', formData.name);
    data.append('companyName', formData.companyName);
    data.append('contact[email]', formData.email);
    data.append('contact[phone]', formData.phone);
    data.append('industry', formData.industry);
    data.append('size', formData.size);
    data.append('url', formData.url);
    data.append('careerPage', formData.careerPage);
    data.append('country', formData.country);
    data.append('state', formData.state);
    data.append('city', formData.city);
    data.append('companySummary', formData.companySummary);
    data.append('recruitmentDetails[openPositions]', formData.openPositions);
    data.append('recruitmentDetails[contactRecruiter]', formData.contactRecruiter);
    data.append('posts', formData.posts);
    if (formData.logo) {
      data.append('imageFiles', formData.logo);
    }

    try {
      const response = await CommonApi.createCompany(data, token);
      console.log('Company created successfully:', response.data);
      onClose();
    } catch (error) {
      console.error('Error creating company:', error);
    }
  };

  return (
    <Box>
      {/* <Typography component="h1" variant="h5" gutterBottom>
        Create Company
      </Typography> */}
      <form className={classes.form} noValidate onSubmit={handleSubmit} encType="multipart/form-data">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="name"
              variant="outlined"
              required
              fullWidth
              id="name"
              label="Name"
              value={formData.name}
              onChange={handleChange}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="companyName"
              variant="outlined"
              required
              fullWidth
              id="companyName"
              label="Company Name"
              value={formData.companyName}
              onChange={handleChange}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="email"
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email"
              value={formData.email}
              onChange={handleChange}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="phone"
              variant="outlined"
              required
              fullWidth
              id="phone"
              label="Phone"
              value={formData.phone}
              onChange={handleChange}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth required className={classes.selectField}>
              <InputLabel id="industry-label">Industry</InputLabel>
              <Select
                labelId="industry-label"
                id="industry"
                name="industry"
                value={formData.industry}
                onChange={handleChange}
                label="Industry"
              >
                {industryOptions.map((industry) => (
                  <MenuItem key={industry} value={industry}>
                    {industry}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth required className={classes.selectField}>
              <InputLabel id="size-label">Size</InputLabel>
              <Select
                labelId="size-label"
                id="size"
                name="size"
                value={formData.size}
                onChange={handleChange}
                label="Size"
              >
                {sizeOptions.map((size) => (
                  <MenuItem key={size} value={size}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth required className={classes.selectField}>
              <InputLabel id="country-label">Country</InputLabel>
              <Select
                labelId="country-label"
                id="country"
                name="country"
                value={formData.country}
                onChange={handleCountryChange}
                label="Country"
              >
                {countries.map((country) => (
                  <MenuItem key={country.isoCode} value={country.isoCode}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth required className={classes.selectField}>
              <InputLabel id="state-label">State</InputLabel>
              <Select
                labelId="state-label"
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
                label="State"
                disabled={!formData.country}
              >
                {states.map((state) => (
                  <MenuItem key={state.isoCode} value={state.isoCode}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="city"
              variant="outlined"
              required
              fullWidth
              id="city"
              label="City"
              value={formData.city}
              onChange={handleChange}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="url"
              variant="outlined"
              required
              fullWidth
              id="url"
              label="URL"
              value={formData.url}
              onChange={handleChange}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="careerPage"
              variant="outlined"
              required
              fullWidth
              id="careerPage"
              label="Career Page"
              value={formData.careerPage}
              onChange={handleChange}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="companySummary"
              variant="outlined"
              required
              fullWidth
              multiline
              rows={4}
              id="companySummary"
              label="Company Summary"
              value={formData.companySummary}
              onChange={handleChange}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="openPositions"
              variant="outlined"
              required
              fullWidth
              id="openPositions"
              label="Open Positions (comma-separated)"
              value={formData.openPositions}
              onChange={handleChange}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              name="contactRecruiter"
              variant="outlined"
              required
              fullWidth
              id="contactRecruiter"
              label="Contact Recruiter"
              value={formData.contactRecruiter}
              onChange={handleChange}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="posts"
              variant="outlined"
              required
              fullWidth
              multiline
              rows={4}
              id="posts"
              label="Posts"
              value={formData.posts}
              onChange={handleChange}
              className={classes.textField}
            />
          </Grid>
          <Grid item xs={12} className={classes.uploadButton}>
            <input
              accept="image/*"
              className={classes.input}
              style={{ display: 'none' }}
              id="logo"
              name="logo"
              type="file"
              onChange={handleChange}
            />
            <label htmlFor="logo">
              <Button
                variant="contained"
                component="span"
                startIcon={<PhotoCamera />}
                className={classes.submitButton}
              >
                Upload Logo
              </Button>
            </label>
            {formData.logo && (
              <Chip
                label={formData.logo.name}
                onDelete={handleRemoveLogo}
                className={classes.uploadChip}
              />
            )}
          </Grid>
        </Grid>
        <Box mt={3} display="flex" justifyContent="flex-end">
          <Button
            onClick={onClose}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            className={classes.submitButton}
          >
            Create Company
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddCompanyForm;
