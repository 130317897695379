// import React, { useState } from "react";
// import {
//   Box,
//   Button,
//   Typography,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Grid,
//   Chip,
//   CircularProgress,
//   Checkbox,
//   Card,
//   CardContent,
//   CardActionArea,
// } from "@mui/material";
// import { useDropzone } from "react-dropzone"
// import AddJobDescription from "./AddJobDescription";
// import CommonApi from "../../../apis/CommonApi";
// import LocalStorage from "../../../utils/LocalStorage";
// import ScheduleInterviewModal from "./ScheduleInterviewModal";
// import { useNavigate } from "react-router-dom";
// import VerticalProgressBar from "../../../components/VerticalProgressBar";

// const JobDescriptionStepper = () => {
//   const [activeStep, setActiveStep] = useState(0);
//   const [jobDescriptionData, setJobDescriptionData] = useState({});
//   const [jobDescriptionId, setJobDescriptionId] = useState(null);
//   const [uploadedFiles, setUploadedFiles] = useState([]);
//   const [parsedResumes, setParsedResumes] = useState([]);
//   const [selectedResumes, setSelectedResumes] = useState([]);
//   const [selectAll, setSelectAll] = useState(false);
//   const [error, setError] = useState(null);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [selectedEmail, setSelectedEmail] = useState("");
//   const [selectedActions, setSelectedActions] = useState({});
//   const [isScheduleModalOpen, setScheduleModalOpen] = useState(false);
//   const [showProgress, setShowProgress] = useState(true); // New state variable
//   const navigate = useNavigate();

//   const steps = ["Create Job Description", "Upload Files & Review", "Parsed Resumes"];

//   const handleNext = async (data) => {
//     if (activeStep === 0) {
//       if (!data) {
//         console.error("No data provided to create job.");
//         setError("No data provided for job creation.");
//         return;
//       }

//       try {
//         const token = LocalStorage.getAccessToken();
//         const response = await CommonApi.createJob(data, token);
//         console.log("Response from createJob API:", response); // Log response
//         setJobDescriptionId(response._id);
//         setJobDescriptionData(data);
//         setActiveStep((prevActiveStep) => prevActiveStep + 1);
//       } catch (error) {
//         console.error("Error creating job description:", error);
//         setError('Error creating job description: ' + error.message);
//       }
//     } else if (activeStep === 1) {
//       await handleParseResumes();
//     } else if (activeStep === 2) {
//       await handleFinish();
//     }
//   };

//   const handleFinish = async () => {
//     try {
//       await handleUpdateJobWithCandidates();
//       setShowProgress(false); // Hide the progress line
//     } catch (error) {
//       setError('Error finishing job setup: ' + error.message);
//     }
//   };

//   const handleUpdateJobWithCandidates = async () => {
//     try {
//       const token = LocalStorage.getAccessToken();
//       const updateData = {
//         candidates: parsedResumes.map(resume => ({
//           name: resume.parsedData.name,
//           email: resume.parsedData.email,
//           phone: resume.parsedData.phone,
//           matchPercentage: resume.recommendation.matchPercentage
//         }))
//       };

//       const response = await CommonApi.addCandidates(jobDescriptionId, updateData, token);
//       console.log("Job updated with candidates:", response);
//       setActiveStep((prevActiveStep) => prevActiveStep + 1);
//     } catch (error) {
//       setError('Error updating job with candidates: ' + error.message);
//     }
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

//   const handleParseResumes = async () => {
//     try {
//       const user = LocalStorage.getUserData();
//       const token = LocalStorage.getAccessToken();
//       const formData = new FormData();
//       uploadedFiles.forEach((file) => formData.append("resumes", file));
//       formData.append("jobDescriptionId", jobDescriptionId);
//       formData.append('candidateProfileId', user.candidateProfileId);

//       console.log('user.user.candidateProfileId', user.candidateProfileId)
//       console.log('user111', user)

//       // Log the form data being sent
//       console.log("Sending form data:", formData);

//       const response = await CommonApi.parseResume(formData, token);

//       if (response && response.results) {
//         setParsedResumes(response.results);
//         setActiveStep((prevActiveStep) => prevActiveStep + 1);
//       } else {
//         throw new Error("Invalid response from the server. Please check the server logs.");
//       }
//     } catch (error) {
//       console.error("Error parsing resumes:", error);
//       setError("Error parsing resumes: " + (error.response?.data?.error || error.message));
//     }
//   };

//   const onDrop = (acceptedFiles) => {
//     setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
//   };

//   const handleRemoveFile = (fileName) => {
//     setUploadedFiles(uploadedFiles.filter((file) => file.name !== fileName));
//   };

//   const handleSendEmail = async (email, name, jobTitle, matchPercentage) => {
//     try {
//       const emailData = { email, name, jobTitle, matchPercentage };
//       const token = LocalStorage.getAccessToken();
//       await CommonApi.sendShortlistedEmail(emailData, token);
//       alert(`Shortlist email sent to ${email}`);
//     } catch (error) {
//       alert("Failed to send email");
//     }
//   };

//   const handleSendEmailToSelected = async () => {
//     try {
//       const token = LocalStorage.getAccessToken();
//       for (const index of selectedResumes) {
//         const resume = parsedResumes[index];
//         const emailData = {
//           email: resume.parsedData.email,
//           name: resume.parsedData.name,
//           jobTitle: resume.recommendation.jobTitle,
//           matchPercentage: resume.recommendation.matchPercentage,
//         };
//         await CommonApi.sendShortlistedEmail(emailData, token);
//       }
//       alert("Shortlist emails sent to selected resumes.");
//     } catch (error) {
//       alert("Failed to send emails to selected resumes");
//     }
//   };

//   const handleMenuClick = (event, email) => {
//     setAnchorEl(event.currentTarget);
//     setSelectedEmail(email);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//     setSelectedEmail("");
//   };

//   const handleMenuSelect = async (option, resume) => {
//     handleMenuClose();
//     const { email, name } = resume;
//     const jobTitle = resume.recommendation?.jobTitle || "N/A";
//     const matchPercentage = resume.recommendation?.matchPercentage || "N/A";

//     if (option === "Send Email") {
//       await handleSendEmail(email, name, jobTitle, matchPercentage);
//     } else if (option === "Schedule An Interview") {
//       setScheduleModalOpen(true);
//     }
//     setSelectedActions((prev) => ({
//       ...prev,
//       [email]: option,
//     }));
//   };

//   const handleCheckboxChange = (index) => {
//     const newSelectedResumes = [...selectedResumes];
//     if (newSelectedResumes.includes(index)) {
//       const indexToRemove = newSelectedResumes.indexOf(index);
//       newSelectedResumes.splice(indexToRemove, 1);
//     } else {
//       newSelectedResumes.push(index);
//     }
//     setSelectedResumes(newSelectedResumes);
//     setSelectAll(newSelectedResumes.length === parsedResumes.length);
//   };

//   const handleSelectAllChange = (event) => {
//     setSelectAll(event.target.checked);
//     if (event.target.checked) {
//       setSelectedResumes(parsedResumes.map((_, index) => index));
//     } else {
//       setSelectedResumes([]);
//     }
//   };

//   const renderRecommendationProgress = (value) => {
//     const percentage = Math.round(value);
//     return (
//       <Box position="relative" display="inline-flex">
//         <CircularProgress
//           variant="determinate"
//           value={100}
//           size={40}
//           thickness={5}
//           sx={{ color: "#e0e0e0" }}
//         />
//         <CircularProgress
//           variant="determinate"
//           value={percentage}
//           size={40}
//           thickness={5}
//           sx={{
//             position: "absolute",
//             left: 0,
//             strokeLinecap: "round",
//             color:
//               percentage >= 75 ? "green" : percentage >= 50 ? "orange" : "red",
//           }}
//         />
//         <Box
//           top={0}
//           left={0}
//           bottom={0}
//           right={0}
//           position="absolute"
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//         >
//           <Typography
//             variant="caption"
//             component="div"
//             color="textSecondary"
//             fontSize="12px"
//             fontWeight="bold"
//           >
//             {`${percentage}%`}
//           </Typography>
//         </Box>
//       </Box>
//     );
//   };

//   const handleCardClick = () => {
//     navigate(`/jobdetails/${jobDescriptionId}`);
//   };

//   const handleCreateNewJob = () => {
//     setActiveStep(0);
//     setJobDescriptionData({});
//     setJobDescriptionId(null);
//     setUploadedFiles([]);
//     setParsedResumes([]);
//     setSelectedResumes([]);
//     setError(null);
//     setAnchorEl(null);
//     setSelectedEmail("");
//     setSelectedActions({});
//     setScheduleModalOpen(false);
//     setShowProgress(true); // Reset the progress bar visibility
//   };

//   const handleScheduleSave = async (interviewDetails) => {
//     try {
//       const interviewDate = new Date(interviewDetails.interviewDate);
//       if (isNaN(interviewDate.getTime())) {
//         throw new Error("Invalid date format");
//       }

//       interviewDetails.interviewDate = interviewDate.toISOString();
//       await CommonApi.scheduleInterview(interviewDetails);
//       alert(`Interview scheduled for ${interviewDetails.candidateEmail}`);
//     } catch (error) {
//       alert("Failed to schedule interview");
//     }
//   };

//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop,
//     maxSize: 1024 * 1024 * 5,
//   });

//   const options = [
//     "Send Email",
//     "Schedule An Interview",
//     "Shortlist to Another Job",
//     "Insufficient Information",
//     "Source Change Request",
//     "Reject",
//     "Recruiter Reject",
//     "Request Documents",
//   ];

//   return (
//     <Box
//       sx={{
//         width: "100%", // Add left padding here
//         paddingLeft: "0px",
//         // padding: "50px 50px 66px 50px",
//         minHeight: "9.5vh !important",
//       }}
//     >
//       <Grid container spacing={2}>
//         <Grid item xs={3}>
//           {showProgress && <VerticalProgressBar sx={{ position: "fixed", marginLeft: "100px" }} steps={steps} currentStep={activeStep} />}
//         </Grid>
//         <Grid item xs={9}>
//           {activeStep === steps.length ? (
//             <>
//               <Typography variant="h5" gutterBottom>
//                 Job Description Created Successfully!
//               </Typography>
//               <Card sx={{ minWidth: 275, mb: 2 }}>
//                 <CardActionArea onClick={handleCardClick}>
//                   <CardContent>
//                     <Typography variant="h5" component="div">
//                       {jobDescriptionData.roleName}
//                     </Typography>
//                     <Typography sx={{ mb: 1.5 }} color="text.secondary">
//                       {jobDescriptionData.address?.city}, {jobDescriptionData.address?.state}, {jobDescriptionData.address?.country}
//                     </Typography>
//                     <Typography variant="body2">
//                       Number of Profiles: {parsedResumes.length}
//                     </Typography>
//                   </CardContent>
//                 </CardActionArea>
//               </Card>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={handleCreateNewJob}
//               >
//                 Create New Job
//               </Button>
//             </>
//           ) : (
//             <Box
//               sx={{
//                 overflowY: "auto",
//                 maxHeight: "75vh",
//                 backgroundColor: "white",
//                 padding: "30px",
//                 boxShadow: "0px 4px 8px #abc4ff !important",
//                 borderRadius: "8px",
//               }}
//             >
//               {activeStep === 0 ? (
//                 <AddJobDescription
//                   jobDescriptionData={jobDescriptionData}
//                   handleNext={handleNext}
//                 />
//               ) : activeStep === 1 ? (
//                 <Box>
//                   <Box
//                     {...getRootProps()}
//                     border="1px dashed grey"
//                     p={3}
//                     mb={2}
//                     textAlign="center"
//                   >
//                     <input {...getInputProps()} />
//                     <Typography>
//                       Drag 'n' drop some files here, or click to select files
//                     </Typography>
//                   </Box>
//                   <Grid container spacing={2}>
//                     {uploadedFiles.map((file, index) => (
//                       <Grid item key={index}>
//                         <Chip
//                           label={file.name}
//                           onDelete={() => handleRemoveFile(file.name)}
//                         />
//                       </Grid>
//                     ))}
//                   </Grid>
//                   <Box mt={2}>
//                     <Button
//                       onClick={handleBack}
//                       color="primary"
//                       variant="contained"
//                       style={{ marginRight: "10px" }}
//                     >
//                       Back
//                     </Button>
//                     <Button
//                       onClick={() => handleNext()}
//                       color="primary"
//                       variant="contained"
//                     >
//                       Next
//                     </Button>
//                   </Box>
//                 </Box>
//               ) : (
//                 <Box>
//                   <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
//                     <Typography
//                       variant="h6"
//                       gutterBottom
//                       sx={{ fontSize: "20px", fontWeight: "bold" }}
//                     >
//                       Parsed Resumes{" "}
//                       <span style={{ color: "grey" }}>
//                         ({parsedResumes.length})
//                       </span>
//                     </Typography>
//                     {selectedResumes.length > 0 && (
//                       <Button
//                         variant="contained"
//                         color="secondary"
//                         sx={{ fontSize: "14px" }}
//                         onClick={handleSendEmailToSelected}
//                       >
//                         Selected Resumes: {selectedResumes.length}
//                       </Button>
//                     )}
//                   </Box>
//                   {error ? (
//                     <Typography color="error">{error}</Typography>
//                   ) : (
//                     <TableContainer component={Paper}>
//                       <Table>
//                         <TableHead>
//                           <TableRow>
//                             <TableCell>
//                               <Checkbox
//                                 checked={selectAll}
//                                 onChange={handleSelectAllChange}
//                                 inputProps={{
//                                   "aria-label": "select all resumes",
//                                 }}
//                               />
//                             </TableCell>
//                             <TableCell
//                               sx={{ fontSize: "14px", fontWeight: "bold" }}
//                             >
//                               Name
//                             </TableCell>
//                             <TableCell
//                               sx={{ fontSize: "14px", fontWeight: "bold" }}
//                             >
//                               Email
//                             </TableCell>
//                             <TableCell
//                               sx={{ fontSize: "14px", fontWeight: "bold" }}
//                             >
//                               Phone
//                             </TableCell>
//                             <TableCell
//                               sx={{ fontSize: "14px", fontWeight: "bold" }}
//                             >
//                               Date
//                             </TableCell>
//                             <TableCell
//                               sx={{
//                                 fontSize: "14px",
//                                 fontWeight: "bold",
//                                 textAlign: "center",
//                               }}
//                             >
//                               Recommendation
//                             </TableCell>
//                             <TableCell
//                               sx={{ fontSize: "14px", fontWeight: "bold" }}
//                             >
//                               Action
//                             </TableCell>
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {parsedResumes.length > 0 ? (
//                             parsedResumes.map((resume, index) => (
//                               <TableRow key={index}>
//                                 <TableCell>
//                                   <Checkbox
//                                     checked={selectedResumes.includes(index)}
//                                     onChange={() => handleCheckboxChange(index)}
//                                   />
//                                 </TableCell>
//                                 <TableCell sx={{ fontSize: "14px" }}>
//                                   {resume.parsedData?.name || "N/A"}
//                                 </TableCell>
//                                 <TableCell sx={{ fontSize: "14px" }}>
//                                   {resume.parsedData?.email || "N/A"}
//                                 </TableCell>
//                                 <TableCell sx={{ fontSize: "14px" }}>
//                                   {resume.parsedData?.phone || "N/A"}
//                                 </TableCell>
//                                 <TableCell sx={{ fontSize: "14px" }}>
//                                   {new Date().toLocaleDateString()}
//                                 </TableCell>
//                                 <TableCell sx={{ textAlign: "center" }}>
//                                   {resume.recommendation?.matchPercentage
//                                     ? renderRecommendationProgress(
//                                         resume.recommendation.matchPercentage
//                                       )
//                                     : "N/A"}
//                                 </TableCell>
//                                 <TableCell>
//                                   <Button
//                                     variant="contained"
//                                     color="primary"
//                                     onClick={() =>
//                                       handleSendEmail(
//                                         resume.parsedData?.email || ""
//                                       )
//                                     }
//                                     sx={{
//                                       minWidth: "120px",
//                                       whiteSpace: "nowrap",
//                                       fontSize: "14px",
//                                       fontWeight: "bold",
//                                       backgroundColor: "#1976d2",
//                                       "&:hover": {
//                                         backgroundColor: "#1565c0",
//                                       },
//                                     }}
//                                   >
//                                     Send Email
//                                   </Button>
//                                 </TableCell>
//                               </TableRow>
//                             ))
//                           ) : (
//                             <TableRow>
//                               <TableCell colSpan={7} align="center">
//                                 No resumes parsed.
//                               </TableCell>
//                             </TableRow>
//                           )}
//                         </TableBody>
//                       </Table>
//                     </TableContainer>
//                   )}
//                   <Box mt={2}>
//                     <Button
//                       onClick={handleBack}
//                       color="primary"
//                       variant="contained"
//                       style={{ marginRight: "10px" }}
//                     >
//                       Back
//                     </Button>
//                     <Button
//                       onClick={handleFinish}
//                       color="primary"
//                       variant="contained"
//                       style={{ fontSize: "14px" }}
//                     >
//                       Finish
//                     </Button>
//                   </Box>
//                 </Box>
//               )}
//             </Box>
//           )}
//         </Grid>
//       </Grid>
//       <Box
//         sx={{
//           width: "100%",
//           // padding: "50px 50px 66px 50px",
//           // minHeight: "90.5vh",
//         }}
//       >
//         <ScheduleInterviewModal
//           open={isScheduleModalOpen}
//           onClose={() => setScheduleModalOpen(false)}
//           onSave={handleScheduleSave}
//           jobId={jobDescriptionId}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default JobDescriptionStepper;

////
// import React, { useState } from "react";

// import {
//   Box,
//   Button,
//   Typography,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Grid,
//   Chip,
//   CircularProgress,
//   Checkbox,
//   Card,
//   CardContent,
//   CardActionArea,
// } from "@mui/material";

// import { useDropzone } from "react-dropzone";

// import AddJobDescription from "./AddJobDescription";

// import CommonApi from "../../../apis/CommonApi";

// import LocalStorage from "../../../utils/LocalStorage";

// import ScheduleInterviewModal from "./ScheduleInterviewModal";

// import { useNavigate } from "react-router-dom";

// import VerticalProgressBar from "../../../components/VerticalProgressBar";

// const JobDescriptionStepper = () => {
//   const [activeStep, setActiveStep] = useState(0);

//   const [jobDescriptionData, setJobDescriptionData] = useState({});

//   const [jobDescriptionId, setJobDescriptionId] = useState(null);

//   const [uploadedFiles, setUploadedFiles] = useState([]);

//   const [parsedResumes, setParsedResumes] = useState([]);

//   const [selectedResumes, setSelectedResumes] = useState([]);

//   const [selectAll, setSelectAll] = useState(false);

//   const [error, setError] = useState(null);

//   const [anchorEl, setAnchorEl] = useState(null);

//   const [selectedEmail, setSelectedEmail] = useState("");

//   const [selectedActions, setSelectedActions] = useState({});

//   const [isScheduleModalOpen, setScheduleModalOpen] = useState(false);

//   const [showProgress, setShowProgress] = useState(true); // New state variable

//   const navigate = useNavigate();

//   const steps = [
//     "Create Job Description",
//     "Upload Files & Review",
//     "Parsed Resumes",
//   ];

//   const handleNext = async (data) => {
//     if (activeStep === 0) {
//       if (!data) {
//         console.error("No data provided to create job.");

//         setError("No data provided for job creation.");

//         return;
//       }

//       try {
//         const token = LocalStorage.getAccessToken();

//         const response = await CommonApi.createJob(data, token);

//         console.log("Response from createJob API:", response); // Log response

//         setJobDescriptionId(response._id);

//         setJobDescriptionData(data);

//         setActiveStep((prevActiveStep) => prevActiveStep + 1);
//       } catch (error) {
//         console.error("Error creating job description:", error);

//         setError("Error creating job description: " + error.message);
//       }
//     } else if (activeStep === 1) {
//       await handleParseResumes();
//     } else if (activeStep === 2) {
//       await handleFinish();
//     }
//   };

//   const handleFinish = async () => {
//     try {
//       await handleUpdateJobWithCandidates();

//       setShowProgress(false); // Hide the progress line
//     } catch (error) {
//       setError("Error finishing job setup: " + error.message);
//     }
//   };

//   const handleUpdateJobWithCandidates = async () => {
//     try {
//       const token = LocalStorage.getAccessToken();

//       const updateData = {
//         candidates: parsedResumes.map((resume) => ({
//           name: resume.parsedData.name,

//           email: resume.parsedData.email,

//           phone: resume.parsedData.phone,

//           matchPercentage: resume.recommendation.matchPercentage,
//         })),
//       };

//       const response = await CommonApi.addCandidates(
//         jobDescriptionId,
//         updateData,
//         token
//       );

//       console.log("Job updated with candidates:", response);

//       setActiveStep((prevActiveStep) => prevActiveStep + 1);
//     } catch (error) {
//       setError("Error updating job with candidates: " + error.message);
//     }
//   };

//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

//   const updateJobApplicationCount = async (parsedResumesCount) => {
//     try {
//       const token = LocalStorage.getAccessToken();

//       const updatedData = { application: parsedResumesCount };

//       await CommonApi.updateJob(jobDescriptionId, updatedData, token);

//       console.log(`Job application count updated to ${parsedResumesCount}`);
//     } catch (error) {
//       console.error("Error updating job application count:", error);
//     }
//   };

//   const handleParseResumes = async () => {
//     try {
//       const user = LocalStorage.getUserData();

//       const token = LocalStorage.getAccessToken();

//       const formData = new FormData();

//       uploadedFiles.forEach((file) => formData.append("resumes", file));

//       formData.append("jobDescriptionId", jobDescriptionId);

//       formData.append("candidateProfileId", user.candidateProfileId);

//       console.log("user.user.candidateProfileId", user.candidateProfileId);

//       console.log("user111", user);

//       const response = await CommonApi.parseResume(formData, token);

//       if (response && response.results) {
//         setParsedResumes(response.results);

//         setActiveStep((prevActiveStep) => prevActiveStep + 1);

//         await updateJobApplicationCount(response.results.length); // Update the application count
//       } else {
//         throw new Error(
//           "Invalid response from the server. Please check the server logs."
//         );
//       }
//     } catch (error) {
//       console.error("Error parsing resumes:", error);

//       setError(
//         "Error parsing resumes: " +
//           (error.response?.data?.error || error.message)
//       );
//     }
//   };

//   const onDrop = (acceptedFiles) => {
//     setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
//   };

//   const handleRemoveFile = (fileName) => {
//     setUploadedFiles(uploadedFiles.filter((file) => file.name !== fileName));
//   };

//   const handleSendEmail = async (email, name, jobTitle, matchPercentage) => {
//     try {
//       const emailData = { email, name, jobTitle, matchPercentage };

//       const token = LocalStorage.getAccessToken();

//       await CommonApi.sendShortlistedEmail(emailData, token);

//       alert(`Shortlist email sent to ${email}`);
//     } catch (error) {
//       alert("Failed to send email");
//     }
//   };

//   const handleSendEmailToSelected = async () => {
//     try {
//       const token = LocalStorage.getAccessToken();

//       for (const index of selectedResumes) {
//         const resume = parsedResumes[index];

//         const emailData = {
//           email: resume.parsedData.email,

//           name: resume.parsedData.name,

//           jobTitle: resume.recommendation.jobTitle,

//           matchPercentage: resume.recommendation.matchPercentage,
//         };

//         await CommonApi.sendShortlistedEmail(emailData, token);
//       }

//       alert("Shortlist emails sent to selected resumes.");
//     } catch (error) {
//       alert("Failed to send emails to selected resumes");
//     }
//   };

//   const handleMenuClick = (event, email) => {
//     setAnchorEl(event.currentTarget);

//     setSelectedEmail(email);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);

//     setSelectedEmail("");
//   };

//   const handleMenuSelect = async (option, resume) => {
//     handleMenuClose();

//     const { email, name } = resume;

//     const jobTitle = resume.recommendation?.jobTitle || "N/A";

//     const matchPercentage = resume.recommendation?.matchPercentage || "N/A";

//     if (option === "Send Email") {
//       await handleSendEmail(email, name, jobTitle, matchPercentage);
//     } else if (option === "Schedule An Interview") {
//       setScheduleModalOpen(true);
//     }

//     setSelectedActions((prev) => ({
//       ...prev,

//       [email]: option,
//     }));
//   };

//   const handleCheckboxChange = (index) => {
//     const newSelectedResumes = [...selectedResumes];

//     if (newSelectedResumes.includes(index)) {
//       const indexToRemove = newSelectedResumes.indexOf(index);

//       newSelectedResumes.splice(indexToRemove, 1);
//     } else {
//       newSelectedResumes.push(index);
//     }

//     setSelectedResumes(newSelectedResumes);

//     setSelectAll(newSelectedResumes.length === parsedResumes.length);
//   };

//   const handleSelectAllChange = (event) => {
//     setSelectAll(event.target.checked);

//     if (event.target.checked) {
//       setSelectedResumes(parsedResumes.map((_, index) => index));
//     } else {
//       setSelectedResumes([]);
//     }
//   };

//   const renderRecommendationProgress = (value) => {
//     const percentage = Math.round(value);

//     return (
//       <Box position="relative" display="inline-flex">
//         <CircularProgress
//           variant="determinate"
//           value={100}
//           size={40}
//           thickness={5}
//           sx={{ color: "#e0e0e0" }}
//         />

//         <CircularProgress
//           variant="determinate"
//           value={percentage}
//           size={40}
//           thickness={5}
//           sx={{
//             position: "absolute",

//             left: 0,

//             strokeLinecap: "round",

//             color:
//               percentage >= 75 ? "green" : percentage >= 50 ? "orange" : "red",
//           }}
//         />

//         <Box
//           top={0}
//           left={0}
//           bottom={0}
//           right={0}
//           position="absolute"
//           display="flex"
//           alignItems="center"
//           justifyContent="center"
//         >
//           <Typography
//             variant="caption"
//             component="div"
//             color="textSecondary"
//             fontSize="12px"
//             fontWeight="bold"
//           >
//             {`${percentage}%`}
//           </Typography>
//         </Box>
//       </Box>
//     );
//   };

//   const handleCardClick = () => {
//     navigate(`/jobdetails/${jobDescriptionId}`);
//   };

//   const handleCreateNewJob = () => {
//     setActiveStep(0);

//     setJobDescriptionData({});

//     setJobDescriptionId(null);

//     setUploadedFiles([]);

//     setParsedResumes([]);

//     setSelectedResumes([]);

//     setError(null);

//     setAnchorEl(null);

//     setSelectedEmail("");

//     setSelectedActions({});

//     setScheduleModalOpen(false);

//     setShowProgress(true); // Reset the progress bar visibility
//   };

//   const handleScheduleSave = async (interviewDetails) => {
//     try {
//       const interviewDate = new Date(interviewDetails.interviewDate);

//       if (isNaN(interviewDate.getTime())) {
//         throw new Error("Invalid date format");
//       }

//       interviewDetails.interviewDate = interviewDate.toISOString();

//       await CommonApi.scheduleInterview(interviewDetails);

//       alert(`Interview scheduled for ${interviewDetails.candidateEmail}`);
//     } catch (error) {
//       alert("Failed to schedule interview");
//     }
//   };

//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop,

//     maxSize: 1024 * 1024 * 5,
//   });

//   const options = [
//     "Send Email",

//     "Schedule An Interview",

//     "Shortlist to Another Job",

//     "Insufficient Information",

//     "Source Change Request",

//     "Reject",

//     "Recruiter Reject",

//     "Request Documents",
//   ];

//   return (
//     <Box
//       sx={{
//         width: "95%",

//         paddingLeft: "0px",
//         marginTop: '20px',

//         minHeight: "9.5vh !important",
//       }}
//     >
//       <Grid container spacing={2}>
//         <Grid item xs={3}>
//           {showProgress && (
//             <VerticalProgressBar
//               sx={{ position: "fixed", marginRight: "100px" }}
//               steps={steps}
//               currentStep={activeStep}
//             />
//           )}
//         </Grid>

//         <Grid item xs={9}>
//           {activeStep === steps.length ? (
//             <>
//               <Typography variant="h5" gutterBottom>
//                 Job Description Created Successfully!
//               </Typography>

//               <Card sx={{ minWidth: 275, mb: 2 }}>
//                 <CardActionArea onClick={handleCardClick}>
//                   <CardContent>
//                     <Typography variant="h5" component="div">
//                       {jobDescriptionData.roleName}
//                     </Typography>

//                     <Typography sx={{ mb: 1.5 }} color="text.secondary">
//                       {jobDescriptionData.address?.city},{" "}
//                       {jobDescriptionData.address?.state},{" "}
//                       {jobDescriptionData.address?.country}
//                     </Typography>

//                     <Typography variant="body2">
//                       Number of Profiles: {parsedResumes.length}
//                     </Typography>
//                   </CardContent>
//                 </CardActionArea>
//               </Card>

//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={handleCreateNewJob}
//               >
//                 Create New Job
//               </Button>
//             </>
//           ) : (
//             <Box
//               sx={{
//                 overflowY: "auto",

//                 maxHeight: "75vh",

//                 backgroundColor: "white",

//                 padding: "30px",

//                 boxShadow: "0px 4px 8px #abc4ff !important",

//                 borderRadius: "8px",
//               }}
//             >
//               {activeStep === 0 ? (
//                 <AddJobDescription
//                   jobDescriptionData={jobDescriptionData}
//                   handleNext={handleNext}
//                 />
//               ) : activeStep === 1 ? (
//                 <Box>
//                   <Box
//                     {...getRootProps()}
//                     border="1px dashed grey"
//                     p={3}
//                     mb={2}
//                     textAlign="center"
//                   >
//                     <input {...getInputProps()} />

//                     <Typography>
//                       Drag 'n' drop some files here, or click to select files
//                     </Typography>
//                   </Box>

//                   <Grid container spacing={2}>
//                     {uploadedFiles.map((file, index) => (
//                       <Grid item key={index}>
//                         <Chip
//                           label={file.name}
//                           onDelete={() => handleRemoveFile(file.name)}
//                         />
//                       </Grid>
//                     ))}
//                   </Grid>

//                   <Box mt={2}>
//                     <Button
//                       onClick={handleBack}
//                       color="primary"
//                       variant="contained"
//                       style={{ marginRight: "10px" }}
//                     >
//                       Back
//                     </Button>

//                     <Button
//                       onClick={() => handleNext()}
//                       color="primary"
//                       variant="contained"
//                     >
//                       Next
//                     </Button>
//                   </Box>
//                 </Box>
//               ) : (
//                 <Box>
//                   <Box
//                     display="flex"
//                     justifyContent="space-between"
//                     alignItems="center"
//                     mb={2}
//                   >
//                     <Typography
//                       variant="h6"
//                       gutterBottom
//                       sx={{ fontSize: "20px", fontWeight: "bold" }}
//                     >
//                       Parsed Resumes{" "}
//                       <span style={{ color: "grey" }}>
//                         ({parsedResumes.length})
//                       </span>
//                     </Typography>

//                     {selectedResumes.length > 0 && (
//                       <Button
//                         variant="contained"
//                         color="secondary"
//                         sx={{ fontSize: "14px" }}
//                         onClick={handleSendEmailToSelected}
//                       >
//                         Selected Resumes: {selectedResumes.length}
//                       </Button>
//                     )}
//                   </Box>

//                   {error ? (
//                     <Typography color="error">{error}</Typography>
//                   ) : (
//                     <TableContainer component={Paper}>
//                       <Table>
//                         <TableHead>
//                           <TableRow>
//                             <TableCell>
//                               <Checkbox
//                                 checked={selectAll}
//                                 onChange={handleSelectAllChange}
//                                 inputProps={{
//                                   "aria-label": "select all resumes",
//                                 }}
//                               />
//                             </TableCell>

//                             <TableCell
//                               sx={{ fontSize: "14px", fontWeight: "bold" }}
//                             >
//                               Name
//                             </TableCell>

//                             <TableCell
//                               sx={{ fontSize: "14px", fontWeight: "bold" }}
//                             >
//                               Email
//                             </TableCell>

//                             <TableCell
//                               sx={{ fontSize: "14px", fontWeight: "bold" }}
//                             >
//                               Phone
//                             </TableCell>

//                             <TableCell
//                               sx={{ fontSize: "14px", fontWeight: "bold" }}
//                             >
//                               Date
//                             </TableCell>

//                             <TableCell
//                               sx={{
//                                 fontSize: "14px",

//                                 fontWeight: "bold",

//                                 textAlign: "center",
//                               }}
//                             >
//                               Recommendation
//                             </TableCell>

//                             <TableCell
//                               sx={{ fontSize: "14px", fontWeight: "bold" }}
//                             >
//                               Action
//                             </TableCell>
//                           </TableRow>
//                         </TableHead>

//                         <TableBody>
//                           {parsedResumes.length > 0 ? (
//                             parsedResumes.map((resume, index) => (
//                               <TableRow key={index}>
//                                 <TableCell>
//                                   <Checkbox
//                                     checked={selectedResumes.includes(index)}
//                                     onChange={() => handleCheckboxChange(index)}
//                                   />
//                                 </TableCell>

//                                 <TableCell sx={{ fontSize: "14px" }}>
//                                   {resume.parsedData?.name || "N/A"}
//                                 </TableCell>

//                                 <TableCell sx={{ fontSize: "14px" }}>
//                                   {resume.parsedData?.email || "N/A"}
//                                 </TableCell>

//                                 <TableCell sx={{ fontSize: "14px" }}>
//                                   {resume.parsedData?.phone || "N/A"}
//                                 </TableCell>

//                                 <TableCell sx={{ fontSize: "14px" }}>
//                                   {new Date().toLocaleDateString()}
//                                 </TableCell>

//                                 <TableCell sx={{ textAlign: "center" }}>
//                                   {resume.recommendation?.matchPercentage
//                                     ? renderRecommendationProgress(
//                                         resume.recommendation.matchPercentage
//                                       )
//                                     : "N/A"}
//                                 </TableCell>

//                                 <TableCell>
//                                   <Button
//                                     variant="contained"
//                                     color="primary"
//                                     onClick={() =>
//                                       handleSendEmail(
//                                         resume.parsedData?.email || ""
//                                       )
//                                     }
//                                     sx={{
//                                       minWidth: "120px",

//                                       whiteSpace: "nowrap",

//                                       fontSize: "14px",

//                                       fontWeight: "bold",

//                                       backgroundColor: "#1976d2",

//                                       "&:hover": {
//                                         backgroundColor: "#1565c0",
//                                       },
//                                     }}
//                                   >
//                                     Send Email
//                                   </Button>
//                                 </TableCell>
//                               </TableRow>
//                             ))
//                           ) : (
//                             <TableRow>
//                               <TableCell colSpan={7} align="center">
//                                 No resumes parsed.
//                               </TableCell>
//                             </TableRow>
//                           )}
//                         </TableBody>
//                       </Table>
//                     </TableContainer>
//                   )}

//                   <Box mt={2}>
//                     <Button
//                       onClick={handleBack}
//                       color="primary"
//                       variant="contained"
//                       style={{ marginRight: "10px" }}
//                     >
//                       Back
//                     </Button>

//                     <Button
//                       onClick={handleFinish}
//                       color="primary"
//                       variant="contained"
//                       style={{ fontSize: "14px" }}
//                     >
//                       Finish
//                     </Button>
//                   </Box>
//                 </Box>
//               )}
//             </Box>
//           )}
//         </Grid>
//       </Grid>

//       <Box
//         sx={{
//           width: "100%",
//         }}
//       >
//         <ScheduleInterviewModal
//           open={isScheduleModalOpen}
//           onClose={() => setScheduleModalOpen(false)}
//           onSave={handleScheduleSave}
//           jobId={jobDescriptionId}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default JobDescriptionStepper;

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  CircularProgress,
  IconButton,
  Chip,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useNavigate, useLocation } from "react-router-dom";
import CommonApi from "../../../apis/CommonApi";
import LocalStorage from "../../../utils/LocalStorage";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SendIcon from "@mui/icons-material/Send";

const JobDescriptionStepper = () => {
  const location = useLocation();
  const jobDescriptionId = location.state?.jobDescriptionId;
  const [activeStep, setActiveStep] = useState(1); // Start on "Upload Files & Review" step
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [parsedResumes, setParsedResumes] = useState([]);
  const [selectedResumes, setSelectedResumes] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const steps = ["Generate Job Description", "Upload Files & Review", "Parsed Resumes"];
  const navigate = useNavigate();

  const onDrop = (acceptedFiles) => {
    setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
  };

  const handleRemoveFile = (fileName) => {
    setUploadedFiles(uploadedFiles.filter((file) => file.name !== fileName));
  };

  const handleNext = async () => {
    if (activeStep === 1) {
      await handleParseResumes();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleParseResumes = async () => {
    if (uploadedFiles.length === 0) {
      setError("No files uploaded. Please upload resumes to parse.");
      return;
    }
    if (!jobDescriptionId) {
      setError("Job description ID is missing. Please try creating the job again.");
      return;
    }

    try {
      setIsLoading(true);
      const token = LocalStorage.getAccessToken();
      const user = LocalStorage.getUserData();
      const candidateProfileId = user?.candidateProfileId;

      if (!candidateProfileId) {
        setError("Candidate Profile ID is required.");
        setIsLoading(false);
        return;
      }

      const formData = new FormData();
      uploadedFiles.forEach((file) => formData.append("resumes", file));
      formData.append("jobDescriptionId", jobDescriptionId);
      formData.append("candidateProfileId", candidateProfileId);

      const response = await CommonApi.parseResume(formData, token);
      if (response && response.results) {
        setParsedResumes(response.results.map((result) => result.value)); // Map to access 'value' directly
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        throw new Error("Invalid response from the server. Please check server logs.");
      }
    } catch (error) {
      setError(`Error parsing resumes: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = (index) => {
    const updatedSelectedResumes = [...selectedResumes];
    if (updatedSelectedResumes.includes(index)) {
      updatedSelectedResumes.splice(updatedSelectedResumes.indexOf(index), 1);
    } else {
      updatedSelectedResumes.push(index);
    }
    setSelectedResumes(updatedSelectedResumes);
    setSelectAll(updatedSelectedResumes.length === parsedResumes.length);
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    setSelectedResumes(event.target.checked ? parsedResumes.map((_, index) => index) : []);
  };

  const renderMatchPercentage = (matchPercentage) => {
    const percentage = Math.round(matchPercentage);
    let color;

    if (percentage >= 75) {
      color = "green";
    } else if (percentage >= 50) {
      color = "orange";
    } else {
      color = "red";
    }

    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          value={100}
          size={40}
          thickness={5}
          sx={{ color: "#e0e0e0" }}
        />
        <CircularProgress
          variant="determinate"
          value={percentage}
          size={40}
          thickness={5}
          sx={{
            position: "absolute",
            left: 0,
            strokeLinecap: "round",
            color: color,
          }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
            fontSize="12px"
            fontWeight="bold"
          >
            {`${percentage}%`}
          </Typography>
        </Box>
      </Box>
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 1024 * 1024 * 5, // 5MB limit per file
  });

  return (
    <Box sx={{ width: "80%", margin: "0 auto", paddingTop: "20px" }}>
      {/* Stepper Progress */}
      <Box display="flex" justifyContent="space-between" mb={3}>
        {steps.map((label, index) => (
          <Box
            key={label}
            display="flex"
            alignItems="center"
            flexDirection="column"
            color={activeStep === index ? "black" : "grey"}
          >
            <Box width="30px" height="30px" borderRadius="50%" bgcolor={activeStep === index ? "black" : "grey"} />
            <Typography mt={1} fontSize="12px">{label}</Typography>
          </Box>
        ))}
      </Box>

      {error && <Typography color="error" mb={2}>{error}</Typography>}

      {/* Upload Section */}
      {activeStep === 1 && (
        <Box {...getRootProps()} sx={{ border: "2px dashed red", padding: "40px", textAlign: "center", cursor: "pointer", mb: 3, borderRadius: "8px" }}>
          <input {...getInputProps()} />
          <Typography variant="h6" color="primary" mb={2}>Drag 'n' drop some files here, or click to select files</Typography>
          <Button variant="contained" color="error">Choose File</Button>
        </Box>
      )}

      {/* Uploaded Documents Section */}
      {activeStep === 1 && (
        <Box>
          <Typography variant="h6" mb={2}>Uploaded Documents</Typography>
          <Box sx={{ border: "2px dashed red", padding: "16px", borderRadius: "8px" }}>
            <Grid container spacing={1}>
              {uploadedFiles.map((file, index) => (
                <Grid item key={index}>
                  <Chip label={file.name} onDelete={() => handleRemoveFile(file.name)} sx={{ backgroundColor: "#fff", border: "1px solid grey", padding: "10px" }} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      )}

      {/* Parsing Resume Progress */}
      {isLoading && (
        <Box mt={3} textAlign="center">
          <CircularProgress />
          <Typography mt={1}>Parsing resumes, please wait...</Typography>
        </Box>
      )}

      {/* Parsed Resumes Table */}
      {activeStep === 2 && (
        <Box mt={3}>
          <Typography variant="h6" gutterBottom>Resumes ({parsedResumes.length})</Typography>
          {parsedResumes.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ backgroundColor: "#ff5722" }}>
                  <TableRow>
                    <TableCell><Checkbox checked={selectAll} onChange={handleSelectAllChange} /></TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>Name</TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>Email</TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>Phone</TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>Recommendation</TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>View Resumes</TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parsedResumes.map((resume, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox
                          checked={selectedResumes.includes(index)}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </TableCell>
                      <TableCell>{resume.parsedData?.name || "N/A"}</TableCell>
                      <TableCell>{resume.parsedData?.email || "N/A"}</TableCell>
                      <TableCell>{resume.parsedData?.phone || "N/A"}</TableCell>
                      <TableCell>{renderMatchPercentage(resume.recommendation?.matchPercentage || 0)}</TableCell>
                      <TableCell>
                        <IconButton color="primary">
                          <VisibilityIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <Button variant="contained" color="primary" startIcon={<SendIcon />}>
                          Send Email
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No resumes parsed yet.</Typography>
          )}
        </Box>
      )}

      {/* Navigation Buttons */}
      <Box display="flex" justifyContent="flex-end" mt={3}>
        {activeStep > 1 && (
          <Button onClick={() => setActiveStep((prev) => prev - 1)} sx={{ mr: 2 }}>Back</Button>
        )}
        <Button variant="contained" color="error" onClick={handleNext} disabled={isLoading}>
          {activeStep < steps.length - 1 ? "Next" : "Finish"}
        </Button>
      </Box>
    </Box>
  );
};

export default JobDescriptionStepper;
