import Snackbar from '../components/snackbar/Snackbar';
import React from "react";
import ReactDOM from "react-dom";
import { Error, WarningAmber, CheckCircle } from "@mui/icons-material";
import  Axios  from 'axios';
import { CircularProgress, Dialog } from "@mui/material";

export function getAxios() {
  const axios = Axios;
  // const userId = LocalStorage.getUser() && LocalStorage.getUser().user_id
  // axios.defaults.headers.common['Authorization'] = `${userId}`
  return axios;
}

export function connectionFailed() {
  ReactDOM.render(
    <Dialog
      open={true}
      fullWidth={true}
      fullScreen
      PaperComponent="div"
      PaperProps={{
        style: {
          justifyContent: "center",
          alignItems: "center",
          backdropFilter: "blur(10px)",
        },
      }}
    >
      {/* <CircularProgress color="primary" size={100} thickness={1.5} /> */}
      <CircularProgress color="inherit" />
      <h2>
        {window.navigator.onLine
          ? window.navigator.connection.effectiveType.endsWith("2g")
            ? "No Internet Connection, Please connect to the network and refresh your page."
            : "Server under maintenance! Please try again after sometime"
          : "No Internet Connection, Please connect to the network and refresh your page."}
      </h2>
    </Dialog>,
    document.getElementById("loader")
  );
}

export function removeLoader() {
  ReactDOM.unmountComponentAtNode(document.getElementById("loader"));
}

export function addSuccessMsg(message) {
  ReactDOM.render(
    <Snackbar
      message={message}
      color="success"
      place="bc"
      icon={<CheckCircle />}
      renderElement={document.getElementById("error")}
    />,
    document.getElementById("error")
  );
}

export function addWarningMsg(message) {
  ReactDOM.render(
    <Snackbar
      message={message}
      color="warning"
      place="bc"
      icon={<WarningAmber />}
      renderElement={document.getElementById("error")}
    />,
    document.getElementById("error")
  );
}

export function addErrorMsg(message) {
  ReactDOM.render(
    <Snackbar
      message={message}
      color="danger"
      place="bc"
      icon={<Error />}
      renderElement={document.getElementById("error")}
    />,
    document.getElementById("error")
  );
}
