// import React from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import { Breadcrumbs, Typography } from '@mui/material';

// const Breadcrumb = () => {
//   const location = useLocation();
//   const pathnames = location.pathname.split('/').filter((x) => x);

//   return (
//     <Breadcrumbs aria-label="breadcrumb" sx={{backgroundColor: 'red'}}>
//       <Link to="/">Home</Link>
//       {pathnames.map((value, index) => {
//         const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
//         const isLast = index === pathnames.length - 1;
//         return isLast ? (
//           <Typography color="textPrimary" key={routeTo}>
//             {value}
//           </Typography>
//         ) : (
//           <Link to={routeTo} key={routeTo}>
//             {value}
//           </Link>
//         );
//       })}
//     </Breadcrumbs>
//   );
// };

// export default Breadcrumb;

import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from "@mui/system";

const useStyles = makeStyles(() => ({
  breadcrumb: {
    paddingLeft: '24px'
  }

}));

const Breadcrumb = () => {
  const location = useLocation();
  const classes = useStyles();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <Breadcrumbs aria-label="breadcrumb"  className={classes.breadcrumb}>
      {/* <Link component={RouterLink} to="/" underline="hover">
        Home
      </Link> */}
      {pathnames.map((value, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <Typography color="textPrimary" key={routeTo} fontWeight="bold">
            {value}
          </Typography>
        ) : (
          <Link component={RouterLink} to={routeTo} key={routeTo} underline="hover">
            {value}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
