import React from 'react';
import { Box, Typography, Link, Grid } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const IndividualFooter = () => {
  return (
    <Box sx={{ width: '100%', position: 'fixed', bottom: 0, bgcolor: '#f2f2f2' }}>
      {/* Top Location Bar */}
      {/* <Box
        sx={{
          bgcolor: '#f2f2f2',
          color: 'text.secondary',
          py: 1,
          px: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: { xs: 'column', sm: 'row' },
        }}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <LocationOnIcon fontSize="small" />
            India
          </Typography>
          <Typography variant="body2" sx={{ mx: 1 }}>
            • Kondapur, Telangana - Based on your past activity
          </Typography>
          <Link href="#" underline="none" color="inherit" variant="body2">
            Update location
          </Link>
        </Box> */}
      {/* </Box> */}

      {/* Main Footer Content */}
      <Box
        sx={{
          bgcolor: '#f2f2f2',
          color: 'text.secondary',
          p: 1,
          px: 3,
          borderTop: '1px solid #e0e0e0',
          textAlign: 'center',
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          {/* Left Section - Country */}
          <Grid item xs={12} sm={4} sx={{mr: 4}}>
            <Typography variant="body2" color="textSecondary" sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
            Made in India
            </Typography>
          </Grid>

          {/* Center Section - Made in India Text */}
          {/* <Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
            Made in India
          </Grid> */}
                  <Box display="flex" alignItems="center">
          <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <LocationOnIcon fontSize="small" />
            India
          </Typography>
          <Typography variant="body2" sx={{ mx: 1 }}>
            • Kondapur, Telangana - Based on your past activity
          </Typography>
          <Link href="#" underline="none" color="inherit" variant="body2">
            Update location
          </Link>
        </Box>

          {/* Right Section - Links */}
          <Grid
            item
            xs={12}
            sm={4}
            sx={{ display: 'flex', justifyContent: 'center', gap: 3, mt: { xs: 2, sm: 0 }, mr: -11 }}
          >
            <Link href="#" underline="none" color="textSecondary" variant="body2">
              Privacy
            </Link>
            <Link href="#" underline="none" color="textSecondary" variant="body2">
              Terms
            </Link>
            <Link href="#" underline="none" color="textSecondary" variant="body2">
              Settings
            </Link>
            <Link href="#" underline="none" color="textSecondary" variant="body2">
              Help
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default IndividualFooter;
