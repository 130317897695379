import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#f0f0f0", // Optional for visibility
  },
  circleContainer: {
    position: "relative",
    width: "100px",
    height: "100px",
  },
  circle: {
    position: "absolute",
    border: "5px solid transparent", // Base border size
    borderRadius: "50%",
    animation:
      "$spin 1s linear infinite, $dynamicWidth 1s ease-in-out infinite",
  },
  circle1: {
    width: "70%", // Adjusted size for the arc
    height: "70%",
    top: "15%",
    left: "15%",
    borderTopColor: "#FF005C", // Pink
    borderLeftColor: "#FF005C",
    transformOrigin: "center center",
  },
  circle2: {
    width: "50%", // Adjusted size for the arc
    height: "50%",
    top: "25%",
    left: "25%",
    borderTopColor: "#2F3640", // Dark Gray
    borderLeftColor: "#2F3640",
    animationDelay: "-1s", // Offset for smoother animation
    rotate: '90deg'
  },
  circle3: {
    width: "30%", // Adjusted size for the arc
    height: "30%",
    top: "35%",
    left: "35%",
    borderTopColor: "#FF5E5E", // Red
    borderLeftColor: "#FF5E5E",
    animationDelay: "-2s", // Offset for smoother animation
    rotate: '180deg'
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  "@keyframes dynamicWidth": {
    //  '0%, 100%': {
    //     clipPath: 'polygon(0% 0%, 100% 0%, 100% 50%, 0% 50%)', // Starting arc
    //   },
    //   '50%': {
    //     clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', // Full half-circle arc
    //   },
    "50%": {
      clipPath: "polygon(0% 0%, 9% 0%, 10% 50%, 0% 50%)",
    },
    "0%, 100%": {
      clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
    },
  },
}));

const CircularLoader = () => {
  const classes = useStyles();
  return (
    <Box className={classes.loaderContainer}>
      <div className={classes.circleContainer}>
        <div className={`${classes.circle} ${classes.circle1}`}></div>
        <div className={`${classes.circle} ${classes.circle2}`}></div>
        <div className={`${classes.circle} ${classes.circle3}`}></div>
      </div>
    </Box>
  );
};

export default CircularLoader;