// src/components/RegistrationForm.js
import React, { useState } from 'react';
import { useTheme } from '../context/ThemeContext'; // Import the custom ThemeContext

const RegistrationForm = () => {
  const { theme } = useTheme(); // Access the current theme
  const [formData, setFormData] = useState({
    email: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', formData);
  };

  return (
    <section className={`py-16 text-center ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'} transition-colors duration-300`}>
      <h3 className="text-2xl font-bold mb-6">Ready to talk to our Optima AI?</h3>
      {/* <p>Your friendly co-pilot for the interviews</p> */}
      <p className={`mb-4 ${theme === 'dark' ? 'text-gray-400' : 'text-gray-700'}`}>
      Your friendly co-pilot for the interviews
      </p>
      <form onSubmit={handleSubmit} className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
        <input
          type="email"
          name="email"
          placeholder="Email Address"
          className={`px-4 py-2 rounded-full outline-none focus:ring-2 focus:ring-orange-500 w-full md:w-auto ${theme === 'dark' ? 'bg-gray-700 text-white' : 'bg-gray-200 text-gray-900'}`}
          value={formData.email}
          onChange={handleChange}
          required
        />
        <button className="bg-orange-500 px-6 py-2 rounded-full hover:bg-orange-400 transition-colors">
          Join
        </button>
      </form>
    </section>
  );
};

export default RegistrationForm;
