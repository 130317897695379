import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Divider,
  InputAdornment,
  Select,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { Search, LocationOn, Business, School } from '@mui/icons-material';

function SearchCandidates() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' }, // Stack on small screens, side-by-side on medium and up
        p: { xs: 2, md: 4 },
        width: '100%',
      }}
    >
      {/* Left Section - Search Form */}
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'white',
          p: { xs: 2, md: 3 },
          borderRadius: '12px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          mb: { xs: 4, md: 0 }, // Add margin on bottom for mobile view
          mr: { md: 2 }, // Add right margin for desktop view
        }}
      >
        <Typography variant="h5" fontWeight="bold" color="#333" mb={2}>
          Search Candidates
        </Typography>

        {/* Keywords Section */}
        <Typography variant="subtitle1" color="textSecondary" fontWeight="medium">
          Keywords
        </Typography>
        <TextField
          fullWidth
          placeholder="Enter keywords like skills, designation, and company"
          variant="outlined"
          sx={{ my: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="disabled" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Typography variant="body2" color="textSecondary">
                  Search in Entire Resume
                </Typography>
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={<Checkbox />}
          label="Mark all keywords as mandatory"
          sx={{ mb: 2 }}
        />
        <Typography variant="body2" color="primary" sx={{ cursor: 'pointer', mb: 1 }}>
          + Add Exclude Keywords
        </Typography>
        <Typography variant="body2" color="primary" sx={{ cursor: 'pointer', mb: 3 }}>
          + Add IT Skills
        </Typography>

        {/* Experience Section */}
        <Typography variant="subtitle1" color="textSecondary" fontWeight="medium">
          Experience
        </Typography>
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={6}>
            <TextField fullWidth placeholder="Min experience" variant="outlined" />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth placeholder="Max experience" variant="outlined" />
          </Grid>
        </Grid>

        {/* Location Section */}
        <Typography variant="subtitle1" color="textSecondary" fontWeight="medium">
          Current location of candidate
        </Typography>
        <TextField
          fullWidth
          placeholder="Add location"
          variant="outlined"
          sx={{ my: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationOn color="disabled" />
              </InputAdornment>
            ),
          }}
        />
        <FormControlLabel
          control={<Checkbox />}
          label="Include candidates who prefer to relocate to above locations"
          sx={{ mb: 1 }}
        />
        <FormControlLabel
          control={<Checkbox />}
          label="Exclude candidates who have mentioned Anywhere in location"
        />

        {/* Annual Salary Section */}
        <Typography variant="subtitle1" color="textSecondary" fontWeight="medium">
          Annual Salary
        </Typography>
        <Grid container spacing={2} sx={{ my: 2 }}>
          <Grid item xs={4}>
            <Select fullWidth variant="outlined" defaultValue="INR">
              <MenuItem value="INR">INR</MenuItem>
              <MenuItem value="USD">USD</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth placeholder="Min salary" variant="outlined" />
          </Grid>
          <Grid item xs={4}>
            <TextField fullWidth placeholder="Max salary" variant="outlined" />
          </Grid>
        </Grid>
        <FormControlLabel
          control={<Checkbox />}
          label="Include candidates who did not mention their current salary"
        />

        {/* Employment Details Section */}
        <Divider sx={{ my: 4 }} />
        <Typography variant="h6" fontWeight="bold">
          Employment Details
        </Typography>
        <TextField
          fullWidth
          placeholder="Add Department/Role"
          variant="outlined"
          sx={{ mt: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Business color="disabled" />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          placeholder="Add industry"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <TextField
          fullWidth
          placeholder="Add company name"
          variant="outlined"
          sx={{ mt: 2 }}
        />
        <Typography variant="body2" color="primary" sx={{ cursor: 'pointer', mt: 1 }}>
          + Add Exclude Company
        </Typography>
        <TextField fullWidth placeholder="Add designation" variant="outlined" sx={{ mt: 2 }} />

        {/* Education Details Section */}
        <Divider sx={{ my: 4 }} />
        <Typography variant="h6" fontWeight="bold">
          Education Details
        </Typography>
        <Box display="flex" flexWrap="wrap" mt={2}>
          <Button variant="outlined" sx={{ mr: 2, mb: 1 }}>
            Any UG qualification
          </Button>
          <Button variant="outlined" sx={{ mr: 2, mb: 1 }}>
            Specific UG qualification
          </Button>
          <Button variant="outlined" sx={{ mb: 1 }}>
            No UG qualification
          </Button>
        </Box>
        <Box display="flex" flexWrap="wrap" mt={2}>
          <Button variant="outlined" sx={{ mr: 2, mb: 1 }}>
            Any PG qualification
          </Button>
          <Button variant="outlined" sx={{ mr: 2, mb: 1 }}>
            Specific PG qualification
          </Button>
          <Button variant="outlined" sx={{ mb: 1 }}>
            No PG qualification
          </Button>
        </Box>
        <Typography variant="body2" color="primary" sx={{ cursor: 'pointer', mt: 1 }}>
          + Add PPG/Doctorate Qualification
        </Typography>

        {/* Diversity and Additional Details Section */}
        <Divider sx={{ my: 4 }} />
        <Typography variant="h6" fontWeight="bold">
          Diversity and Additional Details
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" fontWeight="medium">
            Diversity details
          </Typography>
          <ToggleButtonGroup exclusive color="primary" sx={{ mt: 2 }}>
            <ToggleButton value="all">All candidates</ToggleButton>
            <ToggleButton value="male">Male candidates</ToggleButton>
            <ToggleButton value="female">Female candidates</ToggleButton>
          </ToggleButtonGroup>
          <FormControlLabel
            control={<Checkbox />}
            label="Person with Disabilities only"
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            label="Candidate Category"
            placeholder="Add candidate category"
            variant="outlined"
            sx={{ mt: 2 }}
          />
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={6}>
              <TextField fullWidth label="Candidate Age" placeholder="Min age" variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth placeholder="Max age" variant="outlined" />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Typography variant="subtitle1" fontWeight="medium">
            Display details
          </Typography>
          <ToggleButtonGroup exclusive color="primary" sx={{ mt: 2 }}>
            <ToggleButton value="all">All candidates</ToggleButton>
            <ToggleButton value="new">New registrations</ToggleButton>
            <ToggleButton value="modified">Modified candidates</ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            mt: 4,
            bgcolor: '#1976D2',
            '&:hover': { bgcolor: '#115293' },
          }}
        >
          Search candidates
        </Button>
      </Box>

      {/* Right Sidebar */}
      <Box
        sx={{
          width: { xs: '100%', md: '350px' },
          height: { xs: '350px', md: '400px' },
          p: 3,
          bgcolor: '#f5f6f7',
          borderRadius: '8px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          position: 'sticky',
          top: 100,
          overflowY: 'auto',
        }}
      >
        <Typography variant="h6" fontWeight="bold" mb={2}>
          Recent Searches
        </Typography>
        <Typography variant="body2" color="textSecondary">
          You have not done any activity in the last 7 days
        </Typography>
        <Divider sx={{ my: 3 }} />
        <Typography variant="h6" fontWeight="bold" mb={2}>
          Saved Searches
        </Typography>
        <Typography variant="body2" color="primary" sx={{ cursor: 'pointer' }}>
          View all
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" fontWeight="bold">
            React and Node.js
          </Typography>
          <Typography variant="body2" color="textSecondary">
            reactJS, HTML, CSS, API Integration, Javascript | 1-3 years | Hyderabad
          </Typography>
          <Typography variant="body2" color="primary" sx={{ cursor: 'pointer', mt: 1 }}>
            Fill this search
          </Typography>
          <Typography variant="body2" color="primary">100+ new profiles</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default SearchCandidates;
