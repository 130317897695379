// import { useTheme } from "@mui/material";
// import { makeStyles } from "@mui/styles";
// import { darkSkyBlue } from "../../theme";
// import { heIL } from "@mui/material/locale";

// export const LoginStyles = makeStyles(() => ({
//     box: {
//         [useTheme().breakpoints.down('md')]: {
//             background: "",
//         }
//     },
//     boxOne: {
//         height: '100%',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         marginTop: "-50px ! important",
//         [useTheme().breakpoints.down('md')]: {
//             marginTop: "50px !important",
//             justifyContent: 'center',
//         },
//         [useTheme().breakpoints.down('xs')]: {
//             marginTop: "20px !important", // More room for smaller screens
//         }
//     },
//     boxTwo: {
//         marginLeft: "-90px !important",
//         width: '350px',
//         [useTheme().breakpoints.down('md')]: {
//             width: '350px',
//             marginLeft: "0px !important",
//         },
//         [useTheme().breakpoints.down('sm')]: {
//             width: '70vw',
//             marginLeft: "0px !important",
//         }
//     },
//     rightBox: {
//         background: '#243042 ! important',
//         height: '100vh'
//     },
//     image: {
//         marginTop: "200px",
//         marginLeft: "-200px",
//         width: "100%",
//         [useTheme().breakpoints.down('xs')]: {
//             marginTop: "100px", // Adjust the image margin for small screens
//             marginLeft: "0px",  // No negative margin on small screens
//             width: "80%", // Reduce image size on smaller screens
//         },
//         [useTheme().breakpoints.down('/sm')]: {
//             // display: "none", // Hide the image on small screens if necessary
//         }
//     },
//     inputBoxes: {
//         textAlign: "left",
//         paddingLeft:'32px !important',
//         justifySelf: "center",
//         [useTheme().breakpoints.down('lg')]: {
//             height: "26px !important", // Hide the image on small screens if necessary
//         } ,
//         [useTheme().breakpoints.down('md')]: {
//             height: "24px !important", // Hide the image on small screens if necessary
//         } ,
//         [useTheme().breakpoints.down('sm')]: {
//             height: "20px !important", // Hide the image on small screens if necessary
//         },
//     },
//     header: {
//         color: '#2D2D2D !important',
//         textAlign: 'center',
//         [useTheme().breakpoints.down('lg')]: {
//             fontSize: "16px !important",
//         } ,
//         [useTheme().breakpoints.down('md')]: {
//             fontSize: "16px !important",
//         } ,
//         [useTheme().breakpoints.down('sm')]: {
//             fontSize: "14px !important",
//         }
//     },
//     buttonStyles: {
//         textAlign: 'center',
//         paddingTop: '30px',
//         [useTheme().breakpoints.down('xs')]: {
//             paddingTop: '20px', // Less padding for smaller screens
//         }
//     },
//     forgotPassword: {
//         color: "#4C9FEB !important",
//         fontSize: "11px !important",
//         cursor: "pointer",
//         [useTheme().breakpoints.down('md')]: {
//             fontSize: "10px !important",
//         }
//     },
//     loginButton:{
//         width: "135px !important",
//         background: `${darkSkyBlue} 0% 0% no-repeat padding-box !important`,
//         borderRadius: "3px !important",
//         textTransform: "none !important",
//         font: "13px Poppins !important",
//         [useTheme().breakpoints.down("lg")]: {
//             font: "12px Poppins !important",
//             width: "95px !important",
//         },
//         [useTheme().breakpoints.down("xs")]: {
//             font: "10px Poppins !important",
//             width: "85px !important", // Even smaller for extra small screens
//         },
//         "& .MuiLoadingButton-loadingIndicator": {
//             color: "#FFFFFF",
//           }
//     },
//     Paper: {
//         margin:'6px 0px',
//         display: "flex",
//         alignItems: "center",
//         height: '39px',
//         // background: '#FFFFFF !important',
//         border: '1px solid #1A181E !important',
//         boxShadow: "none !important",
//         borderRadius: '5px !important',
//         [useTheme().breakpoints.down('sm')]: {
//             height: "30px !important", // Hide the image on small screens if necessary
//         }
//     },
//     InputBase: {
//         marginLeft: 7,
//         flex: 1,
//         font: '14px Poppins !important',
//         color: '#1A181E !important',
//         background: '#FFFFFF 0% 0% no-repeat padding-box !important',
//         opacity: 1,
//         borderRadius: '5px',
//         width: '100%',
//         height: '25px ! important',
//         '& input': {
//             textAlign: 'left !important',
//             paddingLeft:'20px !important'
//         },
//         [useTheme().breakpoints.up('lg')]: {
//             padding: "10px", // Slightly smaller padding on extra small screens
//             height: '22px !important'
//         },
//         [useTheme().breakpoints.down('md')]: {
//             padding: "10px", // Slightly smaller padding on extra small screens
//             height: '23px !important'
//         },
//         [useTheme().breakpoints.down('sm')]: {
//             height: "19px !important", // Hide the image on small screens if necessary
//         }
//     },
//     IconButton: {
//         marginTop: '8px !important',
//         marginRight: '10px !important'
//     },
//     visibilityIcon: {
//         height: '18px',
//         width: '18px',
//         cursor: 'pointer'
//     },
//     notVisibleIcon: {
//         height: '18px',
//         width: '18px',
//         cursor: 'pointer'
//     },
//     gridItem: {
//         display:"grid"
//     },
//     breadCrumb: {
//         [useTheme().breakpoints.down('md')]: {
//         "& .css-vjol32-MuiChip-root" : {
//             height: "20px",
//             width: "80px",
//             fontSize: '13px'
//         }
//         } ,
//         [useTheme().breakpoints.down('sm')]: {
//         "& .css-vjol32-MuiChip-root" : {
//             height: "18px",
//             width: "75px",
//             fontSize: '10px'
//         }
//         }
//     },
//     mainContainer:{
//       backgroundImage: "../../assets/svg/red-orange-bg.jpg", // Path to your image
//       backgroundSize: 'cover'
//     },
//     container: {
//         height: '100%',
//          backgroundColor:  'rgb(252 252 255)',
//         //  border: '8px solid #b3040469' ,
//          borderRadius: "34px",
//          padding: '10px',
//         //  boxShadow: "0 2px 8px 8px orange",
//         [useTheme().breakpoints.down('sm')]: {
//             fontSize: "14px",
//         }
//     },
//     otherBtns:{
//         flex: 'unset',
//         height: '60px',
//         border: '2px solid lightgray !important',
//         borderRadius: '30px !important',
//         color: 'black !important',
//         fontWeight:'700 !important',
//     }
// }))

// import { useTheme } from "@mui/material";
// import { makeStyles } from "@mui/styles";
// import { darkSkyBlue } from "../../theme";

// export const LoginStyles = makeStyles(() => ({
//   box: {
//     [useTheme().breakpoints.down("md")]: {
//       background: "",
//     },
//   },
//   boxOne: {
//     height: "100%",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     marginTop: "-50px !important",
//     [useTheme().breakpoints.down("md")]: {
//       marginTop: "50px !important",
//       justifyContent: "center",
//     },
//     [useTheme().breakpoints.down("xs")]: {
//       marginTop: "20px !important", // More room for smaller screens
//     },
//   },
//   boxTwo: {
//     marginLeft: "-90px !important",
//     // width: '350px',
//     [useTheme().breakpoints.down("md")]: {
//       // width: '350px',
//       marginLeft: "0px !important",
//     },
//     [useTheme().breakpoints.down("sm")]: {
//       // width: '70vw',
//       marginLeft: "0px !important",
//     },
//   },
//   rightBox: {
//     background: "#243042 !important",
//     height: "100vh",
//   },
//   image: {
//     marginTop: "200px",
//     // marginLeft: "-200px",
//     width: "100%",
//     [useTheme().breakpoints.down("xs")]: {
//       marginTop: "100px", // Adjust the image margin for small screens
//       marginLeft: "0px", // No negative margin on small screens
//       // width: "80%", // Reduce image size on smaller screens
//     },
//     [useTheme().breakpoints.down("/sm")]: {
//       // display: "none", // Hide the image on small screens if necessary
//     },
//   },
//   inputBoxes: {
//     textAlign: "left",
//     paddingLeft: "32px !important",
//     justifySelf: "center",
//     [useTheme().breakpoints.down("lg")]: {
//       height: "26px !important", // Hide the image on small screens if necessary
//     },
//     [useTheme().breakpoints.down("md")]: {
//       height: "13px !important", // Hide the image on small screens if necessary
//     },
//     [useTheme().breakpoints.down("sm")]: {
//       height: "20px !important", // Hide the image on small screens if necessary
//     },
//   },
//   header: {
//     color: "#2D2D2D !important",
//     textAlign: "center",
//     [useTheme().breakpoints.down("lg")]: {
//       fontSize: "16px !important",
//     },
//     [useTheme().breakpoints.down("md")]: {
//       fontSize: "16px !important",
//     },
//     [useTheme().breakpoints.down("sm")]: {
//       fontSize: "14px !important",
//     },
//   },
//   buttonStyles: {
//     textAlign: "center",
//     paddingTop: "30px",
//     [useTheme().breakpoints.down("xs")]: {
//       paddingTop: "20px", // Less padding for smaller screens
//     },
//   },
//   forgotPassword: {
//     color: "#4C9FEB !important",
//     fontSize: "11px !important",
//     cursor: "pointer",
//     [useTheme().breakpoints.down("md")]: {
//       fontSize: "10px !important",
//     },
//   },
//   loginButton: {
//     width: "100%",
//     height: "50px",
//     marginTop: "20px",
//     borderRadius: "26px",
//     backgroundColor: "#FF5200",
//     boxShadow: "0 4px 8px rgba(255, 140, 0, 0.5)",
//     [useTheme().breakpoints.down("lg")]: {
//       font: "12px Poppins !important",
//       height: "35px",
//     },
//     [useTheme().breakpoints.down("md")]: {
//       font: "10px Poppins !important",
//       height: "36px !important", // Even smaller for extra small screens
//     },
//     "& .MuiLoadingButton-loadingIndicator": {
//       color: "#FFFFFF",
//     },
//   },
//   Paper: {
//     margin: "6px 0px",
//     display: "flex",
//     alignItems: "center",
//     height: "32px",
//     border: "1px solid #1A181E !important",
//     boxShadow: "none !important",
//     borderRadius: "5px !important",
//     [useTheme().breakpoints.down("sm")]: {
//       height: "30px !important", // Hide the image on small screens if necessary
//     },
//     [useTheme().breakpoints.down("md")]: {
//         height: "38px !important", // Hide the image on small screens if necessary
//       },
//       [useTheme().breakpoints.down("lg")]: {
//         height: "36px !important", // Hide the image on small screens if necessary
//       },
//   },
//   InputBase: {
//     marginLeft: 7,
//     flex: 1,
//     font: "16px Poppins !important",
//     color: "#1A181E !important",
//     background: "#FFFFFF 0% 0% no-repeat padding-box !important",
//     opacity: 1,
//     borderRadius: "5px",
//     width: "100%",
//     "& input": {
//       textAlign: "left !important",
//       paddingLeft: "20px !important",
//     },
//     [useTheme().breakpoints.up("lg")]: {
//       padding: "10px", // Slightly smaller padding on extra small screens
//       height: "22px !important",
//     },
//     [useTheme().breakpoints.down("md")]: {
//       padding: "10px", // Slightly smaller padding on extra small screens
//       height: "13px !important",
//       width: "50%",
//     },
//     [useTheme().breakpoints.down("sm")]: {
//       height: "19px !important", // Hide the image on small screens if necessary
//     },
//   },
//   IconButton: {
//     marginTop: "8px !important",
//     marginRight: "10px !important",
//   },
//   visibilityIcon: {
//     height: "18px",
//     width: "18px",
//     cursor: "pointer",
//   },
//   notVisibleIcon: {
//     height: "18px",
//     width: "18px",
//     cursor: "pointer",
//   },
//   gridItem: {
//     display: "grid",
//   },
//   breadCrumb: {
//     [useTheme().breakpoints.down("md")]: {
//       "& .css-vjol32-MuiChip-root": {
//         height: "20px",
//         width: "80px",
//         fontSize: "13px",
//       },
//     },
//     [useTheme().breakpoints.down("sm")]: {
//       "& .css-vjol32-MuiChip-root": {
//         height: "18px",
//         width: "75px",
//         fontSize: "10px",
//       },
//     },
//   },
//   mainContainer: {
//     backgroundImage: "../../assets/svg/red-orange-bg.jpg", // Path to your image
//     backgroundSize: "cover",
//   },
//   container: {
//     height: "100%",
//     backgroundColor: "rgb(252, 252, 255)",
//     borderRadius: "34px",
//     padding: "10px",
//     [useTheme().breakpoints.down("sm")]: {
//       fontSize: "14px",
//     },
//   },
//   otherBtns: {
//     flex: "unset",
//     height: "42px",
//     border: "2px solid lightgray !important",
//     borderRadius: "30px !important",
//     color: "black !important",
//     fontWeight: "700 !important",
//     [useTheme().breakpoints.down("sm")]: {
//         height: "35px",
//       },
//       [useTheme().breakpoints.down("md")]: {
//         height: "38px",
//       },
//   },
// //   contentBox: {
// //     overflow: "scroll !important",
// //   },
//   content: {
//     width: "100% !important",
//     // height: "620px",
//   },
//   gif: {
//     width: "80%",
//     // height: "100%",
//     objectFit: "contain",
//     [useTheme().breakpoints.down("md")]: {
//         height: "90% !important",
//         width: "60% !important",
//       },
//     [useTheme().breakpoints.down("lg")]: {
//       height: "70% !important",
//       width: "80% !important",
//     },
//   },
// }));
import { makeStyles } from "@mui/styles";

export const LoginStyles = makeStyles({
  mainContainer: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#f5f5f5",
    padding: "20px",
    
  },
  innerContainer: {
    width: "100%",
    maxWidth: "1200px",
    backgroundColor: "#fff",
    borderRadius: "24px",
    padding: "20px",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 960px)": {
      flexDirection: "column",
    },
  },
  leftContainer: {
    background: "linear-gradient(to bottom right, #ff2600, #ff7e5f)",
    padding: "60px 40px !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    color: "#fff",
    "@media (max-width: 960px)": {
      borderRadius: "20px 20px 0 0",
      padding: "40px 20px",
      textAlign: "center",
    },
  },
  leftContent: {
    textAlign: "center !important",
    flexGrow: 1,
  },
  headerText: {
    fontSize: "36px",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: 'center !important',
    "@media (max-width: 600px)": {
      fontSize: "28px",
    },
  },
  subText: {
    fontSize: "18px",
    maxWidth: "280px",
    marginBottom: "150px !important",
    marginLeft: '100px !important',
    textAlign: 'center !important',
    "@media (max-width: 600px)": {
      fontSize: "16px",
      maxWidth: "100%",
    },
  },
  illustration: {
    width: "80%",
    maxHeight: "200px",
    objectFit: "contain",
    marginTop: "20px",
    marginLeft: '50px !important',
    "@media (max-width: 960px)": {
      marginTop: "10px",
      width: "60%",
    },
  },
  loginFormBox: {
    padding: "60px 40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    borderTopRightRadius: "24px",
    borderBottomRightRadius: "24px",
    "@media (max-width: 960px)": {
      borderRadius: "0 0 24px 24px",
      padding: "40px 20px",
    },
  },
  logo: {
    width: "80px",
    marginBottom: "16px",
    display: "block",
    margin: "0 auto", // Center the logo
  },
  welText: {
    fontWeight: "bold",
    fontSize: "32px",
    marginBottom: "10px",
    textAlign: "center",
    "@media (max-width: 600px)": {
      fontSize: "28px",
    },
  },
  paraText: {
    color: "gray",
    fontSize: "16px",
    marginBottom: "30px",
    textAlign: "center",
  },
  inputFields: {
    width: "100%",
    maxWidth: "350px",
    marginBottom: "20px",
    backgroundColor: "#f7f7f7",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      padding: "10px 15px",
    },
  },
  forgotPassword: {
    color: "#b0b0b0 !important",
    fontSize: "14px",
    cursor: "pointer",
    marginRight: '20px !important',
    textAlign: "right !important",
    marginBottom: "20px !important",
  },
  loginButton: {
    width: "100%",
    maxWidth: "450px !important",
    padding: "14px !important",
    borderRadius: "15px !important",
    backgroundColor: "#ff2600 !important",
    color: "#fff",
    fontWeight: "bold !important",
    fontSize: "16px !important",
    boxShadow: 'none !important',
    marginTop: '20px !important',
    "&:hover": {
      backgroundColor: "#ff4500",
    },
    marginBottom: "20px",
  },
  dividerText: {
    color: "#b0b0b0",
    fontWeight: "bold",
    margin: "20px 0",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    "&::before, &::after": {
      content: '""',
      flex: 1,
      borderBottom: "1px solid #b0b0b0",
      margin: "0 8px",
    },
  },
  socialButton: {
    width: "100%",
    maxWidth: "170px",
    height: "50px",
    borderRadius: "8px !important",
    margin: "10px 5px",
    border: "1px solid lightgray !important",
    color: "black !important",
    fontWeight: "600 !important",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      marginRight: "8px",
      height: "24px",
      width: "24px",
    },
    "&:hover": {
      borderColor: "gray",
    },
  },
  toggleText: {
    color: "#ff7e5f",
    fontWeight: "bold",
    cursor: "pointer",
    marginLeft: "4px",
  },
});
