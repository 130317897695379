import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList
} from 'recharts';
import { Card, CardContent, CardHeader, Typography, Box } from '@mui/material';

const JobApplicationsOverview = ({ data }) => {
  if (!data || data.topCompanies.length === 0) {
    return (
      <Card sx={{ height: '100%' }}>
        <CardHeader title="Top Companies by Job Applications" />
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            No data available
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title="Top Companies by Job Applications" />
      <CardContent>
        <Box sx={{ height: 400 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={data.topCompanies}
              margin={{
                top: 20, right: 30, left: 20, bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="companyName" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="applicationCount" fill="#8884d8">
                <LabelList dataKey="applicationCount" position="top" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default JobApplicationsOverview;
