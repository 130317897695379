import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Paper,
  LinearProgress,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import CommonApi from "../apis/CommonApi";
import Logo from "../assets/svg/techoptima-02.png";
import { useNavigate } from "react-router-dom";

// Styled components for chat UI
const ChatContainer = styled(Box)({
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  height: "66%",
  overflowY: "auto",
  position: "relative",
  flexGrow: 1,
});

const ChatBubble = styled(Paper)(({ isUser }) => ({
  padding: "10px 15px",
  margin: "10px 0",
  borderRadius: "15px",
  backgroundColor: isUser ? "#ff5722" : "#ffe0cc",
  color: isUser ? "#fff" : "#000",
  alignSelf: isUser ? "flex-end" : "flex-start",
  maxWidth: "80%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
  animation: "fadeIn 0.3s ease-in",
}));

const ProgressContainer = styled(Box)({
  margin: "10px 0",
  padding: "0px 20px",
});

const ProgressBar = styled(LinearProgress)({
  backgroundColor: "white",
  border: "1px solid #ff2600",
  borderRadius: "15px",
  height: "10px",
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#ff2600",
  },
});

const ChatInputContainer = styled(Box)({
  display: "flex",
  padding: "10px",
  position: "relative",
  bottom: 0,
  width: "100%",
  backgroundColor: "#fff",
  boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
  zIndex: 10,
});

const ChatInput = styled(TextField)(() => ({
  flexGrow: 1,
}));

const Header = styled(Typography)({
  fontSize: "18px",
  color: "white",
  backgroundColor: "#ff2600",
  height: "56px !important",
  padding: "5px 20px",
});


const JobSummary = ({ data, onEdit, onNext, jobDescriptionId }) => (
  <>
    <Header variant="h6" gutterBottom>
      Job Summary
    </Header>
    <Box
      padding="15px"
      borderRadius="8px"
      height="85% !important"
      overflowY="auto"
      maxHeight="550px"
      sx={{
        overflowY: "auto",
      }}
    >
      {Object.entries(data).map(([key, value]) => (
        <Box
          key={key}
          marginBottom="10px"
          display="grid"
          gridTemplateColumns="3fr 1fr 7fr"
          alignItems="center"
        >
          <Typography variant="body1" fontWeight="bold">
            {key}
          </Typography>
          <Typography>:-</Typography>
          <Typography variant="body1" style={{ marginLeft: "10px" }}>
            {value}
          </Typography>
        </Box>
      ))}
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button variant="outlined" onClick={onEdit} color="primary">
          Edit
        </Button>
        <Button variant="contained" onClick={onNext} color="primary">
          Next
        </Button>
      </Box>
    </Box>
  </>
);

const ChatWithGPT = ({ onComplete, onJobCreated, setActiveStep, setJobDescriptionId }) => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isSummaryVisible, setIsSummaryVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [jobDescriptionId, setJobDescriptionIdState] = useState(null);
  const navigate = useNavigate();

  const questions = [
    { key: "roleName", question: "Could you please share the name of the role you are hiring for?" },
    { key: "experience", question: "May I know how many years of experience would be ideal for this role?" },
    { key: "domain", question: "Could you kindly specify the domain or industry this job belongs to?" },
    { key: "level", question: "What would be the level of the job position (e.g., Junior, Mid-level, Senior)?" },
    { key: "modeOfWork", question: "What is the preferred mode of work (e.g., On-site, Remote, Hybrid)?" },
    { key: "education", question: "Could you please mention the minimum education level required for this role?" },
    { key: "primarySkills", question: "What are the primary skills you are looking for in an ideal candidate?" },
    { key: "secondarySkills", question: "Are there any secondary skills that would be beneficial to have?" },
    { key: "certification", question: "Would you prefer candidates with any specific certifications?" },
    { key: "responsibility", question: "Could you outline the main responsibilities of this role, please?" },
    { key: "requirements", question: "What are the key requirements that a candidate should meet?" },
    { key: "preferredQualification", question: "Are there any preferred qualifications you would like to see in candidates?" },
    { key: "city", question: "Which city or location is this job based in?" },
  ];

  useEffect(() => {
    setMessages([{ role: "system", content: questions[0].question }]);
  }, []);

  // const handleNext = async () => {
  //   const currentQuestion = questions[questionIndex];
  //   const currentAnswer = inputValue;

  //   setMessages((prev) => [...prev, { role: "user", content: currentAnswer }]);
  //   setAnswers((prev) => ({ ...prev, [currentQuestion.key]: currentAnswer }));
  //   setInputValue(""); // Clear input field

  //   if (questionIndex < questions.length - 1) {
  //     setQuestionIndex((prevIndex) => prevIndex + 1);
  //     setMessages((prev) => [
  //       ...prev,
  //       { role: "system", content: questions[questionIndex + 1].question },
  //     ]);
  //   } else {
  //     setIsLoading(true);
  //     try {
  //       const response = await CommonApi.generateJobDescriptionWithAnswers(answers);
  //       const jobDescriptionId = response.job._id; // Get job ID from response
  //       if (jobDescriptionId) {
  //         setJobDescriptionIdState(jobDescriptionId); // Store jobDescriptionId in state
  //         setIsSummaryVisible(true); // Show the Job Summary after creation
  //       }
  //     } catch (error) {
  //       console.error("Failed to generate job description:", error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // };
  const handleNext = async (isOptionSelected = false) => {
    const currentQuestion = questions[questionIndex];
    const currentAnswer = inputValue;

    // Show the user's answer in the chat only if it wasn't triggered by option selection
    setMessages((prev) => [
      ...prev,
      { role: "user", content: currentAnswer },
    ]);

    // Update answers state with the current answer
    setAnswers((prev) => ({ ...prev, [currentQuestion.key]: currentAnswer }));
    setInputValue(""); // Clear input field for the next question

    if (questionIndex < questions.length - 1) {
      // Move to the next question
      setQuestionIndex((prevIndex) => prevIndex + 1);
      setMessages((prev) => [
        ...prev,
        { role: "system", content: questions[questionIndex + 1].question },
      ]);
    } else {
      // Handle the last question
      setIsLoading(true);
      try {
        const questionsAndAnswers = questions.map((q) => ({
          question: q.question,
          answer: answers[q.key] || "",
        }));

        // Save the questions and answers to the backend
        const response = await CommonApi.generateJobDescriptionWithAnswers({
          ...answers,
          questionsAndAnswers,
        });
        const jobDescription = response.description;

        // Update answers with the generated JobSummary
        setAnswers((prev) => ({ ...prev, JobSummary: jobDescription }));

        const jobData = {
          roleName: answers.roleName,
          level: answers.level,
          experience: answers.experience,
          modeOfWork: answers.modeOfWork,
          domain: answers.domain,
          education: answers.education,
          primarySkills: answers.primarySkills,
          secondarySkills: answers.secondarySkills,
          certification: answers.certification,
          responsibility: answers.responsibility,
          requirements: answers.requirements,
          preferredQualification: answers.preferredQualification,
          description: jobDescription, // The generated job description from GPT
        };

        // Call your backend API to save the job data
        const saveResponse = await CommonApi.saveJobData(jobData);
        const jobDescriptionId = saveResponse.job?._id;

        if (jobDescriptionId) {
          setJobDescriptionIdState(jobDescriptionId); // Store jobDescriptionId in state
          setIsSummaryVisible(true); // Show the Job Summary after creation
        }
      } catch (error) {
        console.error("Failed to generate job description:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };


  const handleEdit = (index) => {
    const key = questions[index].key;
    setInputValue(answers[key] || "");
    setIsEditing(index);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    const currentQuestionKey = questions[questionIndex].key;

    if (currentQuestionKey === "experience" && /^\d*$/.test(value)) {
      setInputValue(value);
    } else if (["roleName", "domain", "primarySkills", "secondarySkills", "certification", "responsibility", "requirements", "preferredQualification", "city"].includes(currentQuestionKey) && /^[a-zA-Z\s]*$/.test(value)) {
      setInputValue(value);
    } else {
      setInputValue(value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (isEditing !== null) {
        saveEditedAnswer(isEditing);
      } else {
        handleNext();
      }
    }
  };

  const saveEditedAnswer = (index) => {
    const key = questions[index].key;
    setAnswers((prev) => ({ ...prev, [key]: inputValue }));
    setMessages((prev) => {
      const newMessages = [...prev];
      newMessages[index] = { role: "user", content: inputValue };
      return newMessages;
    });
    setIsEditing(null);
    setInputValue("");
  };

  const renderInputField = () => {
    const currentKey = questions[questionIndex].key;
    if (currentKey === "experience") {
      return (
        <Box display="flex" alignItems="center">
          <Select
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
            displayEmpty
            style={{ borderColor: "#ff2600", color: "#ff2600", width: "100px" }}
          >
            {[...Array(21).keys()].map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </Box>
      );
    } else if (currentKey === "level") {
      return (
        <Box>
          {["Junior", "Mid-level", "Senior"].map((option) => (
            <Button
              key={option}
              variant={inputValue === option ? "contained" : "outlined"}
              color={inputValue === option ? "primary" : "default"}
              onClick={() => setInputValue(option)}
              style={{ margin: "5px", borderRadius: "20px" }}
            >
              {option}
            </Button>
          ))}
        </Box>
      );
    } else if (currentKey === "modeOfWork") {
      return (
        <Box>
          {["On-site", "Remote", "Hybrid"].map((option) => (
            <Button
              key={option}
              variant={inputValue === option ? "contained" : "outlined"}
              color={inputValue === option ? "primary" : "default"}
              onClick={() => setInputValue(option)}
              style={{ margin: "5px", borderRadius: "20px" }}
            >
              {option}
            </Button>
          ))}
        </Box>
      );
    } else {
      return (
        <ChatInput
          variant="outlined"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          disabled={isLoading}
          placeholder={questions[questionIndex]?.question || ""}
        />
      );
    }
  };

  const progressPercentage = Math.round(
    ((questionIndex + 1) / questions.length) * 100
  );

  const onSummaryNext = () => {
    navigate("/jobdescriptionstepper", { state: { jobDescriptionId } });
  };

  return (
    <>
      {!isSummaryVisible ? (
        <>
          <Header>Generate Job Description</Header>
          <ProgressContainer>
            <ProgressBar variant="determinate" value={progressPercentage} />
            <Typography align="center" style={{ marginTop: "10px" }}>
              {progressPercentage}% Complete
            </Typography>
          </ProgressContainer>
          <ChatContainer id="chat-container">
            {messages.map((message, index) => (
              <ChatBubble key={index} isUser={message.role === "user"}>
                {message.role === "system" && (
                  <img
                    src={Logo}
                    alt="Logo"
                    style={{ width: "50px", height: "50px", marginRight: "10px" }}
                  />
                )}
                <Typography>{message.content}</Typography>
                {message.role === "user" && (
                  <IconButton onClick={() => handleEdit(index)}>
                    <EditIcon />
                  </IconButton>
                )}
              </ChatBubble>
            ))}
          </ChatContainer>

          <ChatInputContainer>
            {renderInputField()}
            <Button
              variant="contained"
              color="primary"
              onClick={
                isEditing !== null ? () => saveEditedAnswer(isEditing) : handleNext
              }
              disabled={isLoading || !inputValue}
              style={{ marginLeft: "10px" }}
            >
              {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
            </Button>
          </ChatInputContainer>
        </>
      ) : (
        <JobSummary
          data={answers}
          onEdit={handleEdit}
          onNext={onSummaryNext}
          jobDescriptionId={jobDescriptionId}
        />
      )}
    </>
  );
};

export default ChatWithGPT;

