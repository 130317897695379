import React, { useEffect, useState } from "react";
import { Container, Grid, Typography, Avatar, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CommonApi from "../apis/CommonApi";
import LocalStorage from "../utils/LocalStorage";
import MyProfileStyles from "./MyProfileStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
  avatar: {
    // width: theme.spacing(15),
    // height: theme.spacing(15),
    // marginBottom: theme.spacing(2),
  },
  button: {
    // marginTop: theme.spacing(2),
  },
  gridItem: {
    // marginBottom: theme.spacing(2),
  },
}));

const MyProfile = () => {
  // const classes = useStyles();
  const [userData, setUserData] = useState(null);

  const classes = MyProfileStyles();

  useEffect(() => {
    const fetchUserData = async () => {
      const token = LocalStorage.getAccessToken();
      try {
        const response = await CommonApi.getUserProfile(token);
        setUserData(response);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  if (!userData) return <Typography>Loading...</Typography>;

  return (
    <Container sx={{height: '90vh'}}>
      <Typography variant="h6" >
        My Profile
      </Typography>
      <Grid container spacing={2} className={classes.listContainer}>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <Typography variant="h6">Email</Typography>
          <Typography>{userData.email}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItem}>
          <Typography variant="h6">Role</Typography>
          <Typography>{userData.role}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MyProfile;
