import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    padding: '20px',
    justifyContent: 'space-between',
    width: "100%",
    alignItems: 'stretch', // Ensure equal height for both sides
  },
  leftSide: {
    flex: 0.6,
    padding: '20px',
    borderRadius: '8px', // Rounded corners
  },
  rightSide: {
    flex: 1,
    // padding: '16px',
    border: '2px solid #ff2600', 
    borderRadius: '4px',
    height: '551px !important',
    marginTop: '21px',
    paddingTop: '0px',

  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '10px',

  },
  box: {
    border: '1px solid #ff2600',
    borderRadius: '8px',
    padding: '16px',
    height: '150px',
    margin: '30px',
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'left', 
    paddingLeft: '80px',
    marginTop: '50px',
    paddingTop: '35px',

  },
  descriptionText: {
    marginTop: '10px',
    fontSize: '12px',
    color: 'lightgrey',
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'column', // Arrange icon and text in a column
  },
  icon: {
    marginBottom: '8px', // Space between icon and text
  },
}));

export default useStyles;
