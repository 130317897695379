import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    Grid,
    Container,
    Modal,
    TextField,
    Divider,
    Alert,
} from '@mui/material';
import IndividualHeader from '../IndividualHeader';
import Clock from '../../../assets/svg/clock.svg';
import Solve from '../../../assets/svg/puzzle-pieces.svg';
import Skills from '../../../assets/svg/skill-alt.svg';
import Certificate from '../../../assets/svg/degree-credential.svg';
import { useNavigate } from 'react-router-dom';

const AssessmentStart = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [welcomeScreen, setWelcomeScreen] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const handleCertificationClick = () => {
        setOpen(true); // Open the pop-up form
    };

    const handleClose = () => setOpen(false);

    const handleStartTest = () => {
        // Close form and open welcome screen
        navigate('/assessment-confirmation-form')
        setOpen(false);
        setWelcomeScreen(true);
    };

    const handleContinue = () => {
        // Navigate to the assessment test page
        navigate('/assessment-test'); // Adjust the route as necessary
    };

    return (
        <>
            <IndividualHeader />
            <Container maxWidth="xl" sx={{ textAlign: 'center', mt: { xs: 8, md: 16 }, mb: { xs: 6, md: 10 } }}>
                {/* Title Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h6" sx={{ color: '#6c757d', fontWeight: '400' }}>
                        Get your assessment as a
                    </Typography>
                    <Typography variant="h3" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                        Frontend Developer
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#333', marginBottom: 3 }}>
                        (React)
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#6c757d', maxWidth: 700, mx: 'auto', marginBottom: 4 }}>
                        Optima AI assessment helps you stand out when applying to jobs and is trusted by companies worldwide.
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={handleCertificationClick}
                        sx={{
                            backgroundColor: '#ff2600', // Primary color for the button
                            color: 'white',
                            fontWeight: 'bold',
                            paddingX: 5,
                            paddingY: 1.8,
                            fontSize: '1rem',
                            borderRadius: '10px',
                            textTransform: 'uppercase',
                            '&:hover': {
                                backgroundColor: '#e02400',
                            },
                        }}
                    >
                        Get Assessment
                    </Button>
                    <Typography variant="body2" sx={{ color: '#6c757d', marginTop: 2 }}>
                        Join 3 million Optima AI Hire certified developers
                    </Typography>
                </Box>

                {/* Requirements Section */}
                <Box
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: 3,
                        boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)',
                        paddingY: 4,
                        paddingX: 4,
                        mt: 8,
                        mx: { xs: 2, md: 6 },
                    }}
                >
                    <Grid container alignItems="left" justifyContent="left">
                        <Grid item xs={12} sm={3} md={3} textAlign="left">
                            <Box>
                                <img src={Clock} alt="1 hr assessment" style={{ height: 35, marginBottom: 8 }} />
                                <Typography variant="body1" sx={{ fontWeight: 'normal', fontSize: '1rem' }}>
                                    Complete a 1 hr assessment
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={3} md={3} textAlign="left">
                            <Box>
                                <img src={Solve} alt="Solve 4 questions" style={{ height: 35, marginBottom: 8 }} />
                                <Typography variant="body1" sx={{ fontWeight: 'normal', fontSize: '1rem' }}>
                                    Solve 4 questions
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={3} md={3} textAlign="left">
                            <Box>
                                <img src={Skills} alt="Demonstrate your skills" style={{ height: 35, marginBottom: 8 }} />
                                <Typography variant="body1" sx={{ fontWeight: 'normal', fontSize: '1rem' }}>
                                    Demonstrate your skills
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={3} md={3} textAlign="left">
                            <Box>
                                <img src={Certificate} alt="Earn a certificate" style={{ height: 35, marginBottom: 8 }} />
                                <Typography variant="body1" sx={{ fontWeight: 'normal', fontSize: '1rem' }}>
                                    Earn an Optima AI certificate
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                {/* Pop-up Form Modal */}
                <Modal open={open} onClose={handleClose}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            borderRadius: 2,
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                            Confirm your details
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#6c757d', mb: 2 }}>
                            This will be displayed on your certificate and cannot be changed later.
                        </Typography>
                        <TextField
                            fullWidth
                            label="First Name"
                            variant="outlined"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            label="Last Name"
                            variant="outlined"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            sx={{ mb: 2 }}
                        />
                        <Alert severity="info" sx={{ mb: 2 }}>
                            <Typography variant="caption">
                                Make sure you’re in a quiet environment with a stable internet connection. Good Luck!
                            </Typography>
                        </Alert>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button onClick={handleClose} sx={{ color: '#ff2600' }}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleStartTest}
                                sx={{
                                    backgroundColor: '#ff2600',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: '#e02400',
                                    },
                                }}
                            >
                                Start Test
                            </Button>
                        </Box>
                    </Box>
                </Modal>

                {/* Welcome Screen */}
                {/* {welcomeScreen && (
                    <Container maxWidth="md" sx={{ textAlign: 'center', py: 6, mt: 6 }}>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                            Welcome to Frontend Developer (React) Role Certification Test
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 3 }}>
                            Hey {firstName} {lastName},
                        </Typography>
                        <Box sx={{ textAlign: 'left', mx: 'auto', maxWidth: 500 }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Instructions
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                1. This is a timed test. Make sure you are not interrupted.
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                2. Ensure a stable internet connection.
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1 }}>
                                3. Try the sample test before taking the main test.
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 3 }}>
                                4. Go through the FAQs to resolve queries.
                            </Typography>
                            <Button variant="contained" onClick={handleContinue} sx={{ backgroundColor: '#ff2600', '&:hover': { backgroundColor: '#e02400' } }}>
                                Continue
                            </Button>
                        </Box>
                    </Container>
                )} */}
            </Container>
        </>
    );
};

export default AssessmentStart;
