// import React, { useRef, useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
// import CommonApi from "../../apis/CommonApi";
// import { Box, Container, Typography, Button } from '@mui/material';
// import io from "socket.io-client";
// import "./Main.css";

// // Initialize Socket.IO client
// const socket = io("http://localhost:8000");

// function Main() {
//   const location = useLocation();
//   const { job, resume } = location.state || { job: null, resume: null };
//   const { transcript, resetTranscript, listening } = useSpeechRecognition();
//   const [isListening, setIsListening] = useState(true);
//   const [lastTranscript, setLastTranscript] = useState('');
//   const [processing, setProcessing] = useState(false);
//   const [speaking, setSpeaking] = useState(false);
//   const [response, setResponse] = useState('');
//   const [audioUrl, setAudioUrl] = useState(''); // Add state to store audio URL
//   const [error, setError] = useState(null);
//   const [greeted, setGreeted] = useState(false);
//   const [interviewPhase, setInterviewPhase] = useState('resume');
//   const [questionCount, setQuestionCount] = useState(0);
//   const [showReport, setShowReport] = useState(false);
//   const maxResponseLength = 150;
//   const debounceTimeoutRef = useRef(null);
//   const localVideoRef = useRef(null); // Local video reference
//   const remoteVideoRef = useRef(null); // Remote video reference
//   const peerConnectionRef = useRef(null);
//   const audioRef = useRef(null); // Reference for audio element

//   // Environment check states
//   const [cameraStatus, setCameraStatus] = useState('Not Detected');
//   const [microphoneStatus, setMicrophoneStatus] = useState('Not Detected');
//   const [speakerStatus, setSpeakerStatus] = useState('Not Detected');
//   const [internetSpeed, setInternetSpeed] = useState(0);
//   const [environmentCheckComplete, setEnvironmentCheckComplete] = useState(false);

//   const speakText = (text) => {
//     const synth = window.speechSynthesis;
//     const utterance = new SpeechSynthesisUtterance(text);
//     utterance.onstart = () => {
//       SpeechRecognition.stopListening();
//       setIsListening(false);
//       setSpeaking(true);
//     };
//     utterance.onend = () => {
//       SpeechRecognition.startListening({ continuous: true });
//       setIsListening(true);
//       setSpeaking(false);
//     };
//     synth.speak(utterance);
//   };

//   useEffect(() => {
//     // Automatically start listening on component mount
//     if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
//       setError("Browser does not support speech recognition.");
//       return;
//     }
//     SpeechRecognition.startListening({ continuous: true });
//     setIsListening(true);
//   }, []);

//   useEffect(() => {
//     const skills = Array.isArray(resume?.primarySkills) ? resume.primarySkills.join(', ') : 'no specific skills mentioned';
//     if (isListening && resume && resume.name && job && !greeted) {
//       const greetCandidate = async () => {
//         try {
//           const initialPrompt = `You are an AI interviewer. Greet the candidate ${resume.name} and start with some questions based on their resume details, focusing on their skills: ${skills}.`;
//           const result = await CommonApi.generateResponseWithAudio({ prompt: initialPrompt });
//           const greeting = result.response;

//           setResponse(greeting);
//           setAudioUrl(result.audio);
//           playAudio(result.audio);
//           setGreeted(true);
//         } catch (error) {
//           setError('Failed to greet candidate.');
//         }
//       };
//       greetCandidate();
//     }
//   }, [isListening, resume, job, greeted]);

//   useEffect(() => {
//     if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
//       return;
//     }

//     const handleSpeechEnd = async () => {
//       if (!isListening || processing || transcript === lastTranscript || transcript.trim().length < 5) {
//         return;
//       }

//       const debounceDuration = Math.min(4000, Math.max(2000, transcript.length * 100));
//       clearTimeout(debounceTimeoutRef.current);

//       debounceTimeoutRef.current = setTimeout(async () => {
//         setProcessing(true);
//         setLastTranscript(transcript);

//         try {
//           const skills = Array.isArray(resume?.primarySkills) ? resume.primarySkills.join(', ') : 'no specific skills mentioned';
//           let currentPrompt;

//           if (interviewPhase === 'resume') {
//             currentPrompt = `You are an AI interviewer. Continue the interview with questions based on the candidate's resume. Focus on their skills: ${skills}, projects, and experiences. Candidate's response: ${transcript}.`;
//             if (questionCount >= 4) {
//               setInterviewPhase('role');
//             }
//           } else if (interviewPhase === 'role') {
//             currentPrompt = `You are an AI interviewer. Now continue the interview with industry-level questions based on the job role "${job?.roleName}", job description: "${job?.description}", and primary skills: "${job?.primarySkills.join(', ')}". Candidate's response: ${transcript}.`;
//           }

//           const result = await CommonApi.generateResponseWithAudio({ prompt: currentPrompt });
//           handleResponse(result.response, result.audio);
//           setQuestionCount(prevCount => prevCount + 1);

//           if (questionCount + 1 === 15) {
//             setShowReport(true);
//           }
//         } catch (error) {
//           setError('Failed to fetch response from the server.');
//         } finally {
//           resetTranscript();
//           setProcessing(false);
//         }
//       }, debounceDuration);
//     };

//     handleSpeechEnd();
//   }, [transcript, lastTranscript, processing, resetTranscript, job, resume, interviewPhase, isListening, questionCount]);

//   const handleResponse = (responseText, audioUrl) => {
//     setResponse(responseText);
//     setAudioUrl(audioUrl);
//     setSpeaking(true);

//     if (audioUrl) {
//       playAudio(audioUrl);
//     } else {
//       speakText(responseText);
//     }

//     setSpeaking(false);
//   };

//   const playAudio = (audioUrl) => {
//     if (audioRef.current) {
//       audioRef.current.src = audioUrl;
//       audioRef.current.play().catch((error) => {
//         console.error("Error playing audio:", error);
//         alert("Audio playback failed. Please check the audio file format and source.");
//       });
//     }
//   };

//   const checkCamera = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ video: true });
//       setCameraStatus('Working');
//       stream.getTracks().forEach(track => track.stop());
//     } catch (error) {
//       setCameraStatus('Not Detected');
//     }
//   };

//   const checkMicrophone = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//       setMicrophoneStatus('Working');
//       stream.getTracks().forEach(track => track.stop());
//     } catch (error) {
//       setMicrophoneStatus('Not Detected');
//     }
//   };

//   const checkSpeaker = () => {
//     // Create an audio element
//     const audio = new Audio('http://localhost:8000/api/uploads/audio.mp3'); // Use the correct path to your audio file

//     // Attempt to play the audio
//     audio.play().then(() => {
//       setSpeakerStatus('Working');
//     }).catch((error) => {
//       console.error("Error playing audio:", error);
//       setSpeakerStatus('Not Detected');
//     });
//   };

//   const checkInternetSpeed = () => {
//     // Simplified internet speed check logic
//     setTimeout(() => {
//       const speed = Math.floor(Math.random() * 50) + 10; // Random speed between 10 and 50 Mbps
//       setInternetSpeed(speed);
//     }, 2000);
//   };

//   const checkEnvironment = () => {
//     checkCamera();
//     checkMicrophone();
//     checkSpeaker();
//     checkInternetSpeed();
//     setTimeout(() => {
//       setEnvironmentCheckComplete(true);
//     }, 3000); // Allow 3 seconds for checks to complete
//   };

//   return (
//     <Container className="main-container">
//       {!environmentCheckComplete ? (
//         <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
//           <Typography variant="h4">Environment Check</Typography>
//           <Box mt={2}>
//             <Typography>Camera: <span style={{ color: cameraStatus === 'Working' ? 'green' : 'red' }}>{cameraStatus}</span></Typography>
//             <Button onClick={checkCamera} variant="contained">Check Camera</Button>
//           </Box>
//           <Box mt={2}>
//             <Typography>Microphone: <span style={{ color: microphoneStatus === 'Working' ? 'green' : 'red' }}>{microphoneStatus}</span></Typography>
//             <Button onClick={checkMicrophone} variant="contained">Check Microphone</Button>
//           </Box>
//           <Box mt={2}>
//             <Typography>Speaker: <span style={{ color: speakerStatus === 'Working' ? 'green' : 'red' }}>{speakerStatus}</span></Typography>
//             <Button onClick={checkSpeaker} variant="contained">Check Speaker</Button>
//           </Box>
//           <Box mt={2}>
//             <Typography>Internet Speed: {internetSpeed} Mbps</Typography>
//             <Button onClick={checkInternetSpeed} variant="contained">Test Speed</Button>
//           </Box>
//           <Button
//             onClick={checkEnvironment}
//             variant="contained"
//             color="primary"
//             disabled={cameraStatus !== 'Working' || microphoneStatus !== 'Working' || speakerStatus !== 'Working' || internetSpeed < 10}
//             style={{ marginTop: '20px' }}
//           >
//             Start Interview
//           </Button>
//         </Box>
//       ) : (
//         <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh" position="relative">
//           <Box
//             width={150}
//             height={150}
//             borderRadius="50%"
//             bgcolor="black"
//             className={`dot ${speaking ? "speaking" : ""}`}
//             title={speaking ? "Responding..." : "Waiting for response"}
//             position="absolute"
//             top="50%"
//             left="50%"
//             transform="translate(-50%, -50%)"
//           />
//           <audio ref={audioRef} /> {/* Audio element for playing dynamic audio */}
//           <Box
//             position="fixed"
//             bottom="20px"
//             right="20px"
//             width="200px"
//             height="150px"
//             border="2px solid black"
//             borderRadius="8px"
//             overflow="hidden"
//           >
//             <video ref={localVideoRef} autoPlay playsInline muted className="video" />
//           </Box>
//         </Box>
//       )}
//     </Container>
//   );
// }

//  const localVideoRef = useRef(null);

// export default Main;

//========================================================================================================

import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import {
  Box,
  Container,
  Typography,
  Modal,
  Button,
  Rating,
} from "@mui/material";
import { CSSTransition } from "react-transition-group";
import CommonApi from "../../apis/CommonApi";
import ReactMarkdown from "react-markdown";
import "./Main.css";

function Main({ job, resume }) {
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [speaking, setSpeaking] = useState(false);
  const [candidateSpeaking, setCandidateSpeaking] = useState(false);
  const [greeted, setGreeted] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [interviewComplete, setInterviewComplete] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [rating, setRating] = useState(0);
  const [askForQuestions, setAskForQuestions] = useState(false); // Whether the bot asks "Do you have anything to ask?"
  const debounceTimeoutRef = useRef(null);
  const location = useLocation();
  // const { job, resume } = location.state || { job: null, resume: null };

  const [finalQuestionsCount, setFinalQuestionsCount] = useState(0);

  // For recording
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);


  useEffect(() => {
    if (!job || !resume) {
      console.error("Job or resume data is missing");
    }
  }, [job, resume]);

  // Function to start recording candidate's webcam video and microphone audio
  async function startRecording() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: { echoCancellation: true }, // Ensure both video and audio are being recorded
      });

      console.log("Media stream tracks:", stream.getTracks());

      const recorder = new MediaRecorder(stream);
      setMediaRecorder(recorder);

      recorder.ondataavailable = (event) => {
        console.log("Data available size:", event.data.size);  // Log the data size
      
        if (event.data.size > 0) {
          setRecordedChunks((prev) => [...prev, event.data]);  // Ensure that chunks are added to the array
        } else {
          console.error("No data available in event.");
        }
      };
      

      recorder.onstop = () => {
        console.log("Recorded Chunks:", recordedChunks);  // Check if recordedChunks contains valid data
      
        if (recordedChunks.length > 0) {
          const mimeType = MediaRecorder.isTypeSupported("video/mp4") ? "video/mp4" : "video/webm";
          const blob = new Blob(recordedChunks, { type: mimeType });
          console.log("Blob created with size:", blob.size);  // Log the blob size to verify
      
          const url = URL.createObjectURL(blob);
          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.download = `interview_recording.${mimeType.split("/")[1]}`;
          downloadLink.click();
        } else {
          console.error("No recorded chunks available, cannot create blob.");
        }
      };
      
      
      recorder.start();
      console.log("Recording started"); // Log to ensure recording is starting
      setIsRecording(true); // Set recording status to true

      // Show the candidate's video on the screen, but mute it to avoid feedback
      const videoElement = document.getElementById("candidate-video");
      videoElement.srcObject = stream;
      videoElement.muted = true;
      videoElement.play();
    } catch (error) {
      console.error("Error starting video/audio recording: ", error);
    }
  }

  useEffect(() => {
    if (!job || !resume) {
      console.error("Job or resume data is missing");
      // Optionally, redirect back to a safe page if data is missing
    }
  }, [job, resume]);

  // Function to stop the recording automatically after the interview ends
  function stopRecording() {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false); // Mark as not recording
    }
  }

  const speakText = (text) => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voice =
      synth
        .getVoices()
        .find((v) => v.name.includes("Google UK English Female")) ||
      synth.getVoices()[0];

    utterance.onstart = () => {
      setSpeaking(true);
      setCandidateSpeaking(false);
      SpeechRecognition.stopListening();
    };

    utterance.onend = () => {
      setSpeaking(false);
      if (!interviewComplete) {
        setTimeout(() => {
          SpeechRecognition.startListening({ continuous: true });
        }, 500);
      }
    };

    synth.speak(utterance);
  };

  const fetchChatResponse = async (prompt, updatedHistory) => {
    try {
      setIsProcessing(true);
      const result = await CommonApi.generateChatResponse(
        prompt,
        resume,
        job,
        conversationHistory,
        finalQuestionsCount
      );
      const responseText = result.response;

      const newHistory = [
        ...updatedHistory,
        { role: "assistant", content: responseText },
      ];

      // Handle final wrap-up if wrapUp flag is set from the backend
      if (result.wrapUp) {
        setInterviewComplete(true); // Mark the interview as complete
        stopRecording(); // Stop the recording
        speakText(responseText); // Thank the candidate
        setTimeout(() => {
          setShowRatingModal(true); // Show the rating modal after wrap-up
        }, 1000);
        return; // Exit early since the interview is wrapped up
      }

      setConversationHistory(newHistory);

      if (!result.evaluation) {
        setTimeout(() => {
          setCurrentQuestion(responseText);
        }, 300);
      } else {
        setCurrentQuestion(""); // Clear the question when the interview is complete
      }

      speakText(responseText);
      resetTranscript();
      setIsProcessing(false);
    } catch (error) {
      console.error("Error generating chat response:", error);
      setIsProcessing(false);
    }
  };

  // Function to wrap up the interview
  const wrapUpInterview = () => {
    setInterviewComplete(true);
    stopRecording(); // Stop recording after the interview ends
    setTimeout(() => {
      setShowRatingModal(true); // Show rating modal after interview wraps up
    }, 1000);
  };

  const greetCandidate = () => {
    if (
      !greeted &&
      (transcript.toLowerCase().includes("hello") ||
        transcript.toLowerCase().includes("hi"))
    ) {
      const greeting = `Hey ${
        resume?.name || "there"
      }! It's awesome to have you here. Let's get started!`;
      fetchChatResponse(greeting);
      setGreeted(true);
    }
  };

  const handleCandidateResponse = () => {
    if (!isProcessing && transcript.trim().length > 0 && !interviewComplete) {
      const updatedHistory = [
        ...conversationHistory,
        { role: "user", content: transcript.trim() },
      ];
      if (askForQuestions) {
        // If chatbot asked for questions, acknowledge and wrap up
        speakText(
          "Thank you for your question! We'll wrap up the interview now."
        );
        wrapUpInterview();
      } else {
        fetchChatResponse(transcript.trim(), updatedHistory);
        resetTranscript();
      }
    }
  };

  const detectEndOfSpeech = () => {
    clearTimeout(debounceTimeoutRef.current);
    debounceTimeoutRef.current = setTimeout(() => {
      if (!speaking && transcript.trim().length > 0 && !interviewComplete) {
        handleCandidateResponse();
      }
    }, 1500);
  };

  const interruptBotSpeech = () => {
    const synth = window.speechSynthesis;
    if (synth.speaking) {
      synth.cancel();
      if (!interviewComplete) {
        SpeechRecognition.startListening({ continuous: true });
      }
    }
  };

  useEffect(() => {
    if (candidateSpeaking && speaking) {
      interruptBotSpeech();
    }
  }, [candidateSpeaking, speaking]);

  useEffect(() => {
    if (!interviewComplete) {
      SpeechRecognition.startListening({ continuous: true });
    }
  }, [interviewComplete]);

  useEffect(() => {
    if (transcript.trim().length > 0) {
      setCandidateSpeaking(true);
    } else {
      setCandidateSpeaking(false);
    }

    detectEndOfSpeech();
  }, [transcript]);

  // Start recording when the page loads
  useEffect(() => {
    if (!isRecording) {
      startRecording(); // Automatically start recording when the component mounts
    }
  }, []);

  // Function to close the rating modal
  const handleRatingClose = () => {
    setShowRatingModal(false);
    console.log("Candidate's rating:", rating);
  };

  return (
    <Container className="main-container">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100vh"
        position="relative"
      >
        {currentQuestion && !interviewComplete && (
          <CSSTransition
            in={!interviewComplete}
            timeout={500}
            classNames="fade"
            unmountOnExit
          >
            <Box className="question-box">
              <Typography variant="body1" align="left">
                {currentQuestion}
              </Typography>
            </Box>
          </CSSTransition>
        )}

        {/* Add video element to show candidate's webcam stream */}
        <video
          id="candidate-video"
          width="400"
          height="300"
          autoPlay
          playsInline
        />

        <Box
          width={100}
          height={100}
          borderRadius="50%"
          bgcolor="black"
          className={`dot ${speaking ? "speaking" : ""}`}
          title={speaking ? "Chatbot is responding..." : "Waiting for response"}
          marginBottom={4}
        />
        <Typography
          variant="h6"
          align="center"
          color={speaking ? "primary" : "textSecondary"}
        >
          {speaking
            ? "Chatbot is speaking..."
            : candidateSpeaking
            ? "You are speaking..."
            : "Waiting for your response..."}
        </Typography>

        {/* Modal for rating the interview process */}
        <Modal open={showRatingModal} onClose={handleRatingClose}>
          <Box
            className="modal-content"
            padding={3}
            bgcolor="white"
            borderRadius={5}
            boxShadow={3}
            width="50%"
            height="auto"
            margin="auto"
            marginTop="10%"
          >
            <Typography
              variant="h6"
              align="center"
              color="primary"
              gutterBottom
            >
              Rate Your Interview Process
            </Typography>
            <Rating
              name="interview-rating"
              value={rating}
              onChange={(event, newValue) => {
                setRating(newValue);
              }}
            />
            <Button
              onClick={handleRatingClose}
              color="primary"
              variant="contained"
              style={{ marginTop: "20px" }}
            >
              Submit
            </Button>
          </Box>
        </Modal>
      </Box>
    </Container>
  );
}

export default Main;

//========================================================================================================
