// import React from 'react';
// import { Box, Typography, Grid, Button, Divider, IconButton, Tooltip } from '@mui/material';
// import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
// import SmsIcon from '@mui/icons-material/Sms';
// import LockIcon from '@mui/icons-material/Lock';
// import EmailIcon from '@mui/icons-material/Email';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import NewReleasesIcon from '@mui/icons-material/NewReleases';

// const VerificationMethod = ({ icon, title, description, newBadge }) => (
//     <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ py: 1 }}>
//         <Box display="flex" alignItems="center" gap={2}>
//             <Box>{icon}</Box>
//             <Box>
//                 <Typography variant="body1" fontWeight="bold" display="flex" alignItems="center" gap={1}>
//                     {title} {newBadge && <NewReleasesIcon color="primary" fontSize="small" />}
//                 </Typography>
//                 <Typography variant="body2" color="textSecondary">
//                     {description}
//                 </Typography>
//             </Box>
//         </Box>
//         <Button variant="text" color="primary" sx={{ textTransform: 'none' }}>
//             Enable
//         </Button>
//     </Box>
// );

// const TwoStepVerification = () => (
//     <Box>
//         <Typography variant="h6" component="h2" fontWeight="bold" sx={{ mb: 1 }}>
//             2-step verification
//             <Tooltip title="Protect your account with an extra layer of security" arrow>
//                 <IconButton size="small" color="primary">
//                     <InfoOutlinedIcon fontSize="small" />
//                 </IconButton>
//             </Tooltip>
//         </Typography>
//         <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
//             Protect your account by adding an extra layer of security when you log in with an email and password.
//         </Typography>

//         <Divider sx={{ my: 1 }} />

//         <VerificationMethod
//             icon={<AppRegistrationIcon color="primary" />}
//             title="Verify logins with the App"
//             description="Get a push notification from the app to verify logins."
//             newBadge
//         />
        
//         <Divider sx={{ my: 1 }} />

//         <VerificationMethod
//             icon={<SmsIcon color="primary" />}
//             title="Verify logins by phone (SMS)"
//             description="Get a verification code sent to your mobile phone."
//         />

//         <Divider sx={{ my: 1 }} />

//         <VerificationMethod
//             icon={<LockIcon color="primary" />}
//             title="Verify logins with an authenticator app"
//             description="Make sure you have an authenticator app installed on your mobile before you start."
//         />

//         <Divider sx={{ my: 1 }} />

//         <VerificationMethod
//             icon={<EmailIcon color="primary" />}
//             title="Verify logins by email"
//             description="Receive a verification code via email to confirm logins."
//         />
//     </Box>
// );

// export default TwoStepVerification;
import React, { useState } from 'react';
import { Box, Typography, Divider, IconButton, Tooltip, Switch, Paper, Grid, Modal, Button, TextField } from '@mui/material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SmsIcon from '@mui/icons-material/Sms';
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import CommonApi from '../../../apis/CommonApi';

const VerificationMethod = ({ icon, title, description, newBadge, isEnabled, onToggle }) => (
    <Paper sx={{ p: 2, boxShadow: 1, borderRadius: 2, height: '100%' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap={1}>
                {icon}
                <Box>
                    <Typography variant="body1" fontWeight="bold" display="flex" alignItems="center" gap={1} sx={{ lineHeight: 1.2 }}>
                        {title} {newBadge && <NewReleasesIcon color="primary" fontSize="small" />}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {description}
                    </Typography>
                </Box>
            </Box>
            <Switch
                checked={isEnabled}
                onChange={onToggle}
                color="primary"
                inputProps={{ 'aria-label': `${title} toggle` }}
            />
        </Box>
    </Paper>
);

const TwoStepVerification = () => {
    const [appEnabled, setAppEnabled] = useState(false);
    const [smsEnabled, setSmsEnabled] = useState(false);
    const [authenticatorEnabled, setAuthenticatorEnabled] = useState(false);
    const [emailEnabled, setEmailEnabled] = useState(false);
    const [showQRCodeModal, setShowQRCodeModal] = useState(false);
    const [qrCodeDataURL, setQRCodeDataURL] = useState('');
    const [secret, setSecret] = useState('');
    const [otpToken, setOtpToken] = useState('');

    const handleEnableAuthenticator = async () => {
        setAuthenticatorEnabled(prev => !prev);

        if (!authenticatorEnabled) {
            try {
                const data = await CommonApi.generateTOTPQRCode();
                setQRCodeDataURL(data.qrCodeDataURL);
                setSecret(data.secret);
                setShowQRCodeModal(true);
            } catch (error) {
                console.error("Failed to generate QR code:", error);
            }
        }
    };

    const handleVerifyToken = async () => {
        try {
            const response = await CommonApi.verifyTOTPToken({ token: otpToken });
            alert(response.message);
            setShowQRCodeModal(false);
        } catch (error) {
            console.error("Error verifying token:", error);
            alert("Failed to verify token. Please try again.");
        }
    };

    return (
        <Paper sx={{ p: 3, boxShadow: 3, borderRadius: 2, height: '100%' }}>
            <Typography variant="h6" component="h2" fontWeight="bold" sx={{ mb: 1 }}>
                2-step verification
                <Tooltip title="Protect your account with an extra layer of security" arrow>
                    <IconButton size="small" color="primary">
                        <InfoOutlinedIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                Protect your account by adding an extra layer of security when you log in with an email and password.
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <VerificationMethod
                        icon={<AppRegistrationIcon color="primary" />}
                        title="Verify logins with the App"
                        description="Get a push notification from the app to verify logins."
                        newBadge
                        isEnabled={appEnabled}
                        onToggle={() => setAppEnabled(!appEnabled)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <VerificationMethod
                        icon={<SmsIcon color="primary" />}
                        title="Verify logins by phone (SMS)"
                        description="Get a verification code sent to your mobile phone."
                        isEnabled={smsEnabled}
                        onToggle={() => setSmsEnabled(!smsEnabled)}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <VerificationMethod
                        icon={<LockIcon color="primary" />}
                        title="Verify logins with an authenticator app"
                        description="Make sure you have an authenticator app installed on your mobile before you start."
                        isEnabled={authenticatorEnabled}
                        onToggle={handleEnableAuthenticator}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <VerificationMethod
                        icon={<EmailIcon color="primary" />}
                        title="Verify logins by email"
                        description="Receive a verification code via email to confirm logins."
                        isEnabled={emailEnabled}
                        onToggle={() => setEmailEnabled(!emailEnabled)}
                    />
                </Grid>
            </Grid>

            {/* Modal for QR Code */}
            <Modal open={showQRCodeModal} onClose={() => setShowQRCodeModal(false)}>
                <Paper sx={{ p: 4, maxWidth: 400, mx: 'auto', mt: 5, textAlign: 'center' }}>
                    <Typography variant="h6">Scan QR Code with an authenticator app</Typography>
                    {qrCodeDataURL && <img src={qrCodeDataURL} alt="QR Code" style={{ margin: '20px 0' }} />}
                    <Typography variant="body2">Secret Key: {secret}</Typography>
                    <TextField
                        label="Enter OTP"
                        value={otpToken}
                        onChange={(e) => setOtpToken(e.target.value)}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                    <Button variant="contained" color="primary" onClick={handleVerifyToken} sx={{ mt: 2 }}>
                        Verify
                    </Button>
                </Paper>
            </Modal>
        </Paper>
    );
};

export default TwoStepVerification;
