import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Avatar,
  Button,
  Chip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

// Function to generate a random pastel color
const getRandomPastelColor = () => {
  const hue = Math.floor(Math.random() * 360);
  const saturation = 60;
  const lightness = 85;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: "15px !important",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    width: "100%",
    height: "280px", // Set a fixed height for consistency
    transition: "transform 0.3s, box-shadow 0.3s",
    "&:hover": {
      transform: "scale(1.02)",
      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.15)",
    },
  },
  cardContent: {
    padding: "20px",
    height: "100%", // Ensure the content fills the card
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // Space out the content vertically
  },
  cardContentBox: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: (props) => props.backgroundColor,
    borderRadius: "10px",
    padding: "15px",
    flexGrow: 1, // Make this section grow to fill the space
    marginBottom: "15px", // Add margin to create space between the colored part and company size
    justifyContent: "center", // Center content vertically
  },
  topSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  companyNameText: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    color: "#333",
  },
  chipsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    marginTop: "10px",
  },
  avatar: {
    width: "60px",
    height: "60px",
  },
  detailsBtn: {
    borderRadius: "20px !important",
    backgroundColor: "#1a1a1a !important",
    textTransform: "none",
    color: "white",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#444444",
    },
  },
  sizeText: {
    fontSize: "1.1rem",
    fontWeight: "bold",
  },
}));

const CompanyCard = ({ company, onDetailsClick }) => {
  const backgroundColor = getRandomPastelColor();
  const classes = useStyles({ backgroundColor });

  // Ensure the `onDetailsClick` function is provided and only then use it
  const handleDetailsClick = (e) => {
    e.stopPropagation(); // Prevent the event from bubbling up to the card click
    if (typeof onDetailsClick === 'function') {
      onDetailsClick(company); // Pass the company data to the handler
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.cardContentBox}>
          <Box className={classes.topSection}>
            <Box>
              <Typography className={classes.roleText}>Company Name</Typography>
              <Typography className={classes.companyNameText}>
                {company.companyName}
              </Typography>
              <Box className={classes.chipsContainer}>
                {company.industry && (
                  <Chip label={company.industry} size="small" />
                )}
                {company.country && (
                  <Chip label={company.country} size="small" />
                )}
                {company.state && <Chip label={company.state} size="small" />}
                {company.city && <Chip label={company.city} size="small" />}
              </Box>
            </Box>
            <Avatar
              src={company.logo}
              alt={company.companyName}
              className={classes.avatar}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography className={classes.sizeText}>
              {company.size}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Company Size
            </Typography>
          </Box>
          <Button
            variant="contained"
            className={classes.detailsBtn}
            onClick={handleDetailsClick} // Use the function handler here
          >
            Details
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CompanyCard;
