import { green, grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";


const LayoutStyles = makeStyles(() => ({

    topSmallBox: {
        color: "#b6b6b6 !important",
        fontSize: '0.8rem',
        fontWeight: 'bold'
    },

    topSmallBoxValue: {
        color: "#121212",
        fontSize: '1.8rem !important',
        fontWeight: 'bolder !important'
    },

    valueStatusUp: {
        // backgroundColor: green[200],
        color: green[900],
        borderRadius: '8px',
        fontSize: '0.8rem !important',
    },
    comparisionText: {
        color: grey[700],
        fontSize: '0.7rem !important'
    }

    // Navbar styles


    // progress: {
    //     background: "#EA6A47 !important"
    // }
    // routesBox: {
    //     height: '78vh',
    //     overflow: 'auto',
    //     "&::-webkit-scrollbar": {
    //         width: "6px",
    //     }, '&::-webkit-scrollbar-thumb': {
    //         backgroundColor: '#f1f1f1',
    //         borderRadius: '10px'
    //     }
    // },


    // sidebar

    // sidebar: {
    //     position: "fixed",
    //     maxWidth: "75px",
    //     width: "100%",
    //     background: "#FFFFFF",
    //     top: 0,
    //     left: 0,
    //     height: "100%",
    //     overflowY: "auto",
    //     scrollbarWidth: "none",
    //     transition: "all .3s ease",
    //     zIndex: 200,
    //     boxShadow: "3px 3px 5px -1px rgba(0, 0, 0, 0.05)",
    //     "&::-webkit-scrollbar": {
    //         display: 'none',
    //     },
    // },
    // sidebarOpen: {
    //     maxWidth: "260px",
    // },

    // brand: {
    //     fontSize: "24px",
    //     display: "flex",
    //     alignItems: "center",
    //     height: "64px",
    //     fontWeight: 600,
    //     color: "rgba(24, 26, 24, 1)",
    //     position: "sticky",
    //     top: 0,
    //     left: 0,
    //     zIndex: 100,
    //     background: "rgba(255, 255, 255, 1)",
    //     transition: "all .3s ease",
    //     // padding: "0 18px",
    //     padding: "0 12px",
    // },

    // sideMenu: {
    //     margin: "32px 0",
    //     // padding: "0 20px",
    //     padding: "0 10px",
    //     transition: "all .3s ease",
    //     backgroundColor: "rgba(255, 255, 255, 1)",
    // },
    // sidebarLink: {
    //     display: "flex",
    //     alignItems: "center",
    //     fontSize: "14px",
    //     color: "rgba(24, 26, 24, 1)",
    //     padding: "12px 15.5px",
    //     transition: "all .3s ease",
    //     borderRadius: "8px",
    //     margin: "6px 0",
    //     whiteSpace: "nowrap",
    // },

    // sidebarLinkActive: {
    //     backgroundColor: "rgba(12, 117, 235, 1)",
    //     color: "#FFFFFF",
    //     transition: "all .3s ease",
    // },

    // sidebarLinkTextMs: {
    //     margin: "0px 14px",
    //     transition: "all .3s ease",
    // },
    // sidebarLinkTextMl: {
    //     margin: "0px 26px",
    //     transition: "all .3s ease",
    // },

    // sideDropdown: {
    //     marginLeft: "42px",
    //     paddingLeft: "8px",
    //     maxHeight: "0px",
    //     overflowY: "hidden",
    //     transition: "all .3s ease",
    //     "&::-webkit-scrollbar": {
    //         display: 'none',
    //     },

    //     borderLeft: "1px solid rgba(12, 117, 235, 1)"
    // },

    // sideDropdownShow: {
    //     maxHeight: "1000px",
    //     transition: "all .3s ease",
    // },
    // sidebarSideDropdownLink: {
    //     display: "flex",
    //     alignItems: "center",
    //     fontSize: "14px",
    //     color: "rgba(24, 26, 24, 1)",
    //     padding: "10px 14px",
    //     transition: "all .3s ease",
    //     borderRadius: "10px",
    //     margin: "4px 0",
    //     whiteSpace: "nowrap",
    // },






    // // Main Content

    // content: {
    //     backgroundColor: "rgba(253, 253, 253, 1)",
    //     position: "relative",
    //     // width: "calc(100% - 260px)",
    //     // left: "260px",
    //     width: "calc(100% - 60px)",
    //     left: "60px",
    //     transition: "all .3s ease",
    // },

    // // NAV
    // nav: {
    //     backgroundColor: "#FFFFFF",
    //     height: "68px",
    //     padding: "0 20px",
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "space-around",
    //     gridGap: "28px",
    //     position: "sticky",
    //     top: 0,
    //     left: 0,
    //     zIndex: 100,
    // },
    // globalSearchInput: {
    //     border: "none",
    //     padding: "0px 0px 0px 10px",
    //     width: "440px",
    //     height: "100%",
    //     background: "transparent",
    //     color: "rgba(38, 38, 38, 1)",
    //     fontFamily: "Nunito Sans, sans-serif",
    //     fontSize: "14px",
    //     fontWeight: "600",
    //     transition: "all .3s ease",
    //     '&::-webkit-input-placeholder': {
    //         color: "rgba(199, 204, 211, 1)",
    //     },
    //     '&:focus': {
    //         outline: "none"
    //     }
    // },

    // // NAV




    // // MAIN
    // main: {
    //     width: "100%",
    //     padding: "20px",
    // },

    // EmployeesSearchInput: {
    //     border: "none",
    //     padding: "0px 0px 0px 10px",
    //     width: "215px",
    //     height: "100%",
    //     background: "transparent",
    //     color: "rgba(38, 38, 38, 1)",
    //     fontFamily: "Nunito Sans, sans-serif",
    //     fontSize: "14px",
    //     fontWeight: "600",
    //     transition: "all .3s ease",
    //     '&::-webkit-input-placeholder': {
    //         color: "rgba(199, 204, 211, 1)",
    //     },
    //     '&:focus': {
    //         outline: "none"
    //     }
    // },
    // checkBox: {

    //     border: '1px solid #c4c2c2',

    //     height: '52px',

    //     borderRadius: '10px', alignItems: 'center !important',

    //     display: 'flex',

    //     flexDirection: 'row'

    // },

    // checkBoxbg: {

    //     height: '52px',

    //     borderRadius: '10px', alignItems: 'center !important',

    //     display: 'flex',

    //     flexDirection: 'row',

    //     background: '#FAFAFA !important'

    // },
    // mediumGreyText: {
    //     fontSize: "14px !important",
    //     fontFamily: "Nunito Sans, sans-serif",
    //     letterSpacing: '0px',
    //     color: "#707070",
    //     opacity: 1
    // },

    // // MAIN

    // inviteLinkBtn: {
    //     margin: "12px 0px",
    //     all: "unset",
    //     cursor: "pointer",
    //     textAlign: "center",
    //     fontSize: "16px",
    //     fontFamily: "Nunito Sans, sans-serif",
    //     fontWeight: "400",
    //     width: "150px",
    //     height: "40px",
    //     border: "1.5px solid rgba(12, 117, 235, 1)",
    //     borderRadius: "6px",
    //     color: "rgba(12, 117, 235, 1)",
    //     transition: "all .3s ease",
    //     '&:hover': {
    //         backgroundColor: 'rgba(12, 117, 235, 1)', color: '#FFFFFF'
    //    },  
    // },
    // onboardBtn: {
    //     all: "unset",
    //     cursor: "pointer",
    //     textAlign: "center",
    //     fontSize: "16px",
    //     fontFamily: "Nunito Sans, sans-serif",
    //     fontWeight: "400",
    //     width: "150px",
    //     height: "40px",
    //     border: "1.5px solid rgba(12, 117, 235, 1)",
    //     borderRadius: "6px",
    //     color: "rgba(12, 117, 235, 1)",
    //     transition: "all .3s ease",
    //     '&:hover': {
    //         backgroundColor: 'rgba(12, 117, 235, 1)', color: '#FFFFFF'
    //    }, 
    // },
    // nextBtn:{
    //     all: "unset", 
    //     padding: "0px 14px", 
    //     display: "flex", 
    //     justifyContent: "center", 
    //     alignItems: "center", 
    //     cursor: "pointer", 
    //     textAlign: "center", 
    //     fontSize: "16px", 
    //     fontFamily: "Nunito Sans, sans-serif", 
    //     fontWeight: "400", 
    //     minWidth: "50px", 
    //     height: "35px", 
    //     border: "1.5px solid rgba(12, 117, 235, 1)", 
    //     backgroundColor: "rgba(12, 117, 235, 1)", 
    //     boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.08)", 
    //     borderRadius: "6px", 
    //     color: "#ffffff", 
    //     transition: "all .3s ease", 
    //     '&:hover': {
    //         backgroundColor: '#FFFFFF', color: 'rgba(12, 117, 235, 1)'
    //    },
    // },

    // cancelBtn:{
    //     all: "unset", 
    //     padding: "0px 16px", 
    //     margin: "0px 18px", 
    //     display: "flex", 
    //     justifyContent: "center", 
    //     alignItems: "center", 
    //     cursor: "pointer", 
    //     textAlign: "center", 
    //     fontSize: "16px", 
    //     fontFamily: "Nunito Sans,  sans-serif", 
    //     fontWeight: "400", 
    //     minWidth: "60px", 
    //     height: "35px", 
    //     border: "1px solid rgba(229, 26, 26, 1)", 
    //     backgroundColor: "#FFFFFF", 
    //     borderRadius: "6px", 
    //     color: "rgba(229, 26, 26, 1)",
    //     transition: "all .3s ease", 
    //     '&:hover': {
    //         backgroundColor: 'rgba(229, 26, 26, 1)', color: '#FFFFFF'
    //    }, 
    // },

    // btn:{
    //     padding: "0px 12px", 
    //     justifyContent: "center", 
    //     alignItems: "center", 
    //     cursor: "pointer", 
    //     textAlign: "center", 
    //     fontSize: "16px",
    //     fontFamily: "Nunito Sans, sans-serif", 
    //     color: '#FFFFFF', 
    //     background: 'rgba(12, 117, 235, 1)', 
    //     borderRadius: '8px', 
    //     border: 'none',
    //     height: '40px', 
    //     marginTop: '15px',
    //     // border: "1.5px solid rgba(12, 117, 235, 1)",
    //     transition: "all .3s ease", 
    //     '&:hover': {
    //         backgroundColor: '#FFFFFF', color: 'rgba(12, 117, 235, 1)'
    //    },
    //    textStyle:{
    //     fontSize: '16px !important',        
    //     color: 'rgba(38, 38, 38, 1) !important',
    //     fontFamily: "Nunito Sans, sans-serif !important"
    //    }
    // }


}))

export default LayoutStyles;
