import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Card, CardContent, CardHeader, Box, Typography } from '@mui/material';

const GeographicalDistribution = ({ data }) => {
  const defaultPosition = [20, 0]; // Center of the map for a global view

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title="Geographical Distribution" />
      <CardContent>
        <Box sx={{ height: 350, borderRadius: '8px', overflow: 'hidden' }}>
          {data.locations.length > 0 ? (
            <MapContainer
              center={defaultPosition}
              zoom={2}
              style={{ height: '100%', width: '100%' }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />
              {data.locations.map((location, index) => (
                <Marker
                  key={index}
                  position={[
                    location.latitude || 20, // Default latitude if null
                    location.longitude || 0, // Default longitude if null
                  ]}
                >
                  <Popup>
                    <Typography variant="body2">
                      <strong>{location.city || "Unknown Location"}</strong><br />
                      Companies: {location.companyCount}
                    </Typography>
                  </Popup>
                </Marker>
              ))}
            </MapContainer>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No location data available
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default GeographicalDistribution;
