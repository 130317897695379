import React, { useState, useEffect } from 'react';
import UserProfileStyles from './UserProfileStyles';
import LocalStorage from '../../../../utils/LocalStorage';
import { Box, Typography, Breadcrumbs, Link, Avatar, Stack, Grid, Button, Divider, ListItemButton } from '@mui/material';
import AvatarSvg from '../../../../assets/svg/avatar.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GeneralDetails from './generaldetails';
import Documents from './document/document/Document';
import IndividualEmployeeAPI from '../../../../apis/employee/addEmployee/IndividualEmployee'

// import name from '../../';

// import { useNavigate } from "react-router-dom";


// const generalDetailsTab = ["General Details", "Contact Details", "Emergency Contact", "Current Address", "Employment Details"];

const dataArr = ["General Details", "Document", "Bank Details", "Immigration", "Timesheet", "Invoice"];


export default function ControlledAccordions() {

    const classes = UserProfileStyles();
    // const navigate = useNavigate();
    // const [employees, setEmployees] = useState([]);
    const [current, setCurrent] = useState("general-details");

    const [individualData, setIndividualData] = useState()
    const request_id = LocalStorage.getEmployeeId();

    // const getIndividualData = () => {
    //     // const data = {
    //     //     // employee_id: individualData.request_id
    //     //     request_id: LocalStorage.uid()
    //     // }
    //     IndividualEmployeeAPI.IndividualEmployee(LocalStorage.uid(),'ed892096-e8bf-40fe-88d3-82e7e112226f', LocalStorage.getAccessToken()).then((res) => {
    //         if (res.data.statusCode === 1003) {
    //             setIndividualData(res.data.data);
    //         }
    //     })
    // }

    // useEffect(() => {
    //     getIndividualData()
    // }, [])

      // getting employee data for sidebar details
  const getEmpData = (id) => {
    IndividualEmployeeAPI.IndividualEmployee(LocalStorage.uid(), id ? id : request_id, LocalStorage.getAccessToken()).then((response) => {
      if (response.data.statusCode == 1003) {
        setIndividualData(response.data.data);
        console.log(response.data.data, "individual data");
      }
    });
  };

  useEffect(() => {
    getEmpData(request_id)
  }, [request_id])

    console.log('individualData', individualData)

    const handleClick = (event) => {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    const basic = individualData?.basic_details
    console.log('basic', basic)

    return (
        <Box className={classes.mainContainer} px={5} py={1}>
            <Box mx={2}>
                <div role="presentation" onClick={handleClick} >
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" className={classes.breadcrumbsLink} color="inherit" href="/employees">
                            Employees
                        </Link>
                        <Typography className={classes.breadcrumbsName}>User Profile</Typography>
                    </Breadcrumbs>
                </div>
            </Box>
            <Box my={2} mx={2}>
                <Grid container spacing={4}>
                    <Grid item lg={3} md={3} sm={4} xs={12}>
                        <Box style={{ width: "100%", minHeight: "76vh", boxShadow: "0px 0px 2px 0px #919EAB4D", borderRadius: "6px" }}>
                                <Box px={3} py={2}>
                                    <Box my={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                                        <Box className={classes.avatarBox}>
                                            <Avatar
                                                alt="Remy Sharp"
                                                src={AvatarSvg}
                                                className={classes.avatar}
                                            />
                                        </Box>
                                    </Box>
                                    <Typography className={classes.profileName}>{basic?.full_name}</Typography>
                                    <Typography className={classes.profileId}>423113455</Typography>
                                    <Stack my={1} direction="row" justifyContent={"center"} spacing={2}>
                                        <Button variant="outlined" className={classes.eVerifyBtn}>
                                            E-verify
                                        </Button>
                                        <Button variant="outlined" className={classes.moreBtn}>
                                            <MoreVertIcon sx={{ fontSize: "18px", color: "#9D9E9F" }} />
                                        </Button>
                                    </Stack>
                                </Box>
                            <Box px={3}>
                                <Divider />
                            </Box>

                            <Box py={3}>
                                <Box px={3} sx={{ maxHeight: "27vh", overflow: "auto", }}>
                                    {
                                        dataArr.map((item, key) => (
                                            <ListItemButton
                                                key={key}
                                                className={`${classes.listItems} ${key === 0 ? classes.listItemsActive : null}`}
                                            >
                                                {item}
                                            </ListItemButton>
                                        ))}
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={9} md={9} sm={8} xs={12}>
                        {
                            current === "general-details" ? <GeneralDetails individualData={individualData ? individualData :''} setIndividualData={setIndividualData} /> : null
                        }
                        {
                            current === "documents" ? <Documents /> : null
                        }
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}