import { makeStyles } from '@mui/styles'; 


const useStyles = makeStyles(() => ({
  mainContainer:{
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)', 

  },
  header: {
    backgroundColor: '#FF2600',
    color: '#fff',
    padding: '10px',
    textAlign: 'center',
    marginBottom: '10px',
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
  },
  tableContainer: {
    height: 500,
    width: '100%', 
    margin: '0 auto',
    border: '2px solid #ff2600', 
    borderRadius: '4px', 
    overflow: 'hidden',
     borderTopLeftRadius: '0px', 
     borderTopRightRadius: '0px' ,
  },
}));

export default useStyles;
