import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CommonApi from '../../apis/CommonApi.js'; 
import LocalStorage from '../../utils/LocalStorage.js';
import useStyles from './ListOfJobsStyles.js';
import { useDropzone } from 'react-dropzone';
import { Document, Packer, Paragraph, TextRun } from "docx"; 
import { saveAs } from "file-saver";

const JobDescriptionsTable = () => {
  const classes = useStyles(); 
  const [jobsData, setJobsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [gridApi, setGridApi] = useState(null);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [parsingDialogOpen, setParsingDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  // const fetchJobs = async () => {
  //   const token = LocalStorage.getAccessToken();
  //   const companyData = LocalStorage.getCompanyData();

  //   try {
  //     const response = await CommonApi.getJobsByCompany(companyData._id, token);
  //     setJobsData(response.jobs || []);
  //   } catch (error) {
  //     console.error("Error fetching jobs:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const fetchJobs = async () => {
    const token = LocalStorage.getAccessToken();
    const companyData = LocalStorage.getCompanyData();
  
    try {
      const response = await CommonApi.getJobsByCompany(companyData._id, token);
      const apiJobs = response.jobs || [];
  
      // Get jobs stored in localStorage
      const localStorageJobs = JSON.parse(localStorage.getItem("jobDescriptions")) || []; 
  
      // Combine both API jobs and localStorage jobs
      setJobsData([...apiJobs, ...localStorageJobs]);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setLoading(false);
    }
  };
  

  // useEffect(() => {
  //   fetchJobs();
  // }, []);
  // useEffect(() => {
  //   fetchJobs();
  
  //   // Listen for localStorage changes and update the table accordingly
  //   window.addEventListener("storage", fetchJobs);
  
  //   // Cleanup event listener on component unmount
  //   return () => {
  //     window.removeEventListener("storage", fetchJobs);
  //   };
  // }, []);

  useEffect(() => {
    fetchJobs();

    
    
    // Listen for the custom event and update jobs immediately when a new job is added
    const handleJobAdded = (event) => {
      setJobsData((prevJobs) => [...prevJobs, event.detail]); // Add the new job to the current job list
    };
  
    window.addEventListener("jobAdded", handleJobAdded);
  
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("jobAdded", handleJobAdded);
    };
  }, []);
  
  const onDrop = (acceptedFiles) => {
    setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 1024 * 1024 * 5,
    accept: 'application/pdf',
  });

  // Handle saving the edited job data immediately after editing
  const saveEditedData = async (updatedJob) => {
    const token = LocalStorage.getAccessToken();
    try {
      const response = await CommonApi.updateJob(updatedJob._id, updatedJob, token);
      console.log('Updated job:', response);
    } catch (error) {
      console.error('Error updating job:', error);
    }
  };

  // Handle editing cell data
  const handleCellEditingStopped = (event) => {
    const updatedData = event.data;
    console.log('Updated row data:', updatedData);
    saveEditedData(updatedData); // Save data immediately after editing
  };

  // Handle opening cell editing for specific fields
  const startEditing = (rowIndex, colKey) => {
    gridApi.startEditingCell({
      rowIndex,
      colKey,
    });
  };

  const openUploadDialog = (jobId) => {
    setSelectedJob(jobId);
    setUploadDialogOpen(true);
  };

  const handleUploadClick = async (jobId) => {
    setUploadDialogOpen(false); // Close the upload dialog when the user clicks "Upload"
    setParsingDialogOpen(true); // Show parsing dialog when upload starts

    const token = LocalStorage.getAccessToken();
    const user = LocalStorage.getUserData();

    if (!user || !user.candidateProfileId) {
      console.error("Candidate profile ID is missing");
      setParsingDialogOpen(false); 
      return;
    }

    const formData = new FormData();
    if (uploadedFiles.length === 0) {
      console.error("No files uploaded");
      setParsingDialogOpen(false); 
      return;
    }

    uploadedFiles.forEach((file) => formData.append("resumes", file));
    formData.append("jobDescriptionId", jobId);
    formData.append("candidateProfileId", user.candidateProfileId);

    try {
      const response = await CommonApi.parseResume(formData, token);
      if (response && response.results) {
        const parsedResumesCount = response.results.length;
        await updateJobApplicationCount(jobId, parsedResumesCount);
        setSuccessMessage(`Resume parsed successfully. Job ID ${jobId} has been updated.`);
        setUploadedFiles([]);
      } else {
        throw new Error("Invalid response from the server.");
      }
    } catch (error) {
      console.error("Error parsing resumes:", error);
      setSuccessMessage("Failed to parse resumes. Please try again.");
    } finally {
      setParsingDialogOpen(false);
    }
  };

  const updateJobApplicationCount = async (jobId, parsedResumesCount) => {
    try {
      const token = LocalStorage.getAccessToken();
      const job = jobsData.find((job) => job._id === jobId);
      if (!job) throw new Error("Job not found.");
      
      const updatedData = {
        ...job,
        application: parsedResumesCount,
      };
      
      await CommonApi.updateJob(jobId, updatedData, token);
      console.log(`Job application count updated to ${parsedResumesCount}`);
    } catch (error) {
      console.error("Error updating job application count:", error);
    }
  };

  // Export job description to DOCX
  const handleExportClick = (job) => {
    const primarySkills = job.primarySkills ? job.primarySkills.join(", ") : "n/a";
    const secondarySkills = job.secondarySkills ? job.secondarySkills.join(", ") : "n/a";

    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: `Job Description for ${job.roleName}`,
                  bold: true,
                  size: 28,
                }),
              ],
            }),
            new Paragraph(`Client: ${job.client}`),
            new Paragraph(`Role: ${job.roleName}`),
            new Paragraph(`Domain: ${job.domain}`),
            new Paragraph(`Education: ${job.education}`),
            new Paragraph(`Experience: ${job.experience}`),
            new Paragraph(`Level: ${job.level}`),
            new Paragraph(`Mode of Work: ${job.modeOfWork}`),
            new Paragraph(`Number of Positions: ${job.numberOfPositions}`),
            new Paragraph(`Number of Applications: ${job.application}`),
            new Paragraph(`Status: ${job.status}`),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Primary Skills: ",
                  bold: true,
                }),
                new TextRun(primarySkills),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Secondary Skills: ",
                  bold: true,
                }),
                new TextRun(secondarySkills),
              ],
            }),
            new Paragraph(`Description: ${job.description}`),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${job.roleName}_jobdescription.docx`);
      console.log("Document created successfully");
    });
  };

  // Custom action renderer for edit, upload, and export buttons
  const ActionCellRenderer = (params) => (
    <div>
      <Tooltip title="Edit">
        <IconButton onClick={(event) => {
          event.stopPropagation();
          console.log('Edit Clicked:', params.data);
          startEditing(params.rowIndex, 'roleName');
        }}>
          <EditIcon color="primary" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Upload Resume">
        <IconButton onClick={(event) => {
          event.stopPropagation(); 
          openUploadDialog(params.data._id);
        }}>
          <UploadIcon color="action" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Export Job Description">
        <IconButton onClick={(event) => {
          event.stopPropagation();
          handleExportClick(params.data);
        }}>
          <FileDownloadIcon color="secondary" />
        </IconButton>
      </Tooltip>
    </div>
  );

  // Column definitions
  const columnDefs = [
    {
      headerName: "Role",
      field: "roleName",
      editable: true,
      sortable: true,
      onCellValueChanged: handleCellEditingStopped,
    },
    {
      headerName: "Applications",
      field: "application",
      editable: true,
      sortable: true,
      onCellValueChanged: handleCellEditingStopped,
    },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: ActionCellRenderer,
    },
  ];

  // Handle grid ready
  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };
  

  return (
    <Box className={classes.mainContainer}>
      <Typography variant="h6" className={classes.header}>
        List of Job Descriptions
      </Typography>
      <div className={`ag-theme-alpine ${classes.tableContainer}`}>
        <AgGridReact
          rowData={jobsData}
          columnDefs={columnDefs}
          pagination={true}
          paginationPageSize={8}
          defaultColDef={{ flex: 1, minWidth: 100 }}
          onGridReady={onGridReady}
        />
      </div>

      {/* Upload Resume Dialog */}
      <Dialog open={uploadDialogOpen} onClose={() => setUploadDialogOpen(false)}>
        <DialogTitle>Upload Resume</DialogTitle>
        <DialogContent>
          <Box {...getRootProps()} border="1px dashed grey" p={3} mb={2} textAlign="center">
            <input {...getInputProps()} />
            <Typography>Drag 'n' drop resumes here, or click to select files</Typography>
          </Box>
          <Box>
            {uploadedFiles.length > 0 && uploadedFiles.map((file, index) => (
              <Typography key={index}>{file.name}</Typography>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUploadDialogOpen(false)} color="secondary">Cancel</Button>
          <Button onClick={() => handleUploadClick(selectedJob)} color="primary">Upload</Button>
        </DialogActions>
      </Dialog>

      {/* Parsing Resumes Dialog */}
      <Dialog open={parsingDialogOpen} onClose={() => setParsingDialogOpen(false)}>
        <DialogContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ ml: 2 }}>Parsing resumes...</Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default JobDescriptionsTable;

