
import React, { useState, useEffect } from 'react';
import { Box, Typography, Link, IconButton, Dialog, DialogTitle, DialogContent, TextField, Button, RadioGroup, FormControlLabel, Radio, Grid, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CommonApi from '../../../apis/CommonApi';

const Employment = () => {
    const [open, setOpen] = useState(false);
    const [employmentData, setEmploymentData] = useState([]);
    const [formValues, setFormValues] = useState({
        isCurrent: "yes",
        employmentType: "full-time",
        totalExperience: "",
        months: "",
        companyName: "",
        jobTitle: "",
        joiningDate: "",
        joiningMonth: "",
        salary: "",
        skillsUsed: "",
        jobProfile: "",
        noticePeriod: ""
    });
    const [error, setError] = useState(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Function to fetch employment data from backend
    const fetchEmploymentData = async () => {
        try {
            const data = await CommonApi.getAccountInformation();
            setEmploymentData(data.employment || []);
        } catch (error) {
            console.error("Error fetching employment data:", error);
        }
    };

    useEffect(() => {
        fetchEmploymentData(); // Fetch employment data when component mounts
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSaveEmployment = async () => {
        try {
            setError(null);
            await CommonApi.updateEmployment(formValues); // Update employment data in backend
            fetchEmploymentData(); // Refresh data in frontend after successful update
            handleClose();
        } catch (error) {
            console.error("Error updating employment:", error);
            setError("Failed to update employment. Please try again.");
        }
    };

    return (
        <Box sx={{ mb: 4 }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Employment</Typography>
                <Link href="#" variant="body2" color="primary" onClick={handleOpen}>Add employment</Link>
            </Box>
            {employmentData.map((employment, index) => (
                <Box key={index} mb={2}>
                    <Box display="flex" alignItems="center" mb={1}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{employment.jobTitle}</Typography>
                        <IconButton size="small" color="primary" onClick={handleOpen}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </Box>
                    <Typography variant="body2" color="textSecondary">{employment.companyName}</Typography>
                    <Typography variant="body2" color="textSecondary">
                        {employment.employmentType} | {employment.joiningMonth} {employment.joiningDate} to Present
                    </Typography>
                    <Typography variant="body2" color="textSecondary">{employment.jobProfile}</Typography>
                </Box>
            ))}

            {/* Employment Dialog */}
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Employment
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                        Details like job title, company name, etc., help employers understand your work.
                    </Typography>
                    <RadioGroup row name="isCurrent" value={formValues.isCurrent} onChange={handleChange} sx={{ mb: 2 }}>
                        <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>Is this your current employment?</Typography>
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                    </RadioGroup>

                    <RadioGroup row name="employmentType" value={formValues.employmentType} onChange={handleChange} sx={{ mb: 2 }}>
                        <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>Employment type</Typography>
                        <FormControlLabel value="full-time" control={<Radio />} label="Full-time" />
                        <FormControlLabel value="internship" control={<Radio />} label="Internship" />
                    </RadioGroup>

                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Total experience" variant="outlined" name="totalExperience" value={formValues.totalExperience} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Months" variant="outlined" name="months" value={formValues.months} onChange={handleChange} />
                        </Grid>
                    </Grid>

                    <TextField fullWidth label="Current company name" variant="outlined" name="companyName" value={formValues.companyName} onChange={handleChange} sx={{ mb: 2 }} />
                    <TextField fullWidth label="Current job title" variant="outlined" name="jobTitle" value={formValues.jobTitle} onChange={handleChange} sx={{ mb: 2 }} />

                    <Grid container spacing={2} mb={2}>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Joining date" variant="outlined" name="joiningDate" value={formValues.joiningDate} onChange={handleChange} select>
                                <MenuItem value="2020">2020</MenuItem>
                                <MenuItem value="2021">2021</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField fullWidth label="Month" variant="outlined" name="joiningMonth" value={formValues.joiningMonth} onChange={handleChange} select>
                                <MenuItem value="January">January</MenuItem>
                                <MenuItem value="February">February</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>

                    <TextField fullWidth label="Current salary" variant="outlined" name="salary" value={formValues.salary} onChange={handleChange} sx={{ mb: 2 }} />
                    <TextField fullWidth label="Skills used" variant="outlined" name="skillsUsed" value={formValues.skillsUsed} onChange={handleChange} sx={{ mb: 2 }} />
                    <TextField fullWidth label="Job profile" variant="outlined" name="jobProfile" value={formValues.jobProfile} onChange={handleChange} multiline rows={4} sx={{ mb: 2 }} />
                    <TextField fullWidth label="Notice period" variant="outlined" name="noticePeriod" value={formValues.noticePeriod} onChange={handleChange} select>
                        <MenuItem value="15 days">15 days</MenuItem>
                        <MenuItem value="1 month">1 month</MenuItem>
                    </TextField>

                    <Box display="flex" justifyContent="space-between" mt={3}>
                        <Button onClick={handleClose} color="primary">Cancel</Button>
                        <Button variant="contained" color="primary" onClick={handleSaveEmployment}>Save</Button>
                    </Box>
                    {error && (
                        <Typography color="error" variant="body2" mt={2}>
                            {error}
                        </Typography>
                    )}
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default Employment;


