// import { makeStyles } from "@mui/styles";

// const SidebarStyles = makeStyles(()=> ({
   
//      // Navbar

//      nav: {
//         backgroundColor: "#FFFFFF",
//         height: "68px",
//         padding: "0 20px",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "space-around",
//         gridGap: "28px",
//         position: "sticky",
//         top: 0,
//         left: 0,
//         zIndex: 100,
//         borderBottom: '1px solid lightgrey'
//     },

//     globalSearchInput: {
//         border: "none",
//         padding: "0px 0px 0px 10px",
//         width: "440px",
//         height: "100%",
//         background: "transparent",
//         color: "rgba(38, 38, 38, 1)",
//         fontFamily: "Nunito Sans, sans-serif",
//         fontSize: "14px",
//         fontWeight: "600",
//         transition: "all .3s ease",
//         '&::-webkit-input-placeholder': {
//             color: "rgba(199, 204, 211, 1)",
//         },
//         '&:focus': {
//             outline: "none"
//         }
//     },
   
//     searchIcon:{
//     all: "unset", 
//     display: "flex", 
//     justifyContent: "center", 
//     alignItems: "center", 
//     cursor: "pointer", 
//     textAlign: "center", 
//     fontSize: "16px", 
//     fontFamily: "Nunito Sans, sans-serif", 
//     width: "45px", 
//     height: "38px", 
//     border: "none", 
//     backgroundColor: "#FFFFFF", 
//     borderRadius: "6px", 

// },

//      searchField:{ 
//         height: "40px", 
//         border: "1.5px solid rgba(199, 204, 211, 1)", 
//         width: "460px", 
//         borderRadius: "6px", 
//         display: "flex", 
//         justifyContent: "space-between", 
//         alignItems: "center" 
//     },

//     rightItems:{ 
//     width: "100%", 
//     display: "flex", 
//     justifyContent: "end", 
//     alignItems: "center", 
//     gap: "14px", 
//     padding: "0px 22px",
//  },

//  bellButton:{ 
//     all: "unset", 
//     display: "flex", 
//     justifyContent: "center", 
//     alignItems: "center", 
//     cursor: "pointer", 
//     textAlign: "center", 
//     fontSize: "16px", 
//     fontFamily: "Nunito Sans, sans-serif", 
//     width: "32px", 
//     height: "32px", 
//     border: "1.5px solid rgba(199, 204, 211, 1)", 
//     backgroundColor: "#FFFFFF", 
//     borderRadius: "6px", 
// },

// image:{
//     height:'20px',
//     width:'20px'
// },
//  Avatar:{
//     height:'35px',
//     width:'35px',
//  },
//  breadCrumbContainer:{
// marginBottom: "20px",
// backgroundColor: "lightgrey",
//  },
// }))


// export default SidebarStyles;


// import { makeStyles } from "@mui/styles";

// const SidebarStyles = makeStyles(() => ({
  
//   nav: {
//     backgroundColor: "#FFFFFF",
//     height: "50px", 
//     // padding: "0 10px", 
//     paddingLeft: "30px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     position: "sticky",
//     top: 0,
//     left: 0,
//     zIndex: 100,
//     borderBottom: '1px solid lightgrey',
//   },
//   companyName: {
//     backgroundColor: "#e2e2e2",
//     padding: "4px 8px",
//     borderRadius: "9999px",
//     color: "#4a4a4a",
//   },
//   navSection: {
//     backgroundColor: "#FF2600", 
//     height: "40px", 
//     display: "flex",
//     alignItems: "center",
//     // padding: "0 10px",
//   },
//   navLinks: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     color: "white",
//   },
//   navLink: {
//     margin: "0 10px",
//     color: "white",
//     textDecoration: "none",
//     transition: "color 0.3s",
//     "&:hover": {
//       color: "#e0e0e0",
//     },
//   },
//   searchInput: {
//     padding: "4px 8px", 
//     border: "1px solid #ccc",
//     borderRadius: "9999px",
//     color: "#4a4a4a",
//   },
//   breadcrumbContainer: {
//     // marginBottom: "20px",
//     paddingTop: "6px",
//     paddingLeft: "12px",
//     backgroundColor: "lightgrey",
//     height: "40px",
//   },
// }));

// export default SidebarStyles;

// import { makeStyles } from "@mui/styles";

// const SidebarStyles = makeStyles(() => ({
//   // Navbar
//   nav: {
//     backgroundColor: "#FFFFFF",
//     height: "50px",
//     paddingLeft: "30px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     position: "sticky",
//     top: 0,
//     left: 0,
//     zIndex: 100,
//     borderBottom: '1px solid lightgrey',
//   },

//   companyName: {
//     backgroundColor: "#e2e2e2",
//     padding: "4px 8px",
//     borderRadius: "9999px",
//     color: "#4a4a4a",
//   },

//   navSection: {
//     backgroundColor: "#FF2600",
//     height: "40px",
//     display: "flex",
//     alignItems: "center",
//   },

//   navLinks: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     color: "white",
//   },

//   navLink: {
//     margin: "0 10px",
//     color: "white",
//     textDecoration: "none",
//     transition: "color 0.3s",
//     "&:hover": {
//       color: "#e0e0e0",
//     },
//   },

//   searchInput: {
//     padding: "4px 8px",
//     border: "1px solid #ccc",
//     borderRadius: "9999px",
//     color: "#4a4a4a",
//   },

//   breadcrumbContainer: {
//     paddingTop: "6px",
//     paddingLeft: "12px",
//     backgroundColor: "lightgrey",
//     height: "40px",
//   },

//   // Dropdown Styles
//   dropdown: {
//     position: "absolute",
//     top: "100%",
//     marginTop: "30px",
//     right: 0,
//     background: "white",
//     border: "1px solid #ff2600",
//     borderRadius: "4px",
//     boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
//     zIndex: 1000,
//   },

//   dropdownButton: {
//     display: "block",
//     width: "100%",
//     padding: "10px",
//     marginRight: "60px",
//     border: "none",
//     background: "transparent",
//     cursor: "pointer",
//     textAlign: "left",
//     "&:hover": {
//       background: "#ff2600 !important",
      
//     },
//   },
// }));

// export default SidebarStyles;

// import { makeStyles } from "@mui/styles";
// import { useTheme } from "@mui/material";

// const NavbarStyles = makeStyles(() => ({
//   // Navbar
//   nav: {
//     backgroundColor: "#FFFFFF",
//     height: "50px",
//     paddingLeft: "30px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     position: "sticky",
//     top: 0,
//     left: 0,
//     zIndex: 100,
//     borderBottom: '1px solid lightgrey',
//     [useTheme().breakpoints.down("sm")]: {
//     //   paddingLeft: "15px", // Less padding on small screens
//     justifyContent: "space-between",
//     },
//   },

//   companyName: {
//     backgroundColor: "#e2e2e2",
//     padding: "4px 8px",
//     borderRadius: "9999px",
//     color: "#4a4a4a",
//   },

//   navSection: {
//     backgroundColor: "#FF2600",
//     height: "40px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     [useTheme().breakpoints.down("sm")]: {
//       height: "60px", // Reduce height for small screens
//     },
//   },

//   navLinks: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     color: "white",
//     [useTheme().breakpoints.down("sm")]: {
//       flexDirection: "column", // Stack links on small screens
//       alignItems: "flex-start", // Align items to the left
//     },
//   },

//   navLink: {
//     margin: "0 10px",
//     color: "white",
//     textDecoration: "none",
//     transition: "color 0.3s",
//     "&:hover": {
//       color: "#e0e0e0",
//     },
//     [useTheme().breakpoints.down("sm")]: {
//     //   margin: "5px 0", // Add margin for stacked links
//     //   fontSize: "12px", // Smaller font size on small screens
//     padding: "10px", // Add padding for mobile links
//     width: "100%", // Full width for better touch targets
//     },
//   },

//   searchInput: {
//     padding: "4px 8px",
//     border: "1px solid #ccc",
//     borderRadius: "9999px",
//     color: "#4a4a4a",
//   },

//   breadcrumbContainer: {
//     paddingTop: "6px",
//     paddingLeft: "12px",
//     backgroundColor: "lightgrey",
//     height: "40px",
//   },

//   // Dropdown Styles
//   dropdown: {
//     position: "absolute",
//     top: "100%",
//     marginTop: "30px",
//     right: 0,
//     background: "white",
//     border: "1px solid #ff2600",
//     borderRadius: "4px",
//     boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
//     zIndex: 1000,
//   },

//   dropdownButton: {
//     display: "block",
//     width: "100%",
//     padding: "10px",
//     marginRight: "60px",
//     border: "none",
//     background: "transparent",
//     cursor: "pointer",
//     textAlign: "left",
//     "&:hover": {
//       background: "#ff2600 !important",
//     },
//   },
// }));

// export default NavbarStyles;

// import { makeStyles } from "@mui/styles";
// import { useTheme } from "@mui/material";

// const NavbarStyles = makeStyles(() => ({
//   // Navbar
//   nav: {
//     backgroundColor: "#FFFFFF",
//     height: "50px",
//     paddingLeft: "30px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     position: "sticky",
//     top: 0,
//     left: 0,
//     zIndex: 100,
//     borderBottom: '1px solid lightgrey',
//   },

//   companyName: {
//     backgroundColor: "#e2e2e2",
//     padding: "4px 8px",
//     borderRadius: "9999px",
//     color: "#4a4a4a",
//   },

//   navSection: {
//     backgroundColor: "#FF2600",
//     height: "40px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//   },

//   navLinks: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     color: "white",
//   },

//   navLink: {
//     margin: "0 10px",
//     color: "white",
//     textDecoration: "none",
//     transition: "color 0.3s",
//     "&:hover": {
//       color: "#e0e0e0",
//     },
//   },

//   breadcrumbContainer: {
//     paddingTop: "6px",
//     paddingLeft: "12px",
//     backgroundColor: "lightgrey",
//     height: "40px",
//   },

//   // Dropdown Styles
//   dropdown: {
//     position: "absolute",
//     top: "100%",
//     marginTop: "30px",
//     right: 0,
//     background: "white",
//     border: "1px solid #ff2600",
//     borderRadius: "4px",
//     boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
//     zIndex: 1000,
//   },

//   dropdownButton: {
//     display: "block",
//     width: "100%",
//     padding: "10px",
//     marginRight: "60px",
//     border: "none",
//     background: "transparent",
//     cursor: "pointer",
//     textAlign: "left",
//     "&:hover": {
//       background: "#ff2600 !important",
//     },
//   },

//   // Mobile Menu Styles
//   mobileMenu: {
//     display: "flex",
//     flexDirection: "column",
//     backgroundColor: "#FF2600",
//     position: "absolute",
//     top: "50px", // Adjust to sit below the navbar
//     width: "100%",
//     zIndex: 1000,
//   },

// }));

// export default NavbarStyles;

// import { makeStyles } from "@mui/styles";
// import { useTheme } from "@mui/material";

// const NavbarStyles = makeStyles(() => ({
//   // Navbar
//   nav: {
//     backgroundColor: "#FFFFFF",
//     height: "50px",
//     paddingLeft: "30px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//     position: "sticky",
//     top: 0,
//     left: 0,
//     zIndex: 100,
//     borderBottom: '1px solid lightgrey',
//     paddingRight: '30px',
//   },

//   companyName: {
//     backgroundColor: "#e2e2e2",
//     padding: "4px 8px",
//     borderRadius: "9999px",
//     color: "#4a4a4a",
//   },

//   navSection: {
//     backgroundColor: "#FF2600",
//     height: "40px",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "space-between",
//   },

//   navLinks: {
//     display: "flex",
//     justifyContent: "space-between",
//     // alignItems: "center",
//     color: "white",
//     paddingLeft: "25px !important",
//     [useTheme().breakpoints.down("md")]: { // Adjust the breakpoint for tablet
//       display: "none", // Hide links for mobile and tablet
//     },
//     [useTheme().breakpoints.down("lg")]: { // Adjust the breakpoint for tablet
//         display: "none", 
//         paddingLeft: "25px !important",
//       },
//   },

//   navLink: {
//     margin: "0 10px",
//     color: "white",
//     textDecoration: "none",
//     transition: "color 0.3s",
//     "&:hover": {
//       color: "#e0e0e0",
//     },
//   },

//   breadcrumbContainer: {
//     paddingTop: "6px",
//     paddingLeft: "12px",
//     backgroundColor: "lightgrey",
//     height: "40px",
//   },

//   // Dropdown Styles
//   dropdown: {
//     position: "absolute",
//     top: "100%",
//     marginTop: "30px",
//     right: 0,
//     background: "white",
//     border: "1px solid #ff2600",
//     borderRadius: "4px",
//     boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
//     zIndex: 1000,
//   },

//   dropdownButton: {
//     display: "block",
//     width: "100%",
//     padding: "10px",
//     marginRight: "60px",
//     border: "none",
//     background: "transparent",
//     cursor: "pointer",
//     textAlign: "left",
//     "&:hover": {
//       background: "#ff2600 !important",
//     },
//   },

//   // Mobile and Tablet Menu Styles
//   mobileMenu: {
//     display: "flex",
//     flexDirection: "column",
//     backgroundColor: "#FF2600",
//     position: "absolute",
//     top: "50px", // Adjust to sit below the navbar
//     width: "100%",
//     zIndex: 1000,
//   },

//   // Responsive styles
//   hamburgerMenu: {
//     display: "block", // Show hamburger icon on mobile and tablet
//     [useTheme().breakpoints.up("md")]: {
//       display: "none", // Hide on larger screens
//     },
//   },
// }));

// export default NavbarStyles;

import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material";

const useStyles = makeStyles(() => ({
  // Navbar
  nav: {
    backgroundColor: "#FFFFFF",
    height: "50px",
    width: '100%',
    paddingLeft: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 100,
    borderBottom: '1px solid lightgrey',
    paddingRight: '30px',
  },

  companyName: {
    backgroundColor: "#e2e2e2",
    padding: "4px 8px",
    borderRadius: "9999px",
    color: "#4a4a4a",
    [useTheme().breakpoints.down("sm")]: { // Adjust for tablet and mobile screens
      display: "none", // Hide orange header on tablet and mobile
    },
  },

  navSection: {
    backgroundColor: "#FF2600",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [useTheme().breakpoints.down("md")]: { // Adjust for tablet and mobile screens
      display: "none", // Hide orange header on tablet and mobile
    },
  },

  navLinks: {
    display: "flex",
    justifyContent: "space-between",
    color: "white",
    paddingLeft: '25px',
  },

  navLink: {
    margin: "0 10px",
    color: "white",
    textDecoration: "none",
    transition: "color 0.3s",
    "&:hover": {
      color: "#e0e0e0",
    },
  },

  breadcrumbContainer: {
    paddingTop: "6px",
    paddingLeft: "12px",
    backgroundColor: "lightgrey",
    height: "40px",
  },

  // Dropdown Styles
  dropdownButton: {
    display: "block",
    width: "100%",
    padding: "10px",
    border: "none",
    background: "transparent",
    cursor: "pointer",
    textAlign: "left",
    "&:hover": {
      background: "#ff2600 !important",
    },
  },

  // Mobile and Tablet Menu Styles
  mobileMenu: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FF2600",
    position: "absolute",
    top: "50px", // Adjust to sit below the navbar
    width: "100%",
    zIndex: 1000,
  },

  // Responsive styles
  hamburgerMenu: {
    display: "block", // Show hamburger icon on mobile and tablet
    [useTheme().breakpoints.up("md")]: {
      display: "none", // Hide on larger screens
    },
  },
}));

export default useStyles;
